import React, { useContext, useEffect, useState } from "react";
import { ProjectContext } from "../../context/ProjectContext/ProjectContextProvider";
import DepartmentsTable from "./DepartmentsTable";
import { DropdownAPIsContext } from "../../context/DropdownAPIsContext/DropdownAPIsContextProvider";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import { VARIABLES } from "../../Constants";

function Departments() {
  const [rawProjectData, setRawProjectData] = useState([]);
  const {
    departmentData,
    apiGetDepartments,
    showAllDepartments,
    setShowAllDepartments,
    isProjectLoading,
  } = useContext(ProjectContext);
  const { st } = useContext(DropdownAPIsContext);
  const privileges = secureLocalStorage.getItem("privileges");
  useEffect(() => {
    setRawProjectData(departmentData);
  }, [departmentData]);

  const apiProjectReport = async () => {
    try {
      const res = await axios.get(
        VARIABLES.url + "/api-app/project-report?category_id=" + st
      );
      const report = res.data;
      const fileLink = res.data.data;
      const link = document.createElement("a");
      link.href = fileLink;
      link.setAttribute("download", "filename.xlsx");

      // Append the link to the document
      document.body.appendChild(link);

      // Trigger the click event on the link to start the download
      link.click();

      // Remove the link from the document after the download is initiated
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    apiGetDepartments(1, 10);
  }, [st]);

  return (
    <>
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            <h5 className="fw-bold py-3 mb-0">Departments</h5>
            <div className="d-flex">
              {privileges.includes("show_all_departments") ? (
                <div className="d-flex py-2 me-2 project-tab flex-wrap w-sm-100">
                  <button
                    type="button"
                    className="create-project btn btn-dark w-sm-100"
                    onClick={() => setShowAllDepartments(!showAllDepartments)}
                    disabled={isProjectLoading}
                  >
                    {!showAllDepartments ? "Show All" : "Show Users"}
                  </button>
                </div>
              ) : (
                ""
              )}
              <div className="d-flex py-2 project-tab sub-project-tab flex-wrap w-sm-100">
                <button
                  type="button"
                  className="btn btn-dark w-sm-100"
                  onClick={() => apiProjectReport()}
                >
                  <i className="icofont-download me-2 fs-6"></i> Download
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DepartmentsTable rawProjectData={rawProjectData} />
    </>
  );
}

export default Departments;
