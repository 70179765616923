import axios from "axios";
import React, { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { useState } from "react";
import { VARIABLES } from "../../Constants";
import Swal from "sweetalert2";
import ResourceAllocation from "./ResourceAllocation";
import Select from "react-select";
import moment from "moment";
import secureLocalStorage from "react-secure-storage";
import ReactPaginate from "react-paginate";
import { DropdownAPIsContext } from "../../context/DropdownAPIsContext/DropdownAPIsContextProvider";
import CopyToClipboard from "react-copy-to-clipboard";

const ResourceMappingTable = (props) => {
  const privileges = secureLocalStorage.getItem("privileges");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const [projectDetails, setprojectDetails] = useState([]);

  const subProjectForProject = props.subProject.filter((api) => {
    return api.project.id == projectDetails.value;
  });

  // Search Filter OnChange from input field
  const [filterResourceMapping, setFilterResourceMapping] = useState([]);

  // OnCHnage Event to get input value
  const onChangeHandler = (event) => {
    let filterResourceMappingData;
    if (event.target.value === "") {
      filterResourceMappingData = props.rawResourceMappingData;
    } else {
      filterResourceMappingData = props.rawResourceMappingData.filter(
        (item) =>
          item.project.project_name
            .toLowerCase()
            .includes(event.target.value) ||
          item?.employee?.username.toLowerCase().includes(event.target.value)
      );
    }
    setFilterResourceMapping(filterResourceMappingData);
  };

  const deleteProject = (id) => {
    var config = {
      method: "delete",
      url: VARIABLES.url + `/api-app/resource-mapping/${id}`,
      headers: {},
    };
    axios(config)
      .then((response) => {
        Swal.fire({
          text: "Resource Mapping Deleted Successfully!",
          icon: "success",
        });
        props.setRawResourceMappingData(
          props.rawResourceMappingData.filter((user) => {
            return user.id !== id;
          })
        );
      })
      .catch((error) => {
        Swal.fire({
          text: "Failed to Deleted Resource mapping!",
          icon: "warning",
        });
        console.log(error);
      });
  };

  const deleteProjectAlert = (id) => {
    Swal.fire({
      title: "Are you sure? You will not be able to recover this map!",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteProject(id);
      }
    });
  };

  const onSubmit = (data) => {
    const currStartDate = moment().format();

    // To get End Date in yyyy-mm-ddThh:mm format
    const currEndDate = currStartDate;

    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), 0, 1);
    var days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));

    var weekNumber = Math.ceil(days / 7);

    var resourceMapping = JSON.stringify({
      project_id: projectDetails.value,
      sub_project_id: data.sub_project.value,
      employee_id: data.employee.value,
      week_number: weekNumber,
      target: data.target,
      release: props.editedResourceMapping.release,
      checklist: props.editedResourceMapping.checklist,
      start_date: currStartDate,
      end_date: currEndDate,
      hours: data.hours,
      created_by_id: secureLocalStorage.getItem("userID"),
      updated_by_id: secureLocalStorage.getItem("userID"),
    });

    var config = {
      method: "patch",
      url:
        VARIABLES.url +
        `/api-app/resource-mapping/${props.editedResourceMapping.id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: resourceMapping,
    };

    axios(config)
      .then((response) => {
        Swal.fire({
          title: "Success!",
          text: "Mapping Resource Updated Successfully",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => props.apiRawResourceMapping());
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Error",
          text: "Failed to Update Mapping Resource",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
    reset();
    setprojectDetails("");
    props.setEditId(null);
  };

  useEffect(() => {
    if (props.editedResourceMapping) {
      // To get Start Date in yyyy-mm-ddThh:mm format
      const currStartDate = moment(
        props.editedResourceMapping.start_date
      ).format("yyyy-mm-DDTHH:MM");

      // To get End Date in yyyy-mm-ddThh:mm format
      const currEndDate = moment(props.editedResourceMapping.end_date).format(
        "yyyy-mm-DDTHH:MM"
      );

      var defaultValues = {};
      defaultValues.week_number = props.editedResourceMapping.week_number;
      defaultValues.target = props.editedResourceMapping.target;
      defaultValues.release = props.editedResourceMapping.release;
      defaultValues.checklist = props.editedResourceMapping.checklist;
      defaultValues.hours = props.editedResourceMapping.hours;
      defaultValues.start_date = currStartDate;
      defaultValues.end_date = currEndDate;
      const getProjectName = {
        value: props.editedResourceMapping.project.id,
        label: props.editedResourceMapping.project.projectName,
      };
      defaultValues.project = getProjectName;
      setprojectDetails(getProjectName);

      const getEmployeeName = {
        value: props.editedResourceMapping.employee.id,
        label: props.editedResourceMapping?.employee?.username,
      };
      defaultValues.employee = getEmployeeName;

      if (props.editedResourceMapping.sub_project === null) {
      }
      if (props.editedResourceMapping.sub_project) {
        const getSubProject = {
          value: props.editedResourceMapping.sub_project.id,
          label: props.editedResourceMapping.sub_project.name,
        };
        defaultValues.sub_project = getSubProject;
      }

      reset(defaultValues);
    }
  }, [props.editedResourceMapping]);

  useEffect(() => {
    setFilterResourceMapping(props.rawResourceMappingData);
  }, [props.rawResourceMappingData]);

  return (
    <>
      <div className="row align-item-center">
        <div className="col-md-12">
          <div className="card mb-3">
            <div className="card-header py-3 bg-transparent border-bottom-0">
              {/* <h6 className="mb-0 fw-bold ">Resource Mapping Table</h6> */}
              <div className="filter">
                <input
                  type="text"
                  placeholder="Search by Project/Employee..."
                  className="form-control"
                  onChange={onChangeHandler}
                />
              </div>
            </div>
            {props.isLoading ? (
              <div className="card-body"> Loading... </div>
            ) : (
              <div className="card-body basic-custome-color">
                <div className="table-responsive">
                  {privileges.includes("resource_allocation") ? (
                    <ResourceAllocation
                      projectData={props.projectData}
                      employeeData={props.employeeData}
                      subProject={props.subProject}
                      apiRawResourceMapping={props.apiRawResourceMapping}
                      rawProjectData={props.rawProjectData}
                    />
                  ) : (
                    ""
                  )}

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Employee</th>
                          <th>Project</th>
                          <th>Sub Project</th>
                          <th>Target</th>
                          <th>Hours</th>
                          {[
                            "edit_resource_mapping",
                            "delete_resource_mapping",
                          ].some((privilege) =>
                            privileges.includes(privilege)
                          ) ? (
                            <th>Action</th>
                          ) : (
                            ""
                          )}
                        </tr>
                      </thead>
                      {filterResourceMapping.length === 0 && (
                        <tbody>
                          <tr>
                            <td></td>
                            <td>No data found</td>
                            <td></td>
                          </tr>
                        </tbody>
                      )}
                      {filterResourceMapping.length > 0 && (
                        <tbody>
                          {filterResourceMapping.map((task, index) => (
                            <React.Fragment key={index}>
                              {/* {
                            props.editID === task.id ? 
                            <tr>
                              <td>
                                <Controller
                                  render={({ field }) => <Select {...field} options={props.employeeData} />}
                                  defaultValue=""
                                  rules={{ required: true }}
                                  name="employee"
                                  control={control} />
                              </td>

                              <td>
                                <Controller
                                  defaultValue=""
                                  name="project"
                                  control={control}
                                  rules={{ required: true }}
                                  render={({ field }) =>
                                    <Select
                                      {...field}
                                      value={projectDetails}
                                      onChange={(data) => {
                                        setprojectDetails(data)
                                        field.onChange(data);
                                      }}
                                      options={props.projectData} />}
                                />
                              </td>

                              <td>
                                <Controller
                                  render={({ field }) => <Select {...field}
                                    options={subProjectForProject.map((userData) => ({
                                      value: userData.id,
                                      label: userData.name,
                                    }))} />}
                                  defaultValue=""
                                  name="sub_project"
                                  control={control} />
                              </td>
                              <td>
                                <input {...register("target", { required: true })} className="form-control" placeholder='enter target' />
                              </td>
                              <td>
                                <input {...register("hours", { required: true })} className="form-control" placeholder='enter hours' />
                              </td>
                              <td>
                                <button type='submit' className='btn btn-outline-primary'> <i className="icofont-plus text-success"></i> </button>
                              </td>

                            </tr> :  */}
                              <tr>
                                <td>
                                  <span className="fw-bold">
                                    {" "}
                                    {task?.employee?.username}
                                  </span>
                                </td>
                                <td> {task.project.project_name} </td>
                                <td>
                                  <span className="ms-1">
                                    {" "}
                                    {task.sub_project == null
                                      ? "-"
                                      : task.sub_project.name}{" "}
                                  </span>
                                </td>
                                <td> {task.target} </td>
                                <td> {task.hours} </td>
                                <td>
                                  <div
                                    className="btn-group"
                                    role="group"
                                    aria-label="Basic outlined example"
                                  >
                                    {privileges.includes(
                                      "edit_resource_mapping"
                                    ) ? (
                                      <button
                                        type="button"
                                        className="btn btn-outline-secondary"
                                        data-bs-toggle="modal"
                                        data-bs-target="#depedit"
                                        onClick={() =>
                                          props.editResourceMappingData(task.id)
                                        }
                                      >
                                        <i className="icofont-edit text-success"></i>
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                    {/* {privileges.includes('edit_resource_mapping') ? <button type="button" className="btn btn-outline-secondary" onClick={() => props.editResourceMappingData(task.id)}><i className="icofont-edit text-success"></i></button> : "" } */}
                                    {privileges.includes(
                                      "delete_resource_mapping"
                                    ) ? (
                                      <button
                                        type="button"
                                        className="btn btn-outline-secondary deleterow"
                                        onClick={() =>
                                          deleteProjectAlert(task.id)
                                        }
                                      >
                                        <i className="icofont-ui-delete text-danger"></i>
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                    {privileges.includes(
                                      "delete_resource_mapping"
                                    ) ? (
                                      <div className="btn-group dropstart">
                                        <button
                                          type="button"
                                          className="btn btn-outline-secondary"
                                          data-bs-toggle="dropdown"
                                          data-bs-display="static"
                                        >
                                          <i className="bi bi-three-dots-vertical"></i>
                                        </button>

                                        <ul className="dropdown-menu dropdown-menu-end dropdown-menu-lg-start">
                                          <li>
                                            <CopyToClipboard
                                              text={`Hello ${
                                                task?.employee?.first_name
                                              },\n\nFollowing are your targets for this week,\n\n${
                                                task?.project?.project_name
                                              }\n\n\t•  ${task?.target} ${
                                                task?.target_type
                                                  ? "- " + task?.target_type
                                                  : ""
                                              }\n\nIf you have any questions or need assistance, feel free to reach out.\n\nBest regards,\n${
                                                task?.created_by?.first_name +
                                                " " +
                                                task?.created_by?.last_name
                                              }`}
                                            >
                                              <button
                                                className="dropdown-item"
                                                type="button"
                                              >
                                                Copy template
                                              </button>
                                            </CopyToClipboard>
                                          </li>
                                          <li>
                                            <CopyToClipboard
                                              text={`${
                                                task?.project?.project_name
                                              }\n\n\t•  ${task?.target} ${
                                                task?.target_type
                                                  ? "- " + task?.target_type
                                                  : ""
                                              }`}
                                            >
                                              <button
                                                className="dropdown-item"
                                                type="button"
                                              >
                                                Copy target
                                              </button>
                                            </CopyToClipboard>
                                          </li>
                                        </ul>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </td>
                              </tr>
                              {/* } */}
                            </React.Fragment>
                          ))}
                        </tbody>
                      )}
                    </table>
                    <div className="d-flex justify-content-center justify-content-md-between mt-3">
                      <ReactPaginate
                        key={3}
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        pageCount={props.noPages}
                        initialPage={props.currentPage - 1}
                        marginPagesDisplayed={2}
                        disableInitialCallback={true}
                        // pageRangeDisplayed={3}
                        onPageChange={(selectedPage) => {
                          // setCurrentPage(selectedPage.selected + 1);
                          props.apiRawResourceMapping(
                            selectedPage.selected + 1,
                            props.entire
                          );
                        }}
                        containerClassName={
                          "pagination justify-content-center justify-content-md-start"
                        }
                        pageClassName={"paginate_button page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"paginate_button page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />

                      <div className="d-flex justify-content-center align-items-center">
                        <div className="me-3">No of entries per page:</div>
                        <div>
                          <select
                            className="form-select"
                            value={props.entire}
                            onChange={(e) => {
                              props.setEntries(e.target.value);
                              props.apiRawResourceMapping(
                                props.currentPage,
                                e.target.value
                              );
                            }}
                          >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <!-- Row End --> */}
    </>
  );
};

export default ResourceMappingTable;
