import React from "react";
import { Doughnut } from "react-chartjs-2";

export const options = {
  cutout: 90,
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: "bottom",
    },
  },
};

const ProjectInfoModal = ({ projects }) => {
  console.log(projects);
  const data = {
    labels: projects.map((p) => p?.project__project_name),
    // [Year in array => Year1 , Year2 , Year3]
    datasets: [
      {
        data:
          projects.length === 0 ? (
            <p>Loading...</p>
          ) : (
            projects.map((p) => p?.total_hours)
          ),
        // [Population in array =>  745674, 758758, 758888...]
        backgroundColor: [
          "#8094ae", // Existing color
          "#ffa353", // Existing color
          "#1ee0ac", // Existing color
          "#ff6384", // Light Red
          "#36a2eb", // Light Blue
          "#ffcd56", // Yellow
          "#4bc0c0", // Teal
          "#9966ff", // Purple
          "#c9cbcf", // Light Grey
          "#f67280", // Coral Pink
          "#6c5ce7", // Light Purple
          "#fdcb6e", // Light Orange
          "#00b894", // Mint Green
          "#e17055", // Burnt Orange
        ],
        borderWidth: 3,
      },
    ],
  };
  return (
    <div className="card shadow">
      <div className="card-header">
        <div className="card-title">
          <h5>Current Projects</h5>
        </div>
      </div>
      <div className="card-body basic-custome-color p-0 m-0">
        {projects?.length !== 0 ? (
          <Doughnut data={data} options={options} />
        ) : (
          <p className="card-header">No data found</p>
        )}
      </div>
      {/* <div className="card-body basic-custome-color p-0 m-0">
        <div className="table-responsive" style={{ maxHeight: "300px" }}>
          <table className="table">
            <thead>
              <tr
                className="bg-light"
                style={{
                  position: "sticky",
                  top: 0,
                }}
              >
                <th className="text-center">Project</th>
                <th className="text-center">Hours</th>
              </tr>
            </thead>
            {projects?.length === 0 && (
              <tbody>
                <tr>
                  <td></td>
                  <td>No data found</td>
                  <td></td>
                </tr>
              </tbody>
            )}
            <tbody>
              {projects &&
                projects.map((project, i) => (
                  <tr key={i}>
                    <td className="text-center">
                      {project?.project__project_name}
                    </td>
                    <td className="text-center">{project?.total_hours}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div> */}
    </div>
  );
};

export default ProjectInfoModal;
