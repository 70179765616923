import React, { useContext, useEffect, useRef } from "react";
import { NotificationsContext } from "../../context/Notifications/NotificationsContextProvider";
import { Link } from "react-router-dom";

const Notification = () => {
  const {
    formatedNotifications,
    unreadNotificationsCount,
    handleNotificationStatus,
    updateNotificationStatus,
    show,
    setShow,
  } = useContext(NotificationsContext);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShow]);

  return (
    <div ref={dropdownRef} className="dropdown notifications zindex-popover">
      <button
        type="button"
        className="btn btn-sm btn-primary position-relative me-3 mt-1"
        style={{ borderRadius: "50%" }}
        data-bs-toggle="dropdown"
        data-bs-display="static"
        data-bs-auto-close="outside"
        onClick={() => setShow(!show)}
      >
        <i className="bi bi-bell-fill text-light"></i>
        {unreadNotificationsCount !== 0 && (
          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
            {unreadNotificationsCount <= 99
              ? unreadNotificationsCount.toString()
              : "99+"}
            <span className="visually-hidden">unread messages</span>
          </span>
        )}
      </button>
      <div
        id="NotificationsDiv"
        className="dropdown-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-xxl-end p-0 mt-1"
      >
        <div className="card border-0 w380">
          <div className="card-header border-0 p-3">
            <h5 className="mb-0 font-weight-light d-flex align-items-center justify-content-between">
              <span>Notifications</span>
              <button
                className="btn btn-sm ms-auto me-1 border-0 p-0"
                onClick={updateNotificationStatus}
                style={{
                  fontWeight: "bolder",
                  fontSize: "1.2em",
                  color: "white",
                }}
              >
                <i className="bi bi-arrow-repeat"></i>
              </button>
            </h5>
          </div>
          <div className="tab-content card-body">
            <div className="tab-pane fade show active">
              <ul className="list-unstyled list mb-0">
                {formatedNotifications.length !== 0 ? (
                  formatedNotifications.map((n, i) => {
                    return (
                      <li
                        className="py-2 mb-1 border-bottom"
                        key={i}
                        onClick={() => handleNotificationStatus(n.id)}
                      >
                        <a href="javascript:void(0);" className="d-flex">
                          <div className="flex-fill ms-2">
                            <p className="d-flex justify-content-between align-items-center mb-0 ">
                              <span className="font-weight-bold">
                                {n.title}
                              </span>
                              <span
                                className="badge"
                                style={{
                                  backgroundColor: n?.is_read ? "green" : "red",
                                }}
                              >
                                {n?.is_read ? "Read" : "Unread"}
                              </span>
                            </p>
                            <p className="mb-0">{n.message}</p>
                            <small>{n.created_at}</small>
                          </div>
                        </a>
                      </li>
                    );
                  })
                ) : (
                  <li className="py-2 mb-1 border-bottom">
                    <a href="javascript:void(0);" className="d-flex">
                      <div className="flex-fill ms-2">
                        <p
                          className="text-center"
                          style={{ fontSize: "2em", color: "gray" }}
                        >
                          Empty
                        </p>
                      </div>
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <Link
            to="/notifications"
            className="card-footer text-center border-top-0"
            href="#"
          >
            View all notifications
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Notification;
