// Default page, all the necessary details available here
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import TargetCard from "./TargetCard";
import FillTimesheet from "../Forms/FillTimesheet";
import TimesheetID from "./TimesheetID";
import { VARIABLES } from "../../Constants";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import moment from "moment";
import WellnessQuote from "../Base/WellnessQuote";
import { bday } from "../TaskManagement/Data";
import LeaveAvaliableChart from "./LeaveAvaliableChart";
import Slider from "react-slick";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BirthdayList from "./BirthdayList";
import { useNavigate } from "react-router-dom";
import { DropdownAPIsContext } from "../../context/DropdownAPIsContext/DropdownAPIsContextProvider";
import UserImagePopper from "../popper-drawer/UserImagePopper";
import Signals from "../Signals/Signals";
// import Bday from '../../../public/assets/images/birthday-bg.png'

export const NoticeBoard = (props) => {
  // State initialization
  const [loading, setLoading] = useState(false);
  // const [list, setList] = useState([]);
  const [taskDropdown, setTaskDropdown] = useState([]);
  const [projectDropdown, setProjectDropdown] = useState([]);
  const [taskLoading, setTaskLoading] = useState(false);
  const [projectLoading, setProjectLoading] = useState(false);
  const [updateCard, setUpdateCard] = useState(false);
  const [notices, setNotices] = useState([]);
  const [birthDays, setBirthDays] = useState([]);
  const navigate = useNavigate();
  const privileges = secureLocalStorage.getItem("privileges");
  const quoteDisplayed = localStorage.getItem("quoteDisplayed");
  const { taskByIdDropdown, projectByIdDropdown, loadingGenericById } =
    useContext(DropdownAPIsContext);
  let month = moment().format("MMMM");

  const statusList = [
    { value: "", label: "All" },
    { value: false, label: "On Going" },
    { value: true, label: "Completed" },
  ];

  // Format for options in react-select
  const [statusFilter, setStatusFilter] = useState({ value: "", label: "All" });

  const loadNotices = async () => {
    setLoading(true);
    const userID = secureLocalStorage.getItem("userID");

    const url = VARIABLES.url + "/api-app/noticeboard?display_to=" + userID;
    let header = { "Content-Type": "application/json" };

    let reqOptions = {
      url: url,
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      mode: "no-cors",
    };

    axios.request(reqOptions).then(function (response) {
      var data = response.data;
      setNotices(data.data[0]);
      setLoading(false);
    });
  };

  const loadBirthdays = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/api-app/user-birthdays`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setBirthDays(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Toggle view is used for mobile view. (Cordova application)
  // This toggles the maincontent from NoticeBoard to TimeSheet
  const handleToggleView = () => {
    // props.toggleView("TimeSheet");
    navigate("/timesheet");
  };

  // Toggles updateCard state to trigger reload of list
  const handleUpdate = () => {
    setUpdateCard(!updateCard);
  };

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // loadCards function is called initially and
  // whenever updateCard and statusFilter states are changed

  useEffect(() => {
    // loadCards();
  }, [updateCard, statusFilter]);

  useEffect(() => {
    setProjectLoading(true);
    if (projectByIdDropdown.length !== 0) {
      setProjectDropdown(
        projectByIdDropdown.map((project) => {
          return { value: project.id, label: project.project_name };
        })
      );
      setProjectLoading(false);
    } else if (!loadingGenericById) {
      setTaskLoading(false);
    }
  }, [projectByIdDropdown, loadingGenericById]);

  useEffect(() => {
    setTaskLoading(true);
    if (taskByIdDropdown.length !== 0) {
      setTaskDropdown(
        taskByIdDropdown.map((task) => {
          return {
            value: task.id,
            label: task?.card_identification_number + "- " + task?.card_title,
          };
        })
      );
      setTaskLoading(false);
    }
    if (!loadingGenericById) {
      setTaskLoading(false);
    }
  }, [taskByIdDropdown, loadingGenericById]);

  // Following functions are called everytime the component is rendered
  useEffect(() => {
    loadNotices();
    loadBirthdays();
  }, []);

  return (
    <div>
      <WellnessQuote />
      <div className="body d-flex">
        <div className="container-xxl">
          <div className="row align-items-center">
            <div className="border-0 mb-4">
              {/* <GuidedTour handleSidebar={props.handleSidebar} /> */}
              <div className="card-header py-2 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                <h5 className="fw-bold mb-0"> Hi 👋, Welcome to Nipun</h5>
                <div className="d-flex py-2 project-tab flex-wrap w-sm-100">
                  {/* {privileges.includes('tw_planner') ? <button type="button" className="btn btn-dark mx-2 btn-set-task w-sm-100" href="https://docs.google.com/spreadsheets/d/1TqcGG0jEK4PVBAL1xt9xr1YaqWtr2maxFvY5eUc_B_I/edit?usp=sharing" ><i className="icofont-file-spreadsheet me-2 fs-6"></i>TW-Planner</button> : "" } */}

                  {privileges.includes("Kanban") ? (
                    <button
                      type="button"
                      className="view-kanban btn btn-dark mx-2 btn-set-task w-sm-100"
                      onClick={() => navigate("/kanban")}
                      id="button-m-g-01-viewtimesheets"
                    >
                      <i className="icofont-black-board me-2 fs-6"></i>Kanban
                    </button>
                  ) : (
                    ""
                  )}

                  {privileges.includes("view_timesheets") ? (
                    <button
                      type="button"
                      className="view-timesheet btn btn-dark mx-2 btn-set-task w-sm-100"
                      onClick={handleToggleView}
                      id="button-m-g-01-viewtimesheets"
                    >
                      <i className="icofont-paperclip me-2 fs-6"></i>View
                      Timesheets
                    </button>
                  ) : (
                    ""
                  )}

                  {privileges.includes("fill_timesheet") ? (
                    <button
                      type="button"
                      className="fill-timesheet btn btn-dark mx-2 btn-set-task w-sm-100"
                      data-bs-toggle="modal"
                      data-bs-target="#createtask"
                      id="button-m-g-02-filltimesheet"
                    >
                      <i className="icofont-plus-circle me-2 fs-6"></i>Fill
                      Timesheet
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row clearfix g-3 mb-4">
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="card notice-board-bg bday-card">
                <img
                  src="assets/images/birthday-bg.png"
                  className="img-fluid position-absolute bottom-0"
                  alt="birthday"
                />
                <div className="card-body" style={{ minHeight: "300px" }}>
                  <h5 className="pb-3 card-title calender-title">
                    {" "}
                    🎉 Birthday's{" "}
                    <span className="text-muted ps-2 fs-6">{month}</span>
                  </h5>
                  {birthDays.map((api, i) => {
                    return (
                      <a
                        key={i}
                        href="javascript:void(0);"
                        className="d-flex ps-3 py-2"
                      >
                        <img
                          className="avatar"
                          src="assets/images/birthday-card.png"
                          alt=""
                        />
                        <div className="flex-fill ms-3 text-truncate">
                          <h6 className="d-flex justify-content-between mb-0">
                            <span>{api.username}</span>
                          </h6>
                          <span className="text-muted">{api.dob}</span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <BirthdayList /> */}
                </div>
              </div>
              <Signals />
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="card">
                <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                  <h6 className="mb-0 fw-bold ">Total Leaves</h6>
                  {/* <h4 className="mb-0 fw-bold ">10</h4> */}
                </div>
                <div className="leavestatus card-body">
                  <LeaveAvaliableChart />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="noticeboard card mb-3 notice-board-bg">
                <div className="card-body">
                  <h5 className="notice-board-title card-title">
                    Notice board
                  </h5>
                  {loading ? (
                    <div>Loading...</div>
                  ) : (
                    <>
                      {notices.length === 0 && <div> No Notice Available</div>}
                      <Slider {...settings}>
                        {notices.map((notice, i) => {
                          return (
                            <React.Fragment key={i}>
                              <h6 className="card-text pb-1">{notice.title}</h6>
                              <div className="card-text text-muted pb-3">
                                {notice.description}
                              </div>
                              <div className="d-flex align-items-center flex-fill">
                                {/* <img
                                  src="assets/images/xs/avatar5.jpg"
                                  className="avatar md rounded-circle img-thumbnail"
                                  alt="avatar"
                                /> */}
                                <UserImagePopper
                                  id={notice?.created_by?.id}
                                  show={true}
                                />
                                <div className="d-flex ps-2 flex-column">
                                  <h6 className="card-title mb-0">
                                    {notice.created_by &&
                                      notice.created_by.username}
                                  </h6>
                                  <span className="small text-muted">
                                    {moment(notice.published_on).format(
                                      "MMMM Do YYYY"
                                    )}
                                  </span>
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        })}
                      </Slider>
                    </>
                  )}
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <h5 className="calender-title card-title">Calender</h5>
                  <Calendar />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FillTimesheet
        taskDropdown={taskDropdown}
        projectDropdown={projectDropdown}
        taskLoading={taskLoading}
        projectLoading={projectLoading}
      />
    </div>
  );
};
