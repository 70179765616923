import React from "react";

const DocumentList = (props) => {
  return (
    <>
      <div className="card">
        <div className="card-body card-body-height py-4">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <h6 className="mb-0 fw-bold mb-3">Attached Files</h6>
              {/* <div className="card">
                <div className="border-bottom">
                  <input className="form-control" placeholder="Search Attached files" />
                </div>
              </div> */}
              {/* .Card End */}
              <div className="card mt-3">
                <div className="card-body">
                  <h6 className="fw-bold mb-3">List of SOP</h6>
                  <div className="flex-grow-1">
                    {props.workflowBlockData ? (
                      props.workflowBlockData.workflow_block.sop_details.map(
                        (sop) => {
                          return (
                            <div className="py-2 d-flex align-items-center border-bottom">
                              <div className="d-flex align-items-center flex-fill">
                                <span className="avatar light-shadow-bg rounded-circle text-center d-flex align-items-center justify-content-center">
                                  <i className="icofont-file-document fs-5"></i>
                                </span>
                                <div className="d-flex flex-column ps-3">
                                  <h6 className="fw-bold mb-0 small-14">
                                    {sop.name}
                                  </h6>
                                  <p className="mb-0 small-14">
                                    {sop.generated_tag}
                                  </p>
                                </div>
                              </div>
                              <a href={sop.link} target="_blank">
                                <i className="icofont-link fs-5"></i>
                              </a>
                            </div>
                          );
                        }
                      )
                    ) : (
                      <p>Loading...</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="card mt-3">
                <div className="card-body">
                  <h6 className="fw-bold mb-3">List of Templates</h6>
                  <div className="flex-grow-1">
                    {props.workflowBlockData ? (
                      props.workflowBlockData.workflow_block.template_details.map(
                        (template) => {
                          return (
                            <div className="py-2 d-flex align-items-center border-bottom">
                              <div className="d-flex align-items-center flex-fill">
                                <span className="avatar light-shadow-bg rounded-circle text-center d-flex align-items-center justify-content-center">
                                  <i className="icofont-file-document fs-5"></i>
                                </span>
                                <div className="d-flex flex-column ps-3">
                                  <h6 className="fw-bold mb-0 small-14">
                                    {template.name}
                                  </h6>
                                  <p className="mb-0 small-14">
                                    {template.generated_tag}
                                  </p>
                                </div>
                              </div>
                              <a href={template.link} target="_blank">
                                <i className="icofont-link fs-5"></i>
                              </a>
                            </div>
                          );
                        }
                      )
                    ) : (
                      <p>Loading...</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentList;
