import React, { useEffect, useState } from "react";
import DataGrid from "react-data-grid";
// import "react-data-grid/dist/react-data-grid.css";
import { VARIABLES } from "../../Constants";
import axios from "axios";
// import "./ResourceStyles.css";

function ResourceSpreadsheet() {
  const [targetData, setTargetData] = useState([]);
  const [rows, setRow] = useState([]);
  const [statusDropdown, setStatusDropdown] = useState([
    {
      value: "open",
      label: "open",
    },
    {
      value: "close",
      label: "close",
    },
    {
      value: "spillover",
      label: "spillover",
    },
    {
      value: "pending",
      label: "pending",
    },
  ]);
  const getTargetData = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: VARIABLES.url + "/api-app/target",
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setTargetData(response.data.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const columns = [
    { key: "id", name: "Sr.No" },
    {
      key: "target",
      name: "Target",
    },
    {
      key: "projectcluster",
      name: "Project Cluster",
    },
    {
      key: "red_flag",
      name: "Red Flag",
      formatter: (props) => (
        <input
          type="checkbox"
          checked={props.row.red_flag}
          // onChange={() => handlerow(props.row.id, !props.row.red_flag)}
        />
      ),
    },
    {
      key: "incharge",
      name: "Incharge",
    },
    {
      key: "type",
      name: "type",
    },
    {
      key: "weekno",
      name: "Week No",
    },
    {
      key: "status",
      name: "Status",
      formatter: (props) => (
        <select
          value={props.status}
          onChange={() => {}}
          style={{ height: "90%", width: "100%" }}
        >
          {statusDropdown.map((status, i) => {
            return (
              <option
                key={i}
                value={status.value}
                selected={props.row.status === status.value ? true : false}
                disabled
              >
                {status.label}
              </option>
            );
          })}
        </select>
      ),
    },
    {
      key: "checklist",
      name: "Check List",
    },
  ];

  useEffect(() => {
    if (targetData.length !== 0) {
      setRow(
        targetData.map((target) => {
          return {
            id: 1,
            target: target.target,
            projectcluster: target.project_cluster.project_type,
            incharge: target.incharge.username,
            weekno: target.week_number,
            status: target.status,
            checklist: target.checklist,
            red_flag: target.red_flag,
            type: target.type,
          };
        })
      );
    }
  }, [targetData]);

  useEffect(() => {
    getTargetData();
  }, []);

  return (
    <>
      <div className="border-0 mb-4">
        <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
          <h5 className="fw-bold mb-0">Resource Spreadsheet</h5>
        </div>
      </div>
      <div style={{ height: "350px" }}>
        <DataGrid
          columns={columns}
          rows={rows}
          defaultColumnOptions={{ resizable: true, sortable: true }}
        />
      </div>
    </>
  );
}

export default ResourceSpreadsheet;
