import React, { useContext } from "react";
import LeaveManagementTable from "./LeaveManagementTable";
import LeaveManagementCreate from "./LeaveManagementCreate";
import { VARIABLES } from "../../Constants";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { LeaveManagementContext } from "../../context/LeaveManagementContext/LeaveManagementProvider";

const LeaveManagement = () => {
  const privileges = secureLocalStorage.getItem("privileges");
  // const [isLoading, setIsLoading] = useState(false);
  const { isLoading, rawLeaveData, leaveType, employeeData, holidays } =
    useContext(LeaveManagementContext);
  let username = " ";
  if (secureLocalStorage.getItem("username")) {
    username = secureLocalStorage.getItem("username");
    // setUsername(username_local)
  }

  let role = " ";
  if (secureLocalStorage.getItem("role")) {
    role = secureLocalStorage.getItem("role");
  }

  const userID = secureLocalStorage.getItem("userID");

  const filterUsernameData = leaveType.find((data) => {
    return data.label === "Sick leave";
  });

  const desiredOptions = leaveType.filter((option) => {
    return option.label === "Sick leave" || option.label === "Casual leave";
  });

  return (
    <>
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            <h5 className="fw-bold mb-0">Leaves</h5>
            {privileges.includes("apply_leave_request") ? (
              <div className="col-auto d-flex w-sm-100">
                <button
                  type="button"
                  className="apply-leave btn btn-dark btn-set-task w-sm-100"
                  data-bs-toggle="modal"
                  data-bs-target="#leaveadd"
                >
                  <i className="icofont-plus-circle me-2 fs-6"></i>Apply Leave
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <LeaveManagementTable
        rawLeaveData={rawLeaveData}
        isLoading={isLoading}
        holidays={holidays}
      />

      <LeaveManagementCreate
        employeeData={employeeData}
        leaveType={leaveType}
        // apiGetLeaveData={apiGetLeaveData}
        role={role}
        username={username}
        desiredOptions={desiredOptions}
        filterUsernameData={filterUsernameData}
      />
    </>
  );
};

export default LeaveManagement;
