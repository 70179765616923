import React, { useContext, useEffect, useState } from "react";
import { ProjectCardContext } from "../../context/ProjectCardsContext/ProjectCardsContextProvider";
import Select from "react-select";
import CardDrawer from "./CardDrawer";
import { VARIABLES } from "../../Constants";
import axios from "axios";
import Swal from "sweetalert2";
import secureLocalStorage from "react-secure-storage";
import { DropdownAPIsContext } from "../../context/DropdownAPIsContext/DropdownAPIsContextProvider";

function ProjectCardsListView() {
  const {
    isLoading,
    currentBoard,
    setCurrentBoard,
    projectCardsList,
    getProjectCardsList,
  } = useContext(ProjectCardContext);
  const [boardsList, setBoardsList] = useState([]);
  const { boards } = useContext(DropdownAPIsContext);
  const [filterProjectCard, setFilterProjectCard] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const privileges = secureLocalStorage.getItem("privileges");
  const handleSelectedBoard = (data) => {
    setCurrentBoard({ id: data.value, name: data.label });
  };
  const onChangeHandler = (event) => {
    if (event.target.value === "") {
      setFilterProjectCard(projectCardsList);
      return;
    }
    let filterProjectCardData = projectCardsList.filter((item) =>
      item.card_title.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setFilterProjectCard(filterProjectCardData);
  };

  const deleteProjectCard = (id) => {
    Swal.fire({
      title: "Are you sure? You will not be able to recover this project!",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        let config = {
          method: "delete",
          maxBodyLength: Infinity,
          url: `${VARIABLES.url}/api-app/project-card/${id}`,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            getProjectCardsList(currentBoard.id);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  useEffect(() => {
    if (boards.length !== 0) {
      setBoardsList(
        boards.map((board) => {
          return {
            value: board.id,
            label: board.name,
          };
        })
      );
    }
  }, [boards]);

  useEffect(() => {
    setFilterProjectCard(projectCardsList);
  }, [projectCardsList]);

  useEffect(() => {
    if (currentBoard !== null) {
      getProjectCardsList(currentBoard.id);
    }
  }, [currentBoard]);

  return (
    <>
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            <h5 className="fw-bold py-3 mb-0">Project Cards</h5>
            <div className="d-flex">
              {currentBoard !== null && (
                <>
                  <div className="me-3">
                    {privileges.includes("create_project_cards") ? (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          setIsOpen(!isOpen);
                        }}
                      >
                        Create Project Card
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                  <Select
                    options={boardsList}
                    defaultValue={{
                      value: currentBoard.name,
                      label: currentBoard.name,
                    }}
                    onChange={handleSelectedBoard}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row g-3 mb-3 row-deck">
        <h5>{currentBoard?.name}</h5>
        <div className="col-md-12">
          <div className="card mb-3">
            <div className="card-body">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  <i className="fa fa-search" aria-hidden="true"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by Project Name/Incharge Name"
                  onChange={onChangeHandler}
                />
              </div>
              {isLoading ? (
                <div className="card-body">Loading...</div>
              ) : (
                <div className="table-responsive">
                  <table
                    id="myProjectTable"
                    className="table table-hover align-middle mb-0"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th>Sr. No</th>
                        {/* <th>Project Id</th> */}
                        {/* <th>Is Converted</th> */}
                        {/* <th>Date Start</th> */}
                        {/* <th>Deadline</th> */}
                        <th>Task Name</th>
                        {/* <th>Completion</th> */}
                        {/* {privileges.includes("view_latest_releases") ? ( */}
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Status</th>
                        {/* ) : (
                          ""
                        )} */}

                        {/* {privileges.includes("edit_project_card") ? ( */}
                        <th>Edit</th>
                        {/* ) : (
                          ""
                        )} */}
                        {/* {privileges.includes("delete_project_card") ? ( */}
                        <th>Delete</th>
                        {/* ) : (
                          ""
                        )} */}
                      </tr>
                    </thead>
                    {filterProjectCard.length === 0 && (
                      <tbody>
                        <tr>
                          <td></td>
                          <td>No data found</td>
                          <td></td>
                        </tr>
                      </tbody>
                    )}
                    {filterProjectCard.length > 0 && (
                      <tbody>
                        {filterProjectCard.map((board, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{board.card_title}</td>
                              <td>{board.start_time}</td>
                              <td>{board.end_time}</td>
                              <td>{board.current_status}</td>
                              {privileges.includes("edit_project_card") ? (
                                <td>
                                  <button
                                    type="button"
                                    className="btn"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                    style={{ color: "green" }}
                                    onClick={() => {
                                      setIsOpen(!isOpen);
                                      const obj = {
                                        ...board,
                                        dependencies: board.dependencies.map(
                                          (e) => e.id.toString()
                                        ),
                                      };
                                      setCurrentItem(obj);
                                    }}
                                  >
                                    <i className="icofont-edit"></i>
                                  </button>
                                </td>
                              ) : (
                                ""
                              )}
                              {privileges.includes("delete_project_card") ? (
                                <td>
                                  <button
                                    type="button"
                                    className="btn"
                                    style={{ color: "red" }}
                                    onClick={() => deleteProjectCard(board.id)}
                                  >
                                    <i className="icofont-ui-delete"></i>
                                  </button>
                                </td>
                              ) : (
                                ""
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <CardDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        currentItem={currentItem}
        setCurrentItem={setCurrentItem}
        currentBoard={currentBoard}
      />
    </>
  );
}

export default ProjectCardsListView;
