import React, { useState } from "react";
import axios from "axios";
import { Controller, useForm, useFormState } from "react-hook-form";
import Select from "react-select";
import Swal from "sweetalert2";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";

const TechnicalRolesCreate = (props) => {
  // TODO : Once we get the user credentials, uncomment it.
  const userID = secureLocalStorage.getItem("userID");

  // react hook form
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const { isValid } = useFormState({
    control,
  });

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    var form_data = JSON.stringify({
      name: data.name,
      description: data.description,
      created_by_id: userID,
      updated_by_id: userID,
    });

    // TODO : Check the API properly from postman
    const url = VARIABLES.url + "/api-app/label";

    var config = {
      method: "post",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: form_data,
    };

    axios(config)
      .then((response) => {
        Swal.fire({
          title: "Success!",
          text: "Tag Created Successfully",
          icon: "success",
          confirmButtonText: "OK",
        });
        props.getProjectTags();
      })
      .catch((error) => {
        console.log(error.response);
        Swal.fire({
          title: "Error",
          text:
            Object.keys(error.response.data)[0] +
            " : " +
            error.response.data[Object.keys(error.response.data)[0]],
          icon: "error",
          confirmButtonText: "OK",
        });
      });
    reset();
  };

  const getResetValues = () => {
    reset();
  };

  return (
    <>
      <div
        className="modal fade"
        id="addProjectTag"
        tabIndex="-1"
        data-bs-backdrop="static"
        aria-labelledby="depaddLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title fw-bold" id="depaddLabel">
                Create Project Tag
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => getResetValues()}
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="row g-4">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <label className="form-label">
                      {" "}
                      Tag Name
                      <span className="required_field">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter The Tag Name Here..."
                      {...register("name", { required: true })}
                    />
                    {errors.name && (
                      <span className="required_field">
                        Tag Name is required
                      </span>
                    )}
                  </div>

                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <label className="form-label">
                      description of Tag{" "}
                      <span className="required_field">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter The Tag Description Here..."
                      {...register("description", { required: true })}
                    />
                    {errors.description && (
                      <span className="required_field">
                        Description is required
                      </span>
                    )}
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      {isValid === true ? (
                        <button
                          type="submit"
                          className="btn btn-lg btn-primary"
                          data-bs-dismiss="modal"
                        >
                          Submit
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-lg btn-primary"
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TechnicalRolesCreate;
