import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import EditProjects from "./EditProjects";
import AddProjects from "./AddProjects";
import { VARIABLES } from "../../Constants";
import ProjectTable from "./ProjectTable";
import secureLocalStorage from "react-secure-storage";
import { ProjectContext } from "../../context/ProjectContext/ProjectContextProvider";
import { UsersContext } from "../../context/UsersContext/UsersContextProvider";
import { DropdownAPIsContext } from "../../context/DropdownAPIsContext/DropdownAPIsContextProvider";

const Projects = (props) => {
  const privileges = secureLocalStorage.getItem("privileges");
  // After Clicking on Edit button specific "id" for row stores in "editId" state
  const [editId, setEditId] = useState(null);
  const [editedProjectData, setEditedProjectData] = useState(null);
  const { projectData, showAllProjects, setShowAllProjects, isProjectLoading } =
    useContext(ProjectContext);
  const { users } = useContext(UsersContext);
  const {
    projectManagementType,
    projectFlagStatus,
    projectWorkflowType,
    projectCategory,
    op,
  } = useContext(DropdownAPIsContext);

  // "rawProjectData" stores the raw Data of Projects in state
  const [rawProjectData, setRawProjectData] = useState([]);

  // "projectIncharge" stores the id and username of incharge and pass in dropdown
  const [projectIncharge, setProjectIncharge] = useState([]);

  // "subCompany" stores the id and name and pass in dropdown
  const [subDivision, setSubDivision] = useState([]);

  // "projectCluster" stores the id and name and pass in dropdown
  const [projectManagement, setProjectManagement] = useState([]);

  // "category" stores the id and name and pass in dropdown
  const [categoryType, setCategoryType] = useState();

  // "devStackDetails" stores the id and name and pass in dropdown
  const [workflowType, setWorkflowType] = useState();

  const [tags, setTags] = useState([]);

  const [flags, setFlags] = useState([]);

  const apiProjectReport = async () => {
    try {
      const res = await axios.get(
        VARIABLES.url + "/api-app/project-report?category_id=" + op
      );
      const report = res.data;
      const fileLink = res.data.data;
      const link = document.createElement("a");
      link.href = fileLink;
      link.setAttribute("download", "filename.xlsx");

      // Append the link to the document
      document.body.appendChild(link);

      // Trigger the click event on the link to start the download
      link.click();

      // Remove the link from the document after the download is initiated
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };

  // API call to store Organization details in "organizationDetails" state
  const getDivision = async () => {
    try {
      const res = await axios.get(VARIABLES.url + "/api-app/subcompany");
      setSubDivision(
        res.data.map((div) => ({
          value: div.id,
          label: div.name,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getProjectTags = async () => {
    try {
      // change
      const res = await axios.get(VARIABLES.url + "/api-app/label");
      setTags(
        res.data.map((tag) => {
          return {
            value: tag.id,
            label: tag.name,
          };
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  // Define a function to select a project based on the ID
  const getEditedProjectData = (id) => {
    const project = rawProjectData.find((p) => p.id == id);
    setEditedProjectData(project);
    setEditId(id);
  };

  useEffect(() => {
    if (projectCategory.length !== 0) {
      setCategoryType(
        projectCategory.map((div) => ({
          value: div.id,
          label: div.name,
        }))
      );
    }
  }, [projectCategory]);

  useEffect(() => {
    if (projectManagementType.length !== 0) {
      setProjectManagement(
        projectManagementType.map((projectType) => {
          return {
            value: projectType.name,
            label: projectType.name,
          };
        })
      );
    }
  }, [projectManagementType]);

  useEffect(() => {
    if (projectWorkflowType.length !== 0) {
      setWorkflowType(
        projectWorkflowType.map((type) => {
          return {
            value: type.name,
            label: type.name,
          };
        })
      );
    }
  }, [projectWorkflowType]);

  useEffect(() => {
    if (projectFlagStatus.length !== 0) {
      setFlags(
        projectFlagStatus.map((tag) => {
          return {
            value: tag.id,
            label: tag.name,
          };
        })
      );
    }
  }, [projectFlagStatus]);

  useEffect(() => {
    setRawProjectData(projectData);
  }, [projectData]);

  useEffect(() => {
    if (users.length !== 0) {
      setProjectIncharge(users);
    }
  }, [users]);

  useEffect(() => {
    getDivision();
    getProjectTags();
  }, []);

  return (
    <>
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            <h5 className="fw-bold py-3 mb-0">Projects</h5>
            <div className="d-flex">
              {privileges.includes("create_project") ? (
                <div className="d-flex py-2 me-2 project-tab flex-wrap w-sm-100">
                  <button
                    type="button"
                    className="create-project btn btn-dark w-sm-100"
                    data-bs-toggle="modal"
                    data-bs-target="#createproject"
                  >
                    <i className="icofont-plus-circle me-2 fs-6"></i>Create
                    Project
                  </button>
                </div>
              ) : (
                ""
              )}
              {/* {privileges.includes("create_project") ? (
                <div className="d-flex py-2 me-2 project-tab flex-wrap w-sm-100">
                  <a
                    type="button"
                    link="#"
                    className="create-project btn btn-dark w-sm-100"
                  >
                    <i className="bi bi-gear-wide me-2 fs-6"></i> Workflow
                  </a>
                </div>
              ) : (
                ""
              )} */}
              <div className="d-flex py-2 project-tab sub-project-tab flex-wrap w-sm-100">
                <button
                  type="button"
                  className="btn btn-dark w-sm-100"
                  onClick={() => apiProjectReport()}
                >
                  <i className="icofont-download me-2 fs-6"></i> Download
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Project Table Display */}
      <ProjectTable
        getEditedProjectData={getEditedProjectData}
        rawProjectData={rawProjectData}
        setRawProjectData={setRawProjectData}
        handleSidebar={props.handleSidebar}
        setProjectId={props.setProjectId}
        setProjectDetails={props.setProjectDetails}
        categoryType={categoryType}
      />

      {/* Project Edit Modal Pop Up */}
      <EditProjects
        project={editedProjectData}
        projectIncharge={projectIncharge}
        subDivision={subDivision}
        categoryType={categoryType}
        workflowType={workflowType}
        projectManagement={projectManagement}
        tags={tags}
        flags={flags}
      />

      {/* Project Create Modal Pop Up */}
      <AddProjects
        projectIncharge={projectIncharge}
        subDivision={subDivision}
        categoryType={categoryType}
        workflowType={workflowType}
        projectManagement={projectManagement}
        setProjectId={props.setProjectId}
        projectId={props.projectId}
        tags={tags}
        flags={flags}
      />
    </>
  );
};

export default Projects;
