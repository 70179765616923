import React, { useContext, useEffect } from "react";
import axios from "axios";
import { Controller, useForm, useFormState } from "react-hook-form";
import Select from "react-select";
import { VARIABLES } from "../../Constants";
import Swal from "sweetalert2";
import { useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { DropdownAPIsContext } from "../../context/DropdownAPIsContext/DropdownAPIsContextProvider";

const TicketGenerateEdit = (props) => {
  const userID = secureLocalStorage.getItem("userID");
  // react hook form
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm();
  const { isDirty, isValid } = useFormState({
    control,
  });
  const { ticketOpen, ticketClosed, ticketSolved } =
    useContext(DropdownAPIsContext);

  // onSubmit handler to post form details //TODO
  const onSubmit = (data) => {
    console.log(data);
    var ticketRaised = JSON.stringify({
      ticket_description: data.ticket_desc,
      ticket_type: data.ticket_type.label,
      ticket_from: "Nipun",
      ticket_status: data?.isResolved
        ? ticketSolved
        : data?.isClosed
        ? ticketClosed
        : ticketOpen,
      updated_by_id: userID,
    });
    var config = {
      method: "patch",
      url:
        VARIABLES.url +
        `/api-app/ticket-management/${props.editedTicketGenerate.id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: ticketRaised,
    };
    axios(config)
      .then((response) => {
        Swal.fire({
          title: "Success!",
          text: "Ticket Updated Successfully",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => props.apiGetRawTicketGenerate());
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Error",
          text: "Failed to Raised Ticket",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
    reset();
  };

  //TODO
  useEffect(() => {
    if (props.editedTicketGenerate) {
      console.log(props.editedTicketGenerate);
      var defaultValues = {};
      defaultValues.ticket_desc = props.editedTicketGenerate.ticket_description;

      const getTicketType = {
        value: props.editedTicketGenerate.ticket_type,
        label: props.editedTicketGenerate.ticket_type,
      };
      defaultValues.ticket_type = getTicketType;

      // const getTicketStatus = {
      //   value: props.editedTicketGenerate.ticket_status,
      //   label: props.editedTicketGenerate.ticket_status,
      // };
      const getTicketStatus = props.ticketStatus.filter(
        (status) =>
          parseInt(status.value) ===
          parseInt(props.editedTicketGenerate.ticket_status)
      )[0];
      defaultValues.ticket_status = getTicketStatus;
      reset(defaultValues);
    }
  }, [props.editedTicketGenerate]);

  const getResetValues = () => {
    reset();
  };
  return (
    <>
      <div
        className="modal fade"
        id="editticket"
        tabIndex="-1"
        data-bs-backdrop="static"
        aria-labelledby="depaddLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title fw-bold" id="depaddLabel">
                {" "}
                Edit Ticket{" "}
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => getResetValues()}
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="mb-3">
                  <label className="form-label">
                    Ticket Description<span className="required_field">*</span>
                  </label>
                  <textarea
                    className="form-control"
                    name="ticket_desc"
                    id="ticket_desc"
                    {...register("ticket_desc", { required: true })}
                    rows="3"
                    placeholder="Ticket Description"
                  ></textarea>
                  {errors.ticket_desc && (
                    <span className="required_field">
                      Ticket Description name is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Ticket Type <span className="required_field">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={props.ticketType}
                        isClearable={true}
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="ticket_type"
                    control={control}
                  />
                  {errors.ticket_type && (
                    <span className="required_field">
                      Ticket Type name is required
                    </span>
                  )}
                </div>
                {parseInt(ticketOpen) ===
                  props.editedTicketGenerate?.ticket_status && (
                  <div className="mb-3">
                    <label className="form-label">Resolved</label>
                    <label className="fancy-checkbox parsley-success">
                      <input
                        type="checkbox"
                        id="isResolved"
                        className="form-check-input ms-2"
                        name="isResolved"
                        {...register("isResolved")}
                      />
                    </label>
                  </div>
                )}
                {parseInt(ticketSolved) ===
                  props.editedTicketGenerate?.ticket_status &&
                  props.editedTicketGenerate?.created_by?.id === userID && (
                    <div className="mb-3">
                      <label className="form-label">Closed</label>
                      <label className="fancy-checkbox parsley-success">
                        <input
                          type="checkbox"
                          id="isClosed"
                          className="form-check-input ms-2"
                          name="isClosed"
                          {...register("isClosed")}
                        />
                      </label>
                    </div>
                  )}
                <div className="modal-footer">
                  {/* <button type="submit" className="btn btn-success">Submit</button> */}
                  {isValid === true ? (
                    <button
                      type="submit"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Submit
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TicketGenerateEdit;
