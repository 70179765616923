import React, { useState } from "react";
import Select from "react-select";
import {
  Controller,
  useFieldArray,
  useForm,
  useFormState,
} from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";

function FillMissedTimesheet(props) {
  const [isOptional, setIsOptional] = useState(false);
  const current = new Date(props.fillDate);
  const current_date = `${current.getDate()}-${
    current.getMonth() + 1
  }-${current.getFullYear()}`;
  const form_date = `${current.getFullYear()}-${
    current.getMonth() + 1
  }-${current.getDate()}`;
  const lead = secureLocalStorage.getItem("lead");

  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      test: [{ task_id: "", project_id: "", hours: "" }],
    },
  });
  const { isDirty, isValid } = useFormState({
    control,
  });

  const privileges = secureLocalStorage.getItem("privileges");
  const { fields, append, remove } = useFieldArray({
    control,
    name: "test",
  });

  const onSubmit = (data) => {
    var timesheet_fill = JSON.stringify({
      timesheet_details: data.test.map((card) => {
        return {
          user_id: secureLocalStorage.getItem("userID"),
          date: form_date,
          task_id_id: card.task_id.value,
          hours: card.hours,
          project_id: card.project_id.value,
          is_optional: isOptional ? 0 : 1,
          created_by_id: secureLocalStorage.getItem("userID"),
          updated_by_id: secureLocalStorage.getItem("userID"),
        };
      }),
      reporting: data.reporting,
    });

    const url = VARIABLES.url + "/api-app/bulk-timesheet-filling";
    var config = {
      method: "post",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: timesheet_fill,
    };

    axios(config)
      .then(function (response) {
        setIsOptional(false);
        Swal.fire(
          "Timesheet for " + response.data.timesheet_details[0].date,
          "successfully filled",
          "success"
        );
        props.loadTimesheets();
      })
      .catch(function (error) {
        Swal.fire({
          icon: "error",
          title: "Failed",
          text: error.response.data.message,
        });
        setIsOptional(false);
      });
    reset();
  };
  return (
    <div
      className="modal fade"
      id="createmissedtimesheet"
      tabIndex="-1"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title  fw-bold" id="createprojectlLabel">
              Fill sheet
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setIsOptional(false)}
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                  <div className="d-flex justify-content-between">
                    <div>
                      <h6>Track time, track success.</h6>
                    </div>
                    {/* {privileges.includes("is_optional_leave") ? ( */}
                    <div className="d-flex">
                      <label className="form-label me-2">
                        Is Optional Leave
                      </label>
                      <input
                        type="checkbox"
                        id="isConverted"
                        className="form-check-input me-3"
                        name="isConverted"
                        checked={isOptional}
                        onChange={() => setIsOptional((prev) => !prev)}
                      />
                      <p>{props.fillDate}</p>
                    </div>
                    {/* ) : (
                      ""
                    )} */}
                  </div>
                </div>
              </div>
              {!isOptional && (
                <div>
                  {fields.map((test, index) => (
                    <React.Fragment key={index}>
                      <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12">
                          <div className="mb-3">
                            <label className="form-label">
                              {" "}
                              Task Id <span className="required_field">*</span>
                            </label>
                            <Controller
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  options={props.taskDropdown}
                                  isClearable={true}
                                  isLoading={props.taskLoading}
                                />
                              )}
                              defaultValue=""
                              rules={{ required: true }}
                              name={`test.${index}.task_id`}
                              control={control}
                            />
                            {errors.test?.[index]?.task_id && (
                              <p className="required_field">
                                Task Id is required
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                          <div className="mb-3">
                            <label className="form-label">
                              {" "}
                              Project Name{" "}
                              <span className="required_field">*</span>
                            </label>
                            <Controller
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  options={props.projectDropdown}
                                  isClearable={true}
                                  isLoading={props.projectLoading}
                                />
                              )}
                              defaultValue=""
                              rules={{ required: true }}
                              name={`test.${index}.project_id`}
                              control={control}
                            />
                            {errors.test?.[index]?.project_id && (
                              <p className="required_field">
                                Project Name is required
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12">
                          <div className="mb-3">
                            <label className="form-label">
                              {" "}
                              Hours <span className="required_field">*</span>
                            </label>
                            <input
                              className="form-control"
                              step="0.01"
                              type="number"
                              placeholder="Enter Hours"
                              {...register(`test.${index}.hours`, {
                                required: true,
                              })}
                            />
                            {errors.test?.[index]?.hours && (
                              <p className="required_field">
                                Hours is required
                              </p>
                            )}
                          </div>
                        </div>
                        {index >= 1 && (
                          <div className="col-lg-1 delete_button col-md-1 col-sm-12">
                            <button
                              type="button"
                              className="btn btn-danger"
                              onClick={() => remove(index)}
                            >
                              <i className="icofont-ui-delete"></i>
                            </button>
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  ))}
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() =>
                      append({ task_id: "", project_id: "", hours: "" })
                    }
                  >
                    {" "}
                    Add{" "}
                  </button>

                  <div className="mb-3">
                    <label className="form-label mt-3">
                      Reporting <span className="required_field">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      name="reporting"
                      id="reporting"
                      {...register("reporting", { required: true })}
                      rows="4"
                      placeholder="Add necessary details if any"
                    ></textarea>
                    {errors.reporting && (
                      <p className="required_field">Reporting is required</p>
                    )}
                  </div>
                </div>
              )}

              <div className="modal-footer">
                {/* <button type="submit" className="btn btn-success">Submit</button> */}
                {isValid === true || isOptional ? (
                  <button
                    type="submit"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Submit
                  </button>
                ) : (
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FillMissedTimesheet;
