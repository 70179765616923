import React from "react";

const MissedTimesheetsList = ({ timesheets }) => {
  console.log(timesheets);
  return (
    <div className="card shadow">
      <div className="card-header">
        <div className="card-title">
          <h5>Missed Timesheets</h5>
        </div>
      </div>
      <div className="card-body basic-custome-color p-0 m-0">
        <div className="table-responsive" style={{ maxHeight: "300px" }}>
          <table className="table">
            <thead>
              <tr
                className="bg-light"
                style={{
                  position: "sticky",
                  top: 0,
                }}
              >
                <th className="text-center">Sr.No</th>
                <th className="text-center">Date</th>
              </tr>
            </thead>
            {timesheets?.length === 0 && (
              <tbody>
                <tr>
                  <td></td>
                  <td>No data found</td>
                  <td></td>
                </tr>
              </tbody>
            )}
            <tbody>
              {timesheets &&
                timesheets.map((timesheet, i) => (
                  <tr key={i}>
                    <td className="text-center">{i + 1}</td>
                    <td className="text-center">{timesheet}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default MissedTimesheetsList;
