export const progress_data = [
  {
    id: "1",
    title: "SRS",
    project: "SAT",
    cardType: "Workflow",
    status: "To Do",
  },
  {
    id: "2",
    title: "Dev Book",
    project: "SAT",
    cardType: "Workflow",
    status: "Done",
  },
  {
    id: "3",
    title: "Dev Book",
    project: "Nipun",
    cardType: "Workflow",
    status: "Doing",
  },
  {
    id: "4",
    title: "SAT Releasev1.2",
    project: "SAT",
    cardType: "Target",
    status: "Done",
  },
];

export const Columns = [
  {
    id: "1",
    column: "To Do",
    status: "Assigned",
  },
  {
    id: "2",
    column: "Doing",
    status: "In Progress",
  },
  {
    id: "3",
    column: "Done",
    status: "Completed",
  },
];

export const bday = [
  {
    name: "Bhavna Dhage",
    bdayDate: "02 Feb",
  },
  {
    name: "Avanti Jaiswal",
    bdayDate: "13 Feb",
  },
  {
    name: "Pravin Chaudhari",
    bdayDate: "17 March",
  },
  {
    name: "Pooja khobragade",
    bdayDate: "24 March",
  },
];

export const review_data = [
  {
    id: "1",
    title: "Hello Review 1",
  },
  {
    id: "2",
    title: "Hello Review 2",
  },
  {
    id: "3",
    title: "Hello Review 3",
  },
  {
    id: "4",
    title: "Hello Review 4",
  },
];

export const completed_data = [
  {
    id: "1",
    title: "Hello Completed 1",
  },
  {
    id: "2",
    title: "Hello Completed 2",
  },
  {
    id: "3",
    title: "Hello Completed 3",
  },
  {
    id: "4",
    title: "Hello Completed 4",
  },
];
