import axios from "axios";
import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";
import TeamReportingTable from "./TeamReportingTable";
import TeamReportingCreate from "./TeamReportingCreate";
import TeamReportingEdit from "./TeamReportingEdit";
import { UsersContext } from "../../context/UsersContext/UsersContextProvider";

const SubProject = () => {
  const privileges = secureLocalStorage.getItem("privileges");
  const [isLoading, setIsLoading] = useState(false);
  const [noPages, setNoPages] = useState(1);
  const [entire, setEntries] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  // "rawReportingConfigurationData" stored the raw data of sub Project
  const [rawReportingConfigurationData, setRawReportingConfigurationData] =
    useState([]);

  const { users } = useContext(UsersContext);

  // "reportingConfigurationData" stores the id and projectName

  // After Clicking on Edit button specific "id" for row stores in "editedSubProject" state
  const [
    editedReportingConfigurationData,
    seteditEdReportingConfigurationData,
  ] = useState(null);

  // API call to store SubProject details in "rawReportingConfigurationData" state
  const getReportingConfigurationData = async (
    page_no = currentPage,
    entires = entire
  ) => {
    try {
      const source = axios.CancelToken.source();
      const res = await axios.get(
        VARIABLES.url +
          `/api-app/reporting-configuration?page_no=${page_no}&entries=${entires}`,
        { cancelToken: source.token }
      );
      setNoPages(res.data.num_pages);
      setCurrentPage(res.data.current_page);
      setRawReportingConfigurationData(res.data.results.data);
      setIsLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
      } else {
        console.log("Error:", error.message);
      }
    }
  };

  // Define a function to select a sub project based on the ID (Edit Button)
  const editreportingConfigurationData = (id) => {
    const getEditedTask = rawReportingConfigurationData.find((task) => {
      return task.id === id;
    });
    seteditEdReportingConfigurationData(getEditedTask);
  };

  useEffect(() => {
    if (rawReportingConfigurationData.length === 0) {
      setIsLoading(true);
    }
  }, [rawReportingConfigurationData]);

  useEffect(() => {
    getReportingConfigurationData();
  }, []);

  return (
    <>
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            <h5 className="fw-bold py-3 mb-0">Reporting Leads</h5>
            {privileges.includes("assign_leads") ? (
              <div className="d-flex py-2 project-tab sub-project-tab flex-wrap w-sm-100">
                <button
                  type="button"
                  className="btn btn-dark w-sm-100"
                  data-bs-toggle="modal"
                  data-bs-target="#assignlead"
                >
                  <i className="icofont-plus-circle me-2 fs-6"></i>Assign Lead
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <TeamReportingTable
        isLoading={isLoading}
        rawReportingConfigurationData={rawReportingConfigurationData}
        editreportingConfigurationData={editreportingConfigurationData}
        setRawReportingConfigurationData={setRawReportingConfigurationData}
        noPages={noPages}
        entire={entire}
        currentPage={currentPage}
        setEntries={setEntries}
        getReportingConfigurationData={getReportingConfigurationData}
      />

      <TeamReportingCreate
        getReportingConfigurationData={getReportingConfigurationData}
        users={users}
      />

      <TeamReportingEdit
        getReportingConfigurationData={getReportingConfigurationData}
        users={users}
        editedReportingConfigurationData={editedReportingConfigurationData}
      />
    </>
  );
};

export default SubProject;
