import React, { useEffect } from "react";
import { useState } from "react";
import { progress_data, review_data, completed_data, Columns } from "./Data";
import TaskManagementCard from "./TaskManagementCard";
import { VARIABLES } from "../../Constants";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
// import select

const TaskManagement = (props) => {
  const userID = secureLocalStorage.getItem("userID");
  const [isLoading, setIsLoading] = useState(false);
  const [taskManagement, setTaskManagement] = useState([]);
  const navigate = useNavigate();

  const apiGetTaskManagement = async () => {
    try {
      setIsLoading(true);
      const url = VARIABLES.url + "/api-app/task-management/" + userID + "/";
      console.log(url);
      const res = await axios.get(url);
      setTaskManagement(res.data);
      setIsLoading(false);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCardType = (cardtype, section1, section2, cardId) => {
    console.log(cardtype);
    if (cardtype === "Workflow") {
      // props.handleSidebar(section1);
      // props.setWorkflowBlockId(cardId);
      navigate(`/${section1}`, { state: { data: cardId } });
    }
    if (cardtype === "Target") {
      // props.handleSidebar(section2);
      navigate(`/${section2}`);
    }
  };

  useEffect(() => {
    apiGetTaskManagement();
  }, []);

  return (
    <>
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            <h5 className="fw-bold mb-0">Task Management</h5>
            <div className="col-auto d-flex w-sm-100">
              {/* <button type="button" className="btn btn-dark btn-set-task w-sm-100" data-bs-toggle="modal"
                data-bs-target="#createtask"><i className="icofont-plus-circle me-2 fs-6"></i>Create Task</button> */}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Row end  --> */}
      <div className="row clearfix  g-3">
        <div className="col-lg-12 col-md-12 flex-column">
          <div className="row taskboard g-3">
            {Columns.map((col) => {
              return (
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 mt-xxl-4 mt-xl-4 mt-lg-4 mt-md-4 mt-sm-4 mt-4">
                  <h6 className="fw-bold py-3 mb-0">{col.column}</h6>
                  {/* <Select options={options} /> */}
                  <div className="progress_task">
                    <div className="dd">
                      <ol className="dd-list">
                        {isLoading ? (
                          <p>Loading...</p>
                        ) : (
                          <>
                            {taskManagement.length === 0 && (
                              <div className="card">
                                <div className="card-body">
                                  No card is Assigned
                                </div>
                              </div>
                            )}
                            {taskManagement.map((data, id) => {
                              return (
                                <TaskManagementCard
                                  col={col}
                                  data={data}
                                  getCardType={getCardType}
                                  apiGetTaskManagement={apiGetTaskManagement}
                                />
                              );
                            })}
                          </>
                        )}
                      </ol>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* <!-- Modal Members--> */}
      {/* {showTarget === null ? "" : <div className="modal fade" id="addUser" tabIndex="-1" aria-labelledby="addUserLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title  fw-bold" id="addUserLabel">Task Management Card</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <div className='card'>
                      <div className='card-body'>
                        <h6>{showTarget.title}</h6>
                      </div>
                    </div>
                </div>
            </div>
            </div>
        </div> } */}
    </>
  );
};

export default TaskManagement;
