import React from "react";
import { Doughnut } from "react-chartjs-2";

export const options = {
  cutout: 90,
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: "bottom",
    },
  },
};

const TimesheetProjectWiseTime = ({ timespend }) => {
  console.log(timespend);
  const data = {
    labels: timespend.map((p) => p?.project__project_name),
    // [Year in array => Year1 , Year2 , Year3]
    datasets: [
      {
        data:
          timespend.length === 0 ? (
            <p>Loading...</p>
          ) : (
            timespend.map((p) => p?.total_hours)
          ),
        // [Population in array =>  745674, 758758, 758888...]
        backgroundColor: [
          "#8094ae", // Existing color
          "#ffa353", // Existing color
          "#1ee0ac", // Existing color
          "#ff6384", // Light Red
          "#36a2eb", // Light Blue
          "#ffcd56", // Yellow
          "#4bc0c0", // Teal
          "#9966ff", // Purple
          "#c9cbcf", // Light Grey
          "#f67280", // Coral Pink
          "#6c5ce7", // Light Purple
          "#fdcb6e", // Light Orange
          "#00b894", // Mint Green
          "#e17055", // Burnt Orange
        ],
        borderWidth: 3,
      },
    ],
  };
  return (
    <div className="card shadow">
      <div className="card-header">
        <div className="card-title">
          <h5>Project wise time in hours</h5>
        </div>
      </div>
      <div className="card-body basic-custome-color p-0 m-0">
        {timespend?.length !== 0 ? (
          <Doughnut data={data} options={options} />
        ) : (
          <p className="card-header">No data found</p>
        )}
      </div>
    </div>
  );
};

export default TimesheetProjectWiseTime;
