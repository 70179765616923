import React, { useContext, useEffect, useState } from "react";
import Board from "./kanban/Board";
import { TaskManagementContext } from "../../context/TaskManagementContext/TaskManagementContextProvider";
import secureLocalStorage from "react-secure-storage";
import CardDrawer from "./kanban/CardDrawer";
import AddCard from "./kanban/AddCard";

function TaskBoard() {
  const {
    showAllTasks,
    setShowAllTasks,
    currentBoardId,
    settingsLoading,
    tasksLoading,
    boardsLoading,
  } = useContext(TaskManagementContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const privileges = secureLocalStorage.getItem("privileges");
  const handleShowTasks = () => {
    setShowAllTasks(!showAllTasks);
  };

  useEffect(() => {
    if (currentBoardId === null) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [currentBoardId]);

  return (
    <>
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-start border-bottom flex-wrap">
            <h5 className="fw-bold py-3 mb-0">
              {currentBoardId?.project_name}
            </h5>
            <span>
              {(tasksLoading || settingsLoading || boardsLoading) && (
                <div className="spinner-border text-primary ms-3" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </span>
            <div className="ms-auto d-flex">
              {/* {noData && <h3>No DATA</h3>} */}
              {/* {!isLoading && (
                <>
                  {privileges.includes("show_all_tasks") ? (
                    <div className="me-3 d-flex align-items-center">
                      <label
                        htmlFor="board_level_visibility"
                        className="me-2 form-check-label"
                        style={{ fontWeight: "bold" }}
                      >
                        Show All Task
                      </label>
                      <input
                        type="checkbox"
                        onClick={handleShowTasks}
                        className="form-check-input"
                        id="board_level_visibility"
                        checked={showAllTasks}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )} */}
              {privileges.includes("create_structural_card") &&
              currentBoardId?.category?.name === "ST" ? (
                <>
                  <div className="ms-auto">
                    <button
                      type="button"
                      className="btn btn-dark w-sm-100"
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      <i className="icofont-plus-circle me-2 fs-6"></i>Create
                      Structural Card
                    </button>
                  </div>
                </>
              ) : (
                ""
              )}
              {privileges.includes("create_meeting_card") &&
              currentBoardId?.project_name === "Meet" ? (
                <>
                  <div className="ms-auto">
                    <button
                      type="button"
                      className="btn btn-dark w-sm-100"
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      <i className="icofont-plus-circle me-2 fs-6"></i>Create
                      Meeting Card
                    </button>
                  </div>
                </>
              ) : (
                ""
              )}
              {privileges.includes("create_regular_card") &&
              currentBoardId?.project_name === "Regular Card" ? (
                <>
                  <div className="ms-auto">
                    <button
                      type="button"
                      className="btn btn-dark w-sm-100"
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      <i className="icofont-plus-circle me-2 fs-6"></i>
                      Create Card
                    </button>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <Board />
      <CardDrawer />
      <AddCard isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
}

export default TaskBoard;
