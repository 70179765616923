import axios from "axios";
import React from "react";
import Swal from "sweetalert2";
import { VARIABLES } from "../../Constants";
import moment from "moment";

const TaskManagementCard = (props) => {
  const onCompletedHandler = (data, status) => {
    var completedHandler = JSON.stringify({
      is_completed: data,
      completed_on: moment().format(),
      current_status:
        status === "To Do"
          ? "Assigned"
          : status === "Doing"
          ? "In Progress"
          : "Completed",
    });
    console.log(completedHandler);
    var config = {
      method: "patch",
      url: VARIABLES.url + `/api-app/project-wf-block/${props.data.id}/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: completedHandler,
    };

    axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        Swal.fire({
          title: "Success!",
          text: "Complete Successfully",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => props.apiGetTaskManagement());
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Error",
          text: "Failed to Complete",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };
  return (
    <>
      {props.data.current_status === props.col.status ? (
        <li className="dd-item">
          <div className="dd-handle">
            <div className="task-info d-flex align-items-center justify-content-between">
              <h6 className="light-success-bg py-1 px-2 rounded-1 d-inline-block fw-bold small-14 mb-0">
                {props.data.cardTitle}
              </h6>
              <div className="task-priority d-flex align-items-center justify-content-between">
                {/* <span className="badge bg-warning text-end">{props.data.cardType}</span> */}
                <span className="badge bg-warning text-end">Workflow</span>
                <div className="dropdown">
                  <button
                    className="btn"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="icofont-edit text-primary"></i>
                  </button>
                  <ul className="dropdown-menu border-0 shadow p-3">
                    <li>
                      <a
                        className="dropdown-item py-2 rounded"
                        onClick={() => onCompletedHandler(true, "To Do")}
                        href="#"
                      >
                        To Do
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item py-2 rounded"
                        onClick={() => onCompletedHandler(true, "Doing")}
                        href="#"
                      >
                        Doing
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item py-2 rounded"
                        onClick={() => onCompletedHandler(true, "Done")}
                        href="#"
                      >
                        Done
                      </a>
                    </li>
                  </ul>
                </div>
                {/* <button type='button' className='btn'><i className='icofont-edit text-primary'></i></button> */}
              </div>
            </div>
            <p className="py-2 mb-0">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. In id nec
              scelerisque massa.
            </p>
            <div className="tikit-info row g-3 align-items-center">
              <div className="col-sm justify-content-between d-flex">
                <div
                  className="small text-truncate pointer bg-lightgreen py-1 px-2 rounded-1 d-inline-block fw-bold small"
                  onClick={() =>
                    props.getCardType(
                      "Workflow",
                      "projectWorkflowBlock",
                      "TargetManagement",
                      props.data.id
                    )
                  }
                >
                  View Card{" "}
                </div>
                <div className="small text-truncate light-danger-bg py-1 px-2 rounded-1 d-inline-block fw-bold small">
                  {props.data.project.projectName}{" "}
                </div>
              </div>
            </div>
          </div>
        </li>
      ) : (
        <div className="card">
          <div className="card-body">No card is Assigneds</div>
        </div>
      )}
    </>
  );
};

export default TaskManagementCard;
