import React from "react";
import axios from "axios";
import { Controller, useForm, useFormState } from "react-hook-form";
import Select from "react-select";
import { VARIABLES } from "../../Constants";
import Swal from "sweetalert2";
import { useState } from "react";
import moment from "moment";
import { useEffect } from "react";
import secureLocalStorage from "react-secure-storage";

const TimeSheetCardEdit = (props) => {
  // react hook form
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm();
  const { isDirty, isValid } = useFormState({
    control,
  });

  const dateValidate = (data) => {
    const date = watch("start_date");
    return new Date(date) <= new Date(data);
  };

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    if (new Date(data.start_date) < new Date(data.end_date)) {
      var tasktimeSheet = JSON.stringify({
        card_title: data.cardTitle,
        card_description: data.cardDescription,
        start_time: data.start_date,
        end_time: data.end_date,
        assigned_to_id: data.assigned_to.map((val) => val.value),
        categ_one_id: data.categoryOne.value,
        categ_two_id: data.categoryTwo.value,
        project_category_id: data.projectcategory.value,
        tag_one_id: data.tagOne ? data.tagOne.map((val) => val.value) : [],
        tag_two_id: data.tagTwo ? data.tagTwo.map((val) => val.value) : [],
        accountable_person_id: data.accountablePerson.value,
        org_created_by_id: data.organsation.value,
        task_type: data.taskType.value,
        task_status: data.taskStatus.value,
        updated_by_id: secureLocalStorage.getItem("userID"),
      });

      var config = {
        method: "patch",
        url:
          VARIABLES.url +
          `/api-app/timesheet-card/${props.editTimeSheetCard.id}`,
        headers: {
          "Content-Type": "application/json",
        },
        data: tasktimeSheet,
      };

      axios(config)
        .then((response) => {
          Swal.fire({
            title: "Success!",
            text: "Task Updated Successfully",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => props.apiRawTimeSheetdata());
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            title: "Error",
            text: "Failed to update task",
            icon: "error",
            confirmButtonText: "OK",
          });
        });
      reset();
    } else {
      Swal.fire({
        title: "Error",
        text: "Invalid date input",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  // pre-population of values after clicking on edit button
  useEffect(() => {
    if (props.editTimeSheetCard) {
      // To get Start Date in yyyy-mm-dd format
      const currStartDate = moment(props.editTimeSheetCard.startTime).format(
        "yyyy-mm-DD"
      );

      // To get End Date in yyyy-mm-dd format
      const currEndDate = moment(props.editTimeSheetCard.endTime).format(
        "yyyy-mm-DD"
      );

      var defaultValues = {};
      defaultValues.cardTitle = props.editTimeSheetCard.card_title;
      defaultValues.cardDescription = props.editTimeSheetCard.card_description;
      defaultValues.start_date = props.editTimeSheetCard.start_time;
      defaultValues.end_date = props.editTimeSheetCard.end_time;

      if (props.editTimeSheetCard.start_time === null) {
      }
      if (props.editTimeSheetCard.end_time === null) {
      }

      // category 1
      if (props.editTimeSheetCard.categ_one === null) {
      }
      if (props.editTimeSheetCard.categ_one) {
        const getCategoryOne = {
          value: props.editTimeSheetCard.categ_one.id,
          label: props.editTimeSheetCard.categ_one.name,
        };
        defaultValues.categoryOne = getCategoryOne;
      }

      // assigned to
      const getAssignedTo = props.editTimeSheetCard.assigned_to.map((data) => {
        return { value: data.id, label: data.username };
      });
      defaultValues.assigned_to = getAssignedTo;

      // category two
      if (props.editTimeSheetCard.categ_two === null) {
      }
      if (props.editTimeSheetCard.categ_two) {
        const getCategoryTwo = {
          value: props.editTimeSheetCard.categ_two.id,
          label: props.editTimeSheetCard.categ_two.name,
        };
        defaultValues.categoryTwo = getCategoryTwo;
      }
      if (props.editTimeSheetCard.project_category) {
        const projectCategory = {
          value: props.editTimeSheetCard.project_category.id,
          label: props.editTimeSheetCard.project_category.name,
        };
        defaultValues.projectcategory = projectCategory;
      }

      // tagOne
      if (props.editTimeSheetCard.tag_one === null) {
      }
      if (props.editTimeSheetCard.tag_one) {
        const getTagOne = props.editTimeSheetCard.tag_one.map((data) => {
          return {
            value: data.id,
            label: data.name,
          };
        });
        defaultValues.tagOne = getTagOne;
      }

      // tag two
      if (props.editTimeSheetCard.tag_two === null) {
      }
      if (props.editTimeSheetCard.tag_two) {
        const getTagTwo = props.editTimeSheetCard.tag_two.map((data) => {
          return {
            value: data.id,
            label: data.name,
          };
        });
        defaultValues.tagTwo = getTagTwo;
      }

      // accountable person
      const getAccountablePerson = {
        value: props.editTimeSheetCard.accountable_person.id,
        label: props.editTimeSheetCard.accountable_person.username,
      };
      defaultValues.accountablePerson = getAccountablePerson;

      // Category
      if (props.editTimeSheetCard.org_created_by === null) {
      }
      if (props.editTimeSheetCard.org_created_by) {
        const getOrgansation = {
          value: props.editTimeSheetCard.org_created_by.id,
          label: props.editTimeSheetCard.org_created_by.org_name,
        };
        defaultValues.organsation = getOrgansation;
      }

      // taskStatus
      if (props.editTimeSheetCard.task_status === null) {
      }
      if (props.editTimeSheetCard.task_status) {
        props.taskStatus.map((status) => {
          if (status.value === props.editTimeSheetCard.task_status) {
            defaultValues.taskStatus = status;
          }
        });
      }
      // TaskType
      if (props.editTimeSheetCard.taskType === null) {
      }
      if (props.editTimeSheetCard.task_type) {
        const getTaskType = {
          value: props.editTimeSheetCard.task_type,
          label: props.editTimeSheetCard.task_type,
        };
        defaultValues.taskType = getTaskType;
      }

      reset(defaultValues);
    }
  }, [props.editTimeSheetCard]);

  return (
    <>
      <div
        className="modal fade"
        id="edittimesheetcard"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title fw-bold" id="depaddLabel">
                TimeSheetCard
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="mb-3">
                  <label className="form-label">
                    Card Title<span className="required_field">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Card Title"
                    {...register("cardTitle", { required: true })}
                  />
                  {errors.cardTitle && (
                    <span className="required_field">
                      Card Title is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Card Description<span className="required_field">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Card Description"
                    {...register("cardDescription", { required: true })}
                  />
                  {errors.cardDescription && (
                    <span className="required_field">
                      Card Description is required
                    </span>
                  )}
                </div>
                <div className="deadline-form">
                  <div className="row g-3 mb-3">
                    <div className="col-sm-6">
                      <label className="form-label">
                        Start Date <span className="required_field">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        {...register("start_date", { required: true })}
                      />
                      {errors.start_date && (
                        <span className="required_field">
                          Start Date is required
                        </span>
                      )}
                    </div>
                    <div className="col-sm-6">
                      <label className="form-label">
                        End Date <span className="required_field">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        {...register("end_date", {
                          required: true,
                          validate: { dateValidate },
                        })}
                      />
                      {errors.end_date &&
                        errors.end_date.type === "required" && (
                          <span className="required_field">
                            End Date is required
                          </span>
                        )}
                      {errors.end_date &&
                        errors.end_date.type === "dateValidate" && (
                          <span className="required_field">
                            End date should be greater than or equal to start
                            date
                          </span>
                        )}
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Assigned To <span className="required_field">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select {...field} options={props.employeeData} isMulti />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="assigned_to"
                    control={control}
                  />
                  {errors.assigned_to && (
                    <span className="required_field">
                      Assigned To is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">Category One</label>
                  <Controller
                    render={({ field }) => (
                      <Select {...field} options={props.category} />
                    )}
                    defaultValue=""
                    name="categoryOne"
                    control={control}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Category Two</label>
                  <Controller
                    render={({ field }) => (
                      <Select {...field} options={props.category} />
                    )}
                    defaultValue=""
                    name="categoryTwo"
                    control={control}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Project Category</label>
                  <Controller
                    render={({ field }) => (
                      <Select {...field} options={props.projectCategory} />
                    )}
                    defaultValue=""
                    name="projectcategory"
                    control={control}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Tag One</label>
                  <Controller
                    render={({ field }) => (
                      <Select {...field} options={props.tag} isMulti />
                    )}
                    defaultValue=""
                    name="tagOne"
                    control={control}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Tag Two</label>
                  <Controller
                    render={({ field }) => (
                      <Select {...field} options={props.tag} isMulti />
                    )}
                    defaultValue=""
                    name="tagTwo"
                    control={control}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Organisation <span className="required_field">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select {...field} options={props.organsation} />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="organsation"
                    control={control}
                  />
                  {errors.organsation && (
                    <span className="required_field">
                      Organisation is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Accountable person <span className="required_field">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select {...field} options={props.employeeData} />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="accountablePerson"
                    control={control}
                  />
                  {errors.accountablePerson && (
                    <span className="required_field">
                      Accountable person is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Task Status <span className="required_field">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select {...field} options={props.taskStatus} />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="taskStatus"
                    control={control}
                  />
                  {errors.taskStatus && (
                    <span className="required_field">
                      Task Status is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Task Type <span className="required_field">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select {...field} options={props.taskType} />
                    )}
                    defaultValue=""
                    // rules={{ required: true }}
                    name="taskType"
                    control={control}
                  />
                  {errors.taskType && (
                    <span className="required_field">
                      Task Type is required
                    </span>
                  )}
                </div>
                <div className="modal-footer">
                  {/* <button type="submit" className="btn btn-success">Submit</button> */}
                  {isValid === true ? (
                    <button
                      type="submit"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Submit
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TimeSheetCardEdit;
