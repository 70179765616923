import axios from "axios";
import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { VARIABLES } from "../../Constants";
import TimeSheetCardCreate from "./TimeSheetCardCreate";
import TimeSheetCardEdit from "./TimeSheetCardEdit";
import TimeSheetCardTable from "./TimeSheetCardTable";
import secureLocalStorage from "react-secure-storage";
import { DropdownAPIsContext } from "../../context/DropdownAPIsContext/DropdownAPIsContextProvider";
import { UsersContext } from "../../context/UsersContext/UsersContextProvider";

// Task status dropdown
const taskStatus = [
  { value: "N", label: "New" },
  { value: "A", label: "Assigned" },
  { value: "B", label: "Backlog" },
];

// task type dropdown
const taskType = [
  { value: "A", label: "A" },
  { value: "B", label: "B" },
  { value: "C", label: "C" },
  { value: "D", label: "D" },
  { value: "E", label: "E" },
  { value: "F", label: "F" },
];

const TimeSheetCard = () => {
  const privileges = secureLocalStorage.getItem("privileges");
  const [isLoading, setIsLoading] = useState(false);

  const { users } = useContext(UsersContext);

  const {
    organisations,
    category: categories,
    projectCategory: projectCategories,
  } = useContext(DropdownAPIsContext);

  // after clicking on Edit button specific id data stores in "editTimeSheetCard"
  const [editTimeSheetCard, setEditTimeSheetCard] = useState(null);

  // "timeSheetCard" stores all the data time sheet card
  const [timeSheetCard, setTimeSheetCard] = useState([]);

  // "employeeData" stores the employee data
  const [employeeData, setEmployeeData] = useState([]);

  // "category" in value and label format {id and name}
  const [category, setCategory] = useState([]);
  const [projectCategory, setProjectCategory] = useState([]);

  // "tag" in value and label format {id and name}
  const [tag, setTag] = useState([]);

  // "organsation" in value and label {id and orgName}
  const [organsation, setOrgansation] = useState([]);

  const [noPages, setNoPages] = useState(1);
  const [entire, setEntries] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  // API call of TimeSheetData and stores the data in "timeSheetCard"
  const apiRawTimeSheetdata = async (
    page_no = currentPage,
    entires = entire
  ) => {
    try {
      const res = await axios.get(
        VARIABLES.url +
          `/api-app/timesheet-card?page_no=${page_no}&entries=${entires}`
      );
      setNoPages(res.data.num_pages);
      setCurrentPage(res.data.current_page);
      setTimeSheetCard(res.data.results.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // API call to store tag details in "tag" state
  const getTag = async () => {
    try {
      const res = await axios.get(VARIABLES.url + "/api-app/tag");
      setTag(
        res.data.map((userData) => ({
          value: userData.id,
          label: userData.name,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };
  // function after tiggering the edit button :
  // as a parameters id gets and finds the data and stores in "editTimeSheetCard"
  const editTimeSheetCardData = (id) => {
    const getEditedTask = timeSheetCard.find((task) => {
      return task.id === id;
    });
    setEditTimeSheetCard(getEditedTask);
  };

  useEffect(() => {
    if (users.length !== 0) {
      setEmployeeData(users);
    }
  }, [users]);

  useEffect(() => {
    if (organisations.length !== 0) {
      setOrgansation(
        organisations.map((org) => {
          return {
            value: org.id,
            label: org.org_name,
          };
        })
      );
    }
  }, [organisations]);

  useEffect(() => {
    if (projectCategories.length !== 0) {
      setProjectCategory(
        projectCategories.map((cat) => {
          return {
            value: cat.id,
            label: cat.name,
          };
        })
      );
    }
  }, [projectCategories]);

  useEffect(() => {
    if (categories.length !== 0) {
      setCategory(
        categories.map((cat) => {
          return {
            value: cat.id,
            label: cat.name,
          };
        })
      );
    }
  }, [categories]);

  useEffect(() => {
    if (timeSheetCard.length === 0) {
      setIsLoading(true);
    }
  }, [timeSheetCard]);

  useEffect(() => {
    apiRawTimeSheetdata();
    getTag();
  }, []);
  return (
    <>
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            <h5 className="fw-bold py-3 mb-0">TimeSheet Card</h5>
            {privileges.includes("create_timesheet_card") ? (
              <div className="d-flex py-2 project-tab flex-wrap w-sm-100">
                <button
                  type="button"
                  className="btn btn-dark w-sm-100"
                  data-bs-toggle="modal"
                  data-bs-target="#addtimesheetcard"
                >
                  <i className="icofont-plus-circle me-2 fs-6"></i>TimeSheet
                  Card
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      {/* TimeSheetCardCreate pop up model */}
      <TimeSheetCardCreate
        taskStatus={taskStatus}
        taskType={taskType}
        employeeData={employeeData}
        category={category}
        projectCategory={projectCategory}
        tag={tag}
        organsation={organsation}
        apiRawTimeSheetdata={apiRawTimeSheetdata}
      />

      {/* To edit Time Sheet card pop up modal */}
      <TimeSheetCardEdit
        taskStatus={taskStatus}
        taskType={taskType}
        employeeData={employeeData}
        category={category}
        projectCategory={projectCategory}
        tag={tag}
        organsation={organsation}
        editTimeSheetCard={editTimeSheetCard}
        apiRawTimeSheetdata={apiRawTimeSheetdata}
      />

      {/* TimeSheetCard Table to get the data */}
      <TimeSheetCardTable
        timeSheetCard={timeSheetCard}
        setTimeSheetCard={setTimeSheetCard}
        editTimeSheetCardData={editTimeSheetCardData}
        isLoading={isLoading}
        apiRawTimeSheetdata={apiRawTimeSheetdata}
        noPages={noPages}
        entire={entire}
        currentPage={currentPage}
        setEntries={setEntries}
      />
    </>
  );
};

export default TimeSheetCard;
