import React from 'react'
import ReleasesActiveVersion from './ReleasesActiveVersion'
import ReleasesProjectTable from './ReleasesProjectTable'
import ReleasesSubProject from './ReleasesSubProject'

const ReleasesProjectProfile = (props) => {
  const changeSidebar = (section) => {
    props.handleSidebar(section)
  }

  return (
    <>
      <div className="row clearfix">
        <div className="col-md-12">
          <div className="card border-0 mb-4 no-bg">
            <div className="card-header py-2 px-0 d-flex align-items-center  justify-content-between border-bottom">
              <h3 className=" fw-bold flex-fill mb-0">Project Profile</h3>
              <div className="d-flex py-2 project-tab flex-wrap w-sm-100">
                <button type="button" className="btn btn-outline-info w-sm-100" onClick={()=> changeSidebar("releaseNotes")}><i className="icofont-arrow-left me-2 fs-6"></i>Back</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Row End --> */}
      <div className="row g-3">
        <div className="col-xl-8 col-lg-12 col-md-12">
          <ReleasesSubProject />
          <ReleasesProjectTable />
        </div>
        <ReleasesActiveVersion />
      </div>
    </>
  )
}

export default ReleasesProjectProfile