import React from 'react'

const ReleasesProjectTable = () => {
  return (
    <>
      <div className="row g-3">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header py-3 d-flex justify-content-between align-items-center">
              <div className="info-header">
                <h6 className="mb-0 fw-bold ">Project Releases</h6>
              </div>
            </div>
            <div className="card-body">
              <table id="myProjectTable" className="table table-hover align-middle mb-0" style={{width:"100%"}}>
                <thead>
                  <tr>
                    <th>NO</th>
                    <th>Project</th>
                    <th>Date Start</th>
                    <th>Date End</th>
                    <th>Amount</th>
                    <th>status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><a href="invoices.html" className="fw-bold text-secondary">#00001</a></td>
                    <td><a href="projects.html">Social Geek Made</a></td>
                    <td>10-01-2021</td>
                    <td>10-02-2021</td>
                    <td>$3250</td>
                    <td><span className="badge bg-warning">Pending</span></td>
                  </tr>
                  <tr>
                    <td><a href="invoices.html" className="fw-bold text-secondary">#00002</a></td>
                    <td><a href="projects.html">Practice to Perfect</a></td>
                    <td>12-02-2021</td>
                    <td>10-04-2021</td>
                    <td>$1578</td>
                    <td><span className="badge bg-success">Paid</span></td>
                  </tr>
                  <tr>
                    <td><a href="invoices.html" className="fw-bold text-secondary">#00003</a></td>
                    <td><a href="projects.html">Rhinestone</a></td>
                    <td>18-02-2021</td>
                    <td>20-04-2021</td>
                    <td>$1978</td>
                    <td><span className="badge bg-lavender-purple">Draf</span></td>
                  </tr>
                  <tr>
                    <td><a href="invoices.html" className="fw-bold text-secondary">#00004</a></td>
                    <td><a href="projects.html">Box of Crayons</a></td>
                    <td>28-02-2021</td>
                    <td>30-04-2021</td>
                    <td>$1978</td>
                    <td><span className="badge bg-lavender-purple">Draf</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Row End --> */}
    </>
  )
}

export default ReleasesProjectTable