import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import AddNoticeBoard from "./AddNoticeBoard";
import EditAddNoticeBoard from "./EditAddNoticeBoard";
import { VARIABLES } from "../../Constants";
import NoticeBoardTable from "./NoticeBoardTable";
import secureLocalStorage from "react-secure-storage";
import { UsersContext } from "../../context/UsersContext/UsersContextProvider";
import { DropdownAPIsContext } from "../../context/DropdownAPIsContext/DropdownAPIsContextProvider";

const NoticeBoards = () => {
  const [isLoading, setIsLoading] = useState(false);
  // After Clicking on Edit button specific "id" for row stores in "editId" state
  const [editId, setEditId] = useState(null);
  const [editSelectNoticeBoard, setEditSelectNoticeBoard] = useState(null);

  const [noPages, setNoPages] = useState(1);
  const [entire, setEntries] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const privileges = secureLocalStorage.getItem("privileges");

  const { users, isUsersLoading } = useContext(UsersContext);
  const { department: departmentDropdown } = useContext(DropdownAPIsContext);

  // "rawDataNoticeBoard" stores the raw Data of Notice Board in state
  const [rawDataNoticeBoard, setRawDataNoticeBoard] = useState([]);

  // "department" stores the id and name and pass in dropdown
  const [department, setDepartment] = useState([]);

  const [userDetailsDisplay, setUserDetailsDisplay] = useState();

  // API call of Notice Board Data and stores the data in "rawDataNoticeBoard"
  const apiGetRawNoticeBoard = async (
    page_no = currentPage,
    entires = entire
  ) => {
    try {
      const res = await axios.get(
        VARIABLES.url +
          `/api-app/noticeboard?page_no=${page_no}&entries=${entires}`
      );
      setNoPages(res.data.num_pages);
      setCurrentPage(res.data.current_page);
      setRawDataNoticeBoard(res.data.results.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // Define a function to select a project based on the ID
  const selectProject = (id) => {
    const project = rawDataNoticeBoard.find((p) => p.id == id);
    setEditSelectNoticeBoard(project);
    setEditId(id);
  };

  useEffect(() => {
    if (users.length !== 0) {
      setUserDetailsDisplay(users);
    }
  }, [users]);

  useEffect(() => {
    if (rawDataNoticeBoard.length === 0) {
      setIsLoading(true);
    }
  }, [rawDataNoticeBoard]);

  useEffect(() => {
    apiGetRawNoticeBoard();
  }, []);

  useEffect(() => {
    if (departmentDropdown.length !== 0) {
      setDepartment(
        departmentDropdown.map((dept) => {
          return {
            value: dept.id,
            label: dept.name,
          };
        })
      );
    }
  }, [departmentDropdown]);

  return (
    <>
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            <h5 className="fw-bold py-3 mb-0">Notice Board</h5>
            {privileges.includes("create_notice") ? (
              <div className="d-flex py-2 project-tab flex-wrap w-sm-100">
                <button
                  type="button"
                  className="btn btn-dark w-sm-100"
                  data-bs-toggle="modal"
                  data-bs-target="#noticeboard"
                >
                  <i className="icofont-plus-circle me-2 fs-6"></i>Create Notice
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      {/* Notice Board Table populate */}
      <NoticeBoardTable
        selectProject={selectProject}
        rawDataNoticeBoard={rawDataNoticeBoard}
        setRawDataNoticeBoard={setRawDataNoticeBoard}
        isLoading={isLoading}
        noPages={noPages}
        entire={entire}
        currentPage={currentPage}
        setEntries={setEntries}
        apiGetRawNoticeBoard={apiGetRawNoticeBoard}
      />

      {/* Notice Board Edit Modal Pop Up */}
      <EditAddNoticeBoard
        project={editSelectNoticeBoard}
        department={department}
        userDetailsDisplay={userDetailsDisplay}
        isUsersLoading={isUsersLoading}
        apiGetRawNoticeBoard={apiGetRawNoticeBoard}
      />

      {/* Create Notice Board Modal Pop up */}
      <AddNoticeBoard
        apiGetRawNoticeBoard={apiGetRawNoticeBoard}
        department={department}
        userDetailsDisplay={userDetailsDisplay}
        isUsersLoading={isUsersLoading}
      />
    </>
  );
};

export default NoticeBoards;
