import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import {
  useForm,
  Controller,
  useFormState,
  useFieldArray,
} from "react-hook-form";
import axios from "axios";
import { VARIABLES } from "../../Constants";
import Swal from "sweetalert2";
import ProjectAssignedWorkflow from "./ProjectAssignedWorkflow";
import secureLocalStorage from "react-secure-storage";
import { ProjectContext } from "../../context/ProjectContext/ProjectContextProvider";

const AddProjects = (props) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fkProjectIncharge_id: null,
      projectName: "",
      ProjectDescription: "",
      fkOrganization_id: null,
      fkSubcompany_id: null,
      team: null,
      tags: null,
      flags: null,
      isConverted: true,
      category_id: null,
      devStacks_id: "",
      project_cluster_id: null,
      activity_status: 1,
    },
  });

  const { isDirty, isValid } = useFormState({
    control,
  });

  const { apiGetProjects } = useContext(ProjectContext);

  const onSubmit = (data) => {
    data.isConverted = data.isConverted ? true : false;
    const selectedTeamValues = data.team.map((mod) => parseInt(mod.value));

    const selectedTeam = data.team.map((mod) => mod.value);

    const url = VARIABLES.url + "/api-app/project";

    let headers = {
      "Content-Type": "application/json",
    };

    const projectData = JSON.stringify({
      project_incharge_id: Number(data.fkProjectIncharge_id.value),
      project_name: data.projectName,
      project_description: data.projectDescription,
      ...(data.project_division === null
        ? { subcompany: null }
        : { subcompany_id: data.project_division.value }),
      category_id: data.category_id.value,
      is_converted: data.isConverted,
      project_management_type: data.project_management.label,
      project_workflow_type: data.project_workflow_type.label,
      team_id: data.team.map((val) => val.value),
      label_id: data.tags.map((tag) => tag.value),
      project_flag_status: data.flags.label,
      created_by_id: secureLocalStorage.getItem("userID"),
      updated_by_id: secureLocalStorage.getItem("userID"),
    });

    let reqOptions = {
      url: url,
      method: "POST",
      headers: headers,
      mode: "no-cors",
      data: projectData,
    };

    axios(reqOptions)
      .then((response) => {
        Swal.fire({
          title: "Success!",
          text: "Project Information Created successfully",
          icon: "success",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            apiGetProjects(); // reload page after user clicks OK
            // document.getElementById("openModalButton2").click();
            // props.setProjectId(response.data.id);
          }
        });
      })
      .catch((error) => {
        console.log(error.response);
        Swal.fire({
          title: "Error",
          text: "Failed to create Project",
          icon: "error",
          confirmButtonText: "OK",
        });
      });

    reset({
      project_division: "",
      project_management: "",
      isConverted: false,
      project_workflow_type: "",
    });
  };

  const getProjectResetValues = () => {
    reset({
      project_division: "",
      project_management: "",
      isConverted: false,
      project_workflow_type: "",
    });
  };

  return (
    <>
      <div
        className="modal fade"
        id="createproject"
        tabIndex="-1"
        data-bs-backdrop="static"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title  fw-bold" id="createprojectlLabel">
                Create Project
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => getProjectResetValues()}
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="mb-3">
                  <label className="form-label">
                    {" "}
                    Project Incharge <span className="required_field">*</span>
                  </label>
                  <Controller
                    id="fkProjectIncharge_id"
                    name="fkProjectIncharge_id"
                    className="form-select"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={props.projectIncharge}
                        isClearable={true}
                      />
                    )}
                  />
                  {errors.fkProjectIncharge_id && (
                    <p className="required_field">
                      Project Incharge name is required
                    </p>
                  )}
                </div>

                <div className="mb-3">
                  <label className="form-label">
                    {" "}
                    Project Name <span className="required_field">*</span>
                  </label>
                  <input
                    id="project-name"
                    name="projectName"
                    className="form-control"
                    {...register("projectName", {
                      required: true,
                    })}
                    placeholder="projectName"
                  />
                  {errors.projectName && (
                    <p className="required_field">Project Name is required</p>
                  )}
                </div>

                <div className="mb-3">
                  <label className="form-label">
                    {" "}
                    Description <span className="required_field">*</span>
                  </label>
                  <textarea
                    id="projectDescription"
                    className="form-control"
                    {...register("projectDescription", {
                      required: true,
                    })}
                    placeholder="Project Description"
                  />
                  {errors.projectDescription && (
                    <p className="required_field">
                      Project Description is required
                    </p>
                  )}
                </div>

                <div className="mb-3">
                  <label className="form-label">
                    {" "}
                    Division <span className="required_field">*</span>
                  </label>
                  <Controller
                    id="project_division"
                    name="project_division"
                    className="form-select"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={props.subDivision}
                        isClearable={true}
                      />
                    )}
                  />
                  {errors.project_division && (
                    <p className="required_field">Division name is required</p>
                  )}
                </div>

                <div className="mb-3">
                  <label className="form-label">
                    {" "}
                    Project Management Type{" "}
                    <span className="required_field">*</span>
                  </label>
                  <Controller
                    id="project_management"
                    name="project_management"
                    className="form-select"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={props.projectManagement}
                        isClearable={true}
                      />
                    )}
                  />
                  {errors.project_management && (
                    <p className="required_field">
                      Project Management name is required
                    </p>
                  )}
                </div>

                <div className="mb-3">
                  <label className="form-label">
                    {" "}
                    Team <span className="required_field">*</span>
                  </label>
                  <Controller
                    id="team"
                    name="team"
                    className="form-select"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        isMulti
                        options={props.projectIncharge}
                        isClearable={true}
                      />
                    )}
                  />
                  {errors.team && (
                    <p className="required_field">Team name is required</p>
                  )}
                </div>

                <div className="mb-3">
                  <label className="form-label">
                    {" "}
                    Tags <span className="required_field">*</span>
                  </label>
                  <Controller
                    id="tags"
                    name="tags"
                    className="form-select"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        isMulti
                        options={props.tags}
                        isClearable={true}
                      />
                    )}
                  />
                  {errors.team && (
                    <p className="required_field">Tags are required</p>
                  )}
                </div>

                <div className="mb-3">
                  <label className="form-label">
                    {" "}
                    Priority <span className="required_field">*</span>
                  </label>
                  <Controller
                    id="flags"
                    name="flags"
                    className="form-select"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={props.flags}
                        isClearable={true}
                      />
                    )}
                  />
                  {errors.team && (
                    <p className="required_field">flag is required</p>
                  )}
                </div>

                {/* <div className="mb-3">
                <label className="form-label"> Sub Company / Sub Organization </label>
                <Controller
                  id="fkSubcompany_id"
                  name="fkSubcompany_id"
                  className="form-select"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={subCompanyforOrganization.map((api) => ({
                        value: api.id,
                        label: api.name,
                      }))}
                      isClearable={true}
                    />
                  )}
                />
              </div> */}

                {/* <div className="mb-3">
                <label className="form-label"> Team <span className="required_field">*</span></label>
                <Controller
                  id="team"
                  name="team"
                  className="form-select"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select {...field} options={employeeforOrganization.map((api) => ({
                      value: api.user.id,
                      label: api.user.username
                    }))} isMulti={true} />
                  )}
                />
                {errors.team && (
                  <p className="required_field">Team is required</p>
                )}
              </div> */}

                <div className="mb-3">
                  <label className="form-label">Converted</label>
                  <br />
                  <label className="fancy-checkbox parsley-success">
                    <input
                      type="checkbox"
                      id="isConverted"
                      className="form-check-input"
                      name="isConverted"
                      {...register("isConverted")}
                    />
                    <span className="ms-3">YES</span>
                  </label>
                </div>

                <div className="mb-3">
                  <label className="form-label">
                    {" "}
                    Project Category Type{" "}
                    <span className="required_field">*</span>
                  </label>
                  <Controller
                    id="category_id"
                    name="category_id"
                    className="form-select"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={props.categoryType}
                        isClearable={true}
                      />
                    )}
                  />
                  {errors.category_id && (
                    <p className="required_field">Category is required</p>
                  )}
                </div>

                <div className="mb-3">
                  <label className="form-label">
                    {" "}
                    Project Workflow Type{" "}
                    <span className="required_field">*</span>
                  </label>
                  <Controller
                    id="project_workflow_type"
                    name="project_workflow_type"
                    className="form-select"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={props.workflowType}
                        isClearable={true}
                      />
                    )}
                  />
                  {errors.project_workflow_type && (
                    <p className="required_field">
                      Project Workflow is required
                    </p>
                  )}
                </div>
                <div className="modal-footer">
                  {isValid === true ? (
                    <button
                      type="submit"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Submit
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* <ProjectAssignedWorkflow projectId={props.projectId} /> */}
      <button
        id="openModalButton2"
        type="button"
        className="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#assignedBlock"
        style={{ display: "none" }}
      >
        Open Modal 2
      </button>
    </>
  );
};

export default AddProjects;
