import React, { useContext, useState, useEffect } from "react";
import List from "./List";
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import secureLocalStorage from "react-secure-storage";
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import { createPortal } from "react-dom";
import Card from "./Card";
import "./kanban.css";
import { TaskManagementContext } from "../../../context/TaskManagementContext/TaskManagementContextProvider";

function Board() {
  const { list, setList, patchCardData, currentAllowedSeq } = useContext(
    TaskManagementContext
  );
  const [tempList, setTempList] = useState(null);
  const [draggingItem, setDraggingItem] = useState(null);
  const [draggingParent, setDraggingParent] = useState(null);
  const userID = secureLocalStorage.getItem("userID");
  const [seqMap, setSeqMap] = useState(null);
  const resetList = () => {
    setList([...tempList]);
  };
  const updateTempList = () => {
    setTempList([...list]);
  };
  const patchHandler = (data) => {
    if (draggingItem.current_status !== data.current_status) {
      patchCardData(data.card_identification, {
        card_type: data.card_type,
        is_completed:
          data.current_status === "Approved" ||
          data.current_status === "Completed"
            ? true
            : false,
        is_approved: data.current_status === "Approved" ? true : false,
        // current_status: data.current_status,
        lifecycle_state_id: data.current_status,
        approval_incharge_id: userID,
        updated_by_id: userID,
      });
    }
  };
  const handleDragEnd = (e) => {
    const { active, over } = e;
    if (
      active.data.current.parent === over.data.current.parent &&
      active.data.current.type !== "list" &&
      over.data.current.type !== "list"
    ) {
      if (
        seqMap?.get(draggingParent)?.includes(over.data.current.parent) ||
        draggingParent === over.data.current.parent
      ) {
        updateTempList();
      } else {
        resetList();
        return;
      }
      patchHandler(active.data.current.item);
      let temp = list.filter(
        (items) => active.data.current.parent === items.id
      )[0].items;
      const oldIndex = temp.findIndex((item) => item.id === active.id);
      const newIndex = temp.findIndex((item) => item.id === over.id);
      temp = arrayMove(temp, oldIndex, newIndex);
      setList((list) =>
        list.map((items) => {
          if (active.data.current.parent === items.id) {
            return {
              ...items,
              items: temp,
            };
          }
          return items;
        })
      );
    }
    if (
      active.data.current.type === "list" &&
      over.data.current.type === "list"
    ) {
      const oldIndex = list.findIndex((items) => items.id === active.id);
      const newIndex = list.findIndex((items) => items.id === over.id);
      setList(arrayMove(list, oldIndex, newIndex));
    }
  };

  const handleDragOver = (e) => {
    const { active, over } = e;
    if (
      active.data.current.parent !== over.data.current.parent &&
      active.data.current.type !== "list" &&
      over.data.current.type !== "list"
    ) {
      setList((list) =>
        list.map((items) => {
          if (items.id === active.data.current.parent) {
            return {
              ...items,
              items: items.items.filter((item) => item.id !== active.id),
            };
          } else if (items.id === over.data.current.parent) {
            return {
              ...items,
              items: [
                ...items.items,
                {
                  ...active.data.current.item,
                  current_status: over.data.current.item.current_status,
                  parent: over.data.current.parent,
                },
              ],
            };
          }
          return items;
        })
      );
    }
    if (
      active.data.current.parent !== over.data.current.parent &&
      active.data.current.type === "card" &&
      over.data.current.type === "list" &&
      active.data.current.parent !== over.id
    ) {
      setList(
        list.map((items) => {
          if (items.id === over.data.current.item.id) {
            return {
              ...items,
              items: [
                ...items.items,
                {
                  ...active.data.current.item,
                  current_status: list.filter(
                    (listItem) => listItem.id === over.id
                  )[0].status_id,
                },
              ],
            };
          }
          if (items.id === active.data.current.parent) {
            return {
              ...items,
              items: items.items.filter(
                (item) => item.id !== active.data.current.item.id
              ),
            };
          }
          return items;
        })
      );
    }
  };

  const handleDragStart = (e) => {
    setDraggingItem(e.active.data.current.item);
    setDraggingParent(e.active.data.current.parent);
  };

  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 3,
    },
  });
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);

  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

  useEffect(() => {
    const tempMap = new Map();
    currentAllowedSeq.map((seq) => {
      if (!tempMap.has(seq.previous_id)) {
        tempMap.set(seq.previous_id, [seq.next_id]);
      } else {
        tempMap.set(seq.previous_id, [
          ...tempMap.get(seq.previous_id),
          seq.next_id,
        ]);
      }
    });
    setSeqMap(tempMap);
  }, [currentAllowedSeq]);

  useEffect(() => {
    if (list.length !== 0 && draggingParent === null) {
      setTempList([...list]);
    }
  }, [list]);

  return (
    <div className="Board board-height container-fluid overflow-auto d-flex align-items-start justify-content-start">
      <DndContext
        collisionDetection={closestCenter}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        sensors={sensors}
      >
        <SortableContext items={list} strategy={horizontalListSortingStrategy}>
          {list.map((listItem) => {
            return <List key={listItem.id} listItem={listItem} />;
          })}
        </SortableContext>
        {createPortal(
          <DragOverlay>
            {draggingItem !== null && draggingItem.type === "list" && (
              <List key={draggingItem.id} listItem={draggingItem} />
            )}
            {draggingItem !== null && draggingItem.type === "card" && (
              <Card
                key={draggingItem.id}
                item={draggingItem}
                listId={
                  list.filter((listItems) => {
                    listItems.items.filter((items) => {
                      if (items.id === draggingItem.id) {
                        return listItems.id;
                      }
                    });
                  })[0]
                }
                cursor={"move"}
              />
            )}
          </DragOverlay>,
          document.body
        )}
      </DndContext>
      {/* <AddList /> */}
    </div>
  );
}

export default Board;
