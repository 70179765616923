import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { VARIABLES } from "../../Constants";
import Select from "react-select";

import DocumentList from "./DoumentList";
import SOPForm from "../Forms/SOPForm";
import MsDocForm from "../Forms/MsDocForm";
import TemplateForm from "../Forms/TemplateForm";
import UtilityForm from "../Forms/UtilityForm";
import GenDocTempForm from "../Forms/GenDocTempForm";
import GenDocUtForm from "../Forms/GenDocUtForm";
import { DisplayNewTag } from "./DisplayNewTag";
import secureLocalStorage from "react-secure-storage";
import DocType from "../Forms/DocType";
import { DropdownAPIsContext } from "../../context/DropdownAPIsContext/DropdownAPIsContextProvider";
import { UsersContext } from "../../context/UsersContext/UsersContextProvider";

const DocumentGenerate = (props) => {
  // State initialization
  const [isLoading, setIsLoading] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [organisation, setOrganisation] = useState([]);
  const [department, setDepartment] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [docTypes, setDocTypes] = useState([]);
  const [docTypesUT, setDocTypesUT] = useState([]);
  const [docStats, setDocStats] = useState([]);
  const [project, setProject] = useState([]);
  const [documentSelected, setDocumentSelected] = useState([]);
  const [tagDetails, setTagDetails] = useState();

  const {
    organisations,
    department: departmentDropdown,
    projects,
    typeOfDoc,
  } = useContext(DropdownAPIsContext);
  const { users } = useContext(UsersContext);

  const action = [
    { label: "View", value: "view" },
    { label: "Generate", value: "gen" },
  ];

  const loadDocumentList = async () => {
    var isLead = secureLocalStorage.getItem("lead");
    const url = VARIABLES.url + "/api-app/document-list";

    let reqOptions = {
      url: url,
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      mode: "no-cors",
    };
    setIsLoading(true);
    axios.request(reqOptions).then(function (response) {
      var data = response.data;
      setIsLoading(false);
      setDocumentList(
        data
          .map((doc) => {
            if (doc.name !== "Document(Utility)" && doc.name !== "Utility") {
              return [doc.name, doc.id];
            }
          })
          .filter((doc) => doc !== undefined)
      );

      // Formatting data for react-select options
      // Setting value as id and label as identification number.
      // Label can be set as required.
      // Value must be an id unless instructed otherwise

      // setTaskDropdown(data.map((task)=>{
      //     return {value:task.id,label:task.cardIdentificationNumber + '- ' +task.cardTitle}
      // }));
      // setTimeSheetDetails(data);
    });
  };

  // const loadTypeOfDocs = async () => {
  //   const url = VARIABLES.url + "/api-app/doc-type";

  //   let reqOptions = {
  //     url: url,
  //     method: "GET",
  //     headers: {
  //       Accept: "*/*",
  //       "Content-Type": "application/json",
  //     },
  //     mode: "no-cors",
  //   };

  //   axios.request(reqOptions).then(function (response) {
  //     var data = response.data;
  //     setDocTypes(
  //       data.map((type) => {
  //         return { value: type.id, label: type.name };
  //       })
  //     );
  //   });
  // };

  const getDocumentStats = () => {
    const url = VARIABLES.url + "/api-app/count-of-document";

    let reqOptions = {
      url: url,
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      mode: "no-cors",
    };

    axios.request(reqOptions).then(function (response) {
      var data = response.data;
      setDocStats(Object.entries(data));
    });
  };

  useEffect(() => {
    if (typeOfDoc.length !== 0) {
      setDocTypes(
        typeOfDoc.map((type) => {
          return {
            value: type.id,
            label: type.name,
          };
        })
      );
    }
  }, [typeOfDoc]);

  const loadTypeOfDocsUt = async () => {
    const url = VARIABLES.url + "/api-app/doc-type-ut";

    let reqOptions = {
      url: url,
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      mode: "no-cors",
    };

    axios.request(reqOptions).then(function (response) {
      var data = response.data;
      setDocTypesUT(
        data.map((type) => {
          return { value: type.id, label: type.name };
        })
      );
    });
  };

  const documentSelector = (document) => {
    setDocumentSelected(document);
  };

  // const generatedTag=(tag,name)=>{
  //     setTagDetails([tag,name])
  // }
  const generatedTag = (data) => {
    setTagDetails(data);
  };

  useEffect(() => {
    if (organisations.length !== 0) {
      setOrganisation(
        organisations.map((org) => {
          return {
            value: org.id,
            label: org.org_name,
          };
        })
      );
    }
  }, [organisations]);

  useEffect(() => {
    setDepartment(
      departmentDropdown.map((dept) => {
        return {
          value: dept.id,
          label: dept.name,
        };
      })
    );
  }, [departmentDropdown]);

  useEffect(() => {
    if (users.length !== 0) {
      setEmployees(users);
    }
  }, [users]);

  useEffect(() => {
    if (projects.length !== 0) {
      setProject(
        projects.map((proj) => {
          return {
            value: proj.id,
            label: proj.project_name,
          };
        })
      );
    }
  }, [projects]);

  // Following functions are called everytime the component is rendered
  useEffect(() => {
    loadDocumentList();
    // loadTypeOfDocs();
    loadTypeOfDocsUt();
    getDocumentStats();
  }, []);

  return (
    <div className="body d-flex py-lg-3 py-md-2">
      <div className="container-xxl">
        <div className="row align-items-center">
          <div className="border-0 mb-4">
            <div className="card-header py-3 no-bg bg-transparent align-items-center px-0  border-bottom flex-wrap">
              {/* <h3 className="fw-bold mb-0">Welcome to Nipun</h3> */}
              <h5 className="display-8 fw-bold mb-0">
                Documents available for tag generation
              </h5>
              <small className="text-muted">
                Contact admin if you cannot find your document name{" "}
              </small>
            </div>
          </div>
        </div>
        <div className="row clearfix g-3">
          <div className="col-sm-12">
            <div className="card mb-3">
              {docStats.length !== 0 &&
                docStats.map((doc, i) => (
                  <div key={i} className="card-body">
                    <div className="d-flex align-items-center flex-fill">
                      <span className="avatar lg light-success-bg rounded-circle text-center d-flex align-items-center justify-content-center">
                        <i className="icofont-users-alt-2 fs-5"></i>
                      </span>
                      <div className="d-flex flex-column ps-3  flex-fill">
                        <h6 className="fw-bold mb-0 fs-4">{doc[1]}</h6>
                        <span className="text-muted">{doc[0]}</span>
                      </div>
                      <i className="icofont-chart-bar-graph fs-3 text-muted"></i>
                    </div>
                  </div>
                ))}

              <div className="card-body">
                <div className="row clearfix g-3">
                  <div className="col-lg-4 col-md-12">
                    <div className="feedback-info sticky-top">
                      <div className="card">
                        <div className="card-body">
                          {/* <h4 className="display-8 fw-bold mb-0">Documents available for tag generation</h4>
                          <small className="text-muted">Contact admin if you cannot find your document name </small> */}

                          <div className="customer-like">
                            <h6 className="genrated-doc mb-0 fw-bold ">
                              Document available for tag generation
                            </h6>
                            {isLoading ? (
                              <div className="pt-3">Loading...</div>
                            ) : (
                              <ul className="list-group mt-3">
                                {documentList.length > 0 &&
                                  documentList.map((document, index) => {
                                    return (
                                      <DocumentList
                                        key={index}
                                        documentSelected={documentSelected}
                                        value={document}
                                        documentSelector={documentSelector}
                                        index={index}
                                      />
                                    );
                                  })}
                                {documentList.length === 0 && (
                                  <li className="list-group-item d-flex">
                                    No Documents available for generation
                                  </li>
                                )}
                              </ul>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-12">
                    <div>
                      {documentSelected ? (
                        <>
                          {/* 
                                                    documentSelected[1]: 1 - SOP, 2 - Ms Document
                                                */}
                          {documentSelected[1] === 0 && (
                            <DisplayNewTag
                              document={documentSelected}
                              documentSelector={documentSelector}
                              tagDetails={tagDetails}
                            />
                          )}
                          {documentSelected[1] === 1 && (
                            <SOPForm
                              document={documentSelected}
                              organisation={organisation}
                              department={department}
                              employees={employees}
                              documentSelector={documentSelector}
                              generatedTag={generatedTag}
                              action={action}
                            />
                          )}
                          {documentSelected[1] === 2 && (
                            <MsDocForm
                              document={documentSelected}
                              organisation={organisation}
                              department={department}
                              employees={employees}
                              documentSelector={documentSelector}
                              generatedTag={generatedTag}
                              action={action}
                            />
                          )}
                          {documentSelected[1] === 3 && (
                            <TemplateForm
                              document={documentSelected}
                              organisation={organisation}
                              department={department}
                              employees={employees}
                              docTypes={docTypes}
                              documentSelector={documentSelector}
                              generatedTag={generatedTag}
                              action={action}
                            />
                          )}
                          {documentSelected[1] === 4 && (
                            <UtilityForm
                              document={documentSelected}
                              organisation={organisation}
                              department={department}
                              employees={employees}
                              docTypesUT={docTypesUT}
                              documentSelector={documentSelector}
                              generatedTag={generatedTag}
                              action={action}
                            />
                          )}
                          {documentSelected[1] === 5 && (
                            <GenDocTempForm
                              document={documentSelected}
                              organisation={organisation}
                              department={department}
                              employees={employees}
                              docTypes={docTypes}
                              project={project}
                              documentSelector={documentSelector}
                              generatedTag={generatedTag}
                              action={action}
                            />
                          )}
                          {documentSelected[1] === 6 && (
                            <GenDocUtForm
                              document={documentSelected}
                              organisation={organisation}
                              department={department}
                              employees={employees}
                              docTypesUT={docTypesUT}
                              project={project}
                              documentSelector={documentSelector}
                              generatedTag={generatedTag}
                              action={action}
                            />
                          )}
                          {documentSelected[1] === 7 && (
                            <DocType
                              document={documentSelected}
                              organisation={organisation}
                              department={department}
                              employees={employees}
                              docTypes={docTypesUT}
                              project={project}
                              documentSelector={documentSelector}
                              generatedTag={generatedTag}
                              action={action}
                              // loadTypeOfDocs={loadTypeOfDocs}
                            />
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentGenerate;
