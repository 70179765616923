import axios from "axios";
import React from "react";
import { Controller, useForm, useFormState } from "react-hook-form";
import Select from "react-select";
import { VARIABLES } from "../../Constants";
import Swal from "sweetalert2";
import secureLocalStorage from "react-secure-storage";

const CreateProjectReleases = (props) => {
  // React hook form
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm();
  const userId = secureLocalStorage.getItem("userID");
  // const { isDirty, isValid } = useFormState({
  //   control
  // });
  // console.log(isValid)

  // Onsubmit handler for project release note
  const onSubmit = (data) => {
    var projectRelease = JSON.stringify({
      project_id: data.project.value,
      release_category: data.category.value,
      release_sub_category: data.sub_category.value,
      incharge_id: data.incharge_name.value,
      created_by_id: userId,
    });

    var config = {
      method: "post",
      url: VARIABLES.url + "/api-app/releasemanagement/",
      headers: {
        "Content-Type": "application/json",
      },
      data: projectRelease,
    };

    axios(config)
      .then((response) => {
        Swal.fire({
          title: "Success!",
          text: "Release Note Created Successfully",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => props.apiReleaseData());
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: "Failed to create Release Note",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
    reset();
  };
  return (
    <>
      <div
        className="modal fade"
        id="releaseProject"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title  fw-bold" id="leaveaddLabel">
                Project Release Note
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                  <label className="form-label">
                    Project <span className="required_field">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select {...field} options={props.projectData} />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="project"
                    control={control}
                  />
                  {errors.project && (
                    <span className="required_field">
                      Sub Project name is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Incharge Name<span className="required_field">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select {...field} options={props.employeeData} />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="incharge_name"
                    control={control}
                  />
                  {errors.incharge_name && (
                    <span className="required_field">
                      Sub Project name is required
                    </span>
                  )}
                </div>
                {/* <div className="mb-3">
                  <label className="form-label">Release</label>
                  <Controller
                    render={({ field }) => <Select {...field} options={props.leaveType} />}
                    defaultValue=""
                    rules={{ required: true }}
                    name="release"
                    control={control} />
                </div> */}
                <div className="mb-3">
                  <label className="form-label">
                    Category <span className="required_field">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select {...field} options={props.category} />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="category"
                    control={control}
                  />
                  {errors.category && (
                    <span className="required_field">
                      Sub Project name is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Sub-Category <span className="required_field">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select {...field} options={props.sub_category} />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="sub_category"
                    control={control}
                  />
                  {errors.sub_category && (
                    <span className="required_field">
                      Sub Project name is required
                    </span>
                  )}
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                  {/* {isValid === true ? <button type="submit" className="btn btn-primary" data-bs-dismiss="modal">Submit</button> : <button type="submit" className="btn btn-primary">Submit</button>} */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateProjectReleases;
