import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import ListContextProvider from "./context/ListContextProvider";
import DrawerContextProvider from "./context/DrawerContextProvider";
import LeaveManagementProvider from "./context/LeaveManagementContext/LeaveManagementProvider";
import ProjectContextProvider from "./context/ProjectContext/ProjectContextProvider";
import UsersContextProvider from "./context/UsersContext/UsersContextProvider";
import SubCompanyContextProvider from "./context/SubCompanyContext/SubCompanyContextProvider";
import TaskManagementContextProvider from "./context/TaskManagementContext/TaskManagementContextProvider";
import OrganisationContextProvider from "./context/OrganisationContext/OrganisationContextProvider";
import ProjectCardsContextProvider from "./context/ProjectCardsContext/ProjectCardsContextProvider";
import DropdownAPIsContextProvider from "./context/DropdownAPIsContext/DropdownAPIsContextProvider";
import { SnackbarProvider } from "notistack";
import NotificationsContextProvider from "./context/Notifications/NotificationsContextProvider";
import ResourceManagementContextProvider from "./context/ResourceManagement/ResourceManagementContextProvider";
import TimesheetManagementContextProvider from "./context/TimesheetManagement/TimesheetManagementContextProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <DropdownAPIsContextProvider>
        <UsersContextProvider>
          <OrganisationContextProvider>
            <SubCompanyContextProvider>
              <TaskManagementContextProvider>
                <ListContextProvider>
                  <DrawerContextProvider>
                    <LeaveManagementProvider>
                      <ProjectContextProvider>
                        <ProjectCardsContextProvider>
                          <SnackbarProvider maxSnack={3}>
                            <NotificationsContextProvider>
                              <ResourceManagementContextProvider>
                                <TimesheetManagementContextProvider>
                                  <App />
                                </TimesheetManagementContextProvider>
                              </ResourceManagementContextProvider>
                            </NotificationsContextProvider>
                          </SnackbarProvider>
                        </ProjectCardsContextProvider>
                      </ProjectContextProvider>
                    </LeaveManagementProvider>
                  </DrawerContextProvider>
                </ListContextProvider>
              </TaskManagementContextProvider>
            </SubCompanyContextProvider>
          </OrganisationContextProvider>
        </UsersContextProvider>
      </DropdownAPIsContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
