import React, { useContext, useEffect, useState } from "react";
import { DrawerContext } from "../../../context/DrawerContextProvider";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { ListContext } from "../../../context/ListContextProvider";
import { useMediaQuery } from "react-responsive";
import PriorityListDropdown from "./dropdown/PriorityListDropdown";
import CalendarDropdown from "./dropdown/CalendarDropdown";
// import TagsDropdown from "./dropdown/TagsDropdown";
import Select from "react-select";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { UsersContext } from "../../../context/UsersContext/UsersContextProvider";
import { Controller, useForm, useFormState } from "react-hook-form";
import Swal from "sweetalert2";
import AddActions from "./AddActions";
import { TaskManagementContext } from "../../../context/TaskManagementContext/TaskManagementContextProvider";
import { DropdownAPIsContext } from "../../../context/DropdownAPIsContext/DropdownAPIsContextProvider";
import { VARIABLES } from "../../../Constants";
// import { OrganisationContext } from "../../../context/OrganisationContext/OrganisationContextProvider";

const UpdateBtn = ({ isValid }) => {
  return (
    <div>
      {isValid === true ? (
        <button
          type="submit"
          className="btn btn-primary"
          data-bs-dismiss="modal"
        >
          Update
        </button>
      ) : (
        <button type="submit" className="btn btn-primary">
          Update
        </button>
      )}
    </div>
  );
};

const DeleteBtn = ({ deleteCard, currentItemId }) => {
  return (
    <div>
      <button
        type="button"
        className="btn btn-danger text-light ms-2"
        onClick={() => deleteCard(currentItemId?.card_id_id)}
      >
        Delete
      </button>
    </div>
  );
};

function CardDrawer() {
  // const [isOpen, setIsOpen] = useState(false);
  const { isOpen, setIsOpen, currentItem, currentItemId } =
    useContext(DrawerContext);
  const { currentBoardId, getTasksListForUpdate } = useContext(
    TaskManagementContext
  );
  const { generic } = useContext(DropdownAPIsContext);
  const isMobile = useMediaQuery({
    query: "(max-width: 480px)",
  });
  const userID = secureLocalStorage.getItem("userID");
  // const { users } = useContext(UsersContext);
  const [users, setUsers] = useState([]);
  const { patchRegularCard } = useContext(ListContext);
  const privileges = secureLocalStorage.getItem("privileges");
  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const { isValid } = useFormState({ control });

  const updateStructralCard = (id, payload) => {
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/api-app/struct-card/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };

    axios
      .request(config)
      .then((response) => {
        Swal.fire({
          title: "Success!",
          text: "Structrual Card Updated successfully",
          icon: "success",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            getTasksListForUpdate();
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateMeetingCard = (id, payload) => {
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/api-app/meetingcard/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };

    axios
      .request(config)
      .then((response) => {
        Swal.fire({
          title: "Success!",
          text: "Meeting Card Updated successfully",
          icon: "success",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            getTasksListForUpdate();
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteCard = (id) => {
    setIsOpen(!isOpen);
    Swal.fire({
      title: "Are you sure? You will not be able to recover this project card!",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      let url = "";
      if (currentItemId?.card_type === "Structural Card") {
        url = "/api-app/struct-card/";
      } else if (currentItemId?.card_type === "Meeting Card") {
        url = "/api-app/meetingcard/";
      }
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        let config = {
          method: "delete",
          maxBodyLength: Infinity,
          url: `${VARIABLES.url}${url}${id}`,
          headers: {},
        };
        axios
          .request(config)
          .then((response) => {
            Swal.fire({
              title: "Success!",
              text: `${
                currentItemId?.card_type === "Structural Card"
                  ? "Structural"
                  : "Meeting"
              } Card Deleted successfully`,
              icon: "success",
              confirmButtonText: "OK",
            });
            getTasksListForUpdate();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  const onSubmit = (e) => {
    if (new Date(e.start_date) > new Date(e.end_date)) {
      reset({
        rc_name: currentItem.value,
        start_date: currentItem.startDate,
        end_date: currentItem.endDate,
        rc_description: currentItem.description,
        users:
          currentItem?.card_type === "regularcard"
            ? currentItem.assigned_to.map((user) => {
                return {
                  value: user.id,
                  label: user.first_name,
                };
              })
            : currentItem.assigned_to,
      });
      setIsOpen(!isOpen);
      Swal.fire({
        title: "Error",
        text: "Invalid dates input",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }
    const cardInfo = JSON.stringify({
      updated_by_id: userID,
      card_title: e.rc_name,
      start_time: e.start_date,
      end_time: e.end_date,
      card_description: e?.rc_description,
      assigned_to_id: e?.users?.map((user) => user.value),
    });
    if (currentItemId?.card_type === "Structural Card") {
      updateStructralCard(currentItemId.card_id_id, cardInfo);
    } else if (currentItemId?.card_type === "Meeting Card") {
      updateMeetingCard(currentItemId.card_id_id, {
        updated_by_id: userID,
        card_title: e.rc_name,
        start_time: e.start_date,
        end_time: e.end_date,
        card_description: e?.rc_description,
        assigned_to_id: e?.users?.map((user) => user.value),
        agenda: e.agenda,
        notes: e?.rc_description,
      });
    } else if (currentItemId?.card_type === "Regular Card") {
      patchRegularCard(currentItemId.card_identification, cardInfo);
    }
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (currentItemId !== null) {
      setUsers(
        currentBoardId?.team?.map((user) => ({
          value: user.id,
          label: user.username,
        }))
      );
      reset({
        rc_name: currentItemId?.value,
        start_date: currentItemId?.startDate,
        end_date: currentItemId?.endDate,
        rc_description:
          currentItemId?.card_type === "Meeting Card"
            ? currentItemId?.notes
            : currentItemId?.description,
        agenda: currentItemId?.agenda,
        users:
          currentItemId?.card_type === "regularcard"
            ? currentItemId?.assigned_to?.map((user) => {
                return {
                  value: user.id,
                  label: user.first_name,
                };
              })
            : users?.filter((e) =>
                currentItemId?.assigned_to?.includes(e.value)
              ),
      });
    }
  }, [currentItemId]);

  return (
    <Drawer
      open={isOpen}
      onClose={() => {
        setIsOpen(!isOpen);
        // getProjectResetValues();
      }}
      direction="right"
      className="bla bla bla index-drawer"
      size={isMobile ? "80%" : "450px"}
      zIndex={2000}
    >
      <div
        style={{
          margin: "0px 10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "55px",
        }}
      >
        <span style={{ fontSize: "20px", marginTop: "5px" }}>
          {currentItem?.card_type === "regularcard"
            ? "Update Regular Card"
            : "Card Details"}
        </span>
        <i
          className="bi bi-x"
          style={{
            cursor: "pointer",
            fontSize: "35px",
          }}
          onClick={() => {
            setIsOpen(!isOpen);
            // getProjectResetValues();
          }}
        ></i>
      </div>
      <div style={{ height: "calc(100dvh - 55px)", overflowY: "auto" }}>
        <form onSubmit={handleSubmit(onSubmit)} className="px-3 mb-2">
          <div className="row mb-3">
            <div className="col-12">
              <label className="form-label">
                Name <span className="required_field ">*</span>
              </label>
              <input
                id="rc-name"
                name="rc-name"
                className="DrawerInput"
                {...register("rc_name", {
                  required: true,
                })}
                placeholder="Regular Card Name"
                disabled={
                  currentItemId?.card_type === "Regular Card" ||
                  currentItemId?.card_type === "Target Card"
                }
              />
              {errors.rc_name && (
                <p className="required_field error">
                  Regular Card Name is Required
                </p>
              )}
            </div>
          </div>
          {currentItemId?.card_type === "Meeting Card" && (
            <div className="row mb-3">
              <div className="col-12">
                <label className="form-label">
                  Agenda <span className="required_field ">*</span>
                </label>
                <input
                  id="agenda"
                  name="agenda"
                  className="DrawerInput"
                  {...register("agenda", {
                    required: true,
                  })}
                  placeholder="Agenda"
                  disabled={
                    currentItemId?.card_type === "Regular Card" ||
                    currentItemId?.card_type === "Target Card"
                  }
                />
                {errors.agenda && (
                  <p className="required_field error">
                    Agenda Name is Required
                  </p>
                )}
              </div>
            </div>
          )}
          <div className="row mb-3 ">
            <div className="col-12">
              <label className="form-label">
                {currentItemId?.card_type !== "Meeting Card"
                  ? "Description"
                  : "Notes"}
              </label>
              <textarea
                id="rc-description"
                className="DrawerInput"
                {...register("rc_description")}
                placeholder={
                  currentItemId?.card_type !== "Meeting Card"
                    ? "Description..."
                    : "Notes..."
                }
                disabled={
                  currentItemId?.card_type === "Regular Card" ||
                  currentItemId?.card_type === "Target Card"
                }
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-6">
              <label className="form-label">
                Start Date
                <span className="required_field">*</span>
              </label>
              <input
                type="date"
                className="DrawerInput"
                id="start_date"
                {...register(`start_date`, {
                  required: true,
                })}
                disabled={
                  currentItemId?.card_type === "Regular Card" ||
                  currentItemId?.card_type === "Target Card"
                }
              />
              {errors.start_date && (
                <span className="required_field error">
                  Start Date is required
                </span>
              )}
            </div>
            <div className="col-6">
              <label className="form-label">
                End Date <span className="required_field ">*</span>
              </label>
              <input
                type="date"
                className="DrawerInput"
                id="end_date"
                {...register(`end_date`, {
                  required: true,
                })}
                disabled={
                  currentItemId?.card_type === "Regular Card" ||
                  currentItemId?.card_type === "Target Card"
                }
              />
              {errors.end_date && (
                <span className="required_field error">
                  End Date is required
                </span>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-2 mb-3 ">
              <label className="form-label">
                {currentItemId?.card_type !== "Meeting Card"
                  ? "Assigned To"
                  : "Attendies"}
                <span className="required_field ">*</span>
              </label>
              <Controller
                name={`users`}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={users}
                    isMulti
                    isClearable={true}
                    isDisabled={
                      currentItemId?.card_type === "Regular Card" ||
                      currentItemId?.card_type === "Target Card"
                    }
                  />
                )}
              />
              {errors.users && (
                <span className="required_field error">
                  {currentItemId?.card_type !== "Meeting Card"
                    ? "Users"
                    : "Attendies"}
                  are required
                </span>
              )}
            </div>
          </div>
          {currentItemId?.card_type !== "Regular Card" &&
            currentItemId?.card_type !== "Target Card" && (
              <div className="row ">
                <div className="col-12 mt-auto d-flex">
                  {privileges.includes("update_str_card") &&
                    currentItemId?.card_type === "Structural Card" && (
                      <UpdateBtn isValid={isValid} />
                    )}
                  {privileges.includes("update_mtc_card") &&
                    currentItemId?.card_type === "Meeting Card" && (
                      <UpdateBtn isValid={isValid} />
                    )}
                  {privileges.includes("delete_str_card") &&
                    currentItemId?.card_type === "Structural Card" && (
                      <DeleteBtn
                        deleteCard={deleteCard}
                        currentItemId={currentItemId}
                      />
                    )}
                  {privileges.includes("delete_mtc_card") &&
                    currentItemId?.card_type === "Meeting Card" && (
                      <DeleteBtn
                        deleteCard={deleteCard}
                        currentItemId={currentItemId}
                      />
                    )}
                </div>
              </div>
            )}
        </form>
        {currentItemId?.card_type === "Meeting Card" && (
          <AddActions currentItemId={currentItemId} isOpen={isOpen} />
        )}
      </div>
    </Drawer>
  );
}

export default CardDrawer;
