import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import { VARIABLES } from "../../Constants";

export const SubCompanyContext = createContext(null);

function SubCompanyContextProvider({ children }) {
  const [subCompany, setSubCompany] = useState();
  // API call to store Organization details in "organizationDetails" state
  const getSubCompany = async () => {
    try {
      const res = await axios.get(VARIABLES.url + "/api-app/subcompany");
      setSubCompany(
        res.data.map((div) => ({
          value: div.id,
          label: div.name,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getSubCompany();
  }, []);
  const value = { subCompany, getSubCompany };
  return (
    <SubCompanyContext.Provider value={value}>
      {children}
    </SubCompanyContext.Provider>
  );
}

export default SubCompanyContextProvider;
