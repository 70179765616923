import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { VARIABLES } from "../../Constants";
import EditReleases from "./EditReleases";
import DevRelease from "./DevRelease";
import QARelease from "./QARelease";
import { useLocation } from "react-router-dom";

// Category Dropdown
const category = [
  { label: "QA", value: "QA" },
  { label: "Developer", value: "developer" },
];

// sub category dropdown
const sub_category = [
  { label: "Bug Fix", value: "bug fix" },
  { label: "Feature", value: "feature" },
];

const DevQAReleasesList = (props) => {
  // isLoading state for to Load data
  const [isLoading, setIsLoading] = useState(false);

  // Raw Release Note Data stored in "rawReleaseData" state
  const [rawReleaseData, setRawReleaseData] = useState([]);

  // after clicking on Edit Button particular ID of data gets stored in "editedReleaseNote"
  const [editedReleaseNote, setEditedReleaseNote] = useState(null);

  const [rawProjectData, setRawProjectData] = useState([]);

  // project Data stored for dropdown in as id and ProjectName
  const [projectData, setProjectData] = useState([]);

  // sub project stored raw data of sub Project
  const [subProject, setSubProject] = useState([]);

  // EmployeeData stored in id and username format in dropdown
  const [employeeData, setEmployeeData] = useState([]);

  const [projectSelect, setProjectSelect] = useState([]);
  const location = useLocation();

  // API call of Release Data and stores the data in "rawReleaseData"
  const apiReleaseData = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        VARIABLES.url + "/api-app/release-management"
      );
      setRawReleaseData(res.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // API call of Project Data and stores the data in "projectData"
  const apiProjectData = async () => {
    try {
      const res = await axios.get(VARIABLES.url + "/api-app/project");
      setRawProjectData(res.data.results.data);
      setProjectData(
        res.data.results.data.map((userData) => ({
          value: userData.id,
          label: userData.project_name,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  // API call to store sub-project details in "subProject" state
  const getSubProject = async () => {
    try {
      const res = await axios.get(VARIABLES.url + "/api-app/subproject");
      setSubProject(res.data);
      // setSubProject(
      //   res.data.map((userData) => ({
      //     value: userData.id,
      //     label: userData.name,
      //   }))
      // );
    } catch (error) {
      console.log(error);
    }
  };

  // API call to store employee details in "employeeData" state
  const getEmployeeName = async () => {
    try {
      const res = await axios.get(VARIABLES.url + "/api-app/user");
      setEmployeeData(
        res.data.map((userData) => ({
          value: userData.user.id,
          label: userData.user.username,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  // editReleaseNote stored specific data that want to edit
  const editReleaseNote = (id) => {
    const getEditedReleaseNote = rawReleaseData.find((note) => {
      return note.id === id;
    });
    setEditedReleaseNote(getEditedReleaseNote);
  };

  useEffect(() => {
    apiReleaseData();
    apiProjectData();
    getSubProject();
    getEmployeeName();
  }, []);
  return (
    <>
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            {location.pathname !== "/devrelease" ? (
              <h5 className="fw-bold py-3 mb-0">QA Releases</h5>
            ) : (
              <h5 className="fw-bold py-3 mb-0">Developer Releases</h5>
            )}
          </div>
        </div>
      </div>

      {/* All release Note Table in one Table */}
      {location.pathname === "/qarelease" ? (
        <QARelease
          rawReleaseData={rawReleaseData}
          projectData={projectData}
          setProjectSelect={setProjectSelect}
          editReleaseNote={editReleaseNote}
          isLoading={isLoading}
        />
      ) : (
        ""
      )}

      {location.pathname === "/devrelease" ? (
        <DevRelease
          rawReleaseData={rawReleaseData}
          projectData={projectData}
          setProjectSelect={setProjectSelect}
          editReleaseNote={editReleaseNote}
          isLoading={isLoading}
        />
      ) : (
        ""
      )}

      {/* Edit Modal for Release */}
      <EditReleases
        apiReleaseData={apiReleaseData}
        category={category}
        rawProjectData={rawProjectData}
        projectData={projectData}
        sub_category={sub_category}
        subProject={subProject}
        editedReleaseNote={editedReleaseNote}
        employeeData={employeeData}
      />
    </>
  );
};

export default DevQAReleasesList;
