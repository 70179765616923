import React, { useEffect, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

function ProjectCardsCard({ item, listId }) {
  const [cardHeight, setCardheight] = useState(0);

  const calculteHeight = () => {
    const days =
      (new Date(item.end_date).getTime() -
        new Date(item.start_date).getTime()) /
      (1000 * 60 * 60 * 24);
    setCardheight(100 * (days + 1) + days * 10);
  };
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: item.id,
      data: {
        item: item,
        listId: listId,
      },
    });
  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
    marginBottom: "10px",
  };
  useEffect(() => {
    calculteHeight();
  }, []);
  return (
    <div
      className={`card TaskMargin bg-light rounded-1 ${
        item.name !== "" ? "" : "pe-none"
      }`}
      style={style}
      {...attributes}
      {...listeners}
      ref={setNodeRef}
    >
      <div
        className={`card-body rounded-1 px-3 py-0 position-relative Task`}
        style={{
          minHeight: `${cardHeight}px`,
          background: item.name === "" && "transparent",
        }}
      >
        <p className="pt-3">
          <span className="me-2">{item.name}</span>

          <span className="me-2">{item.start_date}</span>
          <span className="me-2">{item.end_date}</span>
        </p>
      </div>
    </div>
  );
}

export default ProjectCardsCard;
