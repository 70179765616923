import axios from "axios";
import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { VARIABLES } from "../../Constants";
import SubProjectCreate from "./SubProjectCreate";
import SubProjectEdit from "./SubProjectEdit";
import SubProjectTable from "./SubProjectTable";
import secureLocalStorage from "react-secure-storage";
import { DropdownAPIsContext } from "../../context/DropdownAPIsContext/DropdownAPIsContextProvider";

const SubProject = () => {
  const privileges = secureLocalStorage.getItem("privileges");
  const [isLoading, setIsLoading] = useState(false);
  // "rawSubprojectdata" stored the raw data of sub Project
  const [rawSubProjectData,setRawSubProjectData] = useState([])

  // "projectData" stores the id and projectName
  const [projectData,setProjectData] = useState([])

  // After Clicking on Edit button specific "id" for row stores in "editedSubProject" state
  const [editedSubProject, setEditedSubProject] = useState(null);

  const { projects } = useContext(DropdownAPIsContext);

  // API call to store SubProject details in "rawSubProjectData" state
  const getSubProject = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(VARIABLES.url + "/api-app/subproject");
      setRawSubProjectData(res.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // Define a function to select a sub project based on the ID (Edit Button)
  const editSubProjectData = (id) => {
    const getEditedTask = rawSubProjectData.find((task) => {
      return task.id === id
    })
    setEditedSubProject(getEditedTask);
  };

  useEffect(() => {
    if (projects.length !== 0) {
      setProjectData(
        projects.map((proj) => {
          return {
            value: proj.id,
            label: proj.project_name,
          };
        })
      );
    }
  }, [projects]);

  useEffect(() => {
    getSubProject();
  }, []);

  return (
    <>
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            <h5 className="fw-bold py-3 mb-0">Sub Project</h5>
            {privileges.includes("create_sub_project") ? (
              <div className="d-flex py-2 project-tab sub-project-tab flex-wrap w-sm-100">
                <button
                  type="button"
                  className="btn btn-dark w-sm-100"
                  data-bs-toggle="modal"
                  data-bs-target="#addsubproject"
                >
                  <i className="icofont-plus-circle me-2 fs-6"></i>Create Sub
                  Project
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      {/* SubProject Create Modal Pop Up */}
      <SubProjectCreate
        getSubProject={getSubProject}
        projectData={projectData}
      />

      {/* SubProject Edit Modal Pop Up */}
      <SubProjectEdit
        getSubProject={getSubProject}
        projectData={projectData}
        editedSubProject={editedSubProject}
      />

      {/* SubProject Table Page */}
      <SubProjectTable
        isLoading={isLoading}
        rawSubProjectData={rawSubProjectData}
        editSubProjectData={editSubProjectData}
        setRawSubProjectData={setRawSubProjectData}
      />
    </>
  );
};

export default SubProject;
