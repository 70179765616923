import React, { useEffect } from "react";
import { VARIABLES } from "../../../../Constants";
import axios from "axios";
import { Controller, useForm, useFormState } from "react-hook-form";
import Swal from "sweetalert2";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import moment from "moment";

const AddMeetingCard = ({
  currentBoardId,
  getTasksList,
  userID,
  users,
  isUsersLoading,
  isOpen,
  setIsOpen,
  meetBoardId,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      users: null,
    },
  });
  const { isValid } = useFormState({ control });
  const location = useLocation();
  const createMeetingCard = (data) => {
    const res = {
      created_by_id: data.created_by_id,
      updated_by_id: data.updated_by_id,
      notes: data.card_description,
      agenda: data.agenda,
      board_id_id: meetBoardId,
      current_status: "Assigned",
      card_title: data.card_title,
      start_time: data.start_time,
      end_time: data.end_time,
      org_created_by_id: 2,
      assigned_to_id: data.assigned_to_id,
    };
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/api-app/meetingcard`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(res),
    };

    axios
      .request(config)
      .then((response) => {
        Swal.fire({
          title: "Success!",
          text: "Meeting Card Created successfully",
          icon: "success",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            getTasksList(currentBoardId.id);
          }
        });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Error",
          text: "Failed to Create Meeting Card",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  const onSubmit = (e) => {
    if (new Date(e.start_date) > new Date(e.end_date)) {
      reset({
        rc_name: "",
        start_date: "",
        end_date: "",
        rc_description: "",
        users: null,
        agenda: "",
      });
      setIsOpen(!isOpen);
      Swal.fire({
        title: "Error",
        text: "Invalid dates input",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }
    const data = {
      created_by_id: userID,
      updated_by_id: userID,
      card_title: e.rc_name,
      start_time: e.start_date,
      end_time: e.end_date,
      project_id: parseInt(location.pathname.split("_")[1]),
      card_description: e?.rc_description,
      org_created_by_id: 2,
      assigned_to_id: [...e?.users?.map((user) => user.value)],
      current_status: "Assigned",
      agenda: e.agenda,
    };
    createMeetingCard(data);
    reset({
      rc_name: "",
      start_date: "",
      end_date: "",
      rc_description: "",
      users: null,
      agenda: "",
    });
    setIsOpen(!isOpen);
  };
  const getProjectResetValues = () => {
    reset({
      rc_name: "",
      start_date: "",
      end_date: "",
      rc_description: "",
      users: null,
      agenda: "",
    });
  };
  useEffect(() => {
    if (!isOpen) {
      getProjectResetValues();
    }
    const getEndOfWeek = () => {
      const currentDay = new Date().getDay();
      const endOfWeekDate = new Date();
      endOfWeekDate.setDate(new Date().getDate() + (6 - currentDay));
      return moment(endOfWeekDate).format("YYYY-MM-DD");
    };

    reset({
      rc_name: "",
      start_date: moment(new Date()).format("YYYY-MM-DD"),
      end_date: getEndOfWeek(),
      rc_description: "",
      users: null,
    });
  }, [isOpen]);
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="px-3 pb-3 overflow-auto"
      style={{ height: "100%" }}
    >
      <div className="row mb-3">
        <div className="col-12">
          <label className="form-label">
            Name <span className="required_field ">*</span>
          </label>
          <input
            id="rc-name"
            name="rc-name"
            className="DrawerInput"
            {...register("rc_name", {
              required: true,
            })}
            placeholder="Card Name"
          />
          {errors.rc_name && (
            <p className="required_field error">Card Name is Required</p>
          )}
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-12">
          <label className="form-label">
            Agenda <span className="required_field ">*</span>
          </label>
          <input
            id="agenda"
            name="agenda"
            className="DrawerInput"
            {...register("agenda", {
              required: true,
            })}
            placeholder="Agenda"
          />
          {errors.agenda && (
            <p className="required_field error">Agenda Name is Required</p>
          )}
        </div>
      </div>

      <div className="row mb-3 ">
        <div className="col-12">
          <label className="form-label">Notes</label>
          <textarea
            id="rc-description"
            className="DrawerInput"
            {...register("rc_description")}
            placeholder="Notes..."
          />
        </div>
      </div>
      <div className="row mb-3">
        <div className="col-6">
          <label className="form-label">
            Start Date
            <span className="required_field">*</span>
          </label>
          <input
            type="date"
            className="DrawerInput"
            id="start_date"
            {...register(`start_date`, {
              required: true,
            })}
          />
          {errors.start_date && (
            <span className="required_field error">Start Date is required</span>
          )}
        </div>
        <div className="col-6">
          <label className="form-label">
            End Date <span className="required_field ">*</span>
          </label>
          <input
            type="date"
            className="DrawerInput"
            id="end_date"
            {...register(`end_date`, {
              required: true,
            })}
          />
          {errors.end_date && (
            <span className="required_field error">End Date is required</span>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-2 mb-3 ">
          <label className="form-label">
            Attendies
            <span className="required_field ">*</span>
          </label>
          <Controller
            name={`users`}
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                options={users.filter((user) =>
                  currentBoardId?.team.map((e) => e.id).includes(user.value)
                )}
                isMulti
                isClearable={true}
                isLoading={isUsersLoading}
              />
            )}
          />
          {errors.users && (
            <span className="required_field error">Attendies are required</span>
          )}
        </div>
      </div>
      <div className="row ">
        <div className="col-12 mt-auto">
          {isValid === true ? (
            <button
              type="submit"
              className="btn btn-primary"
              data-bs-dismiss="modal"
            >
              Submit
            </button>
          ) : (
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          )}
        </div>
      </div>
    </form>
  );
};

export default AddMeetingCard;
