import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import { useForm, Controller, useFormState } from "react-hook-form";
import axios from "axios";
import { VARIABLES } from "../../Constants";
import Swal from "sweetalert2";
import secureLocalStorage from "react-secure-storage";
import { ProjectContext } from "../../context/ProjectContext/ProjectContextProvider";

const EditProjects = (props) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();
  const { isDirty, isValid } = useFormState({
    control,
  });
  const { apiGetProjects } = useContext(ProjectContext);

  const [organization, setOrganzation] = useState([]);

  // const subCompanyforOrganization = props.subCompany.filter((api) => {
  //     return api.organisation.id === organization.value
  //   })

  //   const employeeforOrganization = props.userDetails.filter((api) => {
  //     return api.organisation.find(data => data.id === organization.value)
  //   })

  const onSubmit = (data) => {
    data.isConverted = data.isConverted ? true : false;
    const selectedTeamValues = data.team.map((mod) => parseInt(mod.value));

    const selectedTeam = data.team.map((mod) => mod.value);

    const url = VARIABLES.url + "/api-app/project/" + props.project.id;

    const projectEdit = JSON.stringify({
      project_incharge_id: Number(data.fkProjectIncharge_id.value),
      project_name: data.projectName,
      project_description: data.projectDescription,
      ...(data.project_division === null
        ? { subcompany: null }
        : { subcompany_id: data.project_division.value }),
      category_id: data.category_id.value,
      is_converted: data.isConverted,
      project_management_type: data.project_management.label,
      project_workflow_type: data.project_workflow_type.label,
      team_id: selectedTeamValues,
      label_id: data.tags.map((tag) => tag.value),
      project_flag_status: data.flags.label,
      updated_by_id: secureLocalStorage.getItem("userID"),
    });

    let headers = {
      "Content-Type": "application/json",
    };
    let reqOptions = {
      url: url,
      method: "PATCH",
      headers: headers,
      mode: "no-cors",
      data: projectEdit,
    };

    axios
      .request(reqOptions)
      .then(function (response) {
        Swal.fire({
          title: "Success!",
          text: "Project Information updated successfully",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => apiGetProjects());
      })
      .catch((error) => {
        console.log(error.response);
        Swal.fire({
          icon: "error",
          title: "Failed to update Project",
          text: "Something went wrong. Please try again.",
        });
      });
    reset();
  };

  useEffect(() => {
    if (props.project) {
      let defaultValues = {};
      defaultValues.projectName = props.project.project_name;
      defaultValues.projectDescription = props.project.project_description;
      defaultValues.isConverted = props.project.is_converted;

      if (props.project.project_incharge !== null) {
        const projectInchargeSelection = {
          value: props.project.project_incharge.id,
          label: props.project.project_incharge.username,
        };
        defaultValues.fkProjectIncharge_id = projectInchargeSelection;
      }

      if (props.project.subcompany !== null) {
        const getsubdivision = {
          value: props.project.subcompany.id,
          label: props.project.subcompany.name,
        };
        defaultValues.project_division = getsubdivision;
      }

      if (props.project.project_management_type !== null) {
        const project_management = {
          value: props.project.project_management_type,
          label: props.project.project_management_type,
        };
        defaultValues.project_management = project_management;
      }

      if (props.project.project_workflow_type !== null) {
        const workflow_type = {
          value: props.project.project_workflow_type,
          label: props.project.project_workflow_type,
        };
        defaultValues.project_workflow_type = workflow_type;
      }

      if (props.project.category !== null) {
        const category = {
          value: props.project.category.id,
          label: props.project.category.name,
        };
        defaultValues.category_id = category;
      }

      if (props.project.team !== null) {
        const multiTeamSelection = props.project.team.map((item) => {
          return {
            value: item.id,
            label: item.username,
          };
        });
        defaultValues.team = multiTeamSelection;
      }

      if (props.project?.label !== null) {
        const multiTeamSelection = props.project?.label.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        defaultValues.tags = multiTeamSelection;
      }

      if (props.project?.project_flag_status !== null) {
        const multiTeamSelection = {
          value: props.project?.project_flag_status,
          label: props.project?.project_flag_status,
        };
        defaultValues.flags = multiTeamSelection;
      }

      reset(defaultValues);
    }
  }, [props.project]);

  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex="-1"
      data-bs-backdrop="static"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title  fw-bold" id="createprojectlLabel">
              Edit Project
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-3">
                <label className="form-label">
                  {" "}
                  Project Incharge <span className="required_field">*</span>
                </label>
                <Controller
                  id="fkProjectIncharge_id"
                  name="fkProjectIncharge_id"
                  className="form-select"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={props.projectIncharge}
                      isClearable={true}
                    />
                  )}
                />
                {errors.fkProjectIncharge_id && (
                  <p className="required_field">
                    Project Incharge name is required
                  </p>
                )}
              </div>

              <div className="mb-3">
                <label className="form-label">
                  {" "}
                  Project Name <span className="required_field">*</span>
                </label>
                <input
                  id="project-name"
                  name="projectName"
                  className="form-control"
                  {...register("projectName", {
                    required: true,
                  })}
                  placeholder="projectName"
                />
                {errors.projectName && (
                  <p className="required_field">Project Name is required</p>
                )}
              </div>

              <div className="mb-3">
                <label className="form-label">
                  {" "}
                  Description <span className="required_field">*</span>
                </label>
                <textarea
                  id="projectDescription"
                  className="form-control"
                  {...register("projectDescription", {
                    required: true,
                  })}
                  placeholder="Project Description"
                />
                {errors.projectDescription && (
                  <p className="required_field">
                    Project Description is required
                  </p>
                )}
              </div>

              <div className="mb-3">
                <label className="form-label">
                  {" "}
                  Division <span className="required_field">*</span>
                </label>
                <Controller
                  id="project_division"
                  name="project_division"
                  className="form-select"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={props.subDivision}
                      isClearable={true}
                    />
                  )}
                />
                {errors.project_division && (
                  <p className="required_field">Division name is required</p>
                )}
              </div>

              <div className="mb-3">
                <label className="form-label">
                  {" "}
                  Project Management Type{" "}
                  <span className="required_field">*</span>
                </label>
                <Controller
                  id="project_management"
                  name="project_management"
                  className="form-select"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={props.projectManagement}
                      isClearable={true}
                    />
                  )}
                />
                {errors.project_management && (
                  <p className="required_field">
                    Project Management name is required
                  </p>
                )}
              </div>

              <div className="mb-3">
                <label className="form-label">
                  {" "}
                  Team <span className="required_field">*</span>
                </label>
                <Controller
                  id="team"
                  name="team"
                  className="form-select"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      isMulti
                      options={props.projectIncharge}
                      isClearable={true}
                    />
                  )}
                />
                {errors.team && (
                  <p className="required_field">Team name is required</p>
                )}
              </div>

              <div className="mb-3">
                <label className="form-label">
                  {" "}
                  Tags <span className="required_field">*</span>
                </label>
                <Controller
                  id="tags"
                  name="tags"
                  className="form-select"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      isMulti
                      options={props.tags}
                      isClearable={true}
                    />
                  )}
                />
                {errors.team && (
                  <p className="required_field">Tags are required</p>
                )}
              </div>

              <div className="mb-3">
                <label className="form-label">
                  {" "}
                  Priority <span className="required_field">*</span>
                </label>
                <Controller
                  id="flags"
                  name="flags"
                  className="form-select"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={props.flags}
                      isClearable={true}
                    />
                  )}
                />
                {errors.team && (
                  <p className="required_field">flag is required</p>
                )}
              </div>

              {/* <div className="mb-3">
                <label className="form-label"> Sub Company / Sub Organization </label>
                <Controller
                  id="fkSubcompany_id"
                  name="fkSubcompany_id"
                  className="form-select"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={subCompanyforOrganization.map((api) => ({
                        value: api.id,
                        label: api.name,
                      }))}
                      isClearable={true}
                    />
                  )}
                />
              </div> */}

              {/* <div className="mb-3">
                <label className="form-label"> Team <span className="required_field">*</span></label>
                <Controller
                  id="team"
                  name="team"
                  className="form-select"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select {...field} options={employeeforOrganization.map((api) => ({
                      value: api.user.id,
                      label: api.user.username
                    }))} isMulti={true} />
                  )}
                />
                {errors.team && (
                  <p className="required_field">Team is required</p>
                )}
              </div> */}

              <div className="mb-3">
                <label className="form-label">Converted</label>
                <br />
                <label className="fancy-checkbox parsley-success">
                  <input
                    type="checkbox"
                    id="isConverted"
                    className="form-check-input"
                    name="isConverted"
                    {...register("isConverted")}
                  />
                  <span className="ms-3">YES</span>
                </label>
              </div>

              <div className="mb-3">
                <label className="form-label">
                  {" "}
                  Project Category Type{" "}
                  <span className="required_field">*</span>
                </label>
                <Controller
                  id="category_id"
                  name="category_id"
                  className="form-select"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={props.categoryType}
                      isClearable={true}
                    />
                  )}
                />
                {errors.category_id && (
                  <p className="required_field">Category is required</p>
                )}
              </div>

              <div className="mb-3">
                <label className="form-label">
                  {" "}
                  Project Workflow Type{" "}
                  <span className="required_field">*</span>
                </label>
                <Controller
                  id="project_workflow_type"
                  name="project_workflow_type"
                  className="form-select"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={props.workflowType}
                      isClearable={true}
                    />
                  )}
                />
                {errors.project_workflow_type && (
                  <p className="required_field">Project Workflow is required</p>
                )}
              </div>
              <div className="modal-footer">
                {/* <button
                type="submit"
                className="btn btn-success"
              >
                Create
              </button> */}
                {isValid === true ? (
                  <button
                    type="submit"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Submit
                  </button>
                ) : (
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProjects;
