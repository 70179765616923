import React from "react";
import { useForm, useFormState } from "react-hook-form";
import Swal from "sweetalert2";
import secureLocalStorage from "react-secure-storage";
import uuid from "react-uuid";

const AddCard = ({ list, setTable, table, startDate, endDate }) => {
  const formatDate = (dateString) => {
    let dateObject = new Date(dateString);
    let monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let month = monthNames[dateObject.getMonth()];
    let day = dateObject.getDate().toString().padStart(2, "0");
    let year = dateObject.getFullYear();
    let currentYear = new Date().getFullYear();
    let formattedDate = month + " " + day + " " + year;
    return formattedDate;
  };
  const userID = secureLocalStorage.getItem("userID");
  // react hook form
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm();
  const { isValid } = useFormState({
    control,
  });

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    const start = formatDate(data.start_date);
    const end = formatDate(data.end_date);
    const startdate = new Date(data.start_date);
    const enddate = new Date(data.end_date);
    let isValid = true;
    if (
      startdate <= enddate &&
      startdate >= startDate &&
      startdate <= endDate &&
      enddate >= startDate &&
      enddate <= endDate
    ) {
      table.map((varifyItem) => {
        if (list.id === varifyItem.id) {
          varifyItem.items.map((varifyCard) => {
            if (varifyCard.name !== "") {
              const x = new Date(varifyCard.start_date);
              const y = new Date(varifyCard.end_date);
              if (
                (x >= startdate && x <= enddate) ||
                (y >= startdate && y <= enddate) ||
                (x >= startdate && y <= enddate) ||
                (x <= startdate && y >= enddate)
              ) {
                isValid = false;
              }
            }

            // if (
            // (startdate >= varifyStartDate && startdate <= varifyEndDate) ||
            // (enddate >= varifyStartDate && enddate <= varifyEndDate) ||
            //   varifyStartDate >= startdate &&
            //   varifyEndDate <= enddate
            // ) {
            //   if (varifyCard.name !== "") {
            //   }

            //   // if (varifyCard.name !== "") {
            //   //   isValid = false;
            //   // }
            // }
            // if (
            //   (varifyStartDate >= startdate && varifyEndDate <= enddate) ||
            //   (varifyStartDate >= startdate && varifyEndDate <= enddate)
            // ) {
            //   if (varifyCard.name !== "") {
            //     // isValid = false;
            //   }
            // }
          });
        }
      });

      if (isValid) {
        setTable(
          table.map((listItem) => {
            if (list.id === listItem.id) {
              const endIndex = listItem.items.findIndex(
                (itemCard) => Date.parse(itemCard.end_date) === Date.parse(end)
              );
              const tempArr = listItem.items.filter((cardItem) => {
                if (
                  !(
                    Date.parse(start) <= Date.parse(cardItem.start_date) &&
                    Date.parse(end) >= Date.parse(cardItem.start_date)
                  )
                ) {
                  return cardItem;
                }
              });
              const finalArr = [
                ...tempArr.slice(0, endIndex),
                {
                  id: uuid(),
                  name: data.card_name,
                  start_date: data.start_date,
                  end_date: data.end_date,
                },
                ...tempArr.slice(endIndex),
              ];

              return {
                ...listItem,
                items: finalArr,
              };
            }
            return listItem;
          })
        );
      } else {
        Swal.fire({
          title: "Error",
          text: "Invalid Date Entered",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } else {
      Swal.fire({
        title: "Error",
        text: "Invalid Date Entered",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
    reset();
  };

  const getResetValues = () => {
    reset();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div
          className="modal fade"
          id="createCard"
          tabIndex="-1"
          data-bs-backdrop="static"
          aria-labelledby="depaddLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title fw-bold" id="depaddLabel">
                  Create Card
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => getResetValues()}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-5 mb-3">
                    <label className="form-label">
                      Card Name <span className="required_field">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter The Card Name Here..."
                      {...register("card_name", { required: true })}
                    />
                    {errors.card_name && (
                      <span
                        className="required_field"
                        style={{ fontSize: ".9em" }}
                      >
                        Card Name is required
                      </span>
                    )}
                  </div>
                  <div className="deadline-form col-7">
                    <div className="row g-3 mb-3">
                      <div className="col-sm-6">
                        <label className="form-label">
                          Start Date <span className="required_field">*</span>{" "}
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="start_date"
                          {...register(`start_date`, {
                            required: true,
                          })}
                        />
                        {errors.start_date && (
                          <span
                            className="required_field"
                            style={{ fontSize: ".9em" }}
                          >
                            Start Date is required
                          </span>
                        )}
                      </div>
                      <div className="col-sm-6">
                        <label className="form-label">
                          End Date <span className="required_field">*</span>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="end_date"
                          {...register(`end_date`, {
                            required: true,
                          })}
                        />
                        {errors.end_date && (
                          <span
                            className="required_field"
                            style={{ fontSize: ".9em" }}
                          >
                            End Date is required
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  {/* <button type="submit" className="btn btn-success">Submit</button> */}
                  {isValid === true ? (
                    <button
                      type="submit"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Submit
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddCard;
