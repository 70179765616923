import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { VARIABLES } from "../../Constants";

const ResourceWeekSheet = () => {
  const [timeLimit, setTimeLimit] = useState([]);
  const [weekNumber, setWeekNumber] = useState(4);
  const [changeWeek, setChangeWeek] = useState([]);
  const [isLoading, setIsloading] = useState(false);

  const getREsourceData = async () => {
    // API call of Project Data and stores the data in "rawProjectData"
    try {
      setIsloading(true);
      const res = await axios.get(
        VARIABLES.url + `/api-app/resourcesheet?week=${weekNumber}`
      );
      setChangeWeek(res.data);
      setIsloading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getTimeLimitStatus = async () => {
    try {
      setIsloading(true);
      const res = await axios.get(
        VARIABLES.url + `/api-app/resourcemapping-refer`
      );
      setTimeLimit(res.data);
      setIsloading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // OnCHnage Event to get input value
  const onChangeHandler = (event) => {
    setWeekNumber(event.target.value);
    if (event.target.value === "") {
      setWeekNumber(1);
      // getREsourceData();
      return;
    }
  };

  useEffect(() => {
    getREsourceData();
    getTimeLimitStatus();
  }, [weekNumber]);

  return (
    <>
      <div className="row align-item-center">
        <div className="border-0 mb-4">
          <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            <h5 className="fw-bold mb-0">Resource Mapping Sheet</h5>
          </div>
        </div>
        <div className="col-md-12">
          <div className="card mb-3">
            <div className="card-header py-3 bg-transparent border-bottom-0">
              <div className="filter">
                <input
                  type="number"
                  placeholder="Search by Week No..."
                  className="form-control"
                  onChange={onChangeHandler}
                />
              </div>
            </div>
            {isLoading ? (
              <div className="card-body">Loading...</div>
            ) : (
              <div className="card-body basic-custome-color">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Resource Name</th>
                        <th>Project</th>
                        <th>Hours</th>
                        <th>Week No</th>
                        <th>Alerts</th>
                      </tr>
                    </thead>
                    {changeWeek.length === 0 && (
                      <tbody>
                        <tr>
                          <td></td>
                          <td>No data found</td>
                          <td></td>
                        </tr>
                      </tbody>
                    )}
                    {changeWeek.length > 0 && (
                      <tbody>
                        {changeWeek.map((task, index) => {
                          return (
                            <tr>
                              <td>
                                <span className="fw-bold">
                                  {" "}
                                  {task.employee}{" "}
                                </span>
                              </td>
                              <td>
                                {task.project.map((data) => {
                                  return (
                                    <>
                                      {" "}
                                      <ul>
                                        <li>
                                          {data.projectName} - {data.hours}
                                        </li>
                                      </ul>{" "}
                                    </>
                                  );
                                })}
                              </td>
                              <td> {task.total_hours} </td>
                              <td> {weekNumber} </td>
                              <td>
                                {/* {task.total_hours > 48 ? "Highest Time Limit Exceeds" : ""}
                            {task.total_hours < 4 ? "Hours Below the Limit" : ""} */}
                                {task.total_hours > 48
                                  ? "Highest Time Limit Exceeds"
                                  : task.total_hours < 4
                                  ? "Hours Below the Limit"
                                  : "Time is in Limit"}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ResourceWeekSheet;
