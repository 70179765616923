import axios from "axios";
import React, { useContext } from "react";
import { useEffect } from "react";
import { Controller, useForm, useFormState } from "react-hook-form";
import Select from "react-select";
import { VARIABLES } from "../../Constants";
import Swal from "sweetalert2";
import moment from "moment";
import secureLocalStorage from "react-secure-storage";
import { LeaveManagementContext } from "../../context/LeaveManagementContext/LeaveManagementProvider";

const approvalStatus = [
  { label: "Pending", value: "Pending" },
  { label: "Approved", value: "Approved" },
  { label: "Rejected", value: "Rejected" },
];

const LeaveApplicationEdit = (props) => {
  const userID = secureLocalStorage.getItem("userID");
  const {
    register,
    handleSubmit,
    watch,
    formState: { isValid, errors },
    control,
    reset,
  } = useForm();
  // const { isDirty, isValid } = useFormState({
  //   control
  // });
  const { apiGetLeaveData, apiGetLeaveDataAll, apiTotalEmployeeLeaveData } =
    useContext(LeaveManagementContext);
  const onSubmit = (data) => {
    var leaveResponse = JSON.stringify({
      employee_id: data.employee.value,
      start_date: data.start_date,
      end_date: data.end_date,
      leave_type_id: data.leave_type.value,
      approval_status: data.approval_status.value,
      reason: data.leave_reason,
      updated_by_id: userID,
    });
    var config = {
      method: "patch",
      url:
        VARIABLES.url + `/api-app/leave-management/${props.editLeaveData.id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: leaveResponse,
    };

    axios(config)
      .then((response) => {
        let leave_data = response.data;
        if (leave_data.approval_status === "Approved") {
          Swal.fire({
            title: "Success!",
            text: "Leave Approved Successfully",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            apiGetLeaveDataAll();
            apiTotalEmployeeLeaveData();
            if (data.employee.value === userID) {
              apiGetLeaveData();
            }
          });
        } else if (leave_data.approval_status === "Rejected") {
          Swal.fire({
            title: "Oops!",
            text: "Leave Rejected",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            apiGetLeaveDataAll();
            apiTotalEmployeeLeaveData();
            if (data.employee.value === userID) {
              apiGetLeaveData();
            }
          });
        } else {
          Swal.fire({
            title: "Yaa!",
            text: "Leave Details Updated Successfully",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            apiGetLeaveDataAll();
            apiTotalEmployeeLeaveData();
            if (data.employee.value === userID) {
              apiGetLeaveData();
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Error",
          text: "API request failed",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  useEffect(() => {
    if (props.editLeaveData) {
      var defaultValues = {};
      defaultValues.leave_reason = props.editLeaveData.reason;
      defaultValues.start_date = props.editLeaveData.start_date;
      defaultValues.end_date = props.editLeaveData.end_date;

      const getEmployeeName = {
        value: props.editLeaveData.employee.id,
        label: props.editLeaveData.employee.username,
      };
      defaultValues.employee = getEmployeeName;

      const getLeaveType = {
        value: props.editLeaveData.leave_type.id,
        label: props.editLeaveData.leave_type.name,
      };
      defaultValues.leave_type = getLeaveType;

      const getApprovalStatus = {
        value: props.editLeaveData.approval_status,
        label: props.editLeaveData.approval_status,
      };
      defaultValues.approval_status = getApprovalStatus;
      reset(defaultValues);
    }
  }, [props.editLeaveData]);
  return (
    <>
      <div className="modal fade" id="depedit" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title  fw-bold" id="leaveaddLabel">
                {" "}
                Leave Request
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                  <label className="form-label">
                    Employee <span className="required_field">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={props.employeeData}
                        isClearable={true}
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="employee"
                    control={control}
                  />
                  {errors.employee && (
                    <span className="required_field">
                      Employee name is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Select Leave type <span className="required_field">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={props.leaveType}
                        isClearable={true}
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="leave_type"
                    control={control}
                  />
                  {errors.leave_type && (
                    <span className="required_field">
                      Leave Type is required
                    </span>
                  )}
                </div>
                <div className="deadline-form">
                  <form>
                    <div className="row g-3 mb-3">
                      <div className="col-sm-6">
                        <label className="form-label">
                          Leave From Date{" "}
                          <span className="required_field">*</span>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="start_date"
                          {...register("start_date", { required: true })}
                        />
                        {errors.start_date && (
                          <span className="required_field">
                            Start Date is required
                          </span>
                        )}
                      </div>
                      <div className="col-sm-6">
                        <label className="form-label">
                          Leave to Date{" "}
                          <span className="required_field">*</span>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="end_date"
                          {...register("end_date", { required: true })}
                        />
                        {errors.end_date && (
                          <span className="required_field">
                            End Date is required
                          </span>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
                {/* <div className="mb-3">
                  <label for="exampleFormControlTextarea79d" className="form-label">Approval Status</label>
                  <input value="Pending" className="form-control" id="exampleFormControlTextarea79d" {...register("approval_status", { required: true })} />
                </div> */}
                <div className="mb-3">
                  <label className="form-label">
                    Approval Status <span className="required_field">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={approvalStatus}
                        isClearable={true}
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="approval_status"
                    control={control}
                  />
                  {errors.approval_status && (
                    <span className="required_field">
                      Approval Status is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Leave Reason <span className="required_field">*</span>
                  </label>
                  <textarea
                    className="form-control"
                    id="leave_reason"
                    rows="3"
                    {...register("leave_reason", { required: true })}
                  ></textarea>
                  {errors.leave_reason && (
                    <span className="required_field">
                      Leave Reason is required
                    </span>
                  )}
                </div>
                <div className="modal-footer">
                  {/* <button type="submit" className="btn btn-primary">Submit</button> */}
                  {isValid === true ? (
                    <button
                      type="submit"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Submit
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveApplicationEdit;
