import React, { useContext } from "react";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { VARIABLES } from "../../Constants";
import LeaveApplicationTable from "./LeaveApplicationTable";
import LeaveApplicationEdit from "./LeaveApplicationEdit";
import { LeaveManagementContext } from "../../context/LeaveManagementContext/LeaveManagementProvider";

const LeaveApplication = () => {
  // const [isLoading, setIsLoading] = useState(false)

  // const [rawLeaveData,setLeaveData] = useState([])

  // const [employeeData,setEmployeeData] = useState([])
  // console.log(employeeData)

  // const [leaveType,setLeaveType] = useState([])
  // console.log(leaveType)

  const {
    isLoading,
    rawLeaveDataAll: rawLeaveData,
    employeeData,
    leaveType,
    noPages,
    entire,
    currentPage,
    setEntries,
    apiGetLeaveDataAll,
  } = useContext(LeaveManagementContext);

  const [editLeaveData, setEditLeaveData] = useState(null);

  // API call of Project Data and stores the data in "rawProjectData"
  // const apiGetLeaveData = async () => {
  //   try {
  //     setIsLoading(true)
  //     const res = await axios.get(VARIABLES.url + '/api-app/leave-management');
  //     setLeaveData(res.data);
  //     console.log(res.data);
  //     setIsLoading(false)
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // // API call to store project incharge details in "projectIncharge" state
  // const getEmployeeName = async () => {
  //   try {
  //     const res = await axios.get(VARIABLES.url+"/api-app/user");
  //     setEmployeeData(
  //       res.data.map((userData) => ({
  //         value: userData.user.id,
  //         label: userData.user.username,
  //       }))
  //     );
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // // API call of Project Data and stores the data in "rawProjectData"
  // const apiLeaveType = async () => {
  //   try {
  //     const res = await axios.get(VARIABLES.url + '/api-app/leave-types');
  //     console.log(res.data)
  //     setLeaveType(
  //       res.data.map((userData) => ({
  //         value: userData.id,
  //         label: userData.name
  //       }))
  //     );
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const editLeaveTypeData = (id) => {
    const getEditedTask = rawLeaveData.find((task) => {
      return task.id === id;
    });
    setEditLeaveData(getEditedTask);
  };

  // useEffect(() => {
  //   apiGetLeaveData();
  //   apiLeaveType();
  //   getEmployeeName();
  // },[])

  return (
    <>
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            <h5 className="fw-bold mb-0">All Leave Request</h5>
          </div>
        </div>
      </div>

      <LeaveApplicationTable
        rawLeaveData={rawLeaveData}
        editLeaveTypeData={editLeaveTypeData}
        isLoading={isLoading}
        noPages={noPages}
        entire={entire}
        currentPage={currentPage}
        setEntries={setEntries}
        apiGetLeaveDataAll={apiGetLeaveDataAll}
      />

      <LeaveApplicationEdit
        leaveType={leaveType}
        // apiGetLeaveData={apiGetLeaveData}
        editLeaveData={editLeaveData}
        employeeData={employeeData}
      />
    </>
  );
};

export default LeaveApplication;
