import React, { useContext, useEffect, useState } from "react";
import { DrawerContext } from "../../../context/DrawerContextProvider";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { ListContext } from "../../../context/ListContextProvider";
import { useMediaQuery } from "react-responsive";
import PriorityListDropdown from "./dropdown/PriorityListDropdown";
import CalendarDropdown from "./dropdown/CalendarDropdown";
// import TagsDropdown from "./dropdown/TagsDropdown";
import Select from "react-select";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { UsersContext } from "../../../context/UsersContext/UsersContextProvider";
import { Controller, useForm, useFormState } from "react-hook-form";
import Swal from "sweetalert2";
import AddActions from "./AddActions";
// import { OrganisationContext } from "../../../context/OrganisationContext/OrganisationContextProvider";

function CardDrawer() {
  // const [isOpen, setIsOpen] = useState(false);
  const { isOpen, setIsOpen, currentItem, currentItemId } =
    useContext(DrawerContext);
  const isMobile = useMediaQuery({
    query: "(max-width: 480px)",
  });
  const userID = secureLocalStorage.getItem("userID");
  const { users } = useContext(UsersContext);
  const { patchRegularCard } = useContext(ListContext);

  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const { isValid } = useFormState({ control });

  const onSubmit = (e) => {
    if (new Date(e.start_date) > new Date(e.end_date)) {
      reset({
        rc_name: currentItemId.value,
        start_date: currentItemId.startDate,
        end_date: currentItemId.endDate,
        rc_description: currentItemId.description,
        users:
          currentItemId?.card_type === "regularcard"
            ? currentItemId.assigned_to.map((user) => {
                return {
                  value: user.id,
                  label: user.first_name,
                };
              })
            : currentItemId.assigned_to,
      });
      setIsOpen(!isOpen);
      Swal.fire({
        title: "Error",
        text: "Invalid dates input",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }
    const newRegularCard = JSON.stringify({
      updated_by_id: userID,
      card_title: e.rc_name,
      start_time: e.start_date,
      end_time: e.end_date,
      card_description: e?.rc_description,
      assigned_to_id: e?.users?.map((user) => user.value),
    });
    patchRegularCard(currentItemId.card_identification, newRegularCard);
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (currentItemId !== null) {
      reset({
        rc_name: currentItemId.value,
        start_date: currentItemId.startDate,
        end_date: currentItemId.endDate,
        rc_description: currentItemId.description,
        agenda: currentItemId.agenda,
        users:
          currentItemId?.card_type === "regularcard"
            ? currentItemId.assigned_to.map((user) => {
                return {
                  value: user.id,
                  label: user.first_name,
                };
              })
            : users.filter((e) => currentItemId.assigned_to.includes(e.value)),
      });
    }
  }, [currentItemId]);

  return (
    <Drawer
      open={isOpen}
      onClose={() => {
        setIsOpen(!isOpen);
        // getProjectResetValues();
      }}
      direction="right"
      className="bla bla bla index-drawer"
      size={isMobile ? "80%" : "450px"}
      zIndex={2000}
    >
      <div
        style={{
          margin: "0px 10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "55px",
        }}
      >
        <span style={{ fontSize: "20px", marginTop: "5px" }}>
          {currentItemId?.card_type === "regularcard"
            ? "Update Card"
            : "Card Details"}
        </span>
        <i
          className="bi bi-x"
          style={{
            cursor: "pointer",
            fontSize: "35px",
          }}
          onClick={() => {
            setIsOpen(!isOpen);
            // getProjectResetValues();
          }}
        ></i>
      </div>
      <div style={{ height: "calc(100dvh - 55px)", overflowY: "auto" }}>
        <form onSubmit={handleSubmit(onSubmit)} className="px-3 mb-2">
          <div className="row mb-3">
            {currentItemId?.card_type === "structuralcard" && (
              <div className="col-12">
                <h4>{currentItemId?.project_name}</h4>
              </div>
            )}
            <div className="col-12">
              <label className="form-label">
                Name <span className="required_field ">*</span>
              </label>
              <input
                id="rc-name"
                name="rc-name"
                className="DrawerInput"
                {...register("rc_name", {
                  required: true,
                })}
                placeholder="Card Name"
                disabled={currentItemId?.card_type !== "regularcard"}
              />
              {errors.rc_name && (
                <p className="required_field error">Card Name is Required</p>
              )}
            </div>
          </div>
          {currentItemId?.card_type === "meetingcard" && (
            <div className="row mb-3">
              <div className="col-12">
                <label className="form-label">
                  Agenda <span className="required_field ">*</span>
                </label>
                <input
                  id="agenda"
                  name="agenda"
                  className="DrawerInput"
                  {...register("agenda", {
                    required: true,
                  })}
                  placeholder="Agenda"
                />
                {errors.agenda && (
                  <p className="required_field error">
                    Agenda Name is Required
                  </p>
                )}
              </div>
            </div>
          )}
          <div className="row mb-3 ">
            <div className="col-12">
              <label className="form-label">
                {currentItemId?.card_type === "meetingcard"
                  ? "Notes"
                  : "Description"}
              </label>
              <textarea
                id="rc-description"
                className="DrawerInput"
                {...register("rc_description")}
                placeholder={
                  currentItemId?.card_type === "meetingcard"
                    ? "Notes..."
                    : "Description..."
                }
                disabled={currentItemId?.card_type !== "regularcard"}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-6">
              <label className="form-label">
                Start Date
                <span className="required_field">*</span>
              </label>
              <input
                type="date"
                className="DrawerInput"
                id="start_date"
                {...register(`start_date`, {
                  required: true,
                })}
                disabled={currentItemId?.card_type !== "regularcard"}
              />
              {errors.start_date && (
                <span className="required_field error">
                  Start Date is required
                </span>
              )}
            </div>
            <div className="col-6">
              <label className="form-label">
                End Date <span className="required_field ">*</span>
              </label>
              <input
                type="date"
                className="DrawerInput"
                id="end_date"
                {...register(`end_date`, {
                  required: true,
                })}
                disabled={currentItemId?.card_type !== "regularcard"}
              />
              {errors.end_date && (
                <span className="required_field error">
                  End Date is required
                </span>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-2 mb-3 ">
              <label className="form-label">
                {currentItemId?.card_type === "meetingcard"
                  ? "Attendies"
                  : "Assigned To"}
                <span className="required_field ">*</span>
              </label>
              <Controller
                name={`users`}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={users}
                    isMulti
                    isClearable={true}
                    isDisabled={currentItemId?.card_type !== "regularcard"}
                  />
                )}
              />
              {errors.users && (
                <span className="required_field error">
                  {currentItemId?.card_type === "meetingcard"
                    ? "Attendies"
                    : "Users"}
                  are required
                </span>
              )}
            </div>
          </div>
          {/* <div className="row">
          <div className="col-12 mt-2 mb-3 ">
            <label className="form-label">
              Organization <span className="required_field ">*</span>
            </label>
            <Controller
              name={`org_created_by`}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select {...field} options={organisation} isClearable={true} />
              )}
            />
            {errors.org_created_by && (
              <p className="required_field error">
                Organisation name is required
              </p>
            )}
          </div>
        </div> */}

          {currentItemId?.card_type === "regularcard" && (
            <div className="row ">
              <div className="col-12 mt-auto">
                {isValid === true ? (
                  <button
                    type="submit"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Update
                  </button>
                ) : (
                  <button type="submit" className="btn btn-primary">
                    Update
                  </button>
                )}
              </div>
            </div>
          )}
        </form>
        {currentItemId?.card_type === "meetingcard" && (
          <AddActions currentItemId={currentItemId} />
        )}
      </div>
    </Drawer>
  );
}

export default CardDrawer;
