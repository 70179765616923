import React from "react";
import { Doughnut } from "react-chartjs-2";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";
// IMP Elements require to implement charts.js
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
ChartJS.register(ArcElement, Tooltip, Legend);

// Option to present how the chart will present
export const options = {
  cutout: 90,
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: "bottom",
    },
  },
};

const LeaveAvaliableChart = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [totalLeaveAvaliable, setTotalLeaveAvaliable] = useState([]);

  const userID = secureLocalStorage.getItem("userID");

  // API call of Project Data and stores the data in "rawProjectData"
  const apiTotalLeave = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        VARIABLES.url +
          "/api-app/leave-available?employee_id=" +
          parseInt(userID)
      );
      setTotalLeaveAvaliable(res.data.data[0]);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    apiTotalLeave();
  }, []);

  const data = {
    labels: ["Casual Leave", "Earned Leave", "Sick Leave"],
    // [Year in array => Year1 , Year2 , Year3]
    datasets: [
      {
        data:
          totalLeaveAvaliable.length === 0 ? (
            <p>Loading...</p>
          ) : (
            [
              totalLeaveAvaliable[0].casual_leaves,
              totalLeaveAvaliable[0].earned_leaves,
              totalLeaveAvaliable[0].sick_leaves,
            ]
          ),
        // [Population in array =>  745674, 758758, 758888...]
        backgroundColor: ["#8094ae", "#ffa353", "#1ee0ac"],
        borderWidth: 3,
      },
    ],
  };
  return (
    <>
      {/* <div style={{ width : 300, marginBottom : "15px"}} className="style_chart"> */}
      {isLoading ? (
        <div className="card-body">Loading...</div>
      ) : (
        <>
          {totalLeaveAvaliable.length === 0 && (
            <div className="row">
              <div className="col-sm-12">
                <div className="card-body">No Leave Avaliable</div>
              </div>
            </div>
          )}
          {totalLeaveAvaliable.length > 0 && (
            <Doughnut data={data} options={options} />
          )}
        </>
      )}
    </>
  );
};

export default LeaveAvaliableChart;
