// import React,{useEffect,useState} from 'react'
import Select from "react-select";
import { useForm, Controller, useFormState } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { VARIABLES } from "../../Constants";
import Swal from "sweetalert2";
import secureLocalStorage from "react-secure-storage";

const AddNoticeBoard = (props) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      description: "",
      department: null,
      displayTo: null,
    },
  });

  const userID = secureLocalStorage.getItem("userID");

  const { isDirty, isValid } = useFormState({
    control,
  });

  const [minDate, setMinDate] = useState(new Date());
  const [minTime, setMinTime] = useState(new Date());

  const getMaxDate = () => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate() + 360);
  };

  const getMaxTime = (selectedDate = new Date()) => {
    const now = new Date();
    const isCurrentDate =
      selectedDate.getDate() === now.getDate() &&
      selectedDate.getMonth() === now.getMonth() &&
      selectedDate.getFullYear() === now.getFullYear();

    if (isCurrentDate) {
      // If selectedDate is the current date, allow all future times
      return new Date(1970, 0, 1, 23, 59, 59);
    } else {
      // Otherwise, allow all future times
      return new Date(1970, 0, 1, 23, 59, 59);
    }
  };

  const onSubmit = (data) => {
    const selectedTeamValues = data.displayTo.map((mod) => parseInt(mod.value));
    const url = VARIABLES.url + "/api-app/noticeboard";
    let headers = {
      "Content-Type": "application/json",
    };

    const addNoticeBoard = JSON.stringify({
      title: data.title,
      description: data.description,
      department_id: data.department.value,
      published_on: data.publishedOn.toISOString().substring(0, 19) + "Z",
      published_by_id: userID,
      display_to_id: selectedTeamValues,
      updated_by_id: userID,
      created_by_id: userID,
    });

    let reqOptions = {
      url: url,
      method: "POST",
      headers: headers,
      mode: "no-cors",
      data: addNoticeBoard,
    };
    axios
      .request(reqOptions)
      .then(function (response) {
        Swal.fire({
          title: "Success!",
          text: "Notice Board Created Successfully",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => props.apiGetRawNoticeBoard()); // reload page after user clicks OK
      })
      .catch((error) => {
        console.log("Caught error");
        console.log(error.response);
        Swal.fire({
          title: "Error",
          text: "Failed to Create Notice Board",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
    reset();
  };

  const getResetValues = () => {
    reset();
  };

  return (
    <div
      className="modal fade"
      id="noticeboard"
      tabIndex="-1"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title  fw-bold" id="createprojectlLabel">
              Create Notice
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => getResetValues()}
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-3">
                <label className="form-label">
                  Title <span className="required_field">*</span>
                </label>
                <input
                  id="title"
                  name="title"
                  className="form-control"
                  {...register("title", {
                    required: true,
                  })}
                  placeholder="Enter the Title"
                />
                {errors.title && (
                  <p className="required_field">Title field is required</p>
                )}
              </div>
              <div className="mb-3">
                <label className="form-label">
                  Description <span className="required_field">*</span>
                </label>
                <textarea
                  id="description"
                  name="description"
                  className="form-control"
                  {...register("description", {
                    required: true,
                  })}
                  placeholder="Enter your Description"
                />
                {errors.description && (
                  <p className="required_field">
                    Description field is required
                  </p>
                )}
              </div>
              <div className="mb-3">
                <label className="form-label">
                  {" "}
                  Department <span className="required_field">*</span>
                </label>
                <Controller
                  id="department"
                  name="department"
                  className="form-select"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select {...field} options={props.department} />
                  )}
                />
                {errors.department && (
                  <p className="required_field">Department field is required</p>
                )}
              </div>
              <div className="mb-3">
                <label className="form-label">
                  {" "}
                  Display To <span className="required_field">*</span>
                </label>
                <Controller
                  id="displayTo"
                  name="displayTo"
                  className="form-select"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={props.userDetailsDisplay}
                      isMulti={true}
                      isLoading={props.isUsersLoading}
                    />
                  )}
                />
                {errors.displayTo && (
                  <p className="required_field">Display To field is required</p>
                )}
              </div>
              <div className="mb-3">
                <label className="form-label">
                  {" "}
                  Published On <span className="required_field">*</span>
                </label>
                <Controller
                  name="publishedOn"
                  control={control}
                  rules={{ required: "Published On is required" }}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <DatePicker
                        selected={value}
                        onChange={(date) => {
                          onChange(date);
                          setMinDate(date);
                          setMinTime(date);
                        }}
                        showTimeSelect
                        dateFormat="yyyy-MM-dd HH:mm:ss"
                        timeFormat="HH:mm:ss"
                        timeIntervals={1}
                        timeCaption="Time"
                        minDate={minDate}
                        minTime={minTime}
                        maxDate={getMaxDate()}
                        maxTime={getMaxTime(value)}
                        className="form-control"
                      />
                      {errors.publishedOn && (
                        <p className="required_field">
                          {errors.publishedOn.message}
                        </p>
                      )}
                    </>
                  )}
                />
              </div>

              <div className="modal-footer">
                {/* <button
                  type="submit"
                  className="btn btn-success"
                >
                  Create
                </button> */}
                {isValid === true ? (
                  <button
                    type="submit"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Submit
                  </button>
                ) : (
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNoticeBoard;
