import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MissedTimesheetsList from "./MissedTimesheetsList";
import TimesheetProjectWiseTime from "./TimesheetProjectWiseTime";

const DashboardViewTimesheet = () => {
  const location = useLocation();
  const selectedEmp = location.state.data;
  const navigate = useNavigate();
  return (
    <div className="py-3">
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            <h5 className="fw-bold mb-0">Timesheet Dashboard</h5>
            <button className="btn btn-primary" onClick={() => navigate(-1)}>
              Back
            </button>
          </div>
        </div>
      </div>
      <div className="row align-item-center">
        <div className="row my-3">
          <div className="col-4">
            <MissedTimesheetsList
              timesheets={selectedEmp?.missed_dates || []}
            />
          </div>
          <div className="col-4">
            <TimesheetProjectWiseTime
              timespend={selectedEmp?.total_projects?.projects || []}
            />
          </div>
          <div className="col-4">
            {/* <HoursInfoModal hours={selectedEmp?.project} /> */}
          </div>
        </div>
        <div className="row my-3">
          <div className="col-6">
            {/* <TicketInfoModal tickets={selectedEmp?.ticket_info} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardViewTimesheet;
