import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import secureLocalStorage from "react-secure-storage";

const DevRelease = (props) => {
  const privileges = secureLocalStorage.getItem("privileges");

  const releaseByDev = props.rawReleaseData.filter((item) => {
    return item.release_category === "developer";
  });

  // Search Filter OnChange from input field
  const [filterProjectReleases, setFilterProjectReleases] = useState([]);

  // OnCHnage Event to get input value
  const onChangeHandler = (event) => {
    if (event.target.value === "") {
      setFilterProjectReleases(releaseByDev);
      return;
    }
    let filterReleasesData = releaseByDev.filter(
      (item) =>
        item.project.project_name.toLowerCase().includes(event.target.value) ||
        item.incharge.username.toLowerCase().includes(event.target.value)
    );
    setFilterProjectReleases(filterReleasesData);
  };

  useEffect(() => {
    setFilterProjectReleases(
      props.rawReleaseData.filter((item) => {
        return item.release_category === "developer";
      })
    );
  }, [props.rawReleaseData]);

  return (
    <>
      <div className="row align-item-center">
        <div className="col-md-12">
          <div className="card mb-3">
            <div className="card-header py-3 bg-transparent border-bottom-0">
              {/* <h6 className="mb-0 fw-bold ">Releases</h6> */}
              <div className="filter">
                <input
                  type="text"
                  placeholder="Search by Project name/Incharge name..."
                  className="form-control"
                  onChange={onChangeHandler}
                />
              </div>
            </div>
            {props.isLoading ? (
              <div className="card-body">Loading...</div>
            ) : (
              <div className="card-body basic-custome-color">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Project</th>
                        <th>Incharge</th>
                        <th>Type</th>
                        <th> A </th>
                        <th> B </th>
                        <th> C </th>
                        <th> D </th>
                        <th> E </th>
                        <th> Version </th>
                        <th> Release Status </th>
                        <th> Action </th>
                      </tr>
                    </thead>
                    {props.rawReleaseData.length === 0 && (
                      <tbody>
                        <tr>
                          <td></td>
                          <td>No data found</td>
                          <td></td>
                        </tr>
                      </tbody>
                    )}
                    {filterProjectReleases.length > 0 ? (
                      <tbody>
                        {filterProjectReleases.map((item) => {
                          const getReleaseData =
                            item.release_version.split(".");
                          const major_release = getReleaseData[0]
                            .split("")
                            .map((word) => word.charAt(0));
                          return (
                            <tr>
                              <td>
                                {" "}
                                {item.project.project_name} /{" "}
                                {item.sub_project == "-"
                                  ? "Intg"
                                  : item.sub_project.name}{" "}
                              </td>
                              <td> {item.incharge.username} </td>
                              <td>
                                {" "}
                                {item.release_category} /{" "}
                                {item.release_sub_category}{" "}
                              </td>
                              <td> {major_release[2]} </td>
                              <td> {getReleaseData[1]} </td>
                              <td> {getReleaseData[2]} </td>
                              <td> {getReleaseData[3]} </td>
                              <td> {getReleaseData[4]} </td>
                              <td> {item.release_version} </td>
                              <td>
                                {" "}
                                <span
                                  className={`${
                                    item.release_status === "completed"
                                      ? "badge rounded-pill light-info-bg text-success"
                                      : item.release_status === "discard"
                                      ? "badge rounded-pill text-danger light-info-bg"
                                      : "badge rounded-pill light-info-bg text-info"
                                  }`}
                                >
                                  {item.release_status}
                                </span>{" "}
                              </td>
                              {privileges.includes("edit_project_releases") ? (
                                <td>
                                  {" "}
                                  <button
                                    type="button"
                                    className="btn btn-outline-secondary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#releaseEdit"
                                    onClick={() =>
                                      props.editReleaseNote(item.id)
                                    }
                                  >
                                    <i className="icofont-edit text-success"></i>
                                  </button>
                                </td>
                              ) : (
                                ""
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td></td>
                          <td>No Data Found</td>
                          <td></td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DevRelease;
