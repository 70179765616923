import React from "react";

const TargetManagement = () => {
  return (
    <>
      <div className="row">
        <div className="col-lg-8 col-md-8 col-sm-12">
          <div className="card">
            <div className="title_card">
              <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                <h6 className="mb-0 fw-bold ">
                  <i className="icofont-edit me-2 fs-6"></i> SRS
                </h6>
              </div>
            </div>
            <div className="card-body">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="form-label">
                    <i className="icofont-ghost me-2 fs-6"></i> Description
                  </label>
                  <textarea
                    className="form-control"
                    rows="5"
                    cols="30"
                    required=""
                    placeholder="Add Description"
                  ></textarea>
                </div>
              </div>

              <div className="col-md-12 mt-5">
                <div className="form-group">
                  <label className="form-label mb-4">
                    <i className="icofont-ghost me-2 fs-6"></i> Activity
                  </label>
                  <div className="form-group">
                    <div className="d-flex">
                      <a className="avatar_comments avatar rounded-circle bg-primary text-white">
                        VU
                      </a>
                      <input
                        className="form-control"
                        placeholder="Add comments"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12">
          <div className="card">
            <div className="card-body">
              <p className="text-muted fw-bold">Add to card</p>
              <button
                className="btn btn-width btn-secondary"
                data-bs-toggle="modal"
                data-bs-target="#createproject"
              >
                <i className="icofont-label me-2 fs-6"></i> Labels
              </button>
              <button className="btn btn-width btn-secondary">
                <i className="icofont-checked me-2 fs-6"></i>Checklist
              </button>
              <button className="btn btn-width btn-secondary">
                <i className="icofont-clock-time me-2 fs-6"></i>Date
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TargetManagement;
