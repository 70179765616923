import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm, useFormState } from "react-hook-form";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";
import Select from "react-select";
import { UsersContext } from "../../context/UsersContext/UsersContextProvider";
import { DropdownAPIsContext } from "../../context/DropdownAPIsContext/DropdownAPIsContextProvider";
import Swal from "sweetalert2";

const CreateProjectDoc = ({ project, getDocuments }) => {
  const [projectList, setProjectList] = useState([]);
  const [doctypeList, setDoctypeList] = useState([]);
  const { projects, typeOfDoc } = useContext(DropdownAPIsContext);
  const { users } = useContext(UsersContext);
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      link: "",
      comments: "",
      name: "",
    },
  });
  const { isValid } = useFormState({
    control,
  });

  const isGoogleDocsOrSheetsLink = (value) => {
    // Regular expression to match Google Docs or Google Sheets links
    const googleDocsRegex =
      /^https:\/\/docs\.google\.com\/(document|spreadsheets)\/d\//;

    return googleDocsRegex.test(value);
  };

  const onSubmit = (data) => {
    console.log(data);
    let genDocTempData = JSON.stringify({
      name: data.name,
      type_of_document_id: data.document_type.value,
      project_id: data.project.value,
      number: 4,
      owner_id: data.employees.value,
      cross_department: false,
      link: data.link,
      comments: data.comments,
      doc_status: "live",
      created_by_id: secureLocalStorage.getItem("userID"),
      updated_by_id: secureLocalStorage.getItem("userID"),
    });

    const url = VARIABLES.url + "/api-app/gen-doc-temp";

    let reqOptions = {
      url: url,
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      mode: "no-cors",
      data: genDocTempData,
    };

    axios
      .request(reqOptions)
      .then(function (response) {
        Swal.fire(
          "Tag " + response.data.generated_tag,
          "generated for " + response.data.name,
          "success"
        );
        getDocuments();
      })
      .catch((error) => {
        console.log("Caught error");
        console.log(error.response);
        Swal.fire(
          "Tag for " + data.name,
          "was not generated. Please try again.",
          "error"
        );
      });
  };
  useEffect(() => {
    if (typeOfDoc.length !== 0) {
      setDoctypeList(
        typeOfDoc.map((type) => {
          return {
            value: type.id,
            label: type.name,
          };
        })
      );
    }
  }, [typeOfDoc]);

  useEffect(() => {
    if (projects.length !== 0) {
      setProjectList(
        projects.map((proj) => {
          return {
            value: proj.id,
            label: proj.project_name,
          };
        })
      );
    }
  }, [projects]);

  useEffect(() => {
    if (project) {
      reset({
        project: projectList.filter((p) => p.value === project)[0],
        document_type: "",
        employees: "",
        name: "",
        link: "",
        comments: "",
      });
    }
  }, [project]);
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3">
          <label className="form-label">
            Project <span className="required_field">*</span>
          </label>
          <Controller
            render={({ field }) => (
              <Select {...field} options={projectList} isDisabled />
            )}
            rules={{ required: true }}
            name="project"
            control={control}
          />
          {errors.project && (
            <span className="required_field">Project name is required</span>
          )}
        </div>
        <div className="mb-3">
          <label className="form-label">
            Document Type <span className="required_field">*</span>
          </label>
          <Controller
            render={({ field }) => (
              <Select {...field} options={doctypeList} isClearable={true} />
            )}
            defaultValue=""
            rules={{ required: true }}
            name="document_type"
            control={control}
          />
          {errors.document_type && (
            <span className="required_field">Document Type is required</span>
          )}
        </div>
        <div className="mb-3">
          <label className="form-label">
            Owner <span className="required_field">*</span>
          </label>
          <Controller
            render={({ field }) => (
              <Select {...field} options={users} isClearable={true} />
            )}
            defaultValue=""
            rules={{ required: true }}
            name="employees"
            control={control}
          />
          {errors.employees && (
            <span className="required_field">Employee name is required</span>
          )}
        </div>

        <div className="mb-3">
          <label className="form-label">
            Document name <span className="required_field">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Document Name"
            {...register("name", { required: true })}
          />
          {errors.name && (
            <span className="required_field">Document name is required</span>
          )}
        </div>

        <div className="mb-3">
          <label className="form-label">
            Link to the document <span className="required_field">*</span>
          </label>
          {/* <input type="text" className="form-control" placeholder="Enter link to the document" {...register("link", { required: true })} />
      {errors.link && <span className='required_field'>Link to the document is required</span>} */}

          <Controller
            name="link"
            control={control}
            defaultValue=""
            rules={{
              required: "This field is required",
              validate: isGoogleDocsOrSheetsLink, // Custom validation function
            }}
            render={({ field }) => (
              <div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter link to the document"
                  {...field}
                />
                {errors.link && (
                  <span className="required_field">
                    Google Doc or Google sheet Link to the document is required
                  </span>
                )}
              </div>
            )}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="Comments" className="form-label">
            Comments (optional)
          </label>
          <textarea
            className="form-control"
            name="comments"
            id="comments"
            {...register("comments")}
            rows="3"
            placeholder="Add necessary details if any"
          ></textarea>
        </div>
        <div className="mb-3">
          {isValid === true ? (
            <button
              type="submit"
              className="btn btn-primary"
              data-bs-dismiss="modal"
            >
              Submit
            </button>
          ) : (
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          )}
        </div>
      </form>
    </>
  );
};

export default CreateProjectDoc;
