import React, { useContext } from "react";
import { DropdownAPIsContext } from "../../../context/DropdownAPIsContext/DropdownAPIsContextProvider";

const TicketInfoModal = ({ tickets }) => {
  const { ticketStatus } = useContext(DropdownAPIsContext);
  return (
    <div className="card shadow">
      <div className="card-header">
        <div className="card-title">
          <h5>Raised Tickets</h5>
        </div>
      </div>
      <div className="card-body basic-custome-color p-0 m-0">
        <div className="table-responsive" style={{ maxHeight: "300px" }}>
          <table className="table">
            <thead>
              <tr
                className="bg-light"
                style={{
                  position: "sticky",
                  top: 0,
                }}
              >
                <th>Sr. No</th>
                <th>Ticket</th>
                <th>Type</th>
                <th>Status</th>
              </tr>
            </thead>
            {tickets === 0 ? (
              <tbody>
                <tr>
                  <td></td>
                  <td>No data found</td>
                  <td></td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {tickets &&
                  tickets.map((ticket, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{ticket?.ticket_description}</td>
                      <td>{ticket?.ticket_type}</td>
                      <td className="d-flex align-items-center justify-content-center">
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{
                            backgroundColor: "black",
                            height: "38px",
                            width: "38px",
                            borderRadius: "5px",
                          }}
                        >
                          <div
                            className="d-flex align-items-center justify-content-center"
                            style={{
                              backgroundColor: "black",
                              height: "38px",
                              width: "38px",
                              borderRadius: "5px",
                            }}
                          >
                            {ticketStatus.filter(
                              (status) =>
                                parseInt(status.value) ===
                                parseInt(ticket?.ticket_status)
                            )[0]?.label === "open" ? (
                              <div
                                style={{
                                  height: "30px",
                                  width: "30px",
                                  borderRadius: "50%",
                                  backgroundColor: "red",
                                }}
                              ></div>
                            ) : ticketStatus.filter(
                                (status) =>
                                  parseInt(status.value) ===
                                  parseInt(ticket?.ticket_status)
                              )[0]?.label === "Solved" ? (
                              <div
                                style={{
                                  height: "30px",
                                  width: "30px",
                                  borderRadius: "50%",
                                  backgroundColor: "yellow",
                                }}
                              ></div>
                            ) : (
                              <div
                                style={{
                                  height: "30px",
                                  width: "30px",
                                  borderRadius: "50%",
                                  backgroundColor: "green",
                                }}
                              ></div>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default TicketInfoModal;
