import React, { useContext, useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { Controller, useForm, useFormState } from "react-hook-form";
import axios from "axios";
import { VARIABLES } from "../../Constants";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import { UsersContext } from "../../context/UsersContext/UsersContextProvider";

function AssignEPC() {
  const privileges = secureLocalStorage.getItem("privileges");
  // Search Filter OnChange from input field
  const [filterUserEPC, setFilterUserEPC] = useState([]);

  const {
    noPages,
    entire,
    setEntries,
    currentPage,
    apiGetRawUserManagement,
    isUsersDataLoading,
    rawDataUserManagement,
  } = useContext(UsersContext);

  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (rawDataUserManagement.length !== 0) {
      setUsers(
        rawDataUserManagement.map((userData) => ({
          id: userData.user.id + "",
          name: userData.user.first_name + " " + userData.user.last_name,
          epc: userData?.epc_no || "",
          card_no: userData.card_no,
          emp_id: userData.employee_id,
        }))
      );
    }
  }, [rawDataUserManagement]);

  const onChangeHandler = (event) => {
    if (event.target.value === "") {
      setFilterUserEPC(users);
      return;
    }
    let filterUserEPCData = users.filter((item) =>
      item.name.toLowerCase().includes(event.target.value)
    );
    setFilterUserEPC(filterUserEPCData);
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const { isDirty, isValid, dirtyFields } = useFormState({
    control,
  });

  const onSubmit = (data) => {
    const values = Object.keys(data).map((key) => {
      return { user_id: key, epc_no: data[key] };
    });
    const keys = Object.keys(dirtyFields).map((key) => key);
    const res = values
      .map((value) => {
        if (keys.includes(value.user_id)) {
          return value;
        }
      })
      .filter((item) => item !== undefined);
    const url = VARIABLES.url + `/api-app/bulk-update-epc/`;
    var config = {
      method: "patch",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: res,
    };
    axios(config)
      .then((response) => {
        Swal.fire({
          title: "Success!",
          text: "EPC Number Assigned Successfully",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => apiGetRawUserManagement());
      })
      .catch((error) => {
        console.log(error.response);
        Swal.fire({
          title: "Error",
          text: error.response.data.error,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
    reset();
  };

  useEffect(() => {
    if (users.length !== 0) {
      setFilterUserEPC(users);
      if (users) {
        const obj = {};
        users.map((user) => {
          obj[user.id] = user.epc;
        });
        reset(obj);
      }
    }
  }, [users]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="row align-items-center">
          <div className="border-0 mb-4">
            <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
              <h5 className="fw-bold py-3 mb-0">Assign EPC</h5>
              {privileges.includes("assign_epc") ? (
                <div className="d-flex py-2 project-tab sub-project-tab flex-wrap w-sm-100">
                  <button
                    type="submit"
                    className={`btn btn-dark w-sm-100 ${
                      isDirty ? "" : "disabled"
                    }`}
                  >
                    Save
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <div className="row align-item-center">
          <div className="col-md-12">
            <div className="card mb-3">
              <div className="card-header py-3 bg-transparent border-bottom-0">
                <div className="filter">
                  <p className="search-title">What are you looking for ?</p>
                  <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1">
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </span>
                    <input
                      type="text"
                      placeholder="Search by name..."
                      className="form-control"
                      onChange={onChangeHandler}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-3">
              {isUsersDataLoading ? (
                <div className="card-body">Loading...</div>
              ) : (
                <div className="card-body basic-custome-color">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Sr. No</th>
                          <th>User</th>
                          <th>Card No</th>
                          <th>Employee Id</th>
                          <th>EPC. No</th>
                        </tr>
                      </thead>
                      {filterUserEPC.length === 0 && (
                        <tbody>
                          <tr>
                            <td></td>
                            <td>No data found</td>
                            <td></td>
                          </tr>
                        </tbody>
                      )}
                      {filterUserEPC.length > 0 && (
                        <tbody>
                          {/* {filterReportingConfiguration.map((task, index) => {
                          return ( */}
                          {filterUserEPC.map((user, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <span className="fw-bold"> {index + 1} </span>
                                </td>
                                <td>
                                  <span className="ms-1">{user.name}</span>
                                </td>
                                <td>
                                  <span className="ms-1">{user.card_no}</span>
                                </td>
                                <td>
                                  <span className="ms-1">{user.emp_id}</span>
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="epc"
                                    {...register(user.id)}
                                  />
                                </td>
                              </tr>
                            );
                          })}

                          {/* );
                        // })} */}
                        </tbody>
                      )}
                    </table>
                    <div className="d-flex justify-content-center justify-content-md-between mt-3">
                      <ReactPaginate
                        key={3}
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        pageCount={noPages}
                        initialPage={currentPage - 1}
                        marginPagesDisplayed={2}
                        disableInitialCallback={true}
                        // pageRangeDisplayed={3}
                        onPageChange={(selectedPage) => {
                          // setCurrentPage(selectedPage.selected + 1);
                          apiGetRawUserManagement(
                            selectedPage.selected + 1,
                            entire
                          );
                        }}
                        containerClassName={
                          "pagination justify-content-center justify-content-md-start"
                        }
                        pageClassName={"paginate_button page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"paginate_button page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="me-3">No of entries per page:</div>
                        <div>
                          <select
                            className="form-select"
                            value={entire}
                            onChange={(e) => {
                              setEntries(e.target.value);
                              apiGetRawUserManagement(
                                currentPage,
                                e.target.value
                              );
                            }}
                          >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default AssignEPC;
