import axios from "axios";
import React from "react";
import { VARIABLES } from "../../Constants";
import Swal from "sweetalert2";
import { useState } from "react";
import { useEffect } from "react";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import ReactPaginate from "react-paginate";

const TicketGenerateTable = (props) => {
  const privileges = secureLocalStorage.getItem("privileges");
  // Search Filter OnChange from input field
  const [filterTicket, setFilterTicket] = useState([]);
  const [defaultShow, setDefaultShow] = useState(null);
  const [viewDropdown, setViewDropdown] = useState([]);

  const handleFilterShow = (data) => {
    if (data.label === "Assigned By Me") {
      props.setShowAssignedByMe(true);
      props.setShowAll(false);
    } else {
      props.setShowAssignedByMe(false);
      props.setShowAll(data.value);
    }
  };

  // OnCHnage Event to get input value //TODO
  const onChangeHandler = (event) => {
    if (event.target.value === "") {
      setFilterTicket(props.rawDataTicketGenerate);
      return;
    }
    let filterTicketData = props.rawDataTicketGenerate.filter(
      (item) =>
        item.ticket_description
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        item.ticket_status
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        item.ticket_type
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
    );
    setFilterTicket(filterTicketData);
  };

  // Delete Function : delete the specific id
  const deleteTicket = (id) => {
    var config = {
      method: "delete",
      url: VARIABLES.url + `/api-app/ticket-management/${id}`,
      headers: {},
    };
    axios(config)
      .then((response) => {
        Swal.fire({
          icon: "success",
          text: "Deleted Successfully!",
        });
        props.setRawDataTicketGenerate(
          props.rawDataTicketGenerate.filter((user) => {
            return user.id !== id;
          })
        );
      })
      .catch((error) => {
        Swal.fire({
          icon: "warning",
          text: "Not Deleted Successfully!",
        });
        console.log(error);
      });
  };

  const deleteTicketAlert = (id) => {
    Swal.fire({
      title: "Are you sure? You will not be able to recover this ticket!",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteTicket(id);
      }
    });
  };

  useEffect(() => {
    setFilterTicket(props.rawDataTicketGenerate);
  }, [props.rawDataTicketGenerate]);

  useEffect(() => {
    if (props.showAssignedByMe) {
      setDefaultShow({ value: 1, label: "Assigned By Me" });
    } else {
      if (!props.showAll) {
        setDefaultShow({ value: false, label: "Assigned To Me" });
      } else {
        setDefaultShow({ value: true, label: "All" });
      }
    }
  }, [props.showAll, props.showAssignedByMe]);

  useEffect(() => {
    if (privileges.includes("view_all_tickets")) {
      setViewDropdown([
        { value: false, label: "Assigned To Me" },
        { value: 1, label: "Assigned By Me" },
        { value: true, label: "All" },
      ]);
    } else {
      setViewDropdown([
        { value: false, label: "Assigned To Me" },
        { value: 1, label: "Assigned By Me" },
      ]);
    }
  }, []);

  return (
    <>
      <div className="row align-item-center">
        <div className="col-md-12">
          <div className="card mb-3">
            <div className="card-header py-3 bg-transparent border-bottom-0">
              {/* <h6 className="mb-0 fw-bold ">Sub Project Table</h6> */}
              <div className="filter">
                <input
                  type="text"
                  placeholder="Search by Description/Status/Type..."
                  className="form-control"
                  onChange={onChangeHandler}
                />
              </div>
              <div className="d-flex align-items-center justify-content-end mt-3 gap-3">
                <h6 className="p-0 m-0">Filter:</h6>
                {defaultShow && (
                  <Select
                    defaultValue={defaultShow}
                    options={viewDropdown}
                    onChange={handleFilterShow}
                  />
                )}
                <Select
                  options={props.ticketType}
                  onChange={(data) => props.setType(data?.value)}
                  isClearable={true}
                />
                <Select
                  options={props.ticketStatus}
                  onChange={(data) => props.setStatus(data?.value)}
                  isClearable={true}
                />
                <Select
                  options={props.ticketApp}
                  onChange={(data) => props.setFrom(data?.value)}
                  isClearable={true}
                />
              </div>
            </div>
            {props.isLoading ? (
              <div className="card-body">Loading...</div>
            ) : (
              <div className="card-body basic-custome-color">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Ticket Description</th>
                        <th>Type</th>
                        <th>Raised by</th>
                        <th>Updated By</th>
                        <th>Status</th>
                        {["edit_sub_project", "delete_sub_project"].some(
                          (privilege) => privileges.includes(privilege)
                        ) ? (
                          <th>Action</th>
                        ) : (
                          ""
                        )}
                      </tr>
                    </thead>
                    {filterTicket.length === 0 && (
                      <tbody>
                        <tr>
                          <td></td>
                          <td>No data found</td>
                          <td></td>
                        </tr>
                      </tbody>
                    )}
                    {filterTicket.length > 0 && (
                      <tbody>
                        {filterTicket.map((task, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <span className="ms-1">
                                  {" "}
                                  {task.ticket_description}{" "}
                                </span>
                              </td>
                              <td>
                                {" "}
                                {task.ticket_type
                                  ? task.ticket_type
                                  : "NONE"}{" "}
                              </td>
                              <td> {task?.created_by?.username} </td>
                              <td> {task?.updated_by?.username} </td>
                              <td className="d-flex align-items-center justify-content-center">
                                <div
                                  className="d-flex align-items-center justify-content-center"
                                  style={{
                                    backgroundColor: "black",
                                    height: "38px",
                                    width: "38px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  {props.ticketStatus.filter(
                                    (status) =>
                                      parseInt(status.value) ===
                                      parseInt(task.ticket_status)
                                  )[0]?.label === "open" ? (
                                    <div
                                      style={{
                                        height: "30px",
                                        width: "30px",
                                        borderRadius: "50%",
                                        backgroundColor: "red",
                                      }}
                                    ></div>
                                  ) : props.ticketStatus.filter(
                                      (status) =>
                                        parseInt(status.value) ===
                                        parseInt(task.ticket_status)
                                    )[0]?.label === "Solved" ? (
                                    <div
                                      style={{
                                        height: "30px",
                                        width: "30px",
                                        borderRadius: "50%",
                                        backgroundColor: "yellow",
                                      }}
                                    ></div>
                                  ) : (
                                    <div
                                      style={{
                                        height: "30px",
                                        width: "30px",
                                        borderRadius: "50%",
                                        backgroundColor: "green",
                                      }}
                                    ></div>
                                  )}
                                </div>
                              </td>
                              <td>
                                <div
                                  className="btn-group"
                                  role="group"
                                  aria-label="Basic outlined example"
                                >
                                  {privileges.includes("edit_raised_ticket") ? (
                                    <button
                                      type="button"
                                      className="btn btn-outline-secondary"
                                      data-bs-toggle="modal"
                                      data-bs-target="#editticket"
                                      onClick={() =>
                                        props.editTicketData(task.id)
                                      }
                                    >
                                      <i className="icofont-edit text-success"></i>
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                  {privileges.includes(
                                    "delete_raised_ticket"
                                  ) ? (
                                    <button
                                      type="button"
                                      className="btn btn-outline-secondary deleterow"
                                      onClick={() => deleteTicketAlert(task.id)}
                                    >
                                      <i className="icofont-ui-delete text-danger"></i>
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                </div>
                <div className="d-flex justify-content-center justify-content-md-between mt-3">
                  <ReactPaginate
                    key={3}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={props.noPages}
                    initialPage={props.currentPage - 1}
                    marginPagesDisplayed={2}
                    disableInitialCallback={true}
                    // pageRangeDisplayed={3}
                    onPageChange={(selectedPage) => {
                      // setCurrentPage(selectedPage.selected + 1);
                      props.apiGetRawTicketGenerate(
                        selectedPage.selected + 1,
                        props.entire
                      );
                    }}
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"paginate_button page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"paginate_button page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />

                  <div className="d-flex justify-content-center align-items-center">
                    <div>
                      Total entries:{" "}
                      <span className="fw-bold me-3">
                        {/* {entryCount === null ? 0 : entryCount} */}
                        {props.entire}
                      </span>
                    </div>
                    <div className="me-3">No of entries per page:</div>
                    <div>
                      <select
                        className="form-select"
                        value={props.entire}
                        onChange={(e) => {
                          props.setEntries(e.target.value);
                          props.apiGetRawTicketGenerate(
                            props.currentPage,
                            e.target.value
                          );
                        }}
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TicketGenerateTable;
