import React, { createContext, useContext, useEffect, useState } from "react";
import { ListContext } from "./ListContextProvider";
import { TaskManagementContext } from "./TaskManagementContext/TaskManagementContextProvider";

export const DrawerContext = createContext(null);

function DrawerContextProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  const { list } = useContext(ListContext);
  const [currentListId, setCurrentListId] = useState(null);
  const [currentItemId, setCurrentItemId] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);
  const { currentBoardId } = useContext(TaskManagementContext);

  const toggleDrawer = (listId, itemId) => {
    setIsOpen((prevState) => !prevState);
    setCurrentListId(listId);
    setCurrentItemId(itemId);
  };

  useEffect(() => {
    if (!isOpen) {
      setCurrentItem(null);
    } else {
      setCurrentItem(currentBoardId);
    }
  }, [isOpen, currentBoardId]);

  const value = {
    isOpen,
    setIsOpen,
    toggleDrawer,
    currentListId,
    currentItem,
    currentItemId,
    setCurrentItem,
  };

  return (
    <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>
  );
}

export default DrawerContextProvider;
