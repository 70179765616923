import React from "react";

const ProjectBlockList = (props) => {
  return (
    <>
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <div className="card mt-3">
          <div className="card-body">
            <h6 className="fw-bold mb-3">Dependency List</h6>
            <div className="flex-grow-1">
              {props.workflowBlockData ? (
                props.workflowBlockData.dependency_list.map((list) => {
                  return (
                    <div className="py-2 d-flex align-items-center border-bottom">
                      <div className="d-flex align-items-center flex-fill">
                        <span className="avatar bg-primary rounded-circle text-white text-center d-flex align-items-center justify-content-center">
                          <i className="icofont-penalty-card"></i>
                        </span>
                        <div className="d-flex flex-column ps-3">
                          <h6 className="fw-bold mb-0 small-14">
                            {list.card_title}
                          </h6>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>Loading...</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <div className="card mt-3">
          <div className="card-body">
            <div className="d-flex align-items-center flex-fill">
              <span className="avatar lg rounded-circle text-center d-flex align-items-center justify-content-center">
                <img
                  src="assets/images/agile.png"
                  className="img-fluid"
                  alt="Critical"
                />
              </span>
              <div className="d-flex flex-column ps-3 flex-fill">
                <h6 className="fw-bold mb-0">Iterative Status</h6>
                <span className="fw-bold">7</span>
              </div>
            </div>
          </div>
        </div>

        <div className="card mt-3">
          <div className="card-body">
            <div className="d-flex align-items-center flex-fill">
              <span className="avatar lg rounded-circle text-center d-flex align-items-center justify-content-center">
                <img
                  src="assets/images/danger.png"
                  className="img-fluid"
                  alt="Critical"
                />
              </span>
              <div className="d-flex flex-column ps-3 flex-fill">
                <h6 className="fw-bold mb-0">Critical Status</h6>
                {props.workflowBlockData ? (
                  <span className="fw-bold">
                    {props.workflowBlockData.workflow_block.critical_status} %
                  </span>
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="card mt-3">
          <div className="card-body">
            <div className="d-flex align-items-center flex-fill">
              <span className="avatar lg rounded-circle text-center d-flex align-items-center justify-content-center">
                <img
                  src="assets/images/select (1).png"
                  className="img-fluid"
                  alt="Critical"
                />
              </span>
              <div className="d-flex flex-column ps-3 flex-fill">
                {props.workflowBlockData ? (
                  <h6 className="fw-bold mb-0">
                    {props.workflowBlockData.workflow_block.is_optional === true
                      ? "Complusory Block"
                      : "Optional Block"}
                  </h6>
                ) : (
                  <p>Loading...</p>
                )}

                {/* <span className="fw-bold">35%</span> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectBlockList;
