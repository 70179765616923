import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { VARIABLES } from "../../Constants";
import { DropdownAPIsContext } from "../../context/DropdownAPIsContext/DropdownAPIsContextProvider";

const DepartmentDocs = () => {
  const location = useLocation();
  const projectDetails = location.state.data;
  const [templates, setTemplates] = useState([]);
  const [materDocs, setMasterDocs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { st } = useContext(DropdownAPIsContext);
  const getDocuments = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/api-app/project-document/${projectDetails.id}?category_id=${st}`,
      headers: {},
    };
    setIsLoading(true);
    axios
      .request(config)
      .then((response) => {
        setIsLoading(false);
        setTemplates(response.data.data.tempdoc);
        setMasterDocs(response.data.data.masterdoc);
        // setDocuments(response.data.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };
  useEffect(() => {
    if (st) {
      getDocuments();
    }
  }, [projectDetails, st]);

  return (
    <>
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            <h5 className="fw-bold py-3 mb-0">{projectDetails.project_name}</h5>
            <button className="btn btn-primary" onClick={() => navigate(-1)}>
              Back
            </button>
          </div>
        </div>
      </div>
      {isLoading ? (
        <h5>Loading...</h5>
      ) : (
        <div className="row d-flex">
          <div className="col-lg-6">
            <h5 className="fw-bold py-3 mb-0">Templates</h5>
            <ol className="list-group list-group-numbered">
              {templates.length !== 0 ? (
                templates.map((doc, i) => {
                  return (
                    <li
                      key={i}
                      className="list-group-item d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <a
                          href={doc.link}
                          style={{
                            fontSize: "0.9em",
                            textDecoration: "underline",
                            color: "gray",
                            paddingLeft: "10px",
                          }}
                          target="_blank"
                        >
                          {doc.name}
                        </a>
                      </div>
                    </li>
                  );
                })
              ) : (
                <p>Templates not found</p>
              )}
            </ol>
          </div>
          <div className="col-lg-6">
            <h5 className="fw-bold py-3 mb-0">Master Docs</h5>
            <ol className="list-group list-group-numbered">
              {materDocs.length !== 0 ? (
                materDocs.map((doc, i) => {
                  return (
                    <li
                      key={i}
                      className="list-group-item d-flex justify-content-between align-items-start"
                    >
                      <div className="ms-2 me-auto">
                        <a
                          href={doc.link}
                          style={{
                            fontSize: "0.9em",
                            textDecoration: "underline",
                            color: "gray",
                            paddingLeft: "10px",
                          }}
                          target="_blank"
                        >
                          {doc.name}
                        </a>
                      </div>
                    </li>
                  );
                })
              ) : (
                <p>Master documents not found</p>
              )}
            </ol>
          </div>
        </div>
      )}
    </>
  );
};

export default DepartmentDocs;
