import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";
import Select from "react-select";
import FillMissedTimesheet from "../Forms/FillMissedTimesheet";
import { DropdownAPIsContext } from "../../context/DropdownAPIsContext/DropdownAPIsContextProvider";

function MissedTimesheet() {
  const [filter, setFilter] = useState({
    label: "week",
    value: "week",
  });
  const [missedDates, setMissedDates] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [taskDropdown, setTaskDropdown] = useState([]);
  const [projectDropdown, setProjectDropdown] = useState([]);
  const [taskLoading, setTaskLoading] = useState(false);
  const [projectLoading, setProjectLoading] = useState(false);
  const [fillDate, setFillDate] = useState(null);
  const privileges = secureLocalStorage.getItem("privileges");
  const { taskByIdDropdown, projectByIdDropdown, loadingGenericById } =
    useContext(DropdownAPIsContext);
  const loadTimesheets = async () => {
    setIsLoading(true);
    const userID = secureLocalStorage.getItem("userID");
    const url = `${VARIABLES.url}/api-app/missed-timesheet-filling/${parseInt(
      userID
    )}${filter.value !== "all" ? `?date_range=${filter.value}` : ""}`;
    let reqOptions = {
      url: url,
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      mode: "no-cors",
    };

    axios.request(reqOptions).then(function (response) {
      var data = response.data;
      setMissedDates(data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    setProjectLoading(true);
    if (projectByIdDropdown.length !== 0) {
      setProjectDropdown(
        projectByIdDropdown.map((project) => {
          return { value: project.id, label: project.project_name };
        })
      );
      setProjectLoading(false);
    } else if (!loadingGenericById) {
      setTaskLoading(false);
    }
  }, [projectByIdDropdown, loadingGenericById]);

  useEffect(() => {
    setTaskLoading(true);
    if (taskByIdDropdown.length !== 0) {
      setTaskDropdown(
        taskByIdDropdown.map((task) => {
          return {
            value: task.id,
            label: task?.card_identification_number + "- " + task?.card_title,
          };
        })
      );
      setTaskLoading(false);
    }
    if (!loadingGenericById) {
      setTaskLoading(false);
    }
  }, [taskByIdDropdown, loadingGenericById]);

  useEffect(() => {
    loadTimesheets();
  }, [filter]);
  useEffect(() => {
    loadTimesheets();
  }, []);
  return (
    <div className="row g-3 mb-3 row-deck">
      <div className="border-0">
        <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
          <h5 className="fw-bold py-3 mb-0">
            {" "}
            Missed Timesheets for {filter.label}{" "}
          </h5>
        </div>
      </div>
      {/* <div className="col-md-12">
    <div className="card mb-3">
      <button type="button" className="btn btn-sm btn-dark btn-set-task w-sm-100" onClick={handleToggleView}><i className="icofont-billboard me-2 fs-6"></i>View Workdesk</button>

    </div>
  </div> */}
      <div className="col-md-12">
        <div className="card mb-3">
          <div className="card-header py-3 d-flex justify-content-between align-items-center">
            {/* <div className="info-header">
          <h6 className="mb-0 fw-bold view-timesheet">Filter</h6>
        </div> */}
            {/* {privileges.includes('timesheet_month') ?  */}
            <div className="info-header">
              <h6> Timesheet Filter </h6>
              <Select
                onChange={setFilter}
                options={[
                  //   {
                  //     label: "all",
                  //     value: "all",
                  //   },
                  {
                    label: "week",
                    value: "week",
                  },
                  {
                    label: "month",
                    value: "month",
                  },
                ]}
                defaultValue={{
                  label: "week",
                  value: "week",
                }}
              />
            </div>
            {/* // : ""} */}
          </div>
          {isLoading || missedDates === null ? (
            <div className="card-body">Loading...</div>
          ) : (
            <div className="card-body">
              <table
                id="myProjectTable"
                className="App table table-hover align-middle mb-0"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th width="20%">Sr.No</th>
                    <th width="60%">Date</th>
                    {privileges.includes("fill_missed_timesheet") ? (
                      <th width="20%">Action</th>
                    ) : (
                      ""
                    )}
                    {/* <th width="25%">Comments</th> */}
                  </tr>
                </thead>
                {missedDates.missed_dates.length === 0 && (
                  <tbody>
                    <tr>
                      <td></td>
                      <td>No data found</td>
                      <td></td>
                    </tr>
                  </tbody>
                )}

                {missedDates.missed_dates.length > 0 && (
                  <tbody>
                    {missedDates.missed_dates.map((item, i) => (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{item}</td>
                        <td>
                          {privileges.includes("fill_missed_timesheet") ? (
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-toggle="modal"
                              data-bs-target="#createmissedtimesheet"
                              onClick={() => setFillDate(item)}
                            >
                              Fill
                            </button>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          )}
        </div>
      </div>
      <FillMissedTimesheet
        taskDropdown={taskDropdown}
        projectDropdown={projectDropdown}
        taskLoading={taskLoading}
        projectLoading={projectLoading}
        fillDate={fillDate}
        loadTimesheets={loadTimesheets}
      />
    </div>
  );
}

export default MissedTimesheet;
