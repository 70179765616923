import React, { useState, useEffect } from "react";
import axios from "axios";
import { Controller, useForm, useFormState } from "react-hook-form";
import Select from "react-select";
import Swal from "sweetalert2";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";

const ProjectCategoriesEdit = (props) => {
  // TODO : Once we get the user credentials, uncomment it.
  const userID = secureLocalStorage.getItem("userID");

  // react hook form
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const { isDirty, isValid } = useFormState({
    control,
  });

  // onSubmit handler for form submitting
  const onSubmit = (data) => {
    var form_data = JSON.stringify({
      name: data.name,
      description: data.description,
      updated_by_id: userID,
    });

    // TODO : Check the API properly from postman
    const url =
      VARIABLES.url +
      `/api-app/project-category/${props.editProjectCategories.id}`;
    var config = {
      method: "patch",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: form_data,
    };
    axios(config)
      .then((response) => {
        Swal.fire({
          title: "Success!",
          text: "Project Category Updated Successfully",
          icon: "success",
          confirmButtonText: "OK",
        });
        props.getProjectCategories();
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Error",
          text: "Failed",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
    reset();
  };

  //   pre-population of values after clicking on edit button
  useEffect(() => {
    if (props.editProjectCategories) {
      const editedProjectCategories = props.editProjectCategories;
      if (editedProjectCategories) {
        var defaultValues = {};
        defaultValues.name = editedProjectCategories.name;
        defaultValues.description = editedProjectCategories.description;
        reset(defaultValues);
      }
    }
  }, [props.editProjectCategories]);

  const getResetValues = () => {
    reset();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div
          className="modal fade"
          id="editProjectCategoryModal"
          tabIndex="-1"
          data-bs-backdrop="static"
          aria-labelledby="depaddLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title fw-bold" id="depaddLabel">
                  Edit Project Category
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => getResetValues()}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row g-4">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <label className="form-label">
                      {" "}
                      Category Name
                      <span className="required_field">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter The Category Name Here..."
                      {...register("name", { required: true })}
                    />
                    {errors.name && (
                      <span className="required_field">
                        Category Name is required
                      </span>
                    )}
                  </div>

                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <label className="form-label">
                      Description of Category
                      <span className="required_field">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter The Category Description Here..."
                      {...register("description", { required: true })}
                    />
                    {errors.description && (
                      <span className="required_field">
                        Category description is required
                      </span>
                    )}
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      {isValid === true ? (
                        <button
                          type="submit"
                          className="btn btn-primary"
                          data-bs-dismiss="modal"
                        >
                          Submit
                        </button>
                      ) : (
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ProjectCategoriesEdit;
