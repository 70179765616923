import React, { useEffect, useState } from "react";
import { VARIABLES } from "../../../Constants";
import axios from "axios";
const AddActions = ({ currentItemId }) => {
  const [cards, setCards] = useState([]);

  const getRegularCards = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/api-app/meetingcard/${currentItemId?.card_identification}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setCards(response.data.regular_cards);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getRegularCards();
  }, [currentItemId]);

  return (
    <form className="px-3 pb-3">
      {/* <div className="row">
        <div className="col-12 mt-2">
          <input className="DrawerInput" disabled />
        </div>
      </div> */}
      <div className="row">
        <div className="col-12 mb-3">
          {cards.length !== 0 && (
            <label className="form-label">
              Action <span className="required_field ">*</span>
            </label>
          )}

          {cards.map((card) => {
            return (
              <input
                className="DrawerInput mb-2"
                placeholder={`${card.card_title}`}
                disabled
              />
            );
          })}
        </div>
      </div>
    </form>
  );
};

export default AddActions;
