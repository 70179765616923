import React from "react";
import axios from "axios";
import { Controller, useForm, useFormState } from "react-hook-form";
import Select from "react-select";
import { VARIABLES } from "../../Constants";
import Swal from "sweetalert2";
import { useState } from "react";
import { useEffect } from "react";
import secureLocalStorage from "react-secure-storage";

const TeamReportingEdit = (props) => {
  const userID = secureLocalStorage.getItem("userID");
  // react hook form
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm();
  const { isDirty, isValid } = useFormState({
    control,
  });

  // onSubmit handler for form submitting
  const onSubmit = (data) => {
    var reportingConfiguration = JSON.stringify({
      employee_id: data.user.value,
      lead_id: data.lead.value,
      start_date: data.start_date,
      end_date: data.end_date,
      stop_reporting: data.stop_reporting,
      updated_by_id: userID,
    });
    const url =
      VARIABLES.url +
      `/api-app/reporting-configuration/${props.editedReportingConfigurationData.id}`;
    var config = {
      method: "patch",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: reportingConfiguration,
    };
    axios(config)
      .then((response) => {
        Swal.fire({
          title: "Success!",
          text: "Lead Updated Successfully",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => props.getReportingConfigurationData());
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Error",
          text: "Failed to Update lead",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
    reset();
  };
  const getResetValues = () => {
    reset();
  };
  // pre-population of values after clicking on edit button
  useEffect(() => {
    if (props.editedReportingConfigurationData) {
      var defaultValues = {};
      defaultValues.user = {
        value: props.editedReportingConfigurationData?.employee?.id,
        label: props.editedReportingConfigurationData?.employee?.first_name,
      };
      defaultValues.lead = {
        value: props.editedReportingConfigurationData?.lead?.id,
        label: props.editedReportingConfigurationData?.lead?.first_name,
      };
      defaultValues.start_date =
        props.editedReportingConfigurationData.start_date;
      defaultValues.end_date = props.editedReportingConfigurationData.end_date;
      defaultValues.stop_reporting =
        props.editedReportingConfigurationData.stop_reporting;
      reset(defaultValues);
    }
  }, [props.editedReportingConfigurationData]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div
          className="modal fade"
          id="editreportinglead"
          tabIndex="-1"
          data-bs-backdrop="static"
          aria-labelledby="depaddLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title fw-bold" id="depaddLabel">
                  Assign Lead
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => getResetValues()}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <label className="form-label">
                    User <span className="required_field">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={props.users}
                        isClearable={true}
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="user"
                    control={control}
                  />
                  {errors.project && (
                    <span className="required_field">
                      User name is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Lead <span className="required_field">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={props.users}
                        isClearable={true}
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="lead"
                    control={control}
                  />
                  {errors.project && (
                    <span className="required_field">
                      Lead name is required
                    </span>
                  )}
                </div>
                <div className="deadline-form">
                  <div className="row g-3 mb-3">
                    <div className="col-sm-6">
                      <label className="form-label">
                        Start Date <span className="required_field">*</span>{" "}
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="start_date"
                        {...register("start_date", { required: true })}
                      />
                      {errors.start_date && (
                        <span className="required_field">
                          Start Date is required
                        </span>
                      )}
                    </div>
                    <div className="col-sm-6">
                      <label className="form-label">
                        End Date <span className="required_field">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="end_date"
                        {...register("end_date", { required: true })}
                      />
                      {errors.end_date && (
                        <span className="required_field">
                          End Date is required
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <label className="form-label me-2">Stop Reporting</label>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="stop_reporting"
                    {...register("stop_reporting")}
                  />
                </div>
                <div className="modal-footer">
                  {/* <button type="submit" className="btn btn-success">Submit</button> */}
                  {isValid === true ? (
                    <button
                      type="submit"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Submit
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default TeamReportingEdit;
