import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { VARIABLES } from "../../Constants"; //TODO : need to check the path of Constants file.
import { NavLink } from "react-router-dom";
import TechnicalRolesTable from "./TechnicalRolesTable";
import secureLocalStorage from "react-secure-storage";
import TechnicalRolesCreate from "./TechnicalRolesCreate";
import TechnicalRolesEdit from "./TechnicalRolesEdit";
import { DropdownAPIsContext } from "../../context/DropdownAPIsContext/DropdownAPIsContextProvider";

const TechnicalRoles = () => {
  // TODO : Once we have privileges uncomment it.
  const privileges = secureLocalStorage.getItem("privileges");
  const [isLoading, setIsLoading] = useState(false);

  const { privilege } = useContext(DropdownAPIsContext);

  // "raw data" stored in the state.
  const [rawTechnicalRolesData, setRawTechnicalRolesData] = useState([]);
  const [privilegesData, setPrivilegesData] = useState([]);
  const [editTechnicalRoles, setEditTechnicalRoles] = useState(null);
  // API call to store SubProject details in "rawSubProjectData" state

  const editTechnicalRolesConfigurationData = (id) => {
    const getEditedTask = rawTechnicalRolesData.find((task) => {
      return task.id === id;
    });
    setEditTechnicalRoles(getEditedTask);
  };

  const getTechnicalRoles = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(VARIABLES.url + "/api-app/technology-role");
      setRawTechnicalRolesData(res.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (privilege.length !== 0) {
      setPrivilegesData(
        privilege.map((key) => ({
          value: key.id,
          label: key.name,
        }))
      );
    }
  }, [privilege]);

  useEffect(() => {
    getTechnicalRoles();
  }, []);

  return (
    <>
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            <h5 className="fw-bold py-3 mb-0"> Technical Roles</h5>
            <div className="d-flex">
              {privileges.includes("create_tech_role") ? (
                <div className="d-flex me-2 py-2 project-tab sub-project-tab flex-wrap w-sm-100">
                  <button
                    type="button"
                    className="btn btn-dark w-sm-100"
                    data-bs-toggle="modal"
                    data-bs-target="#addTechnicalRole"
                  >
                    <i className="icofont-plus-circle me-2 fs-6"></i> Create
                    Technical Roles{" "}
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      <TechnicalRolesTable
        rawTechnicalRolesData={rawTechnicalRolesData}
        isLoading={isLoading}
        setRawTechnicalRolesData={setRawTechnicalRolesData}
        privilegesData={privilegesData}
        editTechnicalRolesConfigurationData={
          editTechnicalRolesConfigurationData
        }
      />
      <TechnicalRolesCreate
        privilegesData={privilegesData}
        getTechnicalRoles={getTechnicalRoles}
      />
      <TechnicalRolesEdit
        privilegesData={privilegesData}
        editTechnicalRoles={editTechnicalRoles}
        getTechnicalRoles={getTechnicalRoles}
      />
    </>
  );
};

export default TechnicalRoles;
