import React from 'react'

const DocumentTable = (props) => {
  return (
    <>
    {props.isLoading ? <div className='card-body'>Loading...</div>:
        <div className='card-body'>
            <table
            id="myProjectTable"
            className="App table table-hover align-middle mb-0"
            style={{ width: "100%" }}
            >
            <thead>
                <tr>
                <th>Document Name</th>
                <th>Document Tag</th>
                <th>Status</th>
                </tr>
            </thead>
            {props.list.length === 0 && 
                <tbody>
                    <tr>
                        
                        <td>No data found</td>
                        <td></td>
                    </tr>
                </tbody>
            }
            
            {props.list.length > 0 && 
                <tbody>
                    {props.list.map((item) => (
                    <tr key={item.id}>
                        <td>{item.name}</td>
                        <td><a target='_blank' href={item.link} >{item.generated_tag}</a></td>
                        <td>{item.doc_status}</td>
                    </tr>
                    ))}
                </tbody>
            }
            </table>
        </div>
    }
    </>
  )
}

export default DocumentTable;  