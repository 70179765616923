import React, { useEffect, useState } from "react";
import { VARIABLES } from "../../Constants";
import axios from "axios";
import DocumentList from "./DocumentList";
import ProjectBlockApproval from "./ProjectBlockApproval";
import ProjectBlockList from "./ProjectBlockList";
import ProjectBlockStatus from "./ProjectBlockStatus";
import ProjectBlockSummary from "./ProjectBlockSummary";
import { useLocation, useNavigate } from "react-router-dom";

const ProjectWorkflowBlock = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [workflowBlockData, setWorkflowBlockData] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const workflowBlockId = location.state.data;
  const apiGetWorkFlowBlockData = async () => {
    try {
      setIsLoading(true);
      const url =
        VARIABLES.url + `/api-app/project-wf-block/${workflowBlockId}/`;

      const res = await axios.get(url);
      setWorkflowBlockData(res.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const changeSidebar = (section, id) => {
    props.handleSidebar(section, id);
  };

  useEffect(() => {
    apiGetWorkFlowBlockData();
  }, []);

  return (
    <>
      <div className="row align-items-center">
        <div className="card-body p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-end flex-wrap">
          <div className="d-flex py-2 project-tab flex-wrap w-sm-100">
            <button
              type="button"
              className="btn btn-outline-info w-sm-100"
              onClick={() =>
                navigate("/projectworkflow", {
                  projectId: workflowBlockData.project.id,
                })
              }
            >
              <i className="icofont-arrow-left me-2 fs-6"></i>Back
            </button>
          </div>
        </div>
      </div>
      <div className="row g-3">
        <div className="col-xxl-8 col-xl-8 col-lg-12 col-md-12">
          <div className="row g-3 mb-3">
            <ProjectBlockStatus workflowBlockData={workflowBlockData} />
          </div>
          <div className="row g-3">
            <ProjectBlockSummary workflowBlockData={workflowBlockData} />
          </div>
          <div className="row g-3 gy-5 pt-4 pb-2 row-deck">
            <ProjectBlockApproval
              workflowBlockData={workflowBlockData}
              workflowBlockId={workflowBlockId}
              apiGetWorkFlowBlockData={apiGetWorkFlowBlockData}
            />
          </div>
          <div className="row">
            <ProjectBlockList workflowBlockData={workflowBlockData} />
          </div>
        </div>
        <div className="col-xl-4 col-xxl-4 col-lg-4 col-md-6 col-sm-12">
          <DocumentList
            isLoading={isLoading}
            workflowBlockData={workflowBlockData}
          />
        </div>
      </div>
    </>
  );
};

export default ProjectWorkflowBlock;
