import React from "react";

const AssignedProjectsModal = ({ projects }) => {
  return (
    <div className="card shadow">
      <div className="card-header">
        <div className="card-title">
          <h5>Assigned Projects</h5>
        </div>
      </div>
      <div className="card-body basic-custome-color p-0 m-0">
        <div className="table-responsive" style={{ maxHeight: "300px" }}>
          <table className="table">
            <thead>
              <tr
                className="bg-light"
                style={{
                  position: "sticky",
                  top: 0,
                }}
              >
                <th className="text-center">Sr.no</th>
                <th className="text-center">Project</th>
              </tr>
            </thead>
            <tbody>
              {projects &&
                projects.map((p, i) => {
                  return (
                    <tr key={i}>
                      <td className="text-center">{i + 1}</td>
                      <td className="text-center">{p?.project_name}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AssignedProjectsModal;
