import React, { useEffect } from "react";
import { ViewMode, Gantt } from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import { ProjectCardContext } from "../../context/ProjectCardsContext/ProjectCardsContextProvider";
import { useContext } from "react";
import Select from "react-select";
import { DropdownAPIsContext } from "../../context/DropdownAPIsContext/DropdownAPIsContextProvider";
import { useState } from "react";
import CardDrawer from "./CardDrawer";
import secureLocalStorage from "react-secure-storage";
import moment from "moment";
import "./ProjectCardsBoard.css";

function GanttView() {
  const [view, setView] = useState(ViewMode.Day);
  const [tasks, setTasks] = useState([]);
  const privileges = secureLocalStorage.getItem("privileges");
  // const [isChecked, setIsChecked] = useState(true);
  const veiwDropdown = [
    {
      value: "day",
      label: "Day",
    },
    {
      value: "month",
      label: "Month",
    },
    {
      value: "year",
      label: "Year",
    },
  ];
  const {
    currentBoard,
    setCurrentBoard,
    projectCardsList,
    getProjectCardsList,
    updateProjectCard,
    noData,
  } = useContext(ProjectCardContext);
  const { boards } = useContext(DropdownAPIsContext);
  const [boardsList, setBoardsList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [width, setWidth] = useState(180);
  const [mouseDown, setMouseDown] = useState(false);
  const userID = secureLocalStorage.getItem("userID");
  let columnWidth = 60;
  if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }
  function getStartEndDateForProject(tasks, projectId) {
    const projectTasks = tasks.filter((t) => t.project === projectId);
    let start = projectTasks[0].start;
    let end = projectTasks[0].end;
    for (let i = 0; i < projectTasks.length; i++) {
      const task = projectTasks[i];
      if (start.getTime() > task.start.getTime()) {
        start = task.start;
      }
      if (end.getTime() < task.end.getTime()) {
        end = task.end;
      }
    }
    return [start, end];
  }
  const handleTaskChange = (task) => {
    let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
    const newCard = {
      created_by_id: userID,
      card_title: task.name,
      start_time: moment(task.start).format("YYYY-MM-DD"),
      end_time: moment(task.end).format("YYYY-MM-DD"),
      card_description: task.card_description,
      assigned_to_id: task.assigned_to.map((user) => user.id),
      board_id_id: task.board_id,
    };
    updateProjectCard(newCard, task.id);
    // if (task.project) {
    //   const [start, end] = getStartEndDateForProject(newTasks, task.project);
    //   const project =
    //     newTasks[newTasks.findIndex((t) => t.id === task.project)];
    //   if (
    //     project.start.getTime() !== start.getTime() ||
    //     project.end.getTime() !== end.getTime()
    //   ) {
    //     const changedProject = { ...project, start, end };
    //     newTasks = newTasks.map((t) =>
    //       t.id === task.project ? changedProject : t
    //     );
    //   }
    // }
    setTasks(newTasks);
  };
  const handleTaskDelete = (task) => {
    const conf = window.confirm("Are you sure about " + task.name + " ?");
    if (conf) {
      setTasks(tasks.filter((t) => t.id !== task.id));
    }
    return conf;
  };
  const handleProgressChange = async (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };
  const handleDblClick = (task) => {
    setIsOpen(!isOpen);
    setCurrentItem(task);
  };
  const handleSelect = (task, isSelected) => {};
  const handleExpanderClick = (task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
  };
  const handleSelectedBoard = (data) => {
    setCurrentBoard({ id: data.value, name: data.label });
  };
  const handleViewFormat = (data) => {
    if (data.value === "day") {
      setView(ViewMode.Day);
    } else if (data.value === "month") {
      setView(ViewMode.Month);
    } else {
      setView(ViewMode.Year);
    }
  };

  const handleMouseDown = (event) => {
    setMouseDown(true);
    event.preventDefault();
  };

  const handleMouseUp = (_event) => {
    setMouseDown(false);
  };

  const handleMouseMove = (event) => {
    if (mouseDown && event.pageX >= 480 && event.pageX <= 800) {
      setWidth(event.pageX - 300);
    }
  };

  useEffect(() => {
    if (boards.length !== 0) {
      setCurrentBoard(boards[0]);
      setBoardsList(
        boards.map((board) => {
          return {
            value: board.id,
            label: board.name,
          };
        })
      );
    }
  }, [boards]);

  useEffect(() => {
    if (projectCardsList.length !== 0) {
      setTasks(
        projectCardsList.map((task) => {
          return {
            start: new Date(task.start_time),
            end: new Date(task.end_time),
            name: task.card_title,
            card_title: task.card_title,
            start_time: task.start_time,
            end_time: task.end_time,
            card_description: task.card_description,
            assigned_to: task.assigned_to,
            id: task.id.toString(),
            progress: 0,
            type: "task",
            project: task.board_id.name,
            board_id: task.board_id.id,
            dependencies: task.dependencies.map((dependency) =>
              dependency.id.toString()
            ),
          };
        })
      );
    }
  }, [projectCardsList, noData]);

  useEffect(() => {
    if (currentBoard !== null) {
      getProjectCardsList(currentBoard.id);
    }
  }, [currentBoard]);

  return (
    <>
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            <h5 className="fw-bold py-3 mb-0">Gantt Chart</h5>
            <div className="d-flex">
              {currentBoard !== null && (
                <>
                  <div className="me-3">
                    {privileges.includes("create_project_cards") ? (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                          setIsOpen(!isOpen);
                        }}
                      >
                        Create Project Card
                      </button>
                    ) : (
                      ""
                    )}
                  </div>

                  <Select
                    options={boardsList}
                    defaultValue={{
                      value: currentBoard.name,
                      label: currentBoard.name,
                    }}
                    onChange={handleSelectedBoard}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            {currentBoard && (
              <>
                <h5 className="fw-bold py-3 mb-0">{currentBoard?.name}</h5>
                <div className="d-flex align-items-center">
                  {/* <div>
                    <label className="me-2" htmlFor="showDate">
                      Show Name & Dates
                    </label>
                    <input
                      type="checkbox"
                      className="me-4"
                      checked={isChecked}
                      id="showDate"
                      onChange={() => setIsChecked(!isChecked)}
                    />
                  </div> */}
                  <Select
                    options={veiwDropdown}
                    defaultValue={{ value: "day", label: "Day" }}
                    onChange={handleViewFormat}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {noData && <div>No Data Found</div>}
      {!noData && tasks.length !== 0 && (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: `${width}px 10px 1fr`,
          }}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
        >
          <div
            style={{
              minWidth: "100px",
              overflow: "auto",
              paddingBottom: "0px",
            }}
          >
            <table
              className="table TableGantt"
              style={{ border: "1px solid #e3e3e3" }}
            >
              <thead>
                <tr style={{ height: "50px" }}>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Start</th>
                  <th scope="col">End</th>
                </tr>
              </thead>
              <tbody style={{ border: "none" }}>
                {tasks.map((task, i) => {
                  return (
                    <tr style={{ height: "50px" }}>
                      <td scope="row">{i + 1}</td>
                      <td
                        className="TableGantt"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "auto",
                          maxWidth: "150px",
                        }}
                      >
                        {task.name}
                      </td>
                      <td
                        className="TableGantt"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {task.start_time}
                      </td>
                      <td
                        className="TableGantt"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {task.end_time}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div style={{ position: "relative" }}>
            <i
              className="bi bi-grip-vertical"
              style={{
                position: "absolute",
                right: "0%",
                top: "50%",
                cursor: "e-resize",
              }}
              onMouseDown={handleMouseDown}
            ></i>
          </div>
          <div style={{ overflow: "auto" }}>
            <Gantt
              tasks={tasks}
              viewMode={view}
              onDateChange={handleTaskChange}
              onDelete={handleTaskDelete}
              // onProgressChange={handleProgressChange}
              onClick={handleDblClick}
              onSelect={handleSelect}
              onExpanderClick={handleExpanderClick}
              listCellWidth={""}
              columnWidth={columnWidth}
              barBackgroundColor="rgb(245, 133, 255)"
            />
          </div>
        </div>
      )}
      <CardDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        currentItem={currentItem}
        setCurrentItem={setCurrentItem}
        currentBoard={currentBoard}
      />
    </>
  );
}

export default GanttView;
