import React from "react";

const ProjectBlockSummary = (props) => {
  return (
    <>
      <div className="col-md-12">
        <div className="card mb-3">
          <div className="card-body">
            {props.workflowBlockData ? (
              <h6 className="fw-bold mb-3 text-danger">
                {props.workflowBlockData.card_title}
              </h6>
            ) : (
              <p>Loading...</p>
            )}
            {props.workflowBlockData ? (
              <p>{props.workflowBlockData.card_description}</p>
            ) : (
              <p>Loading...</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectBlockSummary;
