import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import { VARIABLES } from "../../Constants";
import DocumentTable from "../Display/DocumentTable";
import secureLocalStorage from "react-secure-storage";

export const GenDocUtForm = (props) => {
  const [orgID, setOrgID] = useState({ label: "", value: "" });
  const [deptID, setDeptID] = useState({ label: "", value: "" });
  const [ownerID, setOwnerID] = useState({ label: "", value: "" });
  const [projectID, setProjectID] = useState({ label: "", value: "" });
  const [typeDocID, setTypeDocID] = useState({ label: "", value: "" });
  const [sop, setSop] = useState([]);
  const [sopID, setSopID] = useState({ label: "", value: "" });
  const [utility, setUtility] = useState([]);
  const [utilityID, setUtilityID] = useState({ label: "", value: "" });
  const [docAction, setDocAction] = useState({ label: "", value: "view" });
  const [docList, setDocList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filterList, setFilterList] = useState([]);
  const [filter, setFilter] = useState("");
  const privileges = secureLocalStorage.getItem("privileges");

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isSubmitSuccessful, errors },
  } = useForm({
    defaultValues: {
      link: "",
      comments: "",
      name: "",
    },
  });

  const loadSOP = async () => {
    const url =
      VARIABLES.url + "/api-app/sop-list?department_id=" + deptID.value;

    let reqOptions = {
      url: url,
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      mode: "no-cors",
    };

    axios.request(reqOptions).then(function (response) {
      var data = response.data;
      setSop(
        data.map((type) => {
          return {
            value: type.id,
            label: type.name + ": " + type.generated_tag,
          };
        })
      );
    });
  };

  const loadUtility = async () => {
    const url = VARIABLES.url + "/api-app/utility?sop_id=" + sopID.value;

    let reqOptions = {
      url: url,
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      mode: "no-cors",
    };

    axios.request(reqOptions).then(function (response) {
      var data = response.data;
      setUtility(
        data.map((type) => {
          return {
            value: type.id,
            label: type.name + ": " + type.generatedTag,
          };
        })
      );
    });
  };

  const loadGenDocUt = async () => {
    const url = VARIABLES.url + "/api-app/gen-doc-ut";
    setIsLoading(true);
    let reqOptions = {
      url: url,
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      mode: "no-cors",
    };

    axios.request(reqOptions).then(function (response) {
      var data = response.data;
      setIsLoading(false);
      setDocList(data);
      setFilterList(data);
    });
  };

  useEffect(() => {
    loadGenDocUt();
  }, [docAction]);

  useEffect(() => {
    const searchResults = docList.filter(
      (element) =>
        element.name.toLowerCase().includes(filter.toLowerCase()) |
        element.generated_tag.toLowerCase().includes(filter.toLowerCase()) |
        element.doc_status.toLowerCase().includes(filter.toLowerCase())
    );
    setFilterList(searchResults);
  }, [filter]);

  const onSubmit = (data) => {
    let genDocUtform = JSON.stringify({
      name: data.name,
      link: data.link,
      comments: data.comments,
      organisation_id: data.organisation.value,
      createdBy_id: secureLocalStorage.getItem("userID"),
      department_id: data.department.value,
      project_id: data.project.value,
      sop_id: data.sop.value,
      utility_id: data.utility.value,
      owner_id: data.employees.value,
      typeOfDocument_id: data.document_type.value,
    });

    const url = VARIABLES.url + "/api-app/gen-doc-ut";

    let reqOptions = {
      url: url,
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      mode: "no-cors",

      data: genDocUtform,
    };

    axios
      .request(reqOptions)
      .then(function (response) {
        Swal.fire(
          "Tag " + response.data.generatedTag,
          "generated for " + response.data.name,
          "success"
        );
        props.generatedTag(response.data);
        props.documentSelector([props.document[0], 0]);
      })
      .catch((error) => {
        console.log("Caught error");
        console.log(error.response);
        Swal.fire(
          "Tag for " + data.name,
          "was not generated. Please try again.",
          "error"
        );
      });
    reset();
  };

  const isGoogleDocsOrSheetsLink = (value) => {
    // Regular expression to match Google Docs or Google Sheets links
    const googleDocsRegex =
      /^https:\/\/docs\.google\.com\/(document|spreadsheets)\/d\//;

    return googleDocsRegex.test(value);
  };

  useEffect(() => {
    loadSOP();
  }, [deptID]);

  useEffect(() => {
    loadUtility();
  }, [sopID]);

  return (
    <>
      {privileges.includes("doc_tag_gen_doc_utility") ? (
        <div className="mb-3">
          <label className="form-label">Action</label>
          <Select
            onChange={setDocAction}
            options={props.action}
            value={props.action.filter(
              (option) => option.label === docAction.label
            )}
          />
        </div>
      ) : (
        ""
      )}
      {docAction.value === "view" ? (
        <div className="mb-3">
          <label className="form-label">Search document</label>
          <input
            className="form-control"
            onChange={(e) => setFilter(e.target.value)}
            value={filter}
            placeholder="Search by document name/tag name/document status"
          />
        </div>
      ) : (
        ""
      )}
      {docAction.value === "view" ? (
        <DocumentTable isLoading={isLoading} list={filterList} />
      ) : (
        <>
          <h4>Generate the document tag for {props.document[0]}</h4>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
              <label className="form-label">
                Organisation <span className="required_field">*</span>
              </label>
              <Controller
                render={({ field }) => (
                  <Select
                    {...field}
                    options={props.organisation}
                    isClearable={true}
                  />
                )}
                defaultValue=""
                rules={{ required: true }}
                name="organisation"
                control={control}
              />
              {errors.organisation && (
                <span className="required_field">
                  Organisation name is required
                </span>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">
                Department <span className="required_field">*</span>
              </label>
              <Controller
                render={({ field }) => (
                  <Select
                    {...field}
                    options={props.department}
                    isClearable={true}
                    value={deptID}
                    onChange={(data) => {
                      setDeptID(data);
                      field.onChange(data);
                    }}
                  />
                )}
                defaultValue=""
                rules={{ required: true }}
                name="department"
                control={control}
              />
              {errors.department && (
                <span className="required_field">
                  Department name is required
                </span>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">
                Project <span className="required_field">*</span>
              </label>
              <Controller
                render={({ field }) => (
                  <Select
                    {...field}
                    options={props.project}
                    isClearable={true}
                  />
                )}
                defaultValue=""
                rules={{ required: true }}
                name="project"
                control={control}
              />
              {errors.project && (
                <span className="required_field">Project name is required</span>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label">
                SOP <span className="required_field">*</span>
              </label>
              <Controller
                render={({ field }) => (
                  <Select
                    {...field}
                    options={sop}
                    isClearable={true}
                    value={sopID}
                    onChange={(data) => {
                      setSopID(data);
                      field.onChange(data);
                    }}
                  />
                )}
                defaultValue=""
                rules={{ required: true }}
                name="sop"
                control={control}
              />
              {errors.sop && (
                <span className="required_field">SOP name is required</span>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label">Utility </label>
              <Controller
                render={({ field }) => (
                  <Select {...field} options={utility} isClearable={true} />
                )}
                defaultValue=""
                // rules={{ required: true }}
                name="utility"
                control={control}
              />
              {/* {errors.utility && <span className='required_field'>SOP name is required</span>} */}
            </div>
            <div className="mb-3">
              <label className="form-label">
                Document Type <span className="required_field">*</span>
              </label>
              <Controller
                render={({ field }) => (
                  <Select
                    {...field}
                    options={props.docTypesUT}
                    isClearable={true}
                  />
                )}
                defaultValue=""
                rules={{ required: true }}
                name="document_type"
                control={control}
              />
              {errors.document_type && (
                <span className="required_field">
                  Document Type is required
                </span>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label">
                Owner <span className="required_field">*</span>
              </label>
              <Controller
                render={({ field }) => (
                  <Select
                    {...field}
                    options={props.employees}
                    isClearable={true}
                  />
                )}
                defaultValue=""
                rules={{ required: true }}
                name="employees"
                control={control}
              />
              {errors.employees && (
                <span className="required_field">
                  Employee name is required
                </span>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">
                Utility name <span className="required_field">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Sop Name"
                {...register("name", { required: true })}
              />
              {errors.name && (
                <span className="required_field">Utility name is required</span>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">
                Link to the document <span className="required_field">*</span>
              </label>
              {/* <input type="text" className="form-control" placeholder="Enter link to the document" {...register("link", { required: true })} />
              {errors.link && <span className='required_field'>Link to the document is required</span>} */}

              <Controller
                name="link"
                control={control}
                defaultValue=""
                rules={{
                  required: "This field is required",
                  validate: isGoogleDocsOrSheetsLink, // Custom validation function
                }}
                render={({ field }) => (
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter link to the document"
                      {...field}
                    />
                    {errors.link && (
                      <span className="required_field">
                        Google Doc or Google sheet Link to the document is
                        required
                      </span>
                    )}
                  </div>
                )}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="Comments" className="form-label">
                Comments (optional)
              </label>
              <textarea
                className="form-control"
                name="comments"
                id="comments"
                {...register("comments")}
                rows="3"
                placeholder="Add necessary details if any"
              ></textarea>
            </div>
            <div className="mb-3">
              <button type="submit" className="btn btn-success">
                Submit
              </button>
            </div>
          </form>
        </>
      )}
    </>
  );
};
export default GenDocUtForm;
