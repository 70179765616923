import React from "react";
import axios from "axios";
import {
  Controller,
  useForm,
  useFormState,
  useFieldArray,
} from "react-hook-form";
import Select from "react-select";
import { VARIABLES } from "../../Constants";
import Swal from "sweetalert2";
import { useState } from "react";
import secureLocalStorage from "react-secure-storage";

const CreateCardBoard = ({ setStartDate, setEndDate, setHeaderList }) => {
  const userID = secureLocalStorage.getItem("userID");
  // react hook form
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: {
      input_fields: [{ listname: "" }],
    },
  });
  const { isDirty, isValid } = useFormState({
    control,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "input_fields",
  });
  const onSubmit = (data) => {
    const startdate = new Date(data.start_date);
    const enddate = new Date(new Date(data.end_date));
    if (startdate <= enddate) {
      setStartDate(startdate);
      setEndDate(enddate);
      setHeaderList(
        data.listname.map((input) => {
          return {
            listname: input.listname,
          };
        })
      );
    } else {
      Swal.fire({
        title: "Error",
        text: "Invalid Date Entered",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
    reset();
  };

  const getResetValues = () => {
    reset();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div
          className="modal fade"
          id="createboard"
          tabIndex="-1"
          data-bs-backdrop="static"
          aria-labelledby="depaddLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title fw-bold" id="depaddLabel">
                  Create Board
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => getResetValues()}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row g-3 mb-3">
                  <div className="col-sm-6">
                    <label className="form-label">
                      Start Date <span className="required_field">*</span>{" "}
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="start_date"
                      {...register(`start_date`, {
                        required: true,
                      })}
                    />
                    {errors.start_date && (
                      <span
                        className="required_field"
                        style={{ fontSize: ".9em" }}
                      >
                        Start Date is required
                      </span>
                    )}
                  </div>
                  <div className="col-sm-6">
                    <label className="form-label">
                      End Date{" "}
                      <span
                        className="required_field"
                        style={{ fontSize: ".9em" }}
                      >
                        *
                      </span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="end_date"
                      {...register(`end_date`, {
                        required: true,
                      })}
                    />
                    {errors.end_date && (
                      <span
                        className="required_field"
                        style={{ fontSize: ".9em" }}
                      >
                        End Date is required
                      </span>
                    )}
                  </div>
                </div>
                {fields.map((field, index) => {
                  return (
                    <div className="row">
                      <div className="col-10 mb-3">
                        <label className="form-label">
                          List Name
                          <span
                            className="required_field"
                            style={{ fontSize: ".9em" }}
                          >
                            *
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter The List Name Here..."
                          {...register(`listname.${index}.listname`, {
                            required: true,
                          })}
                        />
                        {errors.listname?.[index]?.listname && (
                          <span
                            className="required_field"
                            style={{ fontSize: ".9em" }}
                          >
                            List Name is required
                          </span>
                        )}
                      </div>
                      <div className="col-2 delete_button col-md-2 col-sm-12">
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => remove(index)}
                        >
                          <i className="icofont-ui-delete"></i>
                        </button>
                      </div>
                    </div>
                  );
                })}

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => append({ listname: "" })}
                >
                  Add
                </button>

                <div className="modal-footer">
                  {/* <button type="submit" className="btn btn-success">Submit</button> */}
                  {isValid === true ? (
                    <button
                      type="submit"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Submit
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default CreateCardBoard;
