import React, { useContext, useRef } from "react";
import TicketGenerateEdit from "./TicketGenerateEdit";
import TicketGenerateTable from "./TicketGenerateTable";
import TicketGenerateCreate from "./TicketGenerateCreate";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";
import { DropdownAPIsContext } from "../../context/DropdownAPIsContext/DropdownAPIsContextProvider";

const TicketGenerate = () => {
  const [isLoading, setIsLoading] = useState(false);
  const privileges = secureLocalStorage.getItem("privileges");
  const userID = secureLocalStorage.getItem("userID");
  const [rawDataTicketGenerate, setRawDataTicketGenerate] = useState([]);
  const [status, setStatus] = useState("");
  const [type, setType] = useState("");
  const [from, setFrom] = useState("");
  const [noPages, setNoPages] = useState(1);
  const [entire, setEntries] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [showAll, setShowAll] = useState(false);
  const [showAssignedByMe, setShowAssignedByMe] = useState(false);
  const cancelTokenSource = useRef(null);

  const { ticketType, ticketStatus, ticketApp } =
    useContext(DropdownAPIsContext);

  const [editedTicketGenerate, setEditedTicketGenerate] = useState(null);

  const apiGetRawTicketGenerate = async (
    page_no = currentPage,
    entires = entire
  ) => {
    if (cancelTokenSource.current) {
      cancelTokenSource.current.cancel(
        "Operation canceled due to new request."
      );
    }

    cancelTokenSource.current = axios.CancelToken.source();
    try {
      setIsLoading(true);
      const res = await axios.get(
        VARIABLES.url +
          `/api-app/ticket-management?ticket_status=${
            status || ""
          }&ticket_type=${type || ""}&ticket_from=${
            from || ""
          }&page_no=${page_no}&entries=${entires}&user_id=${
            showAll ? "" : userID
          }&assigned_by_me=${showAssignedByMe ? "True" : "False"}`,
        {
          cancelToken: cancelTokenSource.current.token,
        }
      );
      setRawDataTicketGenerate(res.data.results);
      setNoPages(res.data.num_pages);
      setCurrentPage(res.data.current_page);
      setIsLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled", error.message);
      } else {
        console.error("Error occurred: ", error);
      }
    }
  };

  const editTicketData = (id) => {
    const getEditedTask = rawDataTicketGenerate.find((task) => {
      return task.id === id;
    });
    setEditedTicketGenerate(getEditedTask);
  };

  useEffect(() => {
    console.log(type);
    apiGetRawTicketGenerate();
  }, [status, type, from, currentPage, entire, showAll, showAssignedByMe]);

  return (
    <>
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            <h5 className="fw-bold py-3 mb-0"> Raised Ticket </h5>
            {privileges.includes("raised_ticket") ? (
              <div className="d-flex py-2 project-tab sub-project-tab flex-wrap w-sm-100">
                <button
                  type="button"
                  className="btn btn-dark w-sm-100"
                  data-bs-toggle="modal"
                  data-bs-target="#addticket"
                >
                  <i className="icofont-plus-circle me-2 fs-6"></i> Raise Ticket{" "}
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      {/* TicketGenerate Create Modal Pop Up */}
      <TicketGenerateCreate
        apiGetRawTicketGenerate={apiGetRawTicketGenerate}
        ticketType={ticketType}
      />

      {/* TicketGenerate Edit Modal Pop Up */}
      <TicketGenerateEdit
        editedTicketGenerate={editedTicketGenerate}
        apiGetRawTicketGenerate={apiGetRawTicketGenerate}
        ticketType={ticketType}
        ticketStatus={ticketStatus}
      />

      {/* TicketGenerate Table Page */}
      <TicketGenerateTable
        rawDataTicketGenerate={rawDataTicketGenerate}
        isLoading={isLoading}
        editTicketData={editTicketData}
        ticketApp={ticketApp}
        ticketType={ticketType}
        ticketStatus={ticketStatus}
        setStatus={setStatus}
        setType={setType}
        setFrom={setFrom}
        setRawDataTicketGenerate={setRawDataTicketGenerate}
        apiGetRawTicketGenerate={apiGetRawTicketGenerate}
        noPages={noPages}
        currentPage={currentPage}
        entire={entire}
        setEntries={setEntries}
        setCurrentPage={setCurrentPage}
        showAll={showAll}
        setShowAll={setShowAll}
        setShowAssignedByMe={setShowAssignedByMe}
        showAssignedByMe={showAssignedByMe}
      />
    </>
  );
};

export default TicketGenerate;
