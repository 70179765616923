import React from "react";

function DateList({ dateList }) {
  return (
    <div className="ListContainer">
      <div
        className={`card rounded-3 position-relative`}
        style={{ minWidth: "230px", maxWidth: "230px" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingTop: "10px",
          }}
        >
          <div className="card-body overflow-y-auto p-2">
            {dateList !== null && (
              <div className={`card rounded-1`}>
                <div
                  className={`card-body rounded-1 px-3 py-0 position-relative`}
                  style={{
                    minHeight: "20px",
                    minWidth: "200px",
                    maxWidth: "200px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "gray",
                    color: "white",
                    marginBottom: "10px",
                  }}
                >
                  <p className="pt-3" style={{ fontSize: "25px" }}>
                    Date
                  </p>
                </div>
              </div>
            )}

            {dateList !== null &&
              dateList.map((date) => {
                return (
                  <div className={`card rounded-1`}>
                    <div
                      className={`card-body rounded-1 px-3 py-0 position-relative`}
                      style={{
                        minHeight: "100px",
                        minWidth: "200px",
                        maxWidth: "200px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "gray",
                        color: "white",
                        marginBottom: "10px",
                      }}
                    >
                      <p className="pt-3" style={{ fontSize: "25px" }}>
                        {date}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DateList;
