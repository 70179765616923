import React, { useContext, useRef, useState } from "react";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Card from "./Card";
import { CSS } from "@dnd-kit/utilities";
import AddCard from "./AddCard";
import { ListContext } from "../../../context/ListContextProvider";
import "./kanbanhome.css";

function List({ listItem }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: listItem.id,
    data: {
      type: "list",
      item: listItem,
    },
  });
  const { completedListRef } = useContext(ListContext);
  // const listRef = useRef(null);
  const { view } = useContext(ListContext);
  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  };
  let totalProgress = 0;
  listItem.items.map((item) => (totalProgress += item.progress));
  let progress;
  if (listItem.items.length !== 0) {
    progress = totalProgress / listItem.items.length;
  } else {
    progress = 0;
  }

  return (
    <div className="ListContainer" style={style} ref={setNodeRef}>
      <div
        className={`List card mx-3 rounded-3 position-relative  ${
          isDragging ? "opacity-50" : ""
        }`}
        style={{
          boxShadow: isDragging
            ? "inset 7px 7px 14px #d9d9d9, inset -7px -7px 14px #ffffff"
            : "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
          backgroundColor: "#F1F0E8",
          minWidth: "300px",
          maxWidth: "300px",
          // listItem.progress === 0
          //   ? "#E6FFFD"
          //   : listItem.progress === 33.33
          //   ? "#FFEAA7"
          //   : listItem.progress === 66.66
          //   ? "#FFCF81"
          //   : "#A1EEBD",
        }}
        ref={listItem.name === "Completed 🎉" ? completedListRef : null}

        // {...listeners}
        // {...attributes}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingLeft: "20px",
            paddingTop: "10px",
          }}
        >
          <div
            style={{
              width: "35px",
              height: "35px",
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            {listItem.progress !== 100 ? (
              <CircularProgressbar
                value={listItem.progress}
                strokeWidth={50}
                styles={buildStyles({
                  strokeLinecap: "butt",
                  trailColor: "rgba(255, 255, 255)",
                  pathColor: `${
                    listItem.progress <= 33.33
                      ? "yellow"
                      : listItem.progress <= 66.66 && listItem.progress >= 33.33
                      ? "gold"
                      : "orange"
                  }`,
                })}
              />
            ) : listItem.name !== "Spillover" ? (
              <i
                className="bi bi-check-circle"
                style={{
                  fontSize: "35px",
                  marginTop: 0,
                  paddingBottom: "5px",
                  color: "green",
                }}
              ></i>
            ) : (
              <i
                className="bi bi-exclamation"
                style={{
                  fontSize: "35px",
                  marginTop: 0,
                  paddingBottom: "5px",
                  color: "red",
                }}
              ></i>
            )}
          </div>
          <h5 className="card-header border-0">
            {listItem.name}
            <span
              className="badge rounded-circle text-light ms-2"
              style={{ fontSize: ".7em", backgroundColor: "gray" }}
            >
              {listItem.items.length}
            </span>
          </h5>
        </div>
        <div className="card-body overflow-auto p-2">
          <SortableContext
            items={listItem.items}
            strategy={verticalListSortingStrategy}
          >
            {listItem.items.map((item) => {
              return <Card key={item.id} item={item} listId={listItem.id} />;
            })}
          </SortableContext>
        </div>
        {view === "user" && listItem.status === "Assigned" && (
          <AddCard currentList={listItem} />
        )}
      </div>
    </div>
  );
}

export default List;
