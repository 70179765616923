import React, { useContext, useEffect, useState } from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import "react-circular-progressbar/dist/styles.css";
import { useMediaQuery } from "react-responsive";
import Select from "react-select";
import secureLocalStorage from "react-secure-storage";
import { Controller, useForm, useFormState } from "react-hook-form";
import { UsersContext } from "../../context/UsersContext/UsersContextProvider";
import { ProjectCardContext } from "../../context/ProjectCardsContext/ProjectCardsContextProvider";
import Swal from "sweetalert2";
import { VARIABLES } from "../../Constants";
import axios from "axios";

function CardDrawer({
  isOpen,
  setIsOpen,
  currentItem,
  setCurrentItem,
  currentBoard,
}) {
  const isMobile = useMediaQuery({
    query: "(max-width: 480px)",
  });
  const userID = secureLocalStorage.getItem("userID");
  const { users } = useContext(UsersContext);
  const {
    projectCardsList,
    createProjectCard,
    updateProjectCard,
    getProjectCardsList,
    currentBoardMembers,
  } = useContext(ProjectCardContext);
  const [projectCardDropdown, setProjectCardDropdown] = useState([]);
  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const { isValid } = useFormState({ control });

  const onSubmit = (e) => {
    if (new Date(e.start_date) > new Date(e.end_date)) {
      reset({
        rc_name: "",
        start_date: "",
        end_date: "",
        rc_description: "",
        users: null,
        dependency: null,
      });
      setIsOpen(!isOpen);
      Swal.fire({
        title: "Error",
        text: "Invalid dates input",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }
    let newProjectCard = {};
    if (currentItem === null) {
      newProjectCard = JSON.stringify({
        created_by_id: userID,
        updated_by_id: userID,
        card_title: e.rc_name,
        start_time: e.start_date,
        end_time: e.end_date,
        card_description: e?.rc_description,
        assigned_to_id: e?.users?.map((user) => user.value),
        board_id_id: currentBoard.id,
        dependencies_id: e?.dependency?.map((dependency) => dependency.value),
      });
      createProjectCard(newProjectCard);
    } else {
      newProjectCard = JSON.stringify({
        created_by_id: userID,
        card_title: e.rc_name,
        start_time: e.start_date,
        end_time: e.end_date,
        card_description: e?.rc_description,
        assigned_to_id: e?.users?.map((user) => user.value),
        board_id_id: currentBoard.id,
        dependencies_id: e.dependency.map((dependency) => dependency.value),
      });
      updateProjectCard(newProjectCard, currentItem.id);
    }
    reset({
      rc_name: "",
      start_date: "",
      end_date: "",
      rc_description: "",
      users: null,
      dependency: null,
    });
    setIsOpen(!isOpen);
  };

  const deleteProjectCard = (id) => {
    setIsOpen(!isOpen);
    Swal.fire({
      title: "Are you sure? You will not be able to recover this project card!",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        let config = {
          method: "delete",
          maxBodyLength: Infinity,
          url: `${VARIABLES.url}/api-app/project-card/${id}`,
          headers: {},
        };

        axios
          .request(config)
          .then((response) => {
            Swal.fire({
              title: "Success!",
              text: "Project Card Deleted successfully",
              icon: "success",
              confirmButtonText: "OK",
            });
            reset({
              rc_name: "",
              start_date: "",
              end_date: "",
              rc_description: "",
              users: null,
              dependency: null,
            });
            getProjectCardsList(currentBoard.id);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  const resetValues = () => {
    reset({
      rc_name: "",
      start_date: "",
      end_date: "",
      rc_description: "",
      users: null,
      dependency: null,
    });
    setCurrentItem(null);
  };

  useEffect(() => {
    if (currentItem !== null) {
      const obj = {
        rc_name: currentItem.card_title,
        start_date: currentItem.start_time,
        end_date: currentItem.end_time,
        rc_description: currentItem.card_description,
        users: currentItem.assigned_to.map((user) => {
          return {
            value: user.id,
            label: user.first_name,
          };
        }),
        dependency: projectCardDropdown.filter((item) => {
          if (currentItem.dependencies.includes(item.value.toString())) {
            return true;
          }
          return false;
        }),
      };
      reset(obj);
    }
  }, [currentItem]);

  useEffect(() => {
    if (projectCardsList.length !== 0) {
      setProjectCardDropdown(
        projectCardsList.map((card) => {
          return {
            value: card.id,
            label: card.card_title,
          };
        })
      );
    }
  }, [projectCardsList]);

  return (
    <Drawer
      open={isOpen}
      onClose={() => {
        setIsOpen(!isOpen);
        resetValues();
      }}
      direction="right"
      className="bla bla bla index-drawer"
      size={isMobile ? "80%" : "450px"}
      zIndex={2000}
    >
      <div
        style={{
          margin: "0px 10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span style={{ fontSize: "20px", marginTop: "5px" }}>
          {currentItem === null ? "Create Project Card" : "Update Project Card"}
        </span>
        <i
          className="bi bi-x"
          style={{
            cursor: "pointer",
            fontSize: "35px",
          }}
          onClick={() => {
            setIsOpen(!isOpen);
            resetValues();
          }}
        ></i>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="px-3 pb-3 overflow-auto"
        style={{ height: "100%" }}
      >
        <div className="row mb-3">
          <div className="col-12 mt-2 mb-3">
            <h5>{currentBoard?.name}</h5>
          </div>
          <div className="col-12">
            <label className="form-label">
              Name <span className="required_field ">*</span>
            </label>
            <input
              id="rc-name"
              name="rc-name"
              className="DrawerInput"
              {...register("rc_name", {
                required: true,
              })}
              placeholder="Project Card Name"
            />
            {errors.rc_name && (
              <p className="required_field error">
                Project Card Name is Required
              </p>
            )}
          </div>
        </div>
        <div className="row mb-3 ">
          <div className="col-12">
            <label className="form-label">Description</label>
            <textarea
              id="rc-description"
              className="DrawerInput"
              {...register("rc_description")}
              placeholder="Description..."
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-6">
            <label className="form-label">
              Start Date
              <span className="required_field">*</span>
            </label>
            <input
              type="date"
              className="DrawerInput"
              id="start_date"
              {...register(`start_date`, {
                required: true,
              })}
            />
            {errors.start_date && (
              <span className="required_field error">
                Start Date is required
              </span>
            )}
          </div>
          <div className="col-6">
            <label className="form-label">
              End Date <span className="required_field ">*</span>
            </label>
            <input
              type="date"
              className="DrawerInput"
              id="end_date"
              {...register(`end_date`, {
                required: true,
              })}
            />
            {errors.end_date && (
              <span className="required_field error">End Date is required</span>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-2 mb-3 ">
            <label className="form-label">Dependency</label>
            <Controller
              name={`dependency`}
              control={control}
              // rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={projectCardDropdown}
                  isMulti
                  isClearable={true}
                />
              )}
            />
            {/* {errors.dependency && (
              <span className="required_field error">
                Dependency is required
              </span>
            )} */}
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-2 mb-3 ">
            <label className="form-label">
              Assigned To <span className="required_field ">*</span>
            </label>
            <Controller
              name={`users`}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={users.filter((user) =>
                    currentBoardMembers.includes(user.value)
                  )}
                  isMulti
                  isClearable={true}
                />
              )}
            />
            {errors.users && (
              <span className="required_field error">
                Assigne at least one user
              </span>
            )}
          </div>
        </div>
        <div className="row ">
          <div className="col-12 mt-auto">
            {isValid === true ? (
              <button
                type="submit"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                {currentItem === null ? "Create" : "Update"}
              </button>
            ) : (
              <button type="submit" className="btn btn-primary">
                {currentItem === null ? "Create" : "Update"}
              </button>
            )}
            {currentItem !== null && (
              <button
                type="button"
                className="btn btn-danger ms-3"
                style={{ color: "white" }}
                onClick={() => deleteProjectCard(currentItem.id)}
              >
                Delete This Project Card
              </button>
            )}
          </div>
        </div>
      </form>
    </Drawer>
  );
}

export default CardDrawer;
