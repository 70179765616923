import React, { useEffect, useState } from "react";
import CorrectionForm from "./CorrectionForm";
import { VARIABLES } from "../../Constants";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";

function CorrectTimesheet({ setCorrectTS }) {
  const [rawListData, setRawListData] = useState([]);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [serverStatus, setServerStatus] = useState("");
  const getOldTimesheets = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${
        VARIABLES.url
      }/api-app/timesheet-to-be-corrected-by-lead/${secureLocalStorage.getItem(
        "userID"
      )}`,
      headers: {},
    };
    setLoading(true);
    axios
      .request(config)
      .then((response) => {
        setRawListData(response.data.data);
        if (response.data.data.length === 0) {
          setServerStatus("No Data");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setServerStatus("Error from server");
      });
  };
  useEffect(() => {
    if (list.length !== 0) {
      let correctionComplete = true;
      list.map((item) => {
        if (item.completed === false) {
          correctionComplete = false;
        }
      });
      if (correctionComplete) {
        setTimeout(() => {
          setCorrectTS(true);
        }, 1000);
      }
    }
  }, [list]);
  useEffect(() => {
    if (rawListData.length !== 0) {
      let usersList = [];
      rawListData.map((userData) => {
        if (
          userData.timesheets.length !== 0 ||
          userData.missed_timesheet_dates.length !== 0
        ) {
          const user = {
            userId: userData.user_id,
            name: userData.user_name,
            timesheets: [
              ...userData.timesheets.map((timesheet) => {
                return {
                  id: timesheet.id,
                  task: timesheet.task_id,
                  project: timesheet.project.project_name,
                  hours: timesheet.hours,
                  date: timesheet.date,
                  ismissed: timesheet.flag_status,
                };
              }),
              ...userData.missed_timesheet_dates.map((timesheet) => {
                return {
                  id: 0,
                  task: "missed",
                  project: "",
                  hours: 0,
                  date: timesheet,
                  ismissed: 0,
                };
              }),
              ...userData.holiday_date.map((timesheet) => {
                return {
                  id: 0,
                  task: "holiday",
                  project: "",
                  hours: 0,
                  date: timesheet,
                  ismissed: 0,
                };
              }),
            ],
            completed: false,
          };
          usersList = [...usersList, user];
        }
      });
      setList(usersList);
    }
  }, [rawListData]);
  useEffect(() => {
    getOldTimesheets();
  }, []);
  return (
    <div>
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            <h5 className="fw-bold py-3 mb-0">Correct Timesheets</h5>
          </div>
        </div>
      </div>
      {loading ? (
        <div>Loading...</div>
      ) : serverStatus === "" ? (
        <div className="accordion" id="correcttimesheetlist">
          {list.map((item) => {
            return (
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className={`accordion-button ${
                      item.completed ? "collapsed text-success" : ""
                    }`}
                    disabled={item.completed}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${item.userId}`}
                  >
                    {item.name}
                    {item.completed && (
                      <i className="bi bi-patch-check-fill ms-2 text-success"></i>
                    )}
                  </button>
                </h2>
                <div
                  id={`collapse${item.userId}`}
                  className={`accordion-collapse collapse ${
                    item.completed ? "" : "show"
                  }`}
                >
                  <div className="accordion-body">
                    <CorrectionForm
                      id={item.userId}
                      timesheets={item.timesheets.sort(
                        (a, b) => new Date(a.date) - new Date(b.date)
                      )}
                      list={list}
                      setList={setList}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div>{serverStatus}</div>
      )}
    </div>
  );
}

export default CorrectTimesheet;
