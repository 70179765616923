import axios from "axios";
import React, { useState, useEffect } from "react";
import { VARIABLES } from "../../Constants"; //TODO : need to check the path of Constants file.
import { NavLink } from "react-router-dom";
import ProjectTagsTable from "./ProjectTagsTable";
import secureLocalStorage from "react-secure-storage";
import ProjectTagsCreate from "./ProjectTagsCreate";
import ProjectTagsEdit from "./ProjectTagsEdit";

const ProjectTags = () => {
  // TODO : Once we have privileges uncomment it.
  const privileges = secureLocalStorage.getItem("privileges");
  const [isLoading, setIsLoading] = useState(false);

  // "raw data" stored in the state.
  const [rawProjectTags, setRawProjectTags] = useState([]);
  const [editProjectTags, setEditProjectTags] = useState(null);
  // API call to store SubProject details in "rawSubProjectData" state

  const editProjectTagsData = (id) => {
    const getEditedTask = rawProjectTags.find((task) => {
      return task.id === id;
    });
    setEditProjectTags(getEditedTask);
  };

  const getProjectTags = async () => {
    try {
      setIsLoading(true);
      // change
      const res = await axios.get(VARIABLES.url + "/api-app/label");
      setRawProjectTags(res.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getProjectTags();
  }, []);

  return (
    <>
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            <h5 className="fw-bold py-3 mb-0">Project Tags</h5>
            <div className="d-flex">
              {privileges.includes("create_tech_role") ? (
                <div className="d-flex me-2 py-2 project-tab sub-project-tab flex-wrap w-sm-100">
                  <button
                    type="button"
                    className="btn btn-dark w-sm-100"
                    data-bs-toggle="modal"
                    data-bs-target="#addProjectTag"
                  >
                    <i className="icofont-plus-circle me-2 fs-6"></i> Create
                    Project Tag{" "}
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      <ProjectTagsTable
        rawProjectTags={rawProjectTags}
        isLoading={isLoading}
        setRawProjectTags={setRawProjectTags}
        editProjectTagsData={editProjectTagsData}
        getProjectTags={getProjectTags}
      />

      <ProjectTagsCreate getProjectTags={getProjectTags} />

      <ProjectTagsEdit
        editProjectTags={editProjectTags}
        getProjectTags={getProjectTags}
      />
    </>
  );
};

export default ProjectTags;
