import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm, useFormState } from "react-hook-form";
import "./password.css";
import { DropdownAPIsContext } from "../../context/DropdownAPIsContext/DropdownAPIsContextProvider";
import Select from "react-select";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import Swal from "sweetalert2";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { enqueueSnackbar } from "notistack";

function PasswordEncDec() {
  const [tab, setTab] = useState("enc");
  const [projectData, setProjectData] = useState([]);
  const [passwordDropdown, setPasswordDropdown] = useState([]);
  const [generatedPass, setGeneratedPass] = useState("");
  const [copyText, setCopyText] = useState(false);
  const userId = secureLocalStorage.getItem("userID");
  const privileges = secureLocalStorage.getItem("privileges");
  const [show, setShow] = useState(false);
  const [key, setKey] = useState("");
  const [passwordToggle, setPasswordToggle] = useState(false);

  const handleClose = () => {
    setShow(false);
    setKey("");
  };
  const handleShow = () => setShow(true);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: {
      isProject: true,
    },
  });
  const { isDirty, isValid } = useFormState({
    control,
  });
  const watchIsProject = watch("isProject");
  const { projects, passwordType } = useContext(DropdownAPIsContext);
  const onSubmit = (data) => {
    let formData;
    if (tab === "enc") {
      if (!watchIsProject) {
        formData = JSON.stringify({
          pass_accessed_by: userId,
          created_by: userId,
          updated_by: userId,
          activity_type: "encryption",
          password: data.password,
          key: data.security_que_enc,
        });
      } else {
        formData = JSON.stringify({
          pass_accessed_by: userId,
          created_by: userId,
          updated_by: userId,
          password_title_one: data.password_type.label,
          activity_type: "encryption",
          password: data.password,
          key: data.security_que_enc,
        });
      }
    } else {
      formData = JSON.stringify({
        pass_accessed_by: userId,
        created_by: userId,
        updated_by: userId,
        activity_type: "decryption",
        password: data.sec_key,
        key: data.security_que_dec,
      });
    }
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/password-app/password`,
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };
    axios
      .request(config)
      .then((response) => {
        if (tab === "enc") {
          setKey(response.data.data);
          handleShow();
          setGeneratedPass(response.data.data);
        } else {
          handleShow();
          setGeneratedPass(response.data.data);
        }
        reset();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onKeyCopy = () => {
    enqueueSnackbar("Copied!", {
      autoHideDuration: 3000,
      anchorOrigin: { horizontal: "right", vertical: "bottom" },
      variant: "success",
    });
    setCopyText(true);
    setTimeout(() => {
      setCopyText(false);
    }, 1000);
  };
  useEffect(() => {
    if (projects.length !== 0) {
      setProjectData(
        projects.map((proj) => {
          return {
            value: proj.id,
            label: proj.project_name,
          };
        })
      );
    }
  }, [projects]);
  useEffect(() => {
    if (passwordType.length !== 0) {
      setPasswordDropdown(
        passwordType.map((pass) => {
          return {
            value: pass.id,
            label: pass.name,
          };
        })
      );
    }
  }, [passwordType]);

  return (
    <>
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            <h5 className="fw-bold py-3 mb-0"> Password </h5>
          </div>
          <div className="mt-3">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              {privileges.includes("enc_pass") ? (
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    onClick={() => setTab("enc")}
                  >
                    Encrypt Password
                  </button>
                </li>
              ) : (
                ""
              )}
              {privileges.includes("dec_pass") ? (
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    onClick={() => setTab("dec")}
                  >
                    Decrypt Password
                  </button>
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
        </div>
      </div>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          {tab === "enc" ? (
            <>
              <div className="row">
                <div className="mb-3 col-12 col-md-6">
                  <label htmlFor="password" className="form-label">
                    Enter password <span className="required_field">*</span>
                  </label>
                  <div className="d-flex" style={{ position: "relative" }}>
                    <input
                      type={`${passwordToggle === true ? "text" : "password"}`}
                      className="InputEncDec"
                      {...register("password", { required: true })}
                      id="password"
                    />
                    <span
                      // className="input-group-text"
                      onClick={() => setPasswordToggle(!passwordToggle)}
                      id="basic-addon2 "
                      style={{ position: "absolute", right: "0px", top: "25%" }}
                    >
                      <i
                        className={`${
                          passwordToggle === true
                            ? "icofont-eye me-2 fs-6"
                            : "icofont-eye-blocked me-2 fs-6"
                        }`}
                      ></i>
                    </span>
                  </div>
                  {errors.password && (
                    <div id="sec_helper" className="form-text text-danger">
                      Password is required
                    </div>
                  )}
                </div>
                <div className="mb-3 col-12 col-md-6">
                  <label htmlFor="security_que_enc" className="form-label">
                    Enter the key
                    <span className="required_field"> *</span>
                  </label>
                  <input
                    type="text"
                    className="InputEncDec"
                    {...register("security_que_enc", { required: true })}
                    id="security_que_enc"
                  />
                  {errors.security_que_enc && (
                    <div id="sec_helper" className="form-text text-danger">
                      Key is required
                    </div>
                  )}
                </div>
              </div>
              <div className="mb-3 form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="is_project_pass"
                  {...register("isProject")}
                />
                <label className="form-check-label" htmlFor="is_project_pass">
                  Password for project
                </label>
              </div>
              {watchIsProject && (
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label className="form-label">
                      Project <span className="required_field">*</span>
                    </label>
                    <Controller
                      defaultValue=""
                      rules={{ required: true }}
                      name="project"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={projectData}
                          isClearable={true}
                        />
                      )}
                    />
                    {errors.project && (
                      <div id="project" className="form-text text-danger">
                        Project name is required
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">
                      Password Type <span className="required_field">*</span>
                    </label>
                    <Controller
                      defaultValue=""
                      rules={{ required: true }}
                      name="password_type"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={passwordDropdown}
                          isClearable={true}
                        />
                      )}
                    />
                    {errors.password_type && (
                      <div id="password_type" className="form-text text-danger">
                        Password type is required
                      </div>
                    )}
                  </div>
                </div>
              )}
              {privileges.includes("generate_pass_key") ? (
                <>
                  <button type="submit" className="btn btn-primary mt-3">
                    Get Encrypted Key
                  </button>
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            <>
              <div className="row">
                <div className="mb-3 col-12 col-md-6">
                  <label htmlFor="sec_key" className="form-label">
                    Enter Encrypted Key{" "}
                    <span className="required_field">*</span>
                  </label>
                  <input
                    type="text"
                    className="InputEncDec"
                    {...register("sec_key", { required: true })}
                    id="sec_key"
                  />
                  {errors.sec_key && (
                    <div id="sec_helper" className="form-text text-danger">
                      Encrypted key is required
                    </div>
                  )}
                </div>
                <div className="mb-3 col-12 col-md-6">
                  <label htmlFor="security_que_dec" className="form-label">
                    Enter the private key
                    <span className="required_field"> *</span>
                  </label>
                  <input
                    type="text"
                    className="InputEncDec"
                    {...register("security_que_dec", { required: true })}
                    id="security_que_dec"
                  />
                  {errors.security_que_dec && (
                    <div id="sec_helper" className="form-text text-danger">
                      Key is private required
                    </div>
                  )}
                </div>
              </div>
              {privileges.includes("get_password") ? (
                <button type="submit" className="btn btn-primary mt-3">
                  Get Password
                </button>
              ) : (
                ""
              )}
            </>
          )}
        </form>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          {tab === "enc" ? (
            <Modal.Title className="mx-auto">
              Encrypted Successfully!
            </Modal.Title>
          ) : (
            <Modal.Title className="mx-auto">
              Decrypted Successfully!
            </Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body className="mx-auto">
          {tab === "enc" ? <>Key: {key}</> : <>Password: {generatedPass}</>}
          {"  "}
          <CopyToClipboard text={generatedPass} onCopy={onKeyCopy}>
            <i
              className={`bi bi-clipboard${
                copyText ? "-check" : ""
              } text-success ms-2`}
              style={{ cursor: "pointer", fontSize: "18px" }}
            ></i>
          </CopyToClipboard>
        </Modal.Body>
        <Modal.Footer className="mx-auto">
          <Button
            variant="success"
            className="text-white"
            onClick={handleClose}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PasswordEncDec;
