import "./App.css";

import secureLocalStorage from "react-secure-storage";

import Base from "./components/Base/Base";
import Login from "./components/Login/Login";
import React, { useState, useEffect } from "react";
import ErrorPage404 from "./components/ErrorPage/ErrorPage404";
import {
  askNotificationPermission,
  haveNotificationPermission,
  supportNotification,
} from "./utils/notification";
import { register } from "./utils/registerServiceWorker";

function App() {
  // States and varibles declarations
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState({});
  const [menubar, setMenubar] = useState("");
  const [correctTS, setCorrectTS] = useState(true);

  // Set values in localstorage, userData and isLoggedIn after successful login
  const loginHandler = (data) => {
    secureLocalStorage.setItem("isLoggedIn", "USER_LOGGED_IN");
    secureLocalStorage.setItem("userID", data[0].user.id);
    secureLocalStorage.setItem("username", data[0].user.username);
    secureLocalStorage.setItem("email", data[0].user.email);
    secureLocalStorage.setItem("gender", data[0].user.gender);
    secureLocalStorage.setItem("designation", data[0].user.designation);
    secureLocalStorage.setItem("role", data[0].level_role.name);
    // setCorrectTS(data.all_timesheets_filled);
    if (data[0].level_role !== null) {
      const privileges_level = data[0].level_role.privileges;
      const privileges_technology = data[0].technology_role.map((tech_role) => {
        return tech_role.privileges.map((privilege) => privilege.name);
      });
      const level_role_privileges = privileges_level.map((pr) => {
        return pr.name;
      });
      const technology_role_privileges = privileges_technology.flat();
      const privileges = level_role_privileges.concat(
        technology_role_privileges
      );
      secureLocalStorage.setItem("privileges", privileges);
    } else {
      const privileges = ["view_timesheets", "workdesk", "fill_timesheet"];
      secureLocalStorage.setItem("privileges", privileges);
    }
    setUserData(data[0]);
    setIsLoggedIn(true);
    if (supportNotification() && !haveNotificationPermission()) {
      askNotificationPermission();
    }
  };

  // Set values in localstorage, isLoggedIn after logout.
  // Called via props from header
  // TODO: use Redux
  const logoutHandler = () => {
    secureLocalStorage.setItem("isLoggedIn", "USER_LOGGED_OUT");
    secureLocalStorage.removeItem("isLoggedIn");
    secureLocalStorage.removeItem("userID");
    secureLocalStorage.removeItem("username");
    secureLocalStorage.removeItem("email");
    secureLocalStorage.removeItem("gender");
    secureLocalStorage.removeItem("designation");
    secureLocalStorage.removeItem("privileges");
    secureLocalStorage.removeItem("role");
    localStorage.removeItem("quoteDisplayed");
    setIsLoggedIn(false);
  };

  // This runs only once because dependencies are none.
  // This runs after all the components are rendered
  useEffect(() => {
    const userStatus = secureLocalStorage.getItem("isLoggedIn");
    if (userStatus === "USER_LOGGED_IN") {
      setIsLoggedIn(true);
    } else if (userStatus === "USER_LOGGED_OUT") {
      setIsLoggedIn(false);
    }
    register();
  }, []);

  // const changeSidebar = (section) => {
  //   setMenubar(section)
  // }

  return (
    // Load components based on isLoggedIn state
    <>
      {isLoggedIn && (
        <Base
          onLogout={logoutHandler}
          correctTS={correctTS}
          setCorrectTS={setCorrectTS}
        />
      )}
      {!isLoggedIn && <Login onLogin={loginHandler} setMenubar={setMenubar} />}
      {/* {menubar === "errorpage" && <ErrorPage404 /> } */}
    </>
  );
}

export default App;
