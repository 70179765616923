import axios from "axios";
import React from "react";
import { VARIABLES } from "../../Constants";
import Swal from "sweetalert2";
import { useState } from "react";
import { useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import ReactPaginate from "react-paginate";

const TeamReportingTable = (props) => {
  const privileges = secureLocalStorage.getItem("privileges");
  // Search Filter OnChange from input field
  const [filterReportingConfiguration, setReportingConfiguration] = useState(
    []
  );

  // OnCHnage Event to get input value
  const onChangeHandler = (event) => {
    if (event.target.value === "") {
      setReportingConfiguration(props.rawReportingConfigurationData);
      return;
    }
    let filterReportingConfigurationData =
      props.rawReportingConfigurationData.filter(
        (item) =>
          item.employee.first_name
            .toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          item.lead.first_name
            .toLowerCase()
            .includes(event.target.value.toLowerCase())
      );
    setReportingConfiguration(filterReportingConfigurationData);
  };

  // Delete Function : delete the specific id
  const deleteProject = (id) => {
    var config = {
      method: "delete",
      url: VARIABLES.url + `/api-app/reporting-configuration/${id}`,
      headers: {},
    };
    axios(config)
      .then((response) => {
        Swal.fire({
          icon: "success",
          text: "Lead removed successfully!",
        });
        props.setRawReportingConfigurationData(
          props.rawReportingConfigurationData.filter((user) => {
            return user.id !== id;
          })
        );
      })
      .catch((error) => {
        Swal.fire({
          icon: "warning",
          text: "Failed to remove lead!",
        });
        console.log(error);
      });
  };

  const deleteProjectAlert = (id) => {
    Swal.fire({
      title: "Are you sure? You will not be able to recover this assignment",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteProject(id);
      }
    });
  };

  useEffect(() => {
    setReportingConfiguration(props.rawReportingConfigurationData);
  }, [props.rawReportingConfigurationData]);
  return (
    <>
      <div className="row align-item-center">
        <div className="col-md-12">
          <div className="card mb-3">
            <div className="card-header py-3 bg-transparent border-bottom-0">
              <div className="filter">
                <p className="search-title">What are you looking for ?</p>
                <div className="input-group mb-3">
                  <span className="input-group-text" id="basic-addon1">
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </span>
                  <input
                    type="text"
                    placeholder="Search by user/lead Name..."
                    className="form-control"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>
            </div>
            {props.isLoading ? (
              <div className="card-body">Loading...</div>
            ) : (
              <div className="card-body basic-custome-color">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Sr. No</th>
                        <th>User</th>
                        <th>Leads</th>
                        <th>Stop Reporting Date</th>
                        {["edit_leads", "delete_leads"].some((privilege) =>
                          privileges.includes(privilege)
                        ) ? (
                          <th>Action</th>
                        ) : (
                          ""
                        )}
                      </tr>
                    </thead>
                    {filterReportingConfiguration === 0 && (
                      <tbody>
                        <tr>
                          <td></td>
                          <td>No data found</td>
                          <td></td>
                        </tr>
                      </tbody>
                    )}
                    {filterReportingConfiguration.length > 0 && (
                      <tbody>
                        {filterReportingConfiguration.map((task, index) => {
                          return (
                            <tr>
                              <td>
                                <span className="fw-bold">
                                  {" "}
                                  {index +
                                    1 +
                                    props.entire * (props.currentPage - 1)}{" "}
                                </span>
                              </td>
                              <td>
                                <span className="ms-1 me-4">
                                  {" "}
                                  {task.employee.first_name}{" "}
                                </span>
                              </td>
                              <td>
                                <span className="ms-1 me-4">
                                  {" "}
                                  {task.lead.first_name}{" "}
                                </span>
                              </td>
                              <td>
                                <span className="ms-1 me-4">
                                  {" "}
                                  {task?.stop_reporting_date || "----"}{" "}
                                </span>
                              </td>
                              <td>
                                <div
                                  className="btn-group"
                                  role="group"
                                  aria-label="Basic outlined example"
                                >
                                  {privileges.includes(
                                    "edit_assigned_leads"
                                  ) ? (
                                    <button
                                      type="button"
                                      className="btn btn-outline-secondary"
                                      data-bs-toggle="modal"
                                      data-bs-target="#editreportinglead"
                                      onClick={() =>
                                        props.editreportingConfigurationData(
                                          task.id
                                        )
                                      }
                                    >
                                      <i className="icofont-edit text-success"></i>
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                  {privileges.includes(
                                    "delete_assigned_leads"
                                  ) ? (
                                    <button
                                      type="button"
                                      className="btn btn-outline-secondary deleterow"
                                      onClick={() =>
                                        deleteProjectAlert(task.id)
                                      }
                                    >
                                      <i className="icofont-ui-delete text-danger"></i>
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                  <div className="d-flex justify-content-center justify-content-md-between mt-3">
                    <ReactPaginate
                      key={3}
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={props.noPages}
                      initialPage={props.currentPage - 1}
                      marginPagesDisplayed={2}
                      disableInitialCallback={true}
                      // pageRangeDisplayed={3}
                      onPageChange={(selectedPage) => {
                        // setCurrentPage(selectedPage.selected + 1);
                        props.getReportingConfigurationData(
                          selectedPage.selected + 1,
                          props.entire
                        );
                      }}
                      containerClassName={
                        "pagination justify-content-center justify-content-md-start"
                      }
                      pageClassName={"paginate_button page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"paginate_button page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />

                    <div className="d-flex justify-content-center align-items-center">
                      <div className="me-3">No of entries per page:</div>
                      <div>
                        <select
                          className="form-select"
                          value={props.entire}
                          onChange={(e) => {
                            props.setEntries(e.target.value);
                            props.getReportingConfigurationData(
                              props.currentPage,
                              e.target.value
                            );
                          }}
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamReportingTable;
