import React, { useState, useEffect } from "react";
import axios from "axios";
import { Controller, useForm, useFormState } from "react-hook-form";
import Select from "react-select";
import Swal from "sweetalert2";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";

const ProjectTagsEdit = (props) => {
  // TODO : Once we get the user credentials, uncomment it.
  const userID = secureLocalStorage.getItem("userID");

  // react hook form
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm();

  // onSubmit handler for form submitting
  const onSubmit = (data) => {
    var form_data = JSON.stringify({
      name: data.name,
      description: data.description,
      updated_by_id: userID,
    });

    // TODO : Check the API properly from postman
    const url = VARIABLES.url + `/api-app/label/${props.editProjectTags.id}`;
    var config = {
      method: "patch",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: form_data,
    };
    axios(config)
      .then((response) => {
        Swal.fire({
          title: "Success!",
          text: "Tag Updated Successfully",
          icon: "success",
          confirmButtonText: "OK",
        });
        props.getProjectTags();
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Error",
          text:
            Object.keys(error.response.data)[0] +
            " : " +
            error.response.data[Object.keys(error.response.data)[0]],
          icon: "error",
          confirmButtonText: "OK",
        });
      });
    reset();
  };

  //   pre-population of values after clicking on edit button
  useEffect(() => {
    if (props.editProjectTags) {
      const editedProjectTags = props.editProjectTags;
      if (editedProjectTags) {
        var defaultValues = {};
        defaultValues.name = editedProjectTags.name;
        defaultValues.description = editedProjectTags.description;
        reset(defaultValues);
      }
    }
  }, [props.editProjectTags]);

  const getResetValues = () => {
    reset();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div
          className="modal fade"
          id="editProjectTag"
          tabIndex="-1"
          data-bs-backdrop="static"
          aria-labelledby="depaddLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title fw-bold" id="depaddLabel">
                  Edit Project Tag
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => getResetValues()}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row g-4">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <label className="form-label">
                      {" "}
                      Tag Name
                      <span className="required_field">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter The Tag Name Here..."
                      {...register("name", { required: true })}
                    />
                    {errors.name && (
                      <span className="required_field">
                        Tag Name is required
                      </span>
                    )}
                  </div>

                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <label className="form-label">description of Tag</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter The Tag Description Here..."
                      {...register("description")}
                    />
                    {/* {errors.description && (
                      <span className="required_field">
                        Description is required
                      </span>
                    )} */}
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-lg btn-primary"
                        data-bs-dismiss="modal"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ProjectTagsEdit;
