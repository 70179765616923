import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import { VARIABLES } from "../../Constants";
import DocumentTable from "../Display/DocumentTable";
import secureLocalStorage from "react-secure-storage";
import { DropdownAPIsContext } from "../../context/DropdownAPIsContext/DropdownAPIsContextProvider";

const DocType = (props) => {
  const [orgID, setOrgID] = useState({ label: "", value: "" });
  const [deptID, setDeptID] = useState({ label: "", value: "" });
  const [ownerID, setOwnerID] = useState({ label: "", value: "" });
  const [docAction, setDocAction] = useState({ label: "", value: "view" });
  const [docList, setDocList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [filter, setFilter] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { getGenericDropdown } = useContext(DropdownAPIsContext);
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isSubmitSuccessful, errors },
  } = useForm({
    defaultValues: {
      name: "",
    },
  });
  const privileges = secureLocalStorage.getItem("privileges");

  const loadGenSop = async () => {
    const url = VARIABLES.url + "/api-app/doc-type";
    setIsLoading(true);
    let reqOptions = {
      url: url,
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      mode: "no-cors",
    };

    axios.request(reqOptions).then(function (response) {
      var data = response.data;
      setIsLoading(false);
      setDocList(data);
      setFilterList(data);
      getGenericDropdown();
    });
  };

  useEffect(() => {
    loadGenSop();
  }, [docAction]);

  useEffect(() => {
    const searchResults = docList.filter((element) =>
      element.name.toLowerCase().includes(filter.toLowerCase())
    );
    setFilterList(searchResults);
  }, [filter]);

  const onSubmit = (data) => {
    let sopData = JSON.stringify({
      created_by_id: secureLocalStorage.getItem("userID"),
      updated_by_id: secureLocalStorage.getItem("userID"),
      name: data.name,
      organisation_id: data.organisation.value,
      department_id: data.department.value,
    });
    const url = VARIABLES.url + "/api-app/doc-type";

    let reqOptions = {
      url: url,
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      mode: "no-cors",
      data: sopData,
    };

    axios
      .request(reqOptions)
      .then(function (response) {
        Swal.fire(
          "Tag for " + response.data.name,
          response.data.generatedTag,
          "success"
        );
        // props.generatedTag([response.data.generatedTag, response.data.name])
        props.generatedTag(response.data);
        props.documentSelector([props.document[0], 0]);
        // props.loadTypeOfDocs();
      })
      .catch((error) => {
        console.log("Caught error");
        console.log(error.response);
        Swal.fire(
          "Tag for " + data.name,
          "was not generated. Please try again.",
          "error"
        );
      });
    reset();
  };

  return (
    <>
      {privileges.includes("doc_tag_gen_sop") ? (
        <div className="mb-3">
          <label className="form-label">Action</label>
          <Select
            className=""
            onChange={setDocAction}
            options={props.action}
            value={props.action.filter(
              (option) => option.label === docAction.label
            )}
          />
        </div>
      ) : (
        ""
      )}
      {docAction.value === "view" ? (
        <div className="mb-3">
          <label className="form-label">Search document</label>
          <input
            className="form-control"
            onChange={(e) => setFilter(e.target.value)}
            value={filter}
            placeholder="Search by document name/tag name/document status"
          />
        </div>
      ) : (
        ""
      )}
      {docAction.value === "view" ? (
        <DocumentTable isLoading={isLoading} list={filterList} />
      ) : (
        <>
          <h4>Generate the document tag for {props.document[0]}</h4>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-3">
              <label className="form-label">
                Organisation <span className="required_field">*</span>
              </label>
              <Controller
                render={({ field }) => (
                  <Select
                    {...field}
                    options={props.organisation}
                    isClearable={true}
                  />
                )}
                defaultValue=""
                rules={{ required: true }}
                name="organisation"
                control={control}
              />
              {errors.organisation && (
                <span className="required_field">
                  Organisation name is required
                </span>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label">
                Department <span className="required_field">*</span>
              </label>
              <Controller
                render={({ field }) => (
                  <Select
                    {...field}
                    options={props.department}
                    isClearable={true}
                  />
                )}
                defaultValue=""
                rules={{ required: true }}
                name="department"
                control={control}
              />
              {errors.department && (
                <span className="required_field">
                  Department name is required
                </span>
              )}
            </div>
            <div className="mb-3">
              <label className="form-label">
                Doc Type name <span className="required_field">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Doc Type Name"
                {...register("name", { required: true })}
              />
              {errors.name && (
                <span className="required_field">Doc Type is required</span>
              )}
            </div>
            <div className="mb-3">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default DocType;
