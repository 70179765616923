// Header components and activity defined here
import React, { useState } from "react";
import secureLocalStorage from "react-secure-storage";
import TicketGenerateCreate from "../../TicketGenerate/TicketGenerateCreate";
import { useNavigate } from "react-router-dom";
import { VARIABLES } from "../../../Constants";
import UserImagePopper from "../../popper-drawer/UserImagePopper";
import Notification from "../../notification/Notification";

const Header = (props) => {
  const privileges = secureLocalStorage.getItem("privileges");
  const navigate = useNavigate();
  // const [username,setUsername] = useState("")
  // const [email,setEmail] = useState("")

  // State causing leak error, this is a temporary fix
  let username = "";
  let email = "";
  let designation = "";
  let gender = "";
  let userID = "";
  // Setting up name and email for logged in user
  // Using additional variable '_local' because I was unable to set
  // state using localstorage inside setState()

  if (secureLocalStorage.getItem("username")) {
    username = secureLocalStorage.getItem("username");
    // setUsername(username_local)
  }
  if (secureLocalStorage.getItem("userID")) {
    userID = secureLocalStorage.getItem("userID");
  }
  if (secureLocalStorage.getItem("gender")) {
    gender = secureLocalStorage.getItem("gender");
    // setUsername(username_local)
  }
  if (secureLocalStorage.getItem("email")) {
    email = secureLocalStorage.getItem("email");
    // setEmail(email_local)
  }
  if (secureLocalStorage.getItem("designation")) {
    designation = secureLocalStorage.getItem("designation");
    // setEmail(email_local)
  }

  // Calling logout function in app.js. State Pull up
  const handleLogout = () => {
    props.onLogout();
    navigate("/");
  };

  return (
    <>
      <div className="header">
        <nav className="navbar py-3">
          <div className="container-xxl">
            <div className="user_menu d-flex align-items-center mr-5 mr-lg-0">
              <Notification />
              <div className="dropdown">
                <button
                  type="button"
                  className="AppDropdownIcon"
                  data-bs-toggle="dropdown"
                >
                  <i
                    className="bi bi-grid-3x3-gap-fill"
                    style={{ marginBottom: "5px" }}
                  ></i>
                </button>
                <ul
                  className="dropdown-menu dropdown-menu-start dropdown-menu-xl-end mt-2 shadow"
                  style={{ width: "200px" }}
                >
                  <li className="ms-3 mt-3">
                    <a
                      href="https://nipun.techwalnut.co.in/"
                      target="_blank"
                      className="language-item"
                    >
                      <img
                        src="https://tw-beaver-builder.s3.ap-south-1.amazonaws.com/static/assets/images/application_logos/nipun.png"
                        alt=""
                        height="28"
                        width="28"
                        className="language-flag me-3"
                      />
                      <span className="language-name">Nipun</span>
                    </a>
                  </li>
                  <li className="ms-3 mt-3">
                    <a
                      href="https://bugzilla.techwalnut.co.in/"
                      target="_blank"
                      className="language-item"
                    >
                      <img
                        src="https://tw-beaver-builder.s3.ap-south-1.amazonaws.com/static/assets/images/application_logos/bugzilla.png"
                        alt=""
                        height="28"
                        width="28"
                        className="language-flag me-3"
                      />
                      <span className="language-name">Bugzilla</span>
                    </a>
                  </li>
                  <li className="ms-3 mt-3">
                    <a
                      href="https://crm-forms.elorca.com/"
                      target="_blank"
                      className="language-item"
                    >
                      <img
                        src="https://tw-beaver-builder.s3.ap-south-1.amazonaws.com/static/assets/images/application_logos/crm.png"
                        alt=""
                        height="28"
                        width="28"
                        className="language-flag me-3"
                      />
                      <span className="language-name">CRM</span>
                    </a>
                  </li>
                  <li className="ms-3 mt-3">
                    <a
                      href="https://et.techwalnut.co.in/"
                      target="_blank"
                      className="language-item"
                    >
                      <img
                        src="https://tw-beaver-builder.s3.ap-south-1.amazonaws.com/static/assets/images/application_logos/et.png"
                        alt=""
                        height="28"
                        width="28"
                        className="language-flag me-3"
                      />
                      <span className="language-name">Expense tracker</span>
                    </a>
                  </li>
                  <li className="ms-3 mt-3">
                    <a
                      href="https://ims.techwalnut.co.in/"
                      target="_blank"
                      className="language-item"
                    >
                      <img
                        src="https://tw-beaver-builder.s3.ap-south-1.amazonaws.com/static/assets/images/application_logos/ims.png"
                        alt=""
                        height="28"
                        width="28"
                        className="language-flag me-3"
                      />
                      <span className="language-name">IMS</span>
                    </a>
                  </li>
                  <li className="ms-3 mt-3">
                    <a
                      href="https://beaver.techwalnut.co.in/"
                      target="_blank"
                      className="language-item"
                    >
                      <img
                        src="https://tw-beaver-builder.s3.ap-south-1.amazonaws.com/static/assets/images/application_logos/meerkat.png"
                        alt=""
                        height="28"
                        width="28"
                        className="language-flag me-3"
                      />
                      <span className="language-name">Meerkat</span>
                    </a>
                  </li>
                  <li className="ms-3 mt-3">
                    <a
                      href="https://beaver.techwalnut.co.in/"
                      target="_blank"
                      className="language-item"
                    >
                      <img
                        src="https://tw-beaver-builder.s3.ap-south-1.amazonaws.com/static/assets/images/application_logos/beaver.png"
                        alt=""
                        height="28"
                        width="28"
                        className="language-flag me-3"
                      />
                      <span className="language-name">Beaver</span>
                    </a>
                  </li>
                  <li className="ms-3 mt-3">
                    <a
                      href="https://www.elorca.com/"
                      target="_blank"
                      className="language-item"
                    >
                      <img
                        src="https://tw-beaver-builder.s3.ap-south-1.amazonaws.com/static/assets/images/logo/elorca-logo.png"
                        alt=""
                        height="28"
                        width="28"
                        className="language-flag me-3"
                      />
                      <span className="language-name">Elorca</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="d-flex">
                {privileges.includes("header_raised_ticket") ? (
                  <div className=" d-flex py-2 project-tab sub-project-tab flex-wrap w-sm-100">
                    <button
                      type="button"
                      className="raiseticket btn btn-dark w-sm-100 me-4"
                      data-bs-toggle="modal"
                      data-bs-target="#addticket"
                    >
                      <i className="icofont-tag fs-6"></i>
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="dropdown notifications zindex-popover"></div>
              <div className="dropdown user-profile ml-2 ml-sm-3 d-flex align-items-center zindex-popover">
                <div className="u-info me-2">
                  <p className="mb-0 text-end line-height-sm ">
                    <span className="font-weight-bold">{username}</span>
                  </p>
                  {/* Needs to be changed */}
                  <small>{designation}</small>
                </div>
                <a
                  className="nav-link dropdown-toggle pulse p-0"
                  href="javascript:void(0)"
                  role="button"
                  data-bs-toggle="dropdown"
                  data-bs-display="static"
                >
                  {/* {gender === "Female" ? (
                    <img
                      className="avatar md rounded-circle img-thumbnail"
                      src="assets/images/profile_female.jpg"
                      alt="profile"
                    />
                  ) : (
                    <img
                      className="avatar md rounded-circle img-thumbnail"
                      src="assets/images/profile_male.png"
                      alt="profile"
                    />
                  )} */}
                  <UserImagePopper id={userID} show={false} />
                </a>
                <div className="dropdown-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-end p-0 m-0">
                  <div className="card border-0 w280">
                    <div className="card-body pb-0">
                      <div className="d-flex py-1">
                        {/* {gender === "Female" ? (
                          <img
                            className="avatar rounded-circle"
                            src="assets/images/profile_female.jpg"
                            alt="profile"
                          />
                        ) : (
                          <img
                            className="avatar rounded-circle"
                            src="assets/images/profile_male.png"
                            alt="profile"
                          />
                        )} */}
                        <div style={{ cursor: "pointer" }}>
                          <UserImagePopper
                            id={userID}
                            show={true}
                            input={true}
                          />
                        </div>
                        <div className="flex-fill ms-3">
                          <p className="mb-0">
                            <span className="font-weight-bold">{username}</span>
                          </p>
                          <small className="">{email}</small>
                        </div>
                      </div>

                      <div>
                        <hr className="dropdown-divider border-dark" />
                      </div>
                    </div>

                    <div className="list-group m-2 ">
                      {/* <a className="list-group-item list-group-item-action border-0 "><i className="icofont-tasks fs-5 me-3"></i>My Profile</a>
                                        <div><hr className="dropdown-divider border-dark" /></div> */}
                      <a
                        href="https://docs.google.com/spreadsheets/d/1TqcGG0jEK4PVBAL1xt9xr1YaqWtr2maxFvY5eUc_B_I/edit?usp=sharing"
                        className="list-group-item list-group-item-action border-0 "
                        target="_black"
                      >
                        <i className="icofont-file-spreadsheet fs-6 me-3"></i>
                        Planner
                      </a>
                    </div>
                    <div className="list-group m-2 ">
                      {/* <a className="list-group-item list-group-item-action border-0 "><i className="icofont-tasks fs-5 me-3"></i>My Profile</a>
                                        <div><hr className="dropdown-divider border-dark" /></div> */}
                      {props.disabledButton === true ? (
                        <a
                          href="javascript:void(0)"
                          onClick={props.handleTour}
                          className="list-group-item list-group-item-action border-0 "
                        >
                          <i className="icofont-travelling fs-6 me-3"></i>Take a
                          Tour
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="list-group m-2 ">
                      {/* <a className="list-group-item list-group-item-action border-0 "><i className="icofont-tasks fs-5 me-3"></i>My Profile</a>
                                        <div><hr className="dropdown-divider border-dark" /></div> */}
                      <a
                        href="javascript:void(0)"
                        onClick={handleLogout}
                        className="list-group-item list-group-item-action border-0 "
                      >
                        <i className="icofont-logout fs-6 me-3"></i>Sign out
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button
              className="navbar-toggler p-0 border-0 menu-toggle"
              onClick={props.showMobileMenuBar}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#mainHeader"
              aria-expanded="true"
            >
              <span className="fa fa-bars"></span>
            </button>
          </div>
        </nav>
      </div>

      <TicketGenerateCreate />
    </>
  );
};

export default Header;
