import React from "react";
import LeaveAvaliable from "./LeaveAvaliable";
import moment from "moment";

const LeaveManagementTable = (props) => {
  return (
    <>
      <div className="row clearfix g-3">
        <div className="col-lg-8 col-md-8 col-sm-12">
          <LeaveAvaliable />

          <div className="card my-3">
            <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
              <h6 className="card-title calender-title mb-0">Applied Leaves</h6>
            </div>
            {props.isLoading ? (
              <div className="card-body">Loading ...</div>
            ) : (
              <div className="card-body">
                {/* <thead>
                        <tr>
                          <th>Leave Type</th>
                          <th>From</th>
                          <th>To</th>
                          <th>Reason</th>
                          <th>Approval Status</th>
                        </tr>
                      </thead> */}
                {props.rawLeaveData.length === 0 && (
                  <div className="card-body">No data found</div>
                )}

                {props.rawLeaveData.length > 0 && (
                  <>
                    {props.rawLeaveData.map((api, i) => {
                      return (
                        <div
                          key={i}
                          className="py-2 d-flex align-items-center border-bottom"
                        >
                          <div className="d-flex ms-2 align-items-center flex-fill">
                            <div className="d-flex flex-column ps-2">
                              <h6 className="mb-0">
                                {moment(api.start_date).format("Do MMM, YYYY")}{" "}
                                - {moment(api.end_date).format("Do MMM, YYYY")}
                              </h6>
                              <p className="mb-0 pt-2 text-muted">
                                Reason : {api.reason}
                              </p>
                              <span className="text-muted">
                                {api.leave_type.name}
                              </span>
                            </div>
                          </div>
                          <span
                            className={`${
                              api.approval_status === "Approved"
                                ? "badge rounded-pill light-info-bg text-success"
                                : api.approval_status === "Rejected"
                                ? "badge rounded-pill text-danger light-info-bg"
                                : "badge rounded-pill light-info-bg text-info"
                            }`}
                          >
                            {api.approval_status}
                          </span>
                          {/* <button type="button" className="btn light-danger-bg text-end" data-bs-toggle="modal" data-bs-target="#dremovetask">{api.approval_status}</button> */}
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12">
          <div className="card">
            <div className="card-body">
              <h6 className="card-title calender-title pb-3 mb-0">Holiday's</h6>
              <div className="holiday-details">
                {props.holidays.map((holiday) => {
                  const dateObject = new Date(holiday.holiday_date);
                  const day = dateObject.getDate();
                  const monthNumber = dateObject.getMonth() + 1;

                  const monthNames = [
                    "Jan",
                    "Feb",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "Aug",
                    "Sept",
                    "Oct",
                    "Nov",
                    "Dec",
                  ];

                  const monthName = monthNames[monthNumber - 1];
                  return (
                    <div className="d-flex align-items-center pb-3">
                      <div className="avatar lg rounded-1 no-thumbnail light-info-bg color-defult">
                        <div className="flex-fill text-center">
                          <div>{day}</div>
                          <div>{monthName}</div>
                        </div>
                      </div>
                      <div className="flex-fill ms-4">
                        <div className="">{holiday.holiday_name}</div>
                        <span className="small text-muted">
                          {" "}
                          {holiday.holiday_day}{" "}
                        </span>
                      </div>
                      {holiday.holiday_type === "Mandatory" ? (
                        <span className="badge rounded-pill light-info-bg text-primary">
                          {" "}
                          {holiday.holiday_type}{" "}
                        </span>
                      ) : (
                        <span className="badge rounded-pill light-info-bg text-warning">
                          {" "}
                          {holiday.holiday_type}{" "}
                        </span>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Row End --> */}
    </>
  );
};

export default LeaveManagementTable;
