import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

export const DisplayNewTag = (props) => {
  const [isCopied, setIsCopied] = useState(false);
  useEffect(() => {
    console.log(props.tagDetails);
  }, [isCopied]);
  return (
    <div>
      <h4> Generated tag for {props.document[0]} </h4>
      {props.document[0] === "Doc Type" ? (
        <h6> Tag Name : {props.tagDetails.label} </h6>
      ) : (
        <h6> Tag Name : {props.tagDetails.generated_tag} </h6>
      )}
      <h6> Document name : {props.tagDetails.name} </h6>
      {/* <button 
            className='btn btn-sm btn-success mt-2'
            onClick={handleCopyClick}>
            <span>{isCopied ? 'Copied!' : 'Copy'}</span>
        </button> */}
      <CopyToClipboard
        text={
          props.document[0] === "Doc Type"
            ? props.tagDetails.label
            : props.tagDetails.generated_tag
        }
        onCopy={() => setIsCopied(true)}
      >
        <button className="btn btn-sm btn-success mt-2">
          <span>{isCopied ? "Copied!" : "Copy"}</span>
        </button>
      </CopyToClipboard>
    </div>
  );
};
