import React from "react";

const ProjectBlockStatus = (props) => {
  return (
    <>
      <div className="col-md-4">
        <div className="card ">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="avatar lg  rounded-1 no-thumbnail bg-lightyellow color-defult">
                <i className="icofont-optic fs-4"></i>
              </div>
              <div className="flex-fill ms-4 text-truncate">
                <div className="text-truncate">Status</div>
                {props.workflowBlockData ? (
                  <span className="badge bg-warning">
                    {props.workflowBlockData.current_status}
                  </span>
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="card ">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="avatar lg  rounded-1 no-thumbnail bg-lightblue color-defult">
                <i className="icofont-user fs-4"></i>
              </div>
              <div className="flex-fill ms-4 text-truncate">
                <div className="text-truncate">Assigned / Incharge Name</div>
                {props.workflowBlockData ? (
                  <span className="fw-bold">
                    {props.workflowBlockData.assigned_to.map(
                      (incharge) => incharge.username
                    )}
                  </span>
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="card ">
          <div className="card-body">
            <div className="d-flex align-items-center">
              <div className="avatar lg  rounded-1 no-thumbnail bg-lightgreen color-defult">
                <i className="icofont-price fs-4"></i>
              </div>
              <div className="flex-fill ms-4 text-truncate">
                <div className="text-truncate">Priority</div>
                {props.workflowBlockData ? (
                  <span className="badge bg-danger">
                    {props.workflowBlockData.workflow_block.priority}
                  </span>
                ) : (
                  <p>Loading...</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectBlockStatus;
