import axios from "axios";
import React, { useState, useEffect } from "react";
import { VARIABLES } from "../../Constants"; //TODO : need to check the path of Constants file.
import { NavLink } from "react-router-dom";
import ProjectCategoriesTable from "./ProjectCategoriesTable";
import secureLocalStorage from "react-secure-storage";
import ProjectCategoriesCreate from "./ProjectCategoriesCreate";
import ProjectCategoriesEdit from "./ProjectCategoriesEdit";

const ProjectCategories = () => {
  // TODO : Once we have privileges uncomment it.
  const privileges = secureLocalStorage.getItem("privileges");
  const [isLoading, setIsLoading] = useState(false);

  // "raw data" stored in the state.
  const [rawProjectCategoriesData, setRawProjectCategoriesData] = useState([]);
  const [editProjectCategories, setEditProjectCategories] = useState(null);
  // API call to store SubProject details in "rawSubProjectData" state

  const editProjectCategoriesData = (id) => {
    const getEditedTask = rawProjectCategoriesData.find((task) => {
      return task.id === id;
    });
    setEditProjectCategories(getEditedTask);
  };

  const getProjectCategories = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(VARIABLES.url + "/api-app/project-category");
      setRawProjectCategoriesData(res.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getProjectCategories();
  }, []);

  return (
    <>
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            <h5 className="fw-bold py-3 mb-0">Project Categories</h5>
            <div className="d-flex">
              {privileges.includes("create_project_category") ? (
                <div className="d-flex me-2 py-2 project-tab sub-project-tab flex-wrap w-sm-100">
                  <button
                    type="button"
                    className="btn btn-dark w-sm-100"
                    data-bs-toggle="modal"
                    data-bs-target="#addProjectCategory"
                  >
                    <i className="icofont-plus-circle me-2 fs-6"></i> Create
                    Project Category
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      <ProjectCategoriesTable
        rawProjectCategoriesData={rawProjectCategoriesData}
        isLoading={isLoading}
        setRawProjectCategoriesData={setRawProjectCategoriesData}
        editProjectCategoriesData={editProjectCategoriesData}
      />

      <ProjectCategoriesCreate getProjectCategories={getProjectCategories} />

      <ProjectCategoriesEdit
        editProjectCategories={editProjectCategories}
        getProjectCategories={getProjectCategories}
      />
    </>
  );
};

export default ProjectCategories;
