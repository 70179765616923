import React from "react";

const HoursInfoModal = ({ hours }) => {
  return (
    <div className="card shadow">
      <div className="card-header">
        <div className="card-title">
          <h5>Targets</h5>
        </div>
      </div>
      <div className="card-body basic-custome-color p-0 m-0">
        <div className="table-responsive" style={{ maxHeight: "300px" }}>
          <table className="table">
            <thead>
              <tr
                className="bg-light"
                style={{
                  position: "sticky",
                  top: 0,
                }}
              >
                <th className="text-center">Project</th>
                <th className="text-center">Type</th>
                <th className="text-center">Hours</th>
              </tr>
            </thead>
            {hours === 0 ? (
              <tbody>
                <tr>
                  <td></td>
                  <td>No data found</td>
                  <td></td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {hours && hours.length !== 0 ? (
                  hours.map((project, i) => (
                    <tr key={i}>
                      <td className="text-center">
                        {project?.project__project_name}
                      </td>
                      <td className="text-center">{project?.target_type}</td>
                      <td className="text-center">{project?.hours}</td>
                    </tr>
                  ))
                ) : (
                  <p className="card-header">No data found</p>
                )}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default HoursInfoModal;
