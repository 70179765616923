import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import { VARIABLES } from "../../Constants";

export const OrganisationContext = createContext(null);

function OrganisationContextProvider({ children }) {
  const [organisation, setOrganisation] = useState([]);
  const loadOrganisation = async () => {
    const url = VARIABLES.url + "/api-app/organisation";

    let reqOptions = {
      url: url,
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      mode: "no-cors",
    };

    axios.request(reqOptions).then(function (response) {
      var data = response.data;
      setOrganisation(
        data.map((org) => {
          return { value: org.id, label: org.org_name };
        })
      );
    });
  };
  useEffect(() => {
    loadOrganisation();
  }, []);
  const value = { organisation };
  return (
    <OrganisationContext.Provider value={value}>
      {children}
    </OrganisationContext.Provider>
  );
}

export default OrganisationContextProvider;
