import React, { useEffect, useState } from "react";
import uuid from "react-uuid";
import ProjectCardsList from "./ProjectCardsList";
import { DndContext, DragOverlay, closestCenter } from "@dnd-kit/core";
import { createPortal } from "react-dom";
import ProjectCardsCard from "./ProjectCardsCard";
import { arrayMove } from "@dnd-kit/sortable";
import DateList from "./DateList";
import CreateCardBoard from "./CreateCardBoard";
import AddCard from "./AddCard";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";

function ProjectCardsBoard() {
  const [table, setTable] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [draggingCard, setDraggingCard] = useState(null);
  const [dateList, setDateList] = useState(null);
  const [headerList, setHeaderList] = useState([]);
  const [currentList, setCurrentList] = useState(null);
  const navigate = useNavigate();
  const privileges = secureLocalStorage.getItem("privileges");
  const hadleDragEnd = (data) => {
    if (
      data.active.data.current.item.id !== data.over.data.current.item.id &&
      data.active.data.current.listId === data.over.data.current.listId
    ) {
      if (data.over.data.current.item.name === "") {
        const tempList = table.filter(
          (item) => item.id === data.active.data.current.listId
        )[0].items;
        const oldIndex = tempList.findIndex(
          (items) => items.id === data.active.id
        );
        const newIndex = tempList.findIndex(
          (items) => items.id === data.over.id
        );

        for (
          let i = Math.min(oldIndex, newIndex) + 1;
          i < Math.max(oldIndex, newIndex);
          i++
        ) {
          if (tempList[i].name !== "") return;
        }
        const shuffledArr = arrayMove(tempList, oldIndex, newIndex);

        const newShuffle = [...shuffledArr];

        let tempEndDate = new Date(endDate);
        let i = 0,
          j = 0;
        while (i < dateList.length) {
          let startTempDate = new Date(newShuffle[j].start_date);
          let endTempDate = new Date(newShuffle[j].end_date);
          const tempDateDiff =
            (endTempDate.getTime() - startTempDate.getTime()) /
            (1000 * 60 * 60 * 24);
          startTempDate.setDate(tempEndDate.getDate() - tempDateDiff);
          endTempDate.setDate(tempEndDate.getDate());
          newShuffle[j].start_date = formatDate(startTempDate);
          newShuffle[j].end_date = formatDate(endTempDate);
          tempEndDate.setDate(tempEndDate.getDate() - (tempDateDiff + 1));
          i += tempDateDiff + 1;
          j += 1;
        }

        setTable(
          table.map((item) => {
            if (item.id === data.over.data.current.listId) {
              return {
                ...item,
                items: newShuffle,
              };
            }
            return item;
          })
        );
      }
    }
  };
  const handleDragStart = (data) => {
    setDraggingCard(data.active.data.current.item);
  };
  const formatDate = (dateString) => {
    let dateObject = new Date(dateString);
    let monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let month = monthNames[dateObject.getMonth()];
    let day = dateObject.getDate().toString().padStart(2, "0");
    let year = dateObject.getFullYear();
    let currentYear = new Date().getFullYear();
    let formattedDate = month + " " + day + " " + year;
    return formattedDate;
  };

  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      const dates = [];
      for (
        let date = new Date(startDate);
        date <= endDate;
        date.setDate(date.getDate() + 1)
      ) {
        // Add the date to the array.
        dates.push(formatDate(date));
      }
      setDateList(dates.reverse());
    }
  }, [startDate, endDate]);
  useEffect(() => {
    if (dateList !== null && headerList.length !== 0) {
      setTable(
        headerList.map((header) => {
          return {
            id: uuid(),
            name: header.listname,
            items: dateList.map((date) => {
              return {
                id: uuid(),
                name: "",
                start_date: date,
                end_date: date,
              };
            }),
          };
        })
      );
    }
  }, [dateList]);
  return (
    <>
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            <h5 className="fw-bold py-3 mb-0">Project Cards</h5>
            <div>
              {privileges.includes("project_card_listview") ? (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => navigate("/projectcardslistview")}
                >
                  List View
                </button>
              ) : (
                ""
              )}
              {/* {privileges.includes("project_card_ganttview") ? ( */}
              <button
                type="button"
                className="btn btn-primary ms-3"
                onClick={() => navigate("/ganttview")}
              >
                Gantt View
              </button>
              {/* ) : (
                ""
              )} */}
              {privileges.includes("create_project_cards") ? (
                <button
                  type="button"
                  className="btn btn-primary ms-3"
                  data-bs-toggle="modal"
                  data-bs-target="#createboard"
                >
                  Create Board
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={hadleDragEnd}
        onDragStart={handleDragStart}
      >
        <div className="Board container-fluid overflow-auto d-flex align-items-start justify-content-start">
          <DateList dateList={dateList} />
          {table.map((list, index) => {
            return (
              <ProjectCardsList
                key={`${index}`}
                list={list}
                setTable={setTable}
                table={table}
                setCurrentList={setCurrentList}
              />
            );
          })}
        </div>
        {createPortal(
          <DragOverlay>
            {draggingCard !== null && <ProjectCardsCard item={draggingCard} />}
          </DragOverlay>,
          document.body
        )}
      </DndContext>
      <CreateCardBoard
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setHeaderList={setHeaderList}
      />
      <AddCard
        list={currentList}
        setTable={setTable}
        table={table}
        startDate={startDate}
        endDate={endDate}
      />
    </>
  );
}

export default ProjectCardsBoard;
