import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import secureLocalStorage from "react-secure-storage";
import ReactPaginate from "react-paginate";

const LeaveApplicationTable = (props) => {
  const privileges = secureLocalStorage.getItem("privileges");
  // Search Filter OnChange from input field
  const [filterLeaveApplication, setFilterLeaveApplication] = useState([]);

  // OnCHnage Event to get input value
  const onChangeHandler = (event) => {
    if (event.target.value === "") {
      setFilterLeaveApplication(props.rawLeaveData);
      return;
    }
    let filterLeaveApplicationData = props.rawLeaveData.filter((item) =>
      item.employee.username.toLowerCase().includes(event.target.value)
    );
    setFilterLeaveApplication(filterLeaveApplicationData);
  };

  useEffect(() => {
    setFilterLeaveApplication(props.rawLeaveData);
  }, [props.rawLeaveData]);

  return (
    <>
      <div className="row align-item-center">
        <div className="col-md-12">
          <div className="card mb-3">
            <div className="card-header py-3 bg-transparent border-bottom-0">
              <div className="filter">
                <p className="search-title">What are you looking for ?</p>
                <div className="input-group mb-3">
                  <span className="input-group-text" id="basic-addon1">
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </span>
                  <input
                    type="text"
                    placeholder="Search by Employee name..."
                    className="form-control"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-3">
            {props.isLoading ? (
              <div className="card-body">Loading...</div>
            ) : (
              <div className="card-body basic-custome-color">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Employee Name</th>
                        <th scope="col">Leave Type</th>
                        <th scope="col">From</th>
                        <th scope="col">To</th>
                        <th scope="col">Reason</th>
                        <th scope="col">Approval Status</th>
                        {privileges.includes("apply_leave_request") ? (
                          <th scope="col">Action</th>
                        ) : (
                          ""
                        )}
                      </tr>
                    </thead>
                    {filterLeaveApplication.length === 0 && (
                      <tbody>
                        <tr>
                          <td></td>
                          <td>No data found</td>
                          <td></td>
                        </tr>
                      </tbody>
                    )}
                    {filterLeaveApplication.length > 0 && (
                      <tbody>
                        {filterLeaveApplication.map((api, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                <span className="fw-bold ms-1">
                                  {" "}
                                  {api?.employee?.username}{" "}
                                </span>
                              </td>
                              <td> {api?.leave_type?.name} </td>
                              <td> {api?.start_date} </td>
                              <td> {api?.end_date} </td>
                              <td> {api?.reason} </td>
                              <td>
                                {" "}
                                <span
                                  className={`${
                                    api.approval_status === "Approved"
                                      ? "badge rounded-pill light-info-bg text-success"
                                      : api.approval_status === "Rejected"
                                      ? "badge rounded-pill text-danger light-info-bg"
                                      : "badge rounded-pill light-info-bg text-info"
                                  }`}
                                >
                                  {api.approval_status}
                                </span>{" "}
                              </td>
                              {privileges.includes("approved_leave_request") ? (
                                <td className="approved-leave">
                                  {" "}
                                  <button
                                    type="button"
                                    className="btn btn-outline-secondary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#depedit"
                                    onClick={() =>
                                      props.editLeaveTypeData(api.id)
                                    }
                                  >
                                    <i className="icofont-edit text-success"></i>
                                  </button>
                                </td>
                              ) : (
                                ""
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                  <div className="d-flex justify-content-center justify-content-md-between mt-3">
                    <ReactPaginate
                      key={3}
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={props.noPages}
                      initialPage={props.currentPage - 1}
                      marginPagesDisplayed={2}
                      disableInitialCallback={true}
                      // pageRangeDisplayed={3}
                      onPageChange={(selectedPage) => {
                        // setCurrentPage(selectedPage.selected + 1);
                        props.apiGetLeaveDataAll(
                          selectedPage.selected + 1,
                          props.entire
                        );
                      }}
                      containerClassName={
                        "pagination justify-content-center justify-content-md-start"
                      }
                      pageClassName={"paginate_button page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"paginate_button page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />

                    <div className="d-flex justify-content-center align-items-center">
                      <div className="me-3">No of entries per page:</div>
                      <div>
                        <select
                          className="form-select"
                          value={props.entire}
                          onChange={(e) => {
                            props.setEntries(e.target.value);
                            props.apiGetLeaveDataAll(
                              props.currentPage,
                              e.target.value
                            );
                          }}
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <!-- Row End --> */}
    </>
  );
};

export default LeaveApplicationTable;
