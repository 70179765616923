import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
// import ReactPaginate from "react-paginate";
import { VARIABLES } from "../../Constants";
import { DropdownAPIsContext } from "../../context/DropdownAPIsContext/DropdownAPIsContextProvider";
import { useLocation } from "react-router-dom";

function ProjectStatsTable() {
  const [projectStatus, setProjectStatus] = useState([]);
  const [totalCounts, setTotalCounts] = useState([]);
  const [filterProject, setFilterProject] = useState([]);
  const [loading, setLoading] = useState(false);
  const { st, op } = useContext(DropdownAPIsContext);
  const location = useLocation();
  const getProjectsStatus = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${
        VARIABLES.url
      }/api-app/count-of-card-project-wise?id=1&category_id=${
        location.pathname === "/projectstatus" ? op : st
      }`,
      headers: {},
    };
    setLoading(true);
    axios
      .request(config)
      .then((response) => {
        setProjectStatus(
          Object.entries(response.data.result).map(([key, value]) => ({
            name: key,
            ...value,
          }))
        );
        setTotalCounts(response.data.total_count);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  // OnCHnage Event to get input value
  const onChangeHandler = (event) => {
    if (event.target.value === "") {
      setFilterProject(projectStatus);
      return;
    }
    let filterProjectData = projectStatus.filter((item) =>
      item.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setFilterProject(filterProjectData);
  };
  useEffect(() => {
    if (projectStatus.length !== 0) {
      setFilterProject(projectStatus);
    }
  }, [projectStatus]);
  useEffect(() => {
    if (st !== null && op !== null) {
      getProjectsStatus();
    }
  }, [st, op, location]);
  return (
    <>
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            <h5 className="fw-bold py-3 mb-0">
              {location.pathname === "/projectstatus"
                ? "Projects "
                : "Departments "}
              Status
            </h5>
            <div className="d-flex"></div>
          </div>
        </div>
      </div>
      <div>
        <div className="row g-3 mb-3 row-deck">
          <div className="col-md-12">
            <div className="card mb-3">
              <div className="card-body">
                <div className="input-group mb-3">
                  <span className="input-group-text" id="basic-addon1">
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by Name"
                    onChange={onChangeHandler}
                  />
                </div>
                <div className="table-responsive">
                  <table
                    id="myProjectTable"
                    className="table table-hover align-middle mb-0"
                    style={{ width: "100%" }}
                  >
                    {totalCounts && (
                      <>
                        <thead>
                          <tr>
                            {Object.keys(totalCounts).map((key, i) => (
                              <th key={i}>Total {key}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {Object.values(totalCounts).map((value, i) => (
                              <td key={i}>{value}</td>
                            ))}
                          </tr>
                        </tbody>
                      </>
                    )}
                  </table>
                </div>
                <div className="table-responsive">
                  <table
                    id="myProjectTable"
                    className="table table-hover align-middle mb-0"
                    style={{ width: "100%" }}
                  >
                    {filterProject.length > 0 && (
                      <thead>
                        <tr>
                          {Object.keys(filterProject[0]).map((key, i) => (
                            <th key={i}>{key}</th>
                          ))}
                        </tr>
                      </thead>
                    )}
                    {filterProject.length === 0 && loading === true && (
                      <p className="mt-3">loading...</p>
                    )}
                    {filterProject.length === 0 && loading === false && (
                      <p className="mt-3">No Data</p>
                    )}
                    {filterProject.length > 0 && (
                      <tbody>
                        {filterProject.map((api, index) => {
                          return (
                            <tr key={index}>
                              {Object.values(filterProject[index]).map(
                                (value, i) => (
                                  <td key={i}>{value}</td>
                                )
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                </div>
                {/* <div className="d-flex justify-content-center justify-content-md-between mt-3">
                  <ReactPaginate
                    key={3}
                    previousLabel={"previous"}
                    nextLabel={"next"}
                    breakLabel={"..."}
                    pageCount={noPages}
                    initialPage={currentPage - 1}
                    marginPagesDisplayed={2}
                    disableInitialCallback={true}
                    pageRangeDisplayed={3}
                    onPageChange={(selectedPage) => {
                      console.log("Selected Page:", selectedPage);
                      setCurrentPage(selectedPage.selected + 1);
                      apiGetRawProjectData(selectedPage.selected + 1, entire);
                    }}
                    containerClassName={
                      "pagination justify-content-center justify-content-md-start"
                    }
                    pageClassName={"paginate_button page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"paginate_button page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />

                  <div className="d-flex justify-content-center align-items-center">
                    <div>
                      Total entries:{" "}
                      <span className="fw-bold me-3">
                        {entryCount === null ? 0 : entryCount}
                        {entire}
                      </span>
                    </div>
                    <div className="me-3">No of entries per page:</div>
                    <div>
                      <select
                        className="form-select"
                        value={entire}
                        onChange={(e) => {
                          setEntries(e.target.value);
                          apiGetRawProjectData(currentPage, e.target.value);
                        }}
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectStatsTable;
