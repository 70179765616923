export const wellnessQuotes = [
    "Health is a state of complete harmony of the body, mind, and spirit. When one is free from physical disabilities and mental distractions, the gates of the soul open. – B.K.S. Iyengar",
    "The greatest wealth is health. – Virgil",
    "Take care of your body. It's the only place you have to live. – Jim Rohn",
    "Wellness encompasses a healthy body, a sound mind, and a tranquil spirit. – Unknown",
    "To keep the body in good health is a duty, otherwise, we shall not be able to keep our mind strong and clear. – Buddha",
    "A healthy outside starts from the inside. – Robert Urich",
    "The groundwork for all happiness is good health. – Leigh Hunt",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "Your body holds deep wisdom. Trust in it. Learn from it. Nourish it. Watch your life transform and be healthy. – Bella Bleue",
    "The first wealth is health. – Ralph Waldo Emerson",
    "Physical fitness is the first requisite of happiness. – Joseph Pilates",
    "The body is your temple. Keep it pure and clean for the soul to reside in. – B.K.S. Iyengar",
    "Health is not about the weight you lose, but about the life you gain. – Unknown",
    "Wellness is the key to longevity and a fulfilling life. – Evita Ochel",
    "Invest in your health today, or pay for your illness tomorrow. – Unknown",
    "The doctor of the future will give no medication, but will interest his patients in the care of the human frame, diet, and in the cause and prevention of disease. – Thomas A. Edison",
    "Nourishing yourself in a way that helps you blossom in the direction you want to go is attainable, and you are worth the effort. – Deborah Day",
    "Happiness is the highest form of health. – Dalai Lama",
    "He who has health has hope, and he who has hope has everything. – Arabian Proverb",
    "The secret of health for both mind and body is not to mourn for the past, worry about the future, or anticipate troubles, but to live in the present moment wisely and earnestly. – Buddha",
    "The body achieves what the mind believes. – Unknown",
    "Good health is not something we can buy. However, it can be an extremely valuable savings account. – Anne Wilson Schaef",
    "It is health that is real wealth and not pieces of gold and silver. – Mahatma Gandhi",
    "A sound mind in a sound body is a short but full description of a happy state in this world. – John Locke",
    "A healthy attitude is contagious but don't wait to catch it from others. Be a carrier. – Tom Stoppard",
    "In minds crammed with thoughts, organs clogged with toxins, and bodies stiffened with neglect, there is just no space for anything else. – Alison Rose Levy",
    "A healthy lifestyle is not just about what you eat. It is also what you consume mentally, emotionally, and spiritually. – Unknown",
    "A fit, healthy body—that is the best fashion statement. – Jess C. Scott",
    "Wellness is a connection of paths: knowledge and action. – Joshua Welch",
    "Your body will be around a lot longer than that expensive handbag. Invest in yourself. – Unknown",
    "The human body has been designed to resist an infinite number of changes and attacks brought about by its environment. The secret of good health lies in successful adjustment to changing stresses on the body. – Harry J. Johnson",
    "The power of community to create health is far greater than any physician, clinic, or hospital. – Mark Hyman",
    "Physical fitness is not only one of the most important keys to a healthy body, it is the basis of dynamic and creative intellectual activity. – John F. Kennedy",
    "The best six doctors anywhere and no one can deny it are sunshine, water, rest, air, exercise, and diet. – Wayne Fields",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "Health is a relationship between you and your body. – Terri Guillemets",
    "A moment of self-compassion can change your entire day. A string of such moments can change the course of your life. – Christopher K. Germer",
    "True health is the ability to live life to the fullest with vibrant energy and enthusiasm. – K.D. Lang",
    "Your body holds deep wisdom. Trust in it. Learn from it. Nourish it. Watch your life transform and be healthy. – Bella Bleue",
    "The first wealth is health. – Ralph Waldo Emerson",
    "Physical fitness is the first requisite of happiness. – Joseph Pilates",
    "The body is your temple. Keep it pure and clean for the soul to reside in. – B.K.S. Iyengar",
    "Health is not about the weight you lose, but about the life you gain. – Unknown",
    "Wellness is the key to longevity and a fulfilling life. – Evita Ochel",
    "Invest in your health today, or pay for your illness tomorrow. – Unknown",
    "The doctor of the future will give no medication, but will interest his patients in the care of the human frame, diet, and in the cause and prevention of disease. – Thomas A. Edison",
    "Nourishing yourself in a way that helps you blossom in the direction you want to go is attainable, and you are worth the effort. – Deborah Day",
    "Happiness is the highest form of health. – Dalai Lama",
    "He who has health has hope, and he who has hope has everything. – Arabian Proverb",
    "The secret of health for both mind and body is not to mourn for the past, worry about the future, or anticipate troubles, but to live in the present moment wisely and earnestly. – Buddha",
    "The body achieves what the mind believes. – Unknown",
    "Good health is not something we can buy. However, it can be an extremely valuable savings account. – Anne Wilson Schaef",
    "It is health that is real wealth and not pieces of gold and silver. – Mahatma Gandhi",
    "A sound mind in a sound body is a short but full description of a happy state in this world. – John Locke",
    "A healthy attitude is contagious but don't wait to catch it from others. Be a carrier. – Tom Stoppard",
    "In minds crammed with thoughts, organs clogged with toxins, and bodies stiffened with neglect, there is just no space for anything else. – Alison Rose Levy",
    "A healthy lifestyle is not just about what you eat. It is also what you consume mentally, emotionally, and spiritually. – Unknown",
    "A fit, healthy body—that is the best fashion statement. – Jess C. Scott",
    "Wellness is a connection of paths: knowledge and action. – Joshua Welch",
    "Your body will be around a lot longer than that expensive handbag. Invest in yourself. – Unknown",
    "The human body has been designed to resist an infinite number of changes and attacks brought about by its environment. The secret of good health lies in successful adjustment to changing stresses on the body. – Harry J. Johnson",
    "The power of community to create health is far greater than any physician, clinic, or hospital. – Mark Hyman",
    "Physical fitness is not only one of the most important keys to a healthy body, it is the basis of dynamic and creative intellectual activity. – John F. Kennedy",
    "The best six doctors anywhere and no one can deny it are sunshine, water, rest, air, exercise, and diet. – Wayne Fields",
    "The higher your energy level, the more efficient your body. The more efficient your body, the better you feel and the more you will use your talent to produce outstanding results. – Anthony Robbins",
    "Wellness is a state of complete physical, mental, and social well-being, and not merely the absence of disease or infirmity. – World Health Organization",
    "Take care of your body. It's the only place you have to live. – Jim Rohn",
    "To ensure good health: eat lightly, breathe deeply, live moderately, cultivate cheerfulness, and maintain an interest in life. – William Londen",
    "You can't control everything in life, but you can control what you put in your body. – Unknown",
    "Your health is what you make of it. Everything you do and think either adds to the vitality, energy, and spirit you possess or takes away from it. – Ann Wigmore",
    "A healthy outside starts from the inside. – Robert Urich",
    "Wellness is the result of personal initiative, seeking a more optimal, holistic, and balanced state of health and well-being across multiple dimensions. – Brady Wilson",
    "Invest in your health today, or pay for your illness tomorrow. – Unknown",
    "The foundation of success in life is good health: that is the substratum fortune; it is also the basis of happiness. A person cannot accumulate a fortune very well when he is sick. – P.T. Barnum",
    "The body is your temple. Keep it pure and clean for the soul to reside in. – B.K.S. Iyengar",
    "The human body is the best picture of the human soul. – Ludwig Wittgenstein",
    "To ensure good health: eat lightly, breathe deeply, live moderately, cultivate cheerfulness, and maintain an interest in life. – William Londen",
    "A healthy attitude is contagious but don't wait to catch it from others. Be a carrier. – Tom Stoppard",
    "Physical fitness is the basis for all other forms of excellence. – John F. Kennedy",
    "The power of community to create health is far greater than any physician, clinic, or hospital. – Mark Hyman",
    "Good nutrition creates health in all areas of our existence. All parts are interconnected. – T. Colin Campbell",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "The food you eat can either be the safest and most powerful form of medicine or the slowest form of poison. – Ann Wigmore",
    "Health is a state of complete harmony of the body, mind, and spirit. When one is free from physical disabilities and mental distractions, the gates of the soul open. – B.K.S. Iyengar",
    "It is health that is real wealth and not pieces of gold and silver. – Mahatma Gandhi",
    "A healthy lifestyle is not just about what you eat. It is also what you consume mentally, emotionally, and spiritually. – Unknown",
    "Happiness is the highest form of health. – Dalai Lama",
    "The secret of health for both mind and body is not to mourn for the past, worry about the future, or anticipate troubles, but to live in the present moment wisely and earnestly. – Buddha",
    "Health is a relationship between you and your body. – Terri Guillemets",
    "In order to change, we must be sick and tired of being sick and tired. – Unknown",
    "Your body will be around a lot longer than that expensive handbag. Invest in yourself. – Unknown",
    "The human body has been designed to resist an infinite number of changes and attacks brought about by its environment. The secret of good health lies in successful adjustment to changing stresses on the body. – Harry J. Johnson",
    "Health is not about the weight you lose, but about the life you gain. – Unknown",
    "Nourishing yourself in a way that helps you blossom in the direction you want to go is attainable, and you are worth the effort. – Deborah Day",
    "Physical fitness is not only one of the most important keys to a healthy body, it is the basis of dynamic and creative intellectual activity. – John F. Kennedy",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "True health is the ability to live life to the fullest with vibrant energy and enthusiasm. – K.D. Lang",
    "You can't control everything in life, but you can control what you put in your body. – Unknown",
    "Your health is what you make of it. Everything you do and think either adds to the vitality, energy, and spirit you possess or takes away from it. – Ann Wigmore",
    "A healthy outside starts from the inside. – Robert Urich",
    "Wellness is the result of personal initiative, seeking a more optimal, holistic, and balanced state of health and well-being across multiple dimensions. – Brady Wilson",
    "Invest in your health today, or pay for your illness tomorrow. – Unknown",
    "The foundation of success in life is good health: that is the substratum fortune; it is also the basis of happiness. A person cannot accumulate a fortune very well when he is sick. – P.T. Barnum",
    "The body is your temple. Keep it pure and clean for the soul to reside in. – B.K.S. Iyengar",
    "The human body is the best picture of the human soul. – Ludwig Wittgenstein",
    "To ensure good health: eat lightly, breathe deeply, live moderately, cultivate cheerfulness, and maintain an interest in life. – William Londen",
    "A healthy attitude is contagious but don't wait to catch it from others. Be a carrier. – Tom Stoppard",
    "Physical fitness is the basis for all other forms of excellence. – John F. Kennedy",
    "The power of community to create health is far greater than any physician, clinic, or hospital. – Mark Hyman",
    "Good nutrition creates health in all areas of our existence. All parts are interconnected. – T. Colin Campbell",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "The food you eat can either be the safest and most powerful form of medicine or the slowest form of poison. – Ann Wigmore",
    "Health is a state of complete harmony of the body, mind, and spirit. When one is free from physical disabilities and mental distractions, the gates of the soul open. – B.K.S. Iyengar",
    "It is health that is real wealth and not pieces of gold and silver. – Mahatma Gandhi",
    "A healthy lifestyle is not just about what you eat. It is also what you consume mentally, emotionally, and spiritually. – Unknown",
    "Happiness is the highest form of health. – Dalai Lama",
    "The secret of health for both mind and body is not to mourn for the past, worry about the future, or anticipate troubles, but to live in the present moment wisely and earnestly. – Buddha",
    "Health is a relationship between you and your body. – Terri Guillemets",
    "In order to change, we must be sick and tired of being sick and tired. – Unknown",
    "Your body will be around a lot longer than that expensive handbag. Invest in yourself. – Unknown",
    "The human body has been designed to resist an infinite number of changes and attacks brought about by its environment. The secret of good health lies in successful adjustment to changing stresses on the body. – Harry J. Johnson",
    "Health is not about the weight you lose, but about the life you gain. – Unknown",
    "Nourishing yourself in a way that helps you blossom in the direction you want to go is attainable, and you are worth the effort. – Deborah Day",
    "Physical fitness is not only one of the most important keys to a healthy body, it is the basis of dynamic and creative intellectual activity. – John F. Kennedy",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "True health is the ability to live life to the fullest with vibrant energy and enthusiasm. – K.D. Lang",
    "You can't control everything in life, but you can control what you put in your body. – Unknown",
    "Your health is what you make of it. Everything you do and think either adds to the vitality, energy, and spirit you possess or takes away from it. – Ann Wigmore",
    "A healthy outside starts from the inside. – Robert Urich",
    "Wellness is the result of personal initiative, seeking a more optimal, holistic, and balanced state of health and well-being across multiple dimensions. – Brady Wilson",
    "Invest in your health today, or pay for your illness tomorrow. – Unknown",
    "The foundation of success in life is good health: that is the substratum fortune; it is also the basis of happiness. A person cannot accumulate a fortune very well when he is sick. – P.T. Barnum",
    "The body is your temple. Keep it pure and clean for the soul to reside in. – B.K.S. Iyengar",
    "The human body is the best picture of the human soul. – Ludwig Wittgenstein",
    "To ensure good health: eat lightly, breathe deeply, live moderately, cultivate cheerfulness, and maintain an interest in life. – William Londen",
    "A healthy attitude is contagious but don't wait to catch it from others. Be a carrier. – Tom Stoppard",
    "Physical fitness is the basis for all other forms of excellence. – John F. Kennedy",
    "The power of community to create health is far greater than any physician, clinic, or hospital. – Mark Hyman",
    "Good nutrition creates health in all areas of our existence. All parts are interconnected. – T. Colin Campbell",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "The food you eat can either be the safest and most powerful form of medicine or the slowest form of poison. – Ann Wigmore",
    "Health is a state of complete harmony of the body, mind, and spirit. When one is free from physical disabilities and mental distractions, the gates of the soul open. – B.K.S. Iyengar",
    "It is health that is real wealth and not pieces of gold and silver. – Mahatma Gandhi",
    "A healthy lifestyle is not just about what you eat. It is also what you consume mentally, emotionally, and spiritually. – Unknown",
    "Happiness is the highest form of health. – Dalai Lama",
    "The secret of health for both mind and body is not to mourn for the past, worry about the future, or anticipate troubles, but to live in the present moment wisely and earnestly. – Buddha",
    "Health is a relationship between you and your body. – Terri Guillemets",
    "In order to change, we must be sick and tired of being sick and tired. – Unknown",
    "Your body will be around a lot longer than that expensive handbag. Invest in yourself. – Unknown",
    "The human body has been designed to resist an infinite number of changes and attacks brought about by its environment. The secret of good health lies in successful adjustment to changing stresses on the body. – Harry J. Johnson",
    "Health is not about the weight you lose, but about the life you gain. – Unknown",
    "Nourishing yourself in a way that helps you blossom in the direction you want to go is attainable, and you are worth the effort. – Deborah Day",
    "Physical fitness is not only one of the most important keys to a healthy body, it is the basis of dynamic and creative intellectual activity. – John F. Kennedy",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "True health is the ability to live life to the fullest with vibrant energy and enthusiasm. – K.D. Lang",
    "You can't control everything in life, but you can control what you put in your body. – Unknown",
    "Your health is what you make of it. Everything you do and think either adds to the vitality, energy, and spirit you possess or takes away from it. – Ann Wigmore",
    "A healthy outside starts from the inside. – Robert Urich",
    "Wellness is the result of personal initiative, seeking a more optimal, holistic, and balanced state of health and well-being across multiple dimensions. – Brady Wilson",
    "Invest in your health today, or pay for your illness tomorrow. – Unknown",
    "The foundation of success in life is good health: that is the substratum fortune; it is also the basis of happiness. A person cannot accumulate a fortune very well when he is sick. – P.T. Barnum",
    "The body is your temple. Keep it pure and clean for the soul to reside in. – B.K.S. Iyengar",
    "The human body is the best picture of the human soul. – Ludwig Wittgenstein",
    "To ensure good health: eat lightly, breathe deeply, live moderately, cultivate cheerfulness, and maintain an interest in life. – William Londen",
    "A healthy attitude is contagious but don't wait to catch it from others. Be a carrier. – Tom Stoppard",
    "Physical fitness is the basis for all other forms of excellence. – John F. Kennedy",
    "The power of community to create health is far greater than any physician, clinic, or hospital. – Mark Hyman",
    "Good nutrition creates health in all areas of our existence. All parts are interconnected. – T. Colin Campbell",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "The food you eat can either be the safest and most powerful form of medicine or the slowest form of poison. – Ann Wigmore",
    "Health is a state of complete harmony of the body, mind, and spirit. When one is free from physical disabilities and mental distractions, the gates of the soul open. – B.K.S. Iyengar",
    "It is health that is real wealth and not pieces of gold and silver. – Mahatma Gandhi",
    "A healthy lifestyle is not just about what you eat. It is also what you consume mentally, emotionally, and spiritually. – Unknown",
    "Happiness is the highest form of health. – Dalai Lama",
    "The secret of health for both mind and body is not to mourn for the past, worry about the future, or anticipate troubles, but to live in the present moment wisely and earnestly. – Buddha",
    "Health is a relationship between you and your body. – Terri Guillemets",
    "In order to change, we must be sick and tired of being sick and tired. – Unknown",
    "Your body will be around a lot longer than that expensive handbag. Invest in yourself. – Unknown",
    "The human body has been designed to resist an infinite number of changes and attacks brought about by its environment. The secret of good health lies in successful adjustment to changing stresses on the body. – Harry J. Johnson",
    "Health is not about the weight you lose, but about the life you gain. – Unknown",
    "Nourishing yourself in a way that helps you blossom in the direction you want to go is attainable, and you are worth the effort. – Deborah Day",
    "Physical fitness is not only one of the most important keys to a healthy body, it is the basis of dynamic and creative intellectual activity. – John F. Kennedy",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "True health is the ability to live life to the fullest with vibrant energy and enthusiasm. – K.D. Lang",
    "You can't control everything in life, but you can control what you put in your body. – Unknown",
    "Your health is what you make of it. Everything you do and think either adds to the vitality, energy, and spirit you possess or takes away from it. – Ann Wigmore",
    "A healthy outside starts from the inside. – Robert Urich",
    "Wellness is the result of personal initiative, seeking a more optimal, holistic, and balanced state of health and well-being across multiple dimensions. – Brady Wilson",
    "Invest in your health today, or pay for your illness tomorrow. – Unknown",
    "The foundation of success in life is good health: that is the substratum fortune; it is also the basis of happiness. A person cannot accumulate a fortune very well when he is sick. – P.T. Barnum",
    "The body is your temple. Keep it pure and clean for the soul to reside in. – B.K.S. Iyengar",
    "The human body is the best picture of the human soul. – Ludwig Wittgenstein",
    "To ensure good health: eat lightly, breathe deeply, live moderately, cultivate cheerfulness, and maintain an interest in life. – William Londen",
    "A healthy attitude is contagious but don't wait to catch it from others. Be a carrier. – Tom Stoppard",
    "Physical fitness is the basis for all other forms of excellence. – John F. Kennedy",
    "The power of community to create health is far greater than any physician, clinic, or hospital. – Mark Hyman",
    "Good nutrition creates health in all areas of our existence. All parts are interconnected. – T. Colin Campbell",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "The food you eat can either be the safest and most powerful form of medicine or the slowest form of poison. – Ann Wigmore",
    "Health is a state of complete harmony of the body, mind, and spirit. When one is free from physical disabilities and mental distractions, the gates of the soul open. – B.K.S. Iyengar",
    "It is health that is real wealth and not pieces of gold and silver. – Mahatma Gandhi",
    "A healthy lifestyle is not just about what you eat. It is also what you consume mentally, emotionally, and spiritually. – Unknown",
    "Happiness is the highest form of health. – Dalai Lama",
    "The secret of health for both mind and body is not to mourn for the past, worry about the future, or anticipate troubles, but to live in the present moment wisely and earnestly. – Buddha",
    "Health is a relationship between you and your body. – Terri Guillemets",
    "In order to change, we must be sick and tired of being sick and tired. – Unknown",
    "Your body will be around a lot longer than that expensive handbag. Invest in yourself. – Unknown",
    "The human body has been designed to resist an infinite number of changes and attacks brought about by its environment. The secret of good health lies in successful adjustment to changing stresses on the body. – Harry J. Johnson",
    "Health is not about the weight you lose, but about the life you gain. – Unknown",
    "Nourishing yourself in a way that helps you blossom in the direction you want to go is attainable, and you are worth the effort. – Deborah Day",
    "Physical fitness is not only one of the most important keys to a healthy body, it is the basis of dynamic and creative intellectual activity. – John F. Kennedy",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "True health is the ability to live life to the fullest with vibrant energy and enthusiasm. – K.D. Lang",
    "You can't control everything in life, but you can control what you put in your body. – Unknown",
    "Your health is what you make of it. Everything you do and think either adds to the vitality, energy, and spirit you possess or takes away from it. – Ann Wigmore",
    "A healthy outside starts from the inside. – Robert Urich",
    "Wellness is the result of personal initiative, seeking a more optimal, holistic, and balanced state of health and well-being across multiple dimensions. – Brady Wilson",
    "Invest in your health today, or pay for your illness tomorrow. – Unknown",
    "The foundation of success in life is good health: that is the substratum fortune; it is also the basis of happiness. A person cannot accumulate a fortune very well when he is sick. – P.T. Barnum",
    "The body is your temple. Keep it pure and clean for the soul to reside in. – B.K.S. Iyengar",
    "The human body is the best picture of the human soul. – Ludwig Wittgenstein",
    "To ensure good health: eat lightly, breathe deeply, live moderately, cultivate cheerfulness, and maintain an interest in life. – William Londen",
    "A healthy attitude is contagious but don't wait to catch it from others. Be a carrier. – Tom Stoppard",
    "Physical fitness is the basis for all other forms of excellence. – John F. Kennedy",
    "The power of community to create health is far greater than any physician, clinic, or hospital. – Mark Hyman",
    "Good nutrition creates health in all areas of our existence. All parts are interconnected. – T. Colin Campbell",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "The food you eat can either be the safest and most powerful form of medicine or the slowest form of poison. – Ann Wigmore",
    "Health is a state of complete harmony of the body, mind, and spirit. When one is free from physical disabilities and mental distractions, the gates of the soul open. – B.K.S. Iyengar",
    "It is health that is real wealth and not pieces of gold and silver. – Mahatma Gandhi",
    "A healthy lifestyle is not just about what you eat. It is also what you consume mentally, emotionally, and spiritually. – Unknown",
    "Happiness is the highest form of health. – Dalai Lama",
    "The secret of health for both mind and body is not to mourn for the past, worry about the future, or anticipate troubles, but to live in the present moment wisely and earnestly. – Buddha",
    "Health is a relationship between you and your body. – Terri Guillemets",
    "In order to change, we must be sick and tired of being sick and tired. – Unknown",
    "Your body will be around a lot longer than that expensive handbag. Invest in yourself. – Unknown",
    "The human body has been designed to resist an infinite number of changes and attacks brought about by its environment. The secret of good health lies in successful adjustment to changing stresses on the body. – Harry J. Johnson",
    "Health is not about the weight you lose, but about the life you gain. – Unknown",
    "Nourishing yourself in a way that helps you blossom in the direction you want to go is attainable, and you are worth the effort. – Deborah Day",
    "Physical fitness is not only one of the most important keys to a healthy body, it is the basis of dynamic and creative intellectual activity. – John F. Kennedy",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "True health is the ability to live life to the fullest with vibrant energy and enthusiasm. – K.D. Lang",
    "You can't control everything in life, but you can control what you put in your body. – Unknown",
    "Your health is what you make of it. Everything you do and think either adds to the vitality, energy, and spirit you possess or takes away from it. – Ann Wigmore",
    "A healthy outside starts from the inside. – Robert Urich",
    "Wellness is the result of personal initiative, seeking a more optimal, holistic, and balanced state of health and well-being across multiple dimensions. – Brady Wilson",
    "Invest in your health today, or pay for your illness tomorrow. – Unknown",
    "The foundation of success in life is good health: that is the substratum fortune; it is also the basis of happiness. A person cannot accumulate a fortune very well when he is sick. – P.T. Barnum",
    "The body is your temple. Keep it pure and clean for the soul to reside in. – B.K.S. Iyengar",
    "The human body is the best picture of the human soul. – Ludwig Wittgenstein",
    "To ensure good health: eat lightly, breathe deeply, live moderately, cultivate cheerfulness, and maintain an interest in life. – William Londen",
    "A healthy attitude is contagious but don't wait to catch it from others. Be a carrier. – Tom Stoppard",
    "Physical fitness is the basis for all other forms of excellence. – John F. Kennedy",
    "The power of community to create health is far greater than any physician, clinic, or hospital. – Mark Hyman",
    "Good nutrition creates health in all areas of our existence. All parts are interconnected. – T. Colin Campbell",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "The food you eat can either be the safest and most powerful form of medicine or the slowest form of poison. – Ann Wigmore",
    "Health is a state of complete harmony of the body, mind, and spirit. When one is free from physical disabilities and mental distractions, the gates of the soul open. – B.K.S. Iyengar",
    "It is health that is real wealth and not pieces of gold and silver. – Mahatma Gandhi",
    "A healthy lifestyle is not just about what you eat. It is also what you consume mentally, emotionally, and spiritually. – Unknown",
    "Happiness is the highest form of health. – Dalai Lama",
    "The secret of health for both mind and body is not to mourn for the past, worry about the future, or anticipate troubles, but to live in the present moment wisely and earnestly. – Buddha",
    "Health is a relationship between you and your body. – Terri Guillemets",
    "In order to change, we must be sick and tired of being sick and tired. – Unknown",
    "Your body will be around a lot longer than that expensive handbag. Invest in yourself. – Unknown",
    "The human body has been designed to resist an infinite number of changes and attacks brought about by its environment. The secret of good health lies in successful adjustment to changing stresses on the body. – Harry J. Johnson",
    "Health is not about the weight you lose, but about the life you gain. – Unknown",
    "Nourishing yourself in a way that helps you blossom in the direction you want to go is attainable, and you are worth the effort. – Deborah Day",
    "Physical fitness is not only one of the most important keys to a healthy body, it is the basis of dynamic and creative intellectual activity. – John F. Kennedy",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "True health is the ability to live life to the fullest with vibrant energy and enthusiasm. – K.D. Lang",
    "You can't control everything in life, but you can control what you put in your body. – Unknown",
    "Your health is what you make of it. Everything you do and think either adds to the vitality, energy, and spirit you possess or takes away from it. – Ann Wigmore",
    "A healthy outside starts from the inside. – Robert Urich",
    "Wellness is the result of personal initiative, seeking a more optimal, holistic, and balanced state of health and well-being across multiple dimensions. – Brady Wilson",
    "Invest in your health today, or pay for your illness tomorrow. – Unknown",
    "The foundation of success in life is good health: that is the substratum fortune; it is also the basis of happiness. A person cannot accumulate a fortune very well when he is sick. – P.T. Barnum",
    "The body is your temple. Keep it pure and clean for the soul to reside in. – B.K.S. Iyengar",
    "The human body is the best picture of the human soul. – Ludwig Wittgenstein",
    "To ensure good health: eat lightly, breathe deeply, live moderately, cultivate cheerfulness, and maintain an interest in life. – William Londen",
    "A healthy attitude is contagious but don't wait to catch it from others. Be a carrier. – Tom Stoppard",
    "Physical fitness is the basis for all other forms of excellence. – John F. Kennedy",
    "The power of community to create health is far greater than any physician, clinic, or hospital. – Mark Hyman",
    "Good nutrition creates health in all areas of our existence. All parts are interconnected. – T. Colin Campbell",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "The food you eat can either be the safest and most powerful form of medicine or the slowest form of poison. – Ann Wigmore",
    "Health is a state of complete harmony of the body, mind, and spirit. When one is free from physical disabilities and mental distractions, the gates of the soul open. – B.K.S. Iyengar",
    "It is health that is real wealth and not pieces of gold and silver. – Mahatma Gandhi",
    "A healthy lifestyle is not just about what you eat. It is also what you consume mentally, emotionally, and spiritually. – Unknown",
    "Happiness is the highest form of health. – Dalai Lama",
    "The secret of health for both mind and body is not to mourn for the past, worry about the future, or anticipate troubles, but to live in the present moment wisely and earnestly. – Buddha",
    "Health is a relationship between you and your body. – Terri Guillemets",
    "In order to change, we must be sick and tired of being sick and tired. – Unknown",
    "Your body will be around a lot longer than that expensive handbag. Invest in yourself. – Unknown",
    "The human body has been designed to resist an infinite number of changes and attacks brought about by its environment. The secret of good health lies in successful adjustment to changing stresses on the body. – Harry J. Johnson",
    "Health is not about the weight you lose, but about the life you gain. – Unknown",
    "Nourishing yourself in a way that helps you blossom in the direction you want to go is attainable, and you are worth the effort. – Deborah Day",
    "Physical fitness is not only one of the most important keys to a healthy body, it is the basis of dynamic and creative intellectual activity. – John F. Kennedy",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "True health is the ability to live life to the fullest with vibrant energy and enthusiasm. – K.D. Lang",
    "You can't control everything in life, but you can control what you put in your body. – Unknown",
    "Your health is what you make of it. Everything you do and think either adds to the vitality, energy, and spirit you possess or takes away from it. – Ann Wigmore",
    "A healthy outside starts from the inside. – Robert Urich",
    "Wellness is the result of personal initiative, seeking a more optimal, holistic, and balanced state of health and well-being across multiple dimensions. – Brady Wilson",
    "Invest in your health today, or pay for your illness tomorrow. – Unknown",
    "The foundation of success in life is good health: that is the substratum fortune; it is also the basis of happiness. A person cannot accumulate a fortune very well when he is sick. – P.T. Barnum",
    "The body is your temple. Keep it pure and clean for the soul to reside in. – B.K.S. Iyengar",
    "The human body is the best picture of the human soul. – Ludwig Wittgenstein",
    "To ensure good health: eat lightly, breathe deeply, live moderately, cultivate cheerfulness, and maintain an interest in life. – William Londen",
    "A healthy attitude is contagious but don't wait to catch it from others. Be a carrier. – Tom Stoppard",
    "Physical fitness is the basis for all other forms of excellence. – John F. Kennedy",
    "The power of community to create health is far greater than any physician, clinic, or hospital. – Mark Hyman",
    "Good nutrition creates health in all areas of our existence. All parts are interconnected. – T. Colin Campbell",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "The food you eat can either be the safest and most powerful form of medicine or the slowest form of poison. – Ann Wigmore",
    "Health is a state of complete harmony of the body, mind, and spirit. When one is free from physical disabilities and mental distractions, the gates of the soul open. – B.K.S. Iyengar",
    "It is health that is real wealth and not pieces of gold and silver. – Mahatma Gandhi",
    "A healthy lifestyle is not just about what you eat. It is also what you consume mentally, emotionally, and spiritually. – Unknown",
    "Happiness is the highest form of health. – Dalai Lama",
    "The secret of health for both mind and body is not to mourn for the past, worry about the future, or anticipate troubles, but to live in the present moment wisely and earnestly. – Buddha",
    "Health is a relationship between you and your body. – Terri Guillemets",
    "In order to change, we must be sick and tired of being sick and tired. – Unknown",
    "Your body will be around a lot longer than that expensive handbag. Invest in yourself. – Unknown",
    "The human body has been designed to resist an infinite number of changes and attacks brought about by its environment. The secret of good health lies in successful adjustment to changing stresses on the body. – Harry J. Johnson",
    "Health is not about the weight you lose, but about the life you gain. – Unknown",
    "Nourishing yourself in a way that helps you blossom in the direction you want to go is attainable, and you are worth the effort. – Deborah Day",
    "Physical fitness is not only one of the most important keys to a healthy body, it is the basis of dynamic and creative intellectual activity. – John F. Kennedy",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "True health is the ability to live life to the fullest with vibrant energy and enthusiasm. – K.D. Lang",
    "You can't control everything in life, but you can control what you put in your body. – Unknown",
    "Your health is what you make of it. Everything you do and think either adds to the vitality, energy, and spirit you possess or takes away from it. – Ann Wigmore",
    "A healthy outside starts from the inside. – Robert Urich",
    "Wellness is the result of personal initiative, seeking a more optimal, holistic, and balanced state of health and well-being across multiple dimensions. – Brady Wilson",
    "Invest in your health today, or pay for your illness tomorrow. – Unknown",
    "The foundation of success in life is good health: that is the substratum fortune; it is also the basis of happiness. A person cannot accumulate a fortune very well when he is sick. – P.T. Barnum",
    "The body is your temple. Keep it pure and clean for the soul to reside in. – B.K.S. Iyengar",
    "The human body is the best picture of the human soul. – Ludwig Wittgenstein",
    "To ensure good health: eat lightly, breathe deeply, live moderately, cultivate cheerfulness, and maintain an interest in life. – William Londen",
    "A healthy attitude is contagious but don't wait to catch it from others. Be a carrier. – Tom Stoppard",
    "Physical fitness is the basis for all other forms of excellence. – John F. Kennedy",
    "The power of community to create health is far greater than any physician, clinic, or hospital. – Mark Hyman",
    "Good nutrition creates health in all areas of our existence. All parts are interconnected. – T. Colin Campbell",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "The food you eat can either be the safest and most powerful form of medicine or the slowest form of poison. – Ann Wigmore",
    "Health is a state of complete harmony of the body, mind, and spirit. When one is free from physical disabilities and mental distractions, the gates of the soul open. – B.K.S. Iyengar",
    "It is health that is real wealth and not pieces of gold and silver. – Mahatma Gandhi",
    "A healthy lifestyle is not just about what you eat. It is also what you consume mentally, emotionally, and spiritually. – Unknown",
    "Happiness is the highest form of health. – Dalai Lama",
    "The secret of health for both mind and body is not to mourn for the past, worry about the future, or anticipate troubles, but to live in the present moment wisely and earnestly. – Buddha",
    "Health is a relationship between you and your body. – Terri Guillemets",
    "In order to change, we must be sick and tired of being sick and tired. – Unknown",
    "Your body will be around a lot longer than that expensive handbag. Invest in yourself. – Unknown",
    "The human body has been designed to resist an infinite number of changes and attacks brought about by its environment. The secret of good health lies in successful adjustment to changing stresses on the body. – Harry J. Johnson",
    "Health is not about the weight you lose, but about the life you gain. – Unknown",
    "Nourishing yourself in a way that helps you blossom in the direction you want to go is attainable, and you are worth the effort. – Deborah Day",
    "Physical fitness is not only one of the most important keys to a healthy body, it is the basis of dynamic and creative intellectual activity. – John F. Kennedy",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "True health is the ability to live life to the fullest with vibrant energy and enthusiasm. – K.D. Lang",
    "You can't control everything in life, but you can control what you put in your body. – Unknown",
    "Your health is what you make of it. Everything you do and think either adds to the vitality, energy, and spirit you possess or takes away from it. – Ann Wigmore",
    "A healthy outside starts from the inside. – Robert Urich",
    "Wellness is the result of personal initiative, seeking a more optimal, holistic, and balanced state of health and well-being across multiple dimensions. – Brady Wilson",
    "Invest in your health today, or pay for your illness tomorrow. – Unknown",
    "The foundation of success in life is good health: that is the substratum fortune; it is also the basis of happiness. A person cannot accumulate a fortune very well when he is sick. – P.T. Barnum",
    "The body is your temple. Keep it pure and clean for the soul to reside in. – B.K.S. Iyengar",
    "The human body is the best picture of the human soul. – Ludwig Wittgenstein",
    "To ensure good health: eat lightly, breathe deeply, live moderately, cultivate cheerfulness, and maintain an interest in life. – William Londen",
    "A healthy attitude is contagious but don't wait to catch it from others. Be a carrier. – Tom Stoppard",
    "Physical fitness is the basis for all other forms of excellence. – John F. Kennedy",
    "The power of community to create health is far greater than any physician, clinic, or hospital. – Mark Hyman",
    "Good nutrition creates health in all areas of our existence. All parts are interconnected. – T. Colin Campbell",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "The food you eat can either be the safest and most powerful form of medicine or the slowest form of poison. – Ann Wigmore",
    "Health is a state of complete harmony of the body, mind, and spirit. When one is free from physical disabilities and mental distractions, the gates of the soul open. – B.K.S. Iyengar",
    "It is health that is real wealth and not pieces of gold and silver. – Mahatma Gandhi",
    "A healthy lifestyle is not just about what you eat. It is also what you consume mentally, emotionally, and spiritually. – Unknown",
    "Happiness is the highest form of health. – Dalai Lama",
    "The secret of health for both mind and body is not to mourn for the past, worry about the future, or anticipate troubles, but to live in the present moment wisely and earnestly. – Buddha",
    "Health is a relationship between you and your body. – Terri Guillemets",
    "In order to change, we must be sick and tired of being sick and tired. – Unknown",
    "Your body will be around a lot longer than that expensive handbag. Invest in yourself. – Unknown",
    "The human body has been designed to resist an infinite number of changes and attacks brought about by its environment. The secret of good health lies in successful adjustment to changing stresses on the body. – Harry J. Johnson",
    "Health is not about the weight you lose, but about the life you gain. – Unknown",
    "Nourishing yourself in a way that helps you blossom in the direction you want to go is attainable, and you are worth the effort. – Deborah Day",
    "Physical fitness is not only one of the most important keys to a healthy body, it is the basis of dynamic and creative intellectual activity. – John F. Kennedy",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "True health is the ability to live life to the fullest with vibrant energy and enthusiasm. – K.D. Lang",
    "You can't control everything in life, but you can control what you put in your body. – Unknown",
    "Your health is what you make of it. Everything you do and think either adds to the vitality, energy, and spirit you possess or takes away from it. – Ann Wigmore",
    "A healthy outside starts from the inside. – Robert Urich",
    "Wellness is the result of personal initiative, seeking a more optimal, holistic, and balanced state of health and well-being across multiple dimensions. – Brady Wilson",
    "Invest in your health today, or pay for your illness tomorrow. – Unknown",
    "The foundation of success in life is good health: that is the substratum fortune; it is also the basis of happiness. A person cannot accumulate a fortune very well when he is sick. – P.T. Barnum",
    "The body is your temple. Keep it pure and clean for the soul to reside in. – B.K.S. Iyengar",
    "The human body is the best picture of the human soul. – Ludwig Wittgenstein",
    "To ensure good health: eat lightly, breathe deeply, live moderately, cultivate cheerfulness, and maintain an interest in life. – William Londen",
    "A healthy attitude is contagious but don't wait to catch it from others. Be a carrier. – Tom Stoppard",
    "Physical fitness is the basis for all other forms of excellence. – John F. Kennedy",
    "The power of community to create health is far greater than any physician, clinic, or hospital. – Mark Hyman",
    "Good nutrition creates health in all areas of our existence. All parts are interconnected. – T. Colin Campbell",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "The food you eat can either be the safest and most powerful form of medicine or the slowest form of poison. – Ann Wigmore",
    "Health is a state of complete harmony of the body, mind, and spirit. When one is free from physical disabilities and mental distractions, the gates of the soul open. – B.K.S. Iyengar",
    "It is health that is real wealth and not pieces of gold and silver. – Mahatma Gandhi",
    "A healthy lifestyle is not just about what you eat. It is also what you consume mentally, emotionally, and spiritually. – Unknown",
    "Happiness is the highest form of health. – Dalai Lama",
    "The secret of health for both mind and body is not to mourn for the past, worry about the future, or anticipate troubles, but to live in the present moment wisely and earnestly. – Buddha",
    "Health is a relationship between you and your body. – Terri Guillemets",
    "In order to change, we must be sick and tired of being sick and tired. – Unknown",
    "Your body will be around a lot longer than that expensive handbag. Invest in yourself. – Unknown",
    "The human body has been designed to resist an infinite number of changes and attacks brought about by its environment. The secret of good health lies in successful adjustment to changing stresses on the body. – Harry J. Johnson",
    "Health is not about the weight you lose, but about the life you gain. – Unknown",
    "Nourishing yourself in a way that helps you blossom in the direction you want to go is attainable, and you are worth the effort. – Deborah Day",
    "Physical fitness is not only one of the most important keys to a healthy body, it is the basis of dynamic and creative intellectual activity. – John F. Kennedy",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "True health is the ability to live life to the fullest with vibrant energy and enthusiasm. – K.D. Lang",
    "You can't control everything in life, but you can control what you put in your body. – Unknown",
    "Your health is what you make of it. Everything you do and think either adds to the vitality, energy, and spirit you possess or takes away from it. – Ann Wigmore",
    "A healthy outside starts from the inside. – Robert Urich",
    "Wellness is the result of personal initiative, seeking a more optimal, holistic, and balanced state of health and well-being across multiple dimensions. – Brady Wilson",
    "Invest in your health today, or pay for your illness tomorrow. – Unknown",
    "The foundation of success in life is good health: that is the substratum fortune; it is also the basis of happiness. A person cannot accumulate a fortune very well when he is sick. – P.T. Barnum",
    "The body is your temple. Keep it pure and clean for the soul to reside in. – B.K.S. Iyengar",
    "The human body is the best picture of the human soul. – Ludwig Wittgenstein",
    "To ensure good health: eat lightly, breathe deeply, live moderately, cultivate cheerfulness, and maintain an interest in life. – William Londen",
    "A healthy attitude is contagious but don't wait to catch it from others. Be a carrier. – Tom Stoppard",
    "Physical fitness is the basis for all other forms of excellence. – John F. Kennedy",
    "The power of community to create health is far greater than any physician, clinic, or hospital. – Mark Hyman",
    "Good nutrition creates health in all areas of our existence. All parts are interconnected. – T. Colin Campbell",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "The food you eat can either be the safest and most powerful form of medicine or the slowest form of poison. – Ann Wigmore",
    "Health is a state of complete harmony of the body, mind, and spirit. When one is free from physical disabilities and mental distractions, the gates of the soul open. – B.K.S. Iyengar",
    "It is health that is real wealth and not pieces of gold and silver. – Mahatma Gandhi",
    "A healthy lifestyle is not just about what you eat. It is also what you consume mentally, emotionally, and spiritually. – Unknown",
    "Happiness is the highest form of health. – Dalai Lama",
    "The secret of health for both mind and body is not to mourn for the past, worry about the future, or anticipate troubles, but to live in the present moment wisely and earnestly. – Buddha",
    "Health is a relationship between you and your body. – Terri Guillemets",
    "In order to change, we must be sick and tired of being sick and tired. – Unknown",
    "Your body will be around a lot longer than that expensive handbag. Invest in yourself. – Unknown",
    "The human body has been designed to resist an infinite number of changes and attacks brought about by its environment. The secret of good health lies in successful adjustment to changing stresses on the body. – Harry J. Johnson",
    "Health is not about the weight you lose, but about the life you gain. – Unknown",
    "Nourishing yourself in a way that helps you blossom in the direction you want to go is attainable, and you are worth the effort. – Deborah Day",
    "Physical fitness is not only one of the most important keys to a healthy body, it is the basis of dynamic and creative intellectual activity. – John F. Kennedy",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "True health is the ability to live life to the fullest with vibrant energy and enthusiasm. – K.D. Lang",
    "You can't control everything in life, but you can control what you put in your body. – Unknown",
    "Your health is what you make of it. Everything you do and think either adds to the vitality, energy, and spirit you possess or takes away from it. – Ann Wigmore",
    "A healthy outside starts from the inside. – Robert Urich",
    "Wellness is the result of personal initiative, seeking a more optimal, holistic, and balanced state of health and well-being across multiple dimensions. – Brady Wilson",
    "Invest in your health today, or pay for your illness tomorrow. – Unknown",
    "The foundation of success in life is good health: that is the substratum fortune; it is also the basis of happiness. A person cannot accumulate a fortune very well when he is sick. – P.T. Barnum",
    "The body is your temple. Keep it pure and clean for the soul to reside in. – B.K.S. Iyengar",
    "The human body is the best picture of the human soul. – Ludwig Wittgenstein",
    "To ensure good health: eat lightly, breathe deeply, live moderately, cultivate cheerfulness, and maintain an interest in life. – William Londen",
    "A healthy attitude is contagious but don't wait to catch it from others. Be a carrier. – Tom Stoppard",
    "Physical fitness is the basis for all other forms of excellence. – John F. Kennedy",
    "The power of community to create health is far greater than any physician, clinic, or hospital. – Mark Hyman",
    "Good nutrition creates health in all areas of our existence. All parts are interconnected. – T. Colin Campbell",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "The food you eat can either be the safest and most powerful form of medicine or the slowest form of poison. – Ann Wigmore",
    "Health is a state of complete harmony of the body, mind, and spirit. When one is free from physical disabilities and mental distractions, the gates of the soul open. – B.K.S. Iyengar",
    "It is health that is real wealth and not pieces of gold and silver. – Mahatma Gandhi",
    "A healthy lifestyle is not just about what you eat. It is also what you consume mentally, emotionally, and spiritually. – Unknown",
    "Happiness is the highest form of health. – Dalai Lama",
    "The secret of health for both mind and body is not to mourn for the past, worry about the future, or anticipate troubles, but to live in the present moment wisely and earnestly. – Buddha",
    "Health is a relationship between you and your body. – Terri Guillemets",
    "In order to change, we must be sick and tired of being sick and tired. – Unknown",
    "Your body will be around a lot longer than that expensive handbag. Invest in yourself. – Unknown",
    "The human body has been designed to resist an infinite number of changes and attacks brought about by its environment. The secret of good health lies in successful adjustment to changing stresses on the body. – Harry J. Johnson",
    "Health is not about the weight you lose, but about the life you gain. – Unknown",
    "Nourishing yourself in a way that helps you blossom in the direction you want to go is attainable, and you are worth the effort. – Deborah Day",
    "Physical fitness is not only one of the most important keys to a healthy body, it is the basis of dynamic and creative intellectual activity. – John F. Kennedy",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "True health is the ability to live life to the fullest with vibrant energy and enthusiasm. – K.D. Lang",
    "You can't control everything in life, but you can control what you put in your body. – Unknown",
    "Your health is what you make of it. Everything you do and think either adds to the vitality, energy, and spirit you possess or takes away from it. – Ann Wigmore",
    "A healthy outside starts from the inside. – Robert Urich",
    "Wellness is the result of personal initiative, seeking a more optimal, holistic, and balanced state of health and well-being across multiple dimensions. – Brady Wilson",
    "Invest in your health today, or pay for your illness tomorrow. – Unknown",
    "The foundation of success in life is good health: that is the substratum fortune; it is also the basis of happiness. A person cannot accumulate a fortune very well when he is sick. – P.T. Barnum",
    "The body is your temple. Keep it pure and clean for the soul to reside in. – B.K.S. Iyengar",
    "The human body is the best picture of the human soul. – Ludwig Wittgenstein",
    "To ensure good health: eat lightly, breathe deeply, live moderately, cultivate cheerfulness, and maintain an interest in life. – William Londen",
    "A healthy attitude is contagious but don't wait to catch it from others. Be a carrier. – Tom Stoppard",
    "Physical fitness is the basis for all other forms of excellence. – John F. Kennedy",
    "The power of community to create health is far greater than any physician, clinic, or hospital. – Mark Hyman",
    "Good nutrition creates health in all areas of our existence. All parts are interconnected. – T. Colin Campbell",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "The food you eat can either be the safest and most powerful form of medicine or the slowest form of poison. – Ann Wigmore",
    "Health is a state of complete harmony of the body, mind, and spirit. When one is free from physical disabilities and mental distractions, the gates of the soul open. – B.K.S. Iyengar",
    "It is health that is real wealth and not pieces of gold and silver. – Mahatma Gandhi",
    "A healthy lifestyle is not just about what you eat. It is also what you consume mentally, emotionally, and spiritually. – Unknown",
    "Happiness is the highest form of health. – Dalai Lama",
    "The secret of health for both mind and body is not to mourn for the past, worry about the future, or anticipate troubles, but to live in the present moment wisely and earnestly. – Buddha",
    "Health is a relationship between you and your body. – Terri Guillemets",
    "In order to change, we must be sick and tired of being sick and tired. – Unknown",
    "Your body will be around a lot longer than that expensive handbag. Invest in yourself. – Unknown",
    "The human body has been designed to resist an infinite number of changes and attacks brought about by its environment. The secret of good health lies in successful adjustment to changing stresses on the body. – Harry J. Johnson",
    "Health is not about the weight you lose, but about the life you gain. – Unknown",
    "Nourishing yourself in a way that helps you blossom in the direction you want to go is attainable, and you are worth the effort. – Deborah Day",
    "Physical fitness is not only one of the most important keys to a healthy body, it is the basis of dynamic and creative intellectual activity. – John F. Kennedy",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "True health is the ability to live life to the fullest with vibrant energy and enthusiasm. – K.D. Lang",
    "You can't control everything in life, but you can control what you put in your body. – Unknown",
    "Your health is what you make of it. Everything you do and think either adds to the vitality, energy, and spirit you possess or takes away from it. – Ann Wigmore",
    "A healthy outside starts from the inside. – Robert Urich",
    "Wellness is the result of personal initiative, seeking a more optimal, holistic, and balanced state of health and well-being across multiple dimensions. – Brady Wilson",
    "Invest in your health today, or pay for your illness tomorrow. – Unknown",
    "The foundation of success in life is good health: that is the substratum fortune; it is also the basis of happiness. A person cannot accumulate a fortune very well when he is sick. – P.T. Barnum",
    "The body is your temple. Keep it pure and clean for the soul to reside in. – B.K.S. Iyengar",
    "The human body is the best picture of the human soul. – Ludwig Wittgenstein",
    "To ensure good health: eat lightly, breathe deeply, live moderately, cultivate cheerfulness, and maintain an interest in life. – William Londen",
    "A healthy attitude is contagious but don't wait to catch it from others. Be a carrier. – Tom Stoppard",
    "Physical fitness is the basis for all other forms of excellence. – John F. Kennedy",
    "The power of community to create health is far greater than any physician, clinic, or hospital. – Mark Hyman",
    "Good nutrition creates health in all areas of our existence. All parts are interconnected. – T. Colin Campbell",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "The food you eat can either be the safest and most powerful form of medicine or the slowest form of poison. – Ann Wigmore",
    "Health is a state of complete harmony of the body, mind, and spirit. When one is free from physical disabilities and mental distractions, the gates of the soul open. – B.K.S. Iyengar",
    "It is health that is real wealth and not pieces of gold and silver. – Mahatma Gandhi",
    "A healthy lifestyle is not just about what you eat. It is also what you consume mentally, emotionally, and spiritually. – Unknown",
    "Happiness is the highest form of health. – Dalai Lama",
    "The secret of health for both mind and body is not to mourn for the past, worry about the future, or anticipate troubles, but to live in the present moment wisely and earnestly. – Buddha",
    "Health is a relationship between you and your body. – Terri Guillemets",
    "In order to change, we must be sick and tired of being sick and tired. – Unknown",
    "Your body will be around a lot longer than that expensive handbag. Invest in yourself. – Unknown",
    "The human body has been designed to resist an infinite number of changes and attacks brought about by its environment. The secret of good health lies in successful adjustment to changing stresses on the body. – Harry J. Johnson",
    "Health is not about the weight you lose, but about the life you gain. – Unknown",
    "Nourishing yourself in a way that helps you blossom in the direction you want to go is attainable, and you are worth the effort. – Deborah Day",
    "Physical fitness is not only one of the most important keys to a healthy body, it is the basis of dynamic and creative intellectual activity. – John F. Kennedy",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "True health is the ability to live life to the fullest with vibrant energy and enthusiasm. – K.D. Lang",
    "You can't control everything in life, but you can control what you put in your body. – Unknown",
    "Your health is what you make of it. Everything you do and think either adds to the vitality, energy, and spirit you possess or takes away from it. – Ann Wigmore",
    "A healthy outside starts from the inside. – Robert Urich",
    "Wellness is the result of personal initiative, seeking a more optimal, holistic, and balanced state of health and well-being across multiple dimensions. – Brady Wilson",
    "Invest in your health today, or pay for your illness tomorrow. – Unknown",
    "The foundation of success in life is good health: that is the substratum fortune; it is also the basis of happiness. A person cannot accumulate a fortune very well when he is sick. – P.T. Barnum",
    "The body is your temple. Keep it pure and clean for the soul to reside in. – B.K.S. Iyengar",
    "The human body is the best picture of the human soul. – Ludwig Wittgenstein",
    "To ensure good health: eat lightly, breathe deeply, live moderately, cultivate cheerfulness, and maintain an interest in life. – William Londen",
    "A healthy attitude is contagious but don't wait to catch it from others. Be a carrier. – Tom Stoppard",
    "Physical fitness is the basis for all other forms of excellence. – John F. Kennedy",
    "The power of community to create health is far greater than any physician, clinic, or hospital. – Mark Hyman",
    "Good nutrition creates health in all areas of our existence. All parts are interconnected. – T. Colin Campbell",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "The food you eat can either be the safest and most powerful form of medicine or the slowest form of poison. – Ann Wigmore",
    "Health is a state of complete harmony of the body, mind, and spirit. When one is free from physical disabilities and mental distractions, the gates of the soul open. – B.K.S. Iyengar",
    "It is health that is real wealth and not pieces of gold and silver. – Mahatma Gandhi",
    "A healthy lifestyle is not just about what you eat. It is also what you consume mentally, emotionally, and spiritually. – Unknown",
    "Happiness is the highest form of health. – Dalai Lama",
    "The secret of health for both mind and body is not to mourn for the past, worry about the future, or anticipate troubles, but to live in the present moment wisely and earnestly. – Buddha",
    "Health is a relationship between you and your body. – Terri Guillemets",
    "In order to change, we must be sick and tired of being sick and tired. – Unknown",
    "Your body will be around a lot longer than that expensive handbag. Invest in yourself. – Unknown",
    "The human body has been designed to resist an infinite number of changes and attacks brought about by its environment. The secret of good health lies in successful adjustment to changing stresses on the body. – Harry J. Johnson",
    "Health is not about the weight you lose, but about the life you gain. – Unknown",
    "Nourishing yourself in a way that helps you blossom in the direction you want to go is attainable, and you are worth the effort. – Deborah Day",
    "Physical fitness is not only one of the most important keys to a healthy body, it is the basis of dynamic and creative intellectual activity. – John F. Kennedy",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "True health is the ability to live life to the fullest with vibrant energy and enthusiasm. – K.D. Lang",
    "You can't control everything in life, but you can control what you put in your body. – Unknown",
    "Your health is what you make of it. Everything you do and think either adds to the vitality, energy, and spirit you possess or takes away from it. – Ann Wigmore",
    "A healthy outside starts from the inside. – Robert Urich",
    "Wellness is the result of personal initiative, seeking a more optimal, holistic, and balanced state of health and well-being across multiple dimensions. – Brady Wilson",
    "Invest in your health today, or pay for your illness tomorrow. – Unknown",
    "The foundation of success in life is good health: that is the substratum fortune; it is also the basis of happiness. A person cannot accumulate a fortune very well when he is sick. – P.T. Barnum",
    "The body is your temple. Keep it pure and clean for the soul to reside in. – B.K.S. Iyengar",
    "The human body is the best picture of the human soul. – Ludwig Wittgenstein",
    "To ensure good health: eat lightly, breathe deeply, live moderately, cultivate cheerfulness, and maintain an interest in life. – William Londen",
    "A healthy attitude is contagious but don't wait to catch it from others. Be a carrier. – Tom Stoppard",
    "Physical fitness is the basis for all other forms of excellence. – John F. Kennedy",
    "The power of community to create health is far greater than any physician, clinic, or hospital. – Mark Hyman",
    "Good nutrition creates health in all areas of our existence. All parts are interconnected. – T. Colin Campbell",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "The food you eat can either be the safest and most powerful form of medicine or the slowest form of poison. – Ann Wigmore",
    "Health is a state of complete harmony of the body, mind, and spirit. When one is free from physical disabilities and mental distractions, the gates of the soul open. – B.K.S. Iyengar",
    "It is health that is real wealth and not pieces of gold and silver. – Mahatma Gandhi",
    "A healthy lifestyle is not just about what you eat. It is also what you consume mentally, emotionally, and spiritually. – Unknown",
    "Happiness is the highest form of health. – Dalai Lama",
    "The secret of health for both mind and body is not to mourn for the past, worry about the future, or anticipate troubles, but to live in the present moment wisely and earnestly. – Buddha",
    "Health is a relationship between you and your body. – Terri Guillemets",
    "In order to change, we must be sick and tired of being sick and tired. – Unknown",
    "Your body will be around a lot longer than that expensive handbag. Invest in yourself. – Unknown",
    "The human body has been designed to resist an infinite number of changes and attacks brought about by its environment. The secret of good health lies in successful adjustment to changing stresses on the body. – Harry J. Johnson",
    "Health is not about the weight you lose, but about the life you gain. – Unknown",
    "Nourishing yourself in a way that helps you blossom in the direction you want to go is attainable, and you are worth the effort. – Deborah Day",
    "Physical fitness is not only one of the most important keys to a healthy body, it is the basis of dynamic and creative intellectual activity. – John F. Kennedy",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "True health is the ability to live life to the fullest with vibrant energy and enthusiasm. – K.D. Lang",
    "You can't control everything in life, but you can control what you put in your body. – Unknown",
    "Your health is what you make of it. Everything you do and think either adds to the vitality, energy, and spirit you possess or takes away from it. – Ann Wigmore",
    "A healthy outside starts from the inside. – Robert Urich",
    "Wellness is the result of personal initiative, seeking a more optimal, holistic, and balanced state of health and well-being across multiple dimensions. – Brady Wilson",
    "Invest in your health today, or pay for your illness tomorrow. – Unknown",
    "The foundation of success in life is good health: that is the substratum fortune; it is also the basis of happiness. A person cannot accumulate a fortune very well when he is sick. – P.T. Barnum",
    "The body is your temple. Keep it pure and clean for the soul to reside in. – B.K.S. Iyengar",
    "The human body is the best picture of the human soul. – Ludwig Wittgenstein",
    "To ensure good health: eat lightly, breathe deeply, live moderately, cultivate cheerfulness, and maintain an interest in life. – William Londen",
    "A healthy attitude is contagious but don't wait to catch it from others. Be a carrier. – Tom Stoppard",
    "Physical fitness is the basis for all other forms of excellence. – John F. Kennedy",
    "The power of community to create health is far greater than any physician, clinic, or hospital. – Mark Hyman",
    "Good nutrition creates health in all areas of our existence. All parts are interconnected. – T. Colin Campbell",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "The food you eat can either be the safest and most powerful form of medicine or the slowest form of poison. – Ann Wigmore",
    "Health is a state of complete harmony of the body, mind, and spirit. When one is free from physical disabilities and mental distractions, the gates of the soul open. – B.K.S. Iyengar",
    "It is health that is real wealth and not pieces of gold and silver. – Mahatma Gandhi",
    "A healthy lifestyle is not just about what you eat. It is also what you consume mentally, emotionally, and spiritually. – Unknown",
    "Happiness is the highest form of health. – Dalai Lama",
    "The secret of health for both mind and body is not to mourn for the past, worry about the future, or anticipate troubles, but to live in the present moment wisely and earnestly. – Buddha",
    "Health is a relationship between you and your body. – Terri Guillemets",
    "In order to change, we must be sick and tired of being sick and tired. – Unknown",
    "Your body will be around a lot longer than that expensive handbag. Invest in yourself. – Unknown",
    "The human body has been designed to resist an infinite number of changes and attacks brought about by its environment. The secret of good health lies in successful adjustment to changing stresses on the body. – Harry J. Johnson",
    "Health is not about the weight you lose, but about the life you gain. – Unknown",
    "Nourishing yourself in a way that helps you blossom in the direction you want to go is attainable, and you are worth the effort. – Deborah Day",
    "Physical fitness is not only one of the most important keys to a healthy body, it is the basis of dynamic and creative intellectual activity. – John F. Kennedy",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "True health is the ability to live life to the fullest with vibrant energy and enthusiasm. – K.D. Lang",
    "You can't control everything in life, but you can control what you put in your body. – Unknown",
    "Your health is what you make of it. Everything you do and think either adds to the vitality, energy, and spirit you possess or takes away from it. – Ann Wigmore",
    "A healthy outside starts from the inside. – Robert Urich",
    "Wellness is the result of personal initiative, seeking a more optimal, holistic, and balanced state of health and well-being across multiple dimensions. – Brady Wilson",
    "Invest in your health today, or pay for your illness tomorrow. – Unknown",
    "The foundation of success in life is good health: that is the substratum fortune; it is also the basis of happiness. A person cannot accumulate a fortune very well when he is sick. – P.T. Barnum",
    "The body is your temple. Keep it pure and clean for the soul to reside in. – B.K.S. Iyengar",
    "The human body is the best picture of the human soul. – Ludwig Wittgenstein",
    "To ensure good health: eat lightly, breathe deeply, live moderately, cultivate cheerfulness, and maintain an interest in life. – William Londen",
    "A healthy attitude is contagious but don't wait to catch it from others. Be a carrier. – Tom Stoppard",
    "Physical fitness is the basis for all other forms of excellence. – John F. Kennedy",
    "The power of community to create health is far greater than any physician, clinic, or hospital. – Mark Hyman",
    "Good nutrition creates health in all areas of our existence. All parts are interconnected. – T. Colin Campbell",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "The food you eat can either be the safest and most powerful form of medicine or the slowest form of poison. – Ann Wigmore",
    "Health is a state of complete harmony of the body, mind, and spirit. When one is free from physical disabilities and mental distractions, the gates of the soul open. – B.K.S. Iyengar",
    "It is health that is real wealth and not pieces of gold and silver. – Mahatma Gandhi",
    "A healthy lifestyle is not just about what you eat. It is also what you consume mentally, emotionally, and spiritually. – Unknown",
    "Happiness is the highest form of health. – Dalai Lama",
    "The secret of health for both mind and body is not to mourn for the past, worry about the future, or anticipate troubles, but to live in the present moment wisely and earnestly. – Buddha",
    "Health is a relationship between you and your body. – Terri Guillemets",
    "In order to change, we must be sick and tired of being sick and tired. – Unknown",
    "Your body will be around a lot longer than that expensive handbag. Invest in yourself. – Unknown",
    "The human body has been designed to resist an infinite number of changes and attacks brought about by its environment. The secret of good health lies in successful adjustment to changing stresses on the body. – Harry J. Johnson",
    "Health is not about the weight you lose, but about the life you gain. – Unknown",
    "Nourishing yourself in a way that helps you blossom in the direction you want to go is attainable, and you are worth the effort. – Deborah Day",
    "Physical fitness is not only one of the most important keys to a healthy body, it is the basis of dynamic and creative intellectual activity. – John F. Kennedy",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "True health is the ability to live life to the fullest with vibrant energy and enthusiasm. – K.D. Lang",
    "You can't control everything in life, but you can control what you put in your body. – Unknown",
    "Your health is what you make of it. Everything you do and think either adds to the vitality, energy, and spirit you possess or takes away from it. – Ann Wigmore",
    "A healthy outside starts from the inside. – Robert Urich",
    "Wellness is the result of personal initiative, seeking a more optimal, holistic, and balanced state of health and well-being across multiple dimensions. – Brady Wilson",
    "Invest in your health today, or pay for your illness tomorrow. – Unknown",
    "The foundation of success in life is good health: that is the substratum fortune; it is also the basis of happiness. A person cannot accumulate a fortune very well when he is sick. – P.T. Barnum",
    "The body is your temple. Keep it pure and clean for the soul to reside in. – B.K.S. Iyengar",
    "The human body is the best picture of the human soul. – Ludwig Wittgenstein",
    "To ensure good health: eat lightly, breathe deeply, live moderately, cultivate cheerfulness, and maintain an interest in life. – William Londen",
    "A healthy attitude is contagious but don't wait to catch it from others. Be a carrier. – Tom Stoppard",
    "Physical fitness is the basis for all other forms of excellence. – John F. Kennedy",
    "The power of community to create health is far greater than any physician, clinic, or hospital. – Mark Hyman",
    "Good nutrition creates health in all areas of our existence. All parts are interconnected. – T. Colin Campbell",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "The food you eat can either be the safest and most powerful form of medicine or the slowest form of poison. – Ann Wigmore",
    "Health is a state of complete harmony of the body, mind, and spirit. When one is free from physical disabilities and mental distractions, the gates of the soul open. – B.K.S. Iyengar",
    "It is health that is real wealth and not pieces of gold and silver. – Mahatma Gandhi",
    "A healthy lifestyle is not just about what you eat. It is also what you consume mentally, emotionally, and spiritually. – Unknown",
    "Happiness is the highest form of health. – Dalai Lama",
    "The secret of health for both mind and body is not to mourn for the past, worry about the future, or anticipate troubles, but to live in the present moment wisely and earnestly. – Buddha",
    "Health is a relationship between you and your body. – Terri Guillemets",
    "In order to change, we must be sick and tired of being sick and tired. – Unknown",
    "Your body will be around a lot longer than that expensive handbag. Invest in yourself. – Unknown",
    "The human body has been designed to resist an infinite number of changes and attacks brought about by its environment. The secret of good health lies in successful adjustment to changing stresses on the body. – Harry J. Johnson",
    "Health is not about the weight you lose, but about the life you gain. – Unknown",
    "Nourishing yourself in a way that helps you blossom in the direction you want to go is attainable, and you are worth the effort. – Deborah Day",
    "Physical fitness is not only one of the most important keys to a healthy body, it is the basis of dynamic and creative intellectual activity. – John F. Kennedy",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "True health is the ability to live life to the fullest with vibrant energy and enthusiasm. – K.D. Lang",
    "You can't control everything in life, but you can control what you put in your body. – Unknown",
    "Your health is what you make of it. Everything you do and think either adds to the vitality, energy, and spirit you possess or takes away from it. – Ann Wigmore",
    "A healthy outside starts from the inside. – Robert Urich",
    "Wellness is the result of personal initiative, seeking a more optimal, holistic, and balanced state of health and well-being across multiple dimensions. – Brady Wilson",
    "Invest in your health today, or pay for your illness tomorrow. – Unknown",
    "The foundation of success in life is good health: that is the substratum fortune; it is also the basis of happiness. A person cannot accumulate a fortune very well when he is sick. – P.T. Barnum",
    "The body is your temple. Keep it pure and clean for the soul to reside in. – B.K.S. Iyengar",
    "The human body is the best picture of the human soul. – Ludwig Wittgenstein",
    "To ensure good health: eat lightly, breathe deeply, live moderately, cultivate cheerfulness, and maintain an interest in life. – William Londen",
    "A healthy attitude is contagious but don't wait to catch it from others. Be a carrier. – Tom Stoppard",
    "Physical fitness is the basis for all other forms of excellence. – John F. Kennedy",
    "The power of community to create health is far greater than any physician, clinic, or hospital. – Mark Hyman",
    "Good nutrition creates health in all areas of our existence. All parts are interconnected. – T. Colin Campbell",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "The food you eat can either be the safest and most powerful form of medicine or the slowest form of poison. – Ann Wigmore",
    "Health is a state of complete harmony of the body, mind, and spirit. When one is free from physical disabilities and mental distractions, the gates of the soul open. – B.K.S. Iyengar",
    "It is health that is real wealth and not pieces of gold and silver. – Mahatma Gandhi",
    "A healthy lifestyle is not just about what you eat. It is also what you consume mentally, emotionally, and spiritually. – Unknown",
    "Happiness is the highest form of health. – Dalai Lama",
    "The secret of health for both mind and body is not to mourn for the past, worry about the future, or anticipate troubles, but to live in the present moment wisely and earnestly. – Buddha",
    "Health is a relationship between you and your body. – Terri Guillemets",
    "In order to change, we must be sick and tired of being sick and tired. – Unknown",
    "Your body will be around a lot longer than that expensive handbag. Invest in yourself. – Unknown",
    "The human body has been designed to resist an infinite number of changes and attacks brought about by its environment. The secret of good health lies in successful adjustment to changing stresses on the body. – Harry J. Johnson",
    "Health is not about the weight you lose, but about the life you gain. – Unknown",
    "Nourishing yourself in a way that helps you blossom in the direction you want to go is attainable, and you are worth the effort. – Deborah Day",
    "Physical fitness is not only one of the most important keys to a healthy body, it is the basis of dynamic and creative intellectual activity. – John F. Kennedy",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "True health is the ability to live life to the fullest with vibrant energy and enthusiasm. – K.D. Lang",
    "You can't control everything in life, but you can control what you put in your body. – Unknown",
    "Your health is what you make of it. Everything you do and think either adds to the vitality, energy, and spirit you possess or takes away from it. – Ann Wigmore",
    "A healthy outside starts from the inside. – Robert Urich",
    "Wellness is the result of personal initiative, seeking a more optimal, holistic, and balanced state of health and well-being across multiple dimensions. – Brady Wilson",
    "Invest in your health today, or pay for your illness tomorrow. – Unknown",
    "The foundation of success in life is good health: that is the substratum fortune; it is also the basis of happiness. A person cannot accumulate a fortune very well when he is sick. – P.T. Barnum",
    "The body is your temple. Keep it pure and clean for the soul to reside in. – B.K.S. Iyengar",
    "The human body is the best picture of the human soul. – Ludwig Wittgenstein",
    "To ensure good health: eat lightly, breathe deeply, live moderately, cultivate cheerfulness, and maintain an interest in life. – William Londen",
    "A healthy attitude is contagious but don't wait to catch it from others. Be a carrier. – Tom Stoppard",
    "Physical fitness is the basis for all other forms of excellence. – John F. Kennedy",
    "The power of community to create health is far greater than any physician, clinic, or hospital. – Mark Hyman",
    "Good nutrition creates health in all areas of our existence. All parts are interconnected. – T. Colin Campbell",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "The food you eat can either be the safest and most powerful form of medicine or the slowest form of poison. – Ann Wigmore",
    "Health is a state of complete harmony of the body, mind, and spirit. When one is free from physical disabilities and mental distractions, the gates of the soul open. – B.K.S. Iyengar",
    "It is health that is real wealth and not pieces of gold and silver. – Mahatma Gandhi",
    "A healthy lifestyle is not just about what you eat. It is also what you consume mentally, emotionally, and spiritually. – Unknown",
    "Happiness is the highest form of health. – Dalai Lama",
    "The secret of health for both mind and body is not to mourn for the past, worry about the future, or anticipate troubles, but to live in the present moment wisely and earnestly. – Buddha",
    "Health is a relationship between you and your body. – Terri Guillemets",
    "In order to change, we must be sick and tired of being sick and tired. – Unknown",
    "Your body will be around a lot longer than that expensive handbag. Invest in yourself. – Unknown",
    "The human body has been designed to resist an infinite number of changes and attacks brought about by its environment. The secret of good health lies in successful adjustment to changing stresses on the body. – Harry J. Johnson",
    "Health is not about the weight you lose, but about the life you gain. – Unknown",
    "Nourishing yourself in a way that helps you blossom in the direction you want to go is attainable, and you are worth the effort. – Deborah Day",
    "Physical fitness is not only one of the most important keys to a healthy body, it is the basis of dynamic and creative intellectual activity. – John F. Kennedy",
    "Wellness is the complete integration of body, mind, and spirit - the realization that everything we do, think, feel, and believe has an effect on our state of well-being. – Greg Anderson",
    "True health is the ability to live life to the fullest with vibrant energy and enthusiasm. – K.D. Lang",
    "You can't control everything in life, but you can control what you put in your body. – Unknown",
    "Your health is what you make of it. Everything you do and think either adds to the vitality, energy, and spirit you possess or takes away from it. – Ann Wigmore",
    "A healthy outside starts from the inside. – Robert Urich",
    "Wellness is the result of personal initiative, seeking a more optimal, holistic, and balanced state of health and well-being across multiple dimensions. – Brady Wilson",
    "Invest in your health today, or pay for your illness tomorrow. – Unknown",
    "The foundation of success in life is good health: that is the substratum fortune; it is also the basis of happiness. A person cannot accumulate a fortune very well when he is sick. – P.T. Barnum",
    "The body is your temple. Keep it pure and clean for the soul to reside in. – B.K.S. Iyengar",
    "The human body is the best picture of the human soul. – Ludwig Wittgenstein",
    "To ensure good health: eat lightly, breathe deeply, live moderately, cultivate cheerfulness, and maintain an interest in life. – William Londen",
    "A healthy attitude is contagious but don't wait to catch it from others. Be a carrier. – Tom Stoppard",
    "Physical fitness is the basis for all other forms of excellence. – John F. Kennedy",
    "The power of community to create health is far greater than any physician, clinic, or hospital. – Mark Hyman",
    "Good nutrition creates health in all areas of our existence. All parts are interconnected. – T. Colin Campbell"
]  