import axios from "axios";
import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { VARIABLES } from "../../Constants";
import LastReleaseNotes from "./LastReleaseNotes";
import ProjectReleaseNote from "./ProjectReleaseNote";
import CreateProjectReleases from "./CreateProjectReleases";
import SubProjectReleases from "./SubProjectReleases";
import secureLocalStorage from "react-secure-storage";
import { DropdownAPIsContext } from "../../context/DropdownAPIsContext/DropdownAPIsContextProvider";
import { ProjectContext } from "../../context/ProjectContext/ProjectContextProvider";
import { UsersContext } from "../../context/UsersContext/UsersContextProvider";

// Category Select option
const category = [
  { label: "QA", value: "QA" },
  { label: "Developer", value: "developer" },
];

// Sub Category Select option
const sub_category = [
  { label: "Bug Fix", value: "bug fix" },
  { label: "Feature", value: "feature" },
];

const ReleaseNotes = (props) => {
  const privileges = secureLocalStorage.getItem("privileges");
  const userId = secureLocalStorage.getItem("userID");

  const { projects } = useContext(DropdownAPIsContext);
  const { allProjects: projectDataRaw } = useContext(ProjectContext);
  const { users } = useContext(UsersContext);

  const [isLoading, setIsLoading] = useState(false);
  // Raw Release Note Data stored in "rawReleaseData" state
  const [rawReleaseData, setRawReleaseData] = useState([]);

  // Raw Project Data stored in "rawProjectData" state
  const [rawProjectData, setRawProjectData] = useState([]);

  const [filterReleaseData, setFilterReleaseData] = useState([]);

  // project Data stored for dropdown in as id and ProjectName
  const [projectData, setProjectData] = useState([]);

  // sub project stored raw data of sub Project
  const [subProject, setSubProject] = useState([]);

  // EmployeeData stored in id and username format in dropdown
  const [employeeData, setEmployeeData] = useState([]);

  // API call of Release Data and stores the data in "rawReleaseData"
  const apiReleaseData = async () => {
    try {
      const res = await axios.get(
        VARIABLES.url + "/api-app/release-management"
      );
      setRawReleaseData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  // API call of sub project data and store in "subProject"
  const getSubProject = async () => {
    try {
      const res = await axios.get(VARIABLES.url + "/api-app/subproject");
      setSubProject(res.data);
      // setSubProject(
      //   res.data.map((userData) => ({
      //     value: userData.id,
      //     label: userData.name,
      //   }))
      // );
    } catch (error) {
      console.log(error);
    }
  };
  // API call of Release Data and stores the data in "rawReleaseData"
  const apiUserWiseReleaseData = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(
        VARIABLES.url + "/api-app/release-management?employee_id=" + userId
      );
      setFilterReleaseData(res.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (users.length !== 0) {
      setEmployeeData(
        users.map((user) => {
          return {
            value: user.value,
            label: user.label,
          };
        })
      );
    }
  }, [users]);

  useEffect(() => {
    if (projects.length !== 0) {
      setProjectData(
        projects.map((proj) => {
          return {
            value: proj.id,
            label: proj.project_name,
          };
        })
      );
    }
  }, [projects]);

  useEffect(() => {
    if (projectDataRaw.length !== 0) {
      setRawProjectData(projectDataRaw);
    }
  }, [projectDataRaw]);

  useEffect(() => {
    apiReleaseData();
    getSubProject();
    apiUserWiseReleaseData();
  }, []);
  return (
    <>
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            <h5 className="fw-bold mb-0">Releases</h5>
            <div className="col-auto d-flex w-sm-100">
              {/* <div className="dropdown px-2">
                <button type="button" className="btn btn-dark btn-set-task w-sm-100" data-bs-toggle="modal" data-bs-target="#releaseProject"><i className="icofont-plus-circle me-2 fs-6"></i>Project Release</button>
              </div> */}
              {privileges.includes("project_releases") ? (
                <div className="dropdown px-2">
                  <button
                    type="button"
                    className="project-release btn btn-dark btn-set-task w-sm-100"
                    data-bs-toggle="modal"
                    data-bs-target="#releaseSubproject"
                  >
                    <i className="icofont-plus-circle me-2 fs-6"></i>Project
                    Release
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      {/* lastest Release update of particular employee */}
      {/* <LastReleaseNotes /> */}
      <ProjectReleaseNote
        handleSidebar={props.handleSidebar}
        rawReleaseData={rawReleaseData}
        filterReleaseData={filterReleaseData}
        isLoading={isLoading}
      />

      {/* Create Release Note For Project */}
      <CreateProjectReleases
        apiReleaseData={apiReleaseData}
        projectData={projectData}
        employeeData={employeeData}
        category={category}
        sub_category={sub_category}
      />

      {/* Create Release Note for Sub Project */}
      <SubProjectReleases
        apiReleaseData={apiReleaseData}
        rawProjectData={rawProjectData}
        projectData={projectData}
        subProject={subProject}
        employeeData={employeeData}
        category={category}
        sub_category={sub_category}
      />
    </>
  );
};

export default ReleaseNotes;
