import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { VARIABLES } from "../../Constants";
import axios from "axios";
import Select from "react-select";
import EditTimesheet from "../Forms/EditTimesheet";
import secureLocalStorage from "react-secure-storage";
import { UsersContext } from "../../context/UsersContext/UsersContextProvider";
import { DropdownAPIsContext } from "../../context/DropdownAPIsContext/DropdownAPIsContextProvider";

const ViewEmployee = () => {
  // Declaring states
  const [timesheets, SetTimesheets] = useState([]);
  const [loading, setLoading] = useState(true);

  const [projectDropdown, setProjectDropdown] = useState([
    { value: "all", label: "--- All ---" },
  ]);
  const [weeks, setWeeks] = useState(null);
  const { users } = useContext(UsersContext);
  const { projects } = useContext(DropdownAPIsContext);
  const privileges = secureLocalStorage.getItem("privileges");

  var today = new Date();

  // Fetching current month
  const currentMonth = moment(today).format("MMMM");
  // Setting up month as string and setting the format "01"
  var month = String(today.getMonth() + 1).padStart(1);

  const dropdownFormat = { value: month, label: currentMonth };

  const [userHours, setUserHours] = useState(0);
  const [leaveHours, setLeaveHours] = useState(0);
  const [filter, setFilter] = useState(dropdownFormat);
  const [projectFilter, setProjectFilter] = useState({
    value: "all",
    label: "--- All ---",
  });
  const [employees, setEmployees] = useState({
    value: "all",
    label: "--- All ---",
  });

  const currentYear = moment(today).format("YYYY");

  const dropdownYearFormat = { value: currentYear, label: currentYear };

  const [yearFilter, setYearFilter] = useState(dropdownYearFormat);

  // Month dropdown
  const months = [
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const years = [
    { value: "2021", label: "2021" },
    { value: "2022", label: "2022" },
    { value: "2023", label: "2023" },
    { value: "2024", label: "2024" },
  ];

  const loadProjectDropDown = async () => {
    let url;
    if (employees.value === "all") {
      if (projects.length !== 0) {
        setProjectDropdown(
          projects.map((p) => {
            return {
              value: p.id,
              label: p.project_name,
            };
          })
        );
      }
    } else {
      url =
        VARIABLES.url + "/api-app/project?user_id=" + parseInt(employees.value);
      // url = VARIABLES.url+ '/api/timesheet-filling/?date=&user__id='+parseInt(userID) +'&fkProject__id=&date_m=&year='+year.value + '&month='+month.value
      let reqOptions = {
        url: url,
        method: "GET",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
        mode: "no-cors",
      };

      axios
        .request(reqOptions)
        .then((response) => {
          var data = response.data;
          var project_dropdown = [{ value: "all", label: "--- All ---" }];
          var project_dropdown_raw = (project_dropdown_raw = data.data[0].map(
            (project) => {
              return { value: project.id, label: project.project_name };
            }
          ));
          project_dropdown_raw.map((dropdown) => {
            project_dropdown.push(dropdown);
          });

          setProjectDropdown(project_dropdown_raw);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // Loading timesheets for current month
  const loadTimesheets = async (filter_month, filter_year) => {
    setLoading(true);
    var select_month = filter_month.value;
    let url;
    if (employees.value === "all" && projectFilter.value === "all") {
      url =
        VARIABLES.url +
        "/api-app/timesheet-filling?month=" +
        select_month +
        "&year=" +
        filter_year.value;
    } else if (employees.value === "all" && projectFilter.value !== "all") {
      url =
        VARIABLES.url +
        "/api-app/timesheet-filling?month=" +
        select_month +
        "&project_id=" +
        parseInt(projectFilter.value) +
        "&year=" +
        filter_year.value;
    } else if (employees.value !== "all" && projectFilter.value === "all") {
      url =
        VARIABLES.url +
        "/api-app/timesheet-filling?month=" +
        select_month +
        "&user_id=" +
        parseInt(employees.value) +
        "&year=" +
        filter_year.value;
    } else {
      url =
        VARIABLES.url +
        "/api-app/timesheet-filling?month=" +
        select_month +
        "&user_id=" +
        parseInt(employees.value) +
        "&project_id=" +
        parseInt(projectFilter.value) +
        "&year=" +
        filter_year.value;
    }
    let reqOptions = {
      url: url,
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      mode: "no-cors",
    };

    axios.request(reqOptions).then(function (response) {
      var data = response.data.data;
      var total_hours = 0;
      var leave_hours = 0;
      data.map((hours) => {
        if (hours.task_id.card_identification_number === "TW-TSK51") {
          leave_hours = leave_hours + parseFloat(hours.hours);
        } else {
          total_hours = total_hours + parseFloat(hours.hours);
        }
      });
      setUserHours(total_hours);
      setLeaveHours(leave_hours);
      SetTimesheets(data);
      setLoading(false);
    });
  };

  useEffect(() => {
    loadProjectDropDown();
  }, [employees, projects]);

  // Call loadTimesheets everytime filter state is changed
  useEffect(() => {
    loadTimesheets(filter, yearFilter);
  }, [filter, employees, projectFilter, yearFilter]);

  useEffect(() => {
    const start_date = new Date(`${yearFilter.value}-01-01`);
    const end_date = new Date(`${yearFilter.value}-12-31`);
    const difference_in_days = (end_date - start_date) / (1000 * 60 * 60 * 24);
    const number_of_weeks = difference_in_days / 7;
    const generateWeeks = [];
    for (let i = 1; i <= number_of_weeks; i++) {
      generateWeeks.push({ value: `${i}`, label: `${i}` });
    }
  }, [yearFilter]);

  const downloadSheet = () => {
    let url = VARIABLES.url + "/api-app/timesheet-report/";
    if (yearFilter.value && filter.value) {
      url += `?year=${yearFilter.value}&month=${filter.value}`;
      if (employees.value !== "all") {
        url += `&user_id=${employees.value}`;
      }
      if (projectFilter.value !== "all") {
        url += `&project_id=${projectFilter.value}`;
      }
    }
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        const fileLink = response.data.data;
        const link = document.createElement("a");
        link.href = fileLink;
        link.setAttribute("download", "filename.xlsx");

        // Append the link to the document
        document.body.appendChild(link);

        // Trigger the click event on the link to start the download
        link.click();

        // Remove the link from the document after the download is initiated
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="row g-3 mb-3 row-deck">
      <div className="col-md-12"></div>
      <div className="col-md-12">
        <div className="card mb-3">
          <div className="row align-items-center">
            <div className="border-0 mb-4">
              <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                <h5 className="fw-bold py-3 mb-0">Employee Timesheet</h5>
                <div className="d-flex py-2 project-tab sub-project-tab flex-wrap w-sm-100">
                  {privileges.includes("employee_timesheet_downlo") ? (
                    <button
                      type="button"
                      className="btn btn-dark w-sm-100"
                      onClick={downloadSheet}
                    >
                      Download
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="card-header py-3 d-flex justify-content-between align-items-center">
            <div className="info-header employee-sheet">
              <h6 className="mb-0 fw-bold ">Timesheet for {filter.label}</h6>
              <h6 className="mb-0 fw-bold ">Total working hours {userHours}</h6>
              <h6 className="mb-0 fw-bold ">Total leave hours {leaveHours}</h6>
            </div>
            {privileges.includes("employee_timesheet_year") ? (
              <div className="info-header">
                <h6> Timesheet Year </h6>
                <Select
                  onChange={setYearFilter}
                  options={years}
                  defaultValue={dropdownYearFormat}
                />
              </div>
            ) : (
              ""
            )}
            {privileges.includes("employee_timesheet_month") ? (
              <div className="info-header">
                <h6> Timesheet Month </h6>
                <Select
                  onChange={setFilter}
                  options={months}
                  defaultValue={dropdownFormat}
                />
              </div>
            ) : (
              ""
            )}
            {privileges.includes("employee_timesheet_name") ? (
              <div className="info-header">
                <h6> Employee </h6>
                <Select
                  onChange={setEmployees}
                  options={[{ value: "all", label: "--- All ---" }, ...users]}
                  defaultValue={{ value: "all", label: "--- All ---" }}
                />
              </div>
            ) : (
              ""
            )}
            {privileges.includes("employee_timesheet_projec") ? (
              <div className="info-header">
                <h6> Project </h6>
                <Select
                  onChange={setProjectFilter}
                  options={projectDropdown}
                  defaultValue={{ value: "all", label: "--- All ---" }}
                />
              </div>
            ) : (
              ""
            )}
          </div>
          {loading ? (
            <div className="card-body">Loading...</div>
          ) : (
            <div className="card-body">
              <table
                id="myProjectTable"
                className="App table table-hover align-middle mb-0"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th>User</th>
                    <th>Task ID</th>
                    <th>Date</th>
                    <th>Project</th>
                    <th>Working Hours</th>
                  </tr>
                </thead>
                {timesheets.length === 0 && (
                  <tbody>
                    <tr>
                      <td></td>
                      <td>No data found</td>
                      <td></td>
                    </tr>
                  </tbody>
                )}

                {timesheets.length > 0 && (
                  <tbody>
                    {timesheets.map((item) => (
                      <tr key={item.id}>
                        <td>{item.user.username}</td>
                        <td>{item.task_id.card_identification_number}</td>
                        <td>{moment(item.date).format("MMMM Do YYYY")}</td>
                        <td>{item?.project?.project_name}</td>
                        <td>{item.hours}</td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewEmployee;
