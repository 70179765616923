import React, { useState, useContext, useEffect } from "react";
import { ListContext } from "../../../context/ListContextProvider";
import secureLocalStorage from "react-secure-storage";
import { Controller, useForm, useFormState } from "react-hook-form";
import { UsersContext } from "../../../context/UsersContext/UsersContextProvider";
import Select from "react-select";
import { OrganisationContext } from "../../../context/OrganisationContext/OrganisationContextProvider";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { useMediaQuery } from "react-responsive";
import Swal from "sweetalert2";
import { DropdownAPIsContext } from "../../../context/DropdownAPIsContext/DropdownAPIsContextProvider";
import moment from "moment";

function AddCard({ isOpen, setIsOpen }) {
  const { createRegularCard } = useContext(ListContext);
  const [show, setShow] = useState(false);
  const userID = secureLocalStorage.getItem("userID");
  const { users, isUsersLoading } = useContext(UsersContext);
  const { organisation } = useContext(OrganisationContext);
  const { regularCardBoardId } = useContext(DropdownAPIsContext);
  const isMobile = useMediaQuery({
    query: "(max-width: 480px)",
  });

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      users: null,
      // org_created_by: null,
    },
  });

  const { isValid } = useFormState({ control });

  const onSubmit = (e) => {
    if (new Date(e.start_date) > new Date(e.end_date)) {
      setShow(false);
      reset({
        rc_name: "",
        start_date: "",
        end_date: "",
        rc_description: "",
        users: null,
      });
      setIsOpen(!isOpen);
      Swal.fire({
        title: "Error",
        text: "Invalid dates input",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }
    const newRegularCard = JSON.stringify({
      created_by_id: userID,
      updated_by_id: userID,
      card_title: e.rc_name,
      start_time: e.start_date,
      end_time: e.end_date,
      card_description: e?.rc_description,
      org_created_by_id: 2,
      // To-Do: change regular card board id manually
      board_id_id: regularCardBoardId,
      assigned_to_id: [userID, ...e?.users?.map((user) => user.value)],
      current_status: "Assigned",
    });
    createRegularCard(newRegularCard);
    setShow(false);
    reset({
      rc_name: "",
      start_date: "",
      end_date: "",
      rc_description: "",
      users: null,
    });
    setIsOpen(!isOpen);
  };

  const getProjectResetValues = () => {
    reset({
      rc_name: "",
      start_date: "",
      end_date: "",
      rc_description: "",
      users: null,
    });
  };

  useEffect(() => {
    const getEndOfWeek = () => {
      const currentDay = new Date().getDay();
      const endOfWeekDate = new Date();
      endOfWeekDate.setDate(new Date().getDate() + (6 - currentDay));
      return moment(endOfWeekDate).format("YYYY-MM-DD");
    };

    reset({
      rc_name: "",
      start_date: moment(new Date()).format("YYYY-MM-DD"),
      end_date: getEndOfWeek(),
      rc_description: "",
      users: null,
    });
  }, [isOpen]);

  return (
    <Drawer
      open={isOpen}
      onClose={() => {
        setIsOpen(!isOpen);
        getProjectResetValues();
      }}
      direction="right"
      className="bla bla bla index-drawer"
      size={isMobile ? "80%" : "450px"}
      zIndex={2000}
    >
      <div
        style={{
          margin: "0px 10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span style={{ fontSize: "20px", marginTop: "5px" }}>Create Card</span>
        <i
          className="bi bi-x"
          style={{
            cursor: "pointer",
            fontSize: "35px",
          }}
          onClick={() => {
            setIsOpen(!isOpen);
            getProjectResetValues();
          }}
        ></i>
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="px-3 pb-3 overflow-auto"
        style={{ height: "100%" }}
      >
        <div className="row mb-3">
          <div className="col-12">
            <label className="form-label">
              Name <span className="required_field ">*</span>
            </label>
            <input
              id="rc-name"
              name="rc-name"
              className="DrawerInput"
              {...register("rc_name", {
                required: true,
              })}
              placeholder="Card Name"
            />
            {errors.rc_name && (
              <p className="required_field error">Card Name is Required</p>
            )}
          </div>
        </div>
        <div className="row mb-3 ">
          <div className="col-12">
            <label className="form-label">Description</label>
            <textarea
              id="rc-description"
              className="DrawerInput"
              {...register("rc_description")}
              placeholder="Description..."
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-6">
            <label className="form-label">
              Start Date
              <span className="required_field">*</span>
            </label>
            <input
              type="date"
              className="DrawerInput"
              id="start_date"
              {...register(`start_date`, {
                required: true,
              })}
            />
            {errors.start_date && (
              <span className="required_field error">
                Start Date is required
              </span>
            )}
          </div>
          <div className="col-6">
            <label className="form-label">
              End Date <span className="required_field ">*</span>
            </label>
            <input
              type="date"
              className="DrawerInput"
              id="end_date"
              {...register(`end_date`, {
                required: true,
              })}
            />
            {errors.end_date && (
              <span className="required_field error">End Date is required</span>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-2 mb-3 ">
            <label className="form-label">
              Assigned To <span className="required_field ">*</span>
            </label>
            <Controller
              name={`users`}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={users}
                  isMulti
                  isClearable={true}
                  isLoading={isUsersLoading}
                />
              )}
            />
            {errors.users && (
              <span className="required_field error">Users are required</span>
            )}
          </div>
        </div>
        {/* <div className="row">
          <div className="col-12 mt-2 mb-3 ">
            <label className="form-label">
              Organization <span className="required_field ">*</span>
            </label>
            <Controller
              name={`org_created_by`}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select {...field} options={organisation} isClearable={true} />
              )}
            />
            {errors.org_created_by && (
              <p className="required_field error">
                Organisation name is required
              </p>
            )}
          </div>
        </div> */}
        <div className="row ">
          <div className="col-12 mt-auto">
            {isValid === true ? (
              <button
                type="submit"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Submit
              </button>
            ) : (
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            )}
          </div>
        </div>
      </form>
    </Drawer>
  );
}

export default AddCard;
