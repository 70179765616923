import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import { VARIABLES } from "../../Constants";

export const UsersContext = createContext(null);

function UsersContextProvider({ children }) {
  const [users, setUsers] = useState([]);
  const [isUsersLoading, setIsUsersLoading] = useState(false);
  const [isUsersDataLoading, setIsUsersDataLoading] = useState(false);
  const [rawDataUserManagement, setRawDataUserManagement] = useState([]);
  const [noPages, setNoPages] = useState(1);
  const [entire, setEntries] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const getUsers = async () => {
    try {
      setIsUsersLoading(true);
      const res = await axios.get(VARIABLES.url + "/api-app/user-list");
      setIsUsersLoading(false);
      setUsers(
        res.data.map((userData) => ({
          value: userData.id,
          label: userData.username,
        }))
      );
    } catch (error) {
      setIsUsersLoading(false);
      console.log(error);
    }
  };
  const apiGetRawUserManagement = async (
    page_no = currentPage,
    entires = entire
  ) => {
    const source = axios.CancelToken.source();
    try {
      const res = await axios.get(
        VARIABLES.url + `/api-app/user?page_no=${page_no}&entries=${entires}`,
        { cancelToken: source.token }
      );
      setNoPages(res.data.num_pages);
      setCurrentPage(res.data.current_page);
      setRawDataUserManagement(res.data.results.data);
      setIsUsersDataLoading(false);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        console.log("Error:", error.message);
      }
      setIsUsersDataLoading(false);
    }
  };
  useEffect(() => {
    getUsers();
    apiGetRawUserManagement();
  }, []);
  const value = {
    users,
    isUsersLoading,
    getUsers,
    noPages,
    setNoPages,
    entire,
    setEntries,
    currentPage,
    setCurrentPage,
    apiGetRawUserManagement,
    isUsersDataLoading,
    setIsUsersDataLoading,
    rawDataUserManagement,
    setRawDataUserManagement,
  };
  return (
    <UsersContext.Provider value={value}>{children}</UsersContext.Provider>
  );
}

export default UsersContextProvider;
