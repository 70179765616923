import React, { useEffect, useState } from "react";
import { VARIABLES } from "../../Constants";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import secureLocalStorage from "react-secure-storage";

const ProjectBlockApproval = (props) => {
  const username = secureLocalStorage.getItem("username");
  // onSubmit handler to post form details
  const onCompletedHandler = (data) => {
    var completedHandler = JSON.stringify({
      is_completed: data,
      completed_on: moment().format(),
      current_status: "Completed",
    });
    var config = {
      method: "patch",
      url:
        VARIABLES.url + `/api-app/project-wf-block/${props.workflowBlockId}/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: completedHandler,
    };

    axios(config)
      .then((response) => {
        Swal.fire({
          title: "Success!",
          text: "Complete Successfully",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => props.apiGetWorkFlowBlockData());
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Error",
          text: "Failed to Complete",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  const onApprovedHandler = (data) => {
    var approvedHandler = JSON.stringify({
      is_approved: data,
      current_status: "Approved",
    });
    var config = {
      method: "patch",
      url:
        VARIABLES.url + `/api-app/project-wf-block/${props.workflowBlockId}/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: approvedHandler,
    };

    axios(config)
      .then((response) => {
        Swal.fire({
          title: "Success!",
          text: "Approved Successfully",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => props.apiGetWorkFlowBlockData());
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Error",
          text: "Failed to Approved",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  return (
    <>
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <div className="card">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between mt-5">
              <div className="lesson_name">
                {/* <div className="project-workflowblock">
                  <img src="assets/images/incomplete.png" className="img-fluid" alt="incomplete status" />
                </div> */}
                {props.workflowBlockData ? (
                  <>
                    <div className="project-block warn-success-bg">
                      <i className="icofont-tick-boxed"></i>
                    </div>
                    <span className="small text-muted project_name fw-bold">
                      {props.workflowBlockData.is_completed === true
                        ? "Completed"
                        : "Not Yet Completed"}{" "}
                    </span>
                  </>
                ) : (
                  <p>Loading...</p>
                )}

                {/* // <span className="small text-muted project_name fw-bold"></span> */}
                <h6 className="mb-0 fw-bold  fs-6 text-center  mb-2">
                  Completion Status
                </h6>
              </div>
              <div
                className="btn-group"
                role="group"
                aria-label="Basic outlined example"
              >
                {props.workflowBlockData &&
                props.workflowBlockData.assigned_to.some(
                  (name) => name.username == username
                ) ? (
                  <button
                    type="button btn-lg"
                    className="btn btn-outline-secondary"
                    style={{ width: "70px", height: "50px" }}
                    onClick={() => onCompletedHandler(true)}
                  >
                    <i
                      className="icofont-tick-mark text-success"
                      style={{ fontSize: "35px" }}
                    ></i>
                  </button>
                ) : (
                  <button
                    type="button btn-lg"
                    disabled
                    className="btn btn-outline-secondary"
                    style={{ width: "70px", height: "50px" }}
                  >
                    <i
                      className="icofont-tick-mark text-success"
                      style={{ fontSize: "35px" }}
                    ></i>
                  </button>
                )}
              </div>
            </div>
            <div className="dividers-block"></div>
          </div>
        </div>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <div className="card">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between mt-5">
              <div className="lesson_name">
                {/* <div className="project-workflowblock">
                  <img src="assets/images/incomplete.png" className="img-fluid" alt="incomplete status" />
                </div> */}
                {props.workflowBlockData ? (
                  <>
                    {props.workflowBlockData.is_approved === true ? (
                      <>
                        <div className="project-block warn-success-bg">
                          <i className="icofont-tick-boxed"></i>
                        </div>
                        <span className="small text-muted project_name fw-bold">
                          {" "}
                          Approved{" "}
                        </span>
                      </>
                    ) : (
                      <>
                        <div className="project-block danger-success-bg">
                          <i className="icofont-close"></i>
                        </div>
                        <span className="small text-muted project_name fw-bold">
                          {" "}
                          Not Yet Approved{" "}
                        </span>
                      </>
                    )}
                    <h6 className="mb-0 fw-bold  fs-6 text-center  mb-2">
                      Approval Status
                    </h6>
                    {props.workflowBlockData.approval_incharge === null ? (
                      <p className="mb-0 fs-6 mb-2">
                        Authorized : No One is Assigned
                      </p>
                    ) : (
                      <p className="mb-0 fs-6 mb-2">
                        Authorized :{" "}
                        {props.workflowBlockData.approval_incharge.username}
                      </p>
                    )}
                  </>
                ) : (
                  <p>Loading...</p>
                )}

                {/* // <span className="small text-muted project_name fw-bold"></span> */}
              </div>
              {/* {props.workflowBlockData.workflow_block.is_optional === true ? props.workflowBlockData.assigned_to.map(incharge => incharge.username) !== props.workflowBlockData.approval_incharge} */}
              {props.workflowBlockData ? (
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic outlined example"
                >
                  {props.workflowBlockData.workflow_block.is_optional ===
                    true && props.workflowBlockData.is_completed === true ? (
                    <button
                      type="button btn-lg"
                      className="btn btn-outline-secondary"
                      style={{ width: "70px", height: "50px" }}
                      onClick={() => onApprovedHandler(true)}
                    >
                      <i
                        className="icofont-tick-mark text-success"
                        style={{ fontSize: "35px" }}
                      ></i>
                    </button>
                  ) : props.workflowBlockData.workflow_block.is_optional ===
                      false &&
                    props.workflowBlockData.is_completed === true &&
                    props.workflowBlockData.assigned_to.some(
                      (name) => name.username === username
                    ) ? (
                    <button
                      type="button btn-lg"
                      className="btn btn-outline-secondary"
                      style={{ width: "70px", height: "50px" }}
                      onClick={() => onApprovedHandler(true)}
                    >
                      <i
                        className="icofont-tick-mark text-success"
                        style={{ fontSize: "35px" }}
                      ></i>
                    </button>
                  ) : (
                    <button
                      type="button btn-lg"
                      className="btn btn-outline-secondary"
                      style={{ width: "70px", height: "50px" }}
                      disabled
                    >
                      <i
                        className="icofont-tick-mark text-success"
                        style={{ fontSize: "35px" }}
                      ></i>
                    </button>
                  )}
                </div>
              ) : (
                <p>Loading...</p>
              )}
            </div>
            <div className="dividers-block"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectBlockApproval;
