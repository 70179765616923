import axios from "axios";
import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { VARIABLES } from "../../Constants";
import { LeaveManagementContext } from "../../context/LeaveManagementContext/LeaveManagementProvider";
import ReactPaginate from "react-paginate";

const LeaveStatus = () => {
  // const [isLoading, setIsLoading] = useState(false)
  // const [totalEmployeeLeaveData, setTotalEmployeeLeaveData] = useState([])

  const {
    isLoading,
    totalEmployeeLeaveData,
    noPagesLeaveAva,
    entireLeaveAva,
    currentPageLeaveAva,
    setEntriesLeaveAva,
    apiTotalEmployeeLeaveData,
  } = useContext(LeaveManagementContext);

  // Search Filter OnChange from input field
  const [filterEmployeeLeave, setFilterEmployeeLeave] = useState([]);

  // OnCHnage Event to get input value
  const onChangeHandler = (event) => {
    if (event.target.value === "") {
      setFilterEmployeeLeave(totalEmployeeLeaveData);
      return;
    }
    let filterEmployeeLeaveData = totalEmployeeLeaveData.filter((item) =>
      item.employee.username.toLowerCase().includes(event.target.value)
    );
    setFilterEmployeeLeave(filterEmployeeLeaveData);
  };

  // API call of Project Data and stores the data in "rawProjectData"
  // const apiTotalEmployeeLeaveData = async () => {
  //   try {
  //     setIsLoading(true)
  //     const res = await axios.get(VARIABLES.url + '/api-app/leave-available');
  //     setTotalEmployeeLeaveData(res.data);
  //     setIsLoading(false)
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    setFilterEmployeeLeave(totalEmployeeLeaveData);
  }, [totalEmployeeLeaveData]);

  // useEffect(() => {
  //   apiTotalEmployeeLeaveData();
  // }, [])

  return (
    <>
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            <h5 className="fw-bold mb-0">Leave Status</h5>
          </div>
        </div>
      </div>

      <div className="row align-item-center">
        <div className="col-md-12">
          <div className="card mb-3">
            <div className="card-header py-3 bg-transparent border-bottom-0">
              {/* <h6 className="mb-0 fw-bold ">Leave Status</h6> */}
              <div className="filter">
                <p className="search-title">What are you looking for ?</p>
                <div className="input-group mb-3">
                  <span className="input-group-text" id="basic-addon1">
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by Employee name......"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-3">
            {isLoading ? (
              <div className="card-body">Loading ...</div>
            ) : (
              <div className="card-body basic-custome-color">
                <div className="table-responsive">
                  <table className="table">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col">Employee Name</th>
                        <th scope="col">Sick Leave</th>
                        <th scope="col">Earned Leave</th>
                        <th scope="col">Casual Leave</th>
                        <th scope="col">Half day</th>
                      </tr>
                    </thead>
                    {filterEmployeeLeave.length === 0 && (
                      <tbody>
                        <tr>
                          <td></td>
                          <td>No data found</td>
                          <td></td>
                        </tr>
                      </tbody>
                    )}
                    {filterEmployeeLeave.length > 0 && (
                      <tbody>
                        {filterEmployeeLeave.map((api, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                <span className="fw-bold ms-1">
                                  {" "}
                                  {api?.employee?.username}{" "}
                                </span>
                              </td>
                              <td
                                className={`${
                                  api.sick_leaves < 0
                                    ? "text-negative"
                                    : "text-normal"
                                }`}
                              >
                                {" "}
                                {api.sick_leaves}{" "}
                              </td>
                              <td
                                className={`${
                                  api.earned_leaves < 0
                                    ? "text-negative"
                                    : "text-normal"
                                }`}
                              >
                                {" "}
                                {api.earned_leaves}{" "}
                              </td>
                              <td
                                className={`${
                                  api.casual_leaves < 0
                                    ? "text-negative"
                                    : "text-normal"
                                }`}
                              >
                                {" "}
                                {api.casual_leaves}{" "}
                              </td>
                              <td> {api.half_day} </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                  <div className="d-flex justify-content-center justify-content-md-between mt-3">
                    <ReactPaginate
                      key={3}
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={noPagesLeaveAva}
                      initialPage={currentPageLeaveAva - 1}
                      marginPagesDisplayed={2}
                      disableInitialCallback={true}
                      // pageRangeDisplayed={3}
                      onPageChange={(selectedPage) => {
                        // setCurrentPage(selectedPage.selected + 1);
                        apiTotalEmployeeLeaveData(
                          selectedPage.selected + 1,
                          entireLeaveAva
                        );
                      }}
                      containerClassName={
                        "pagination justify-content-center justify-content-md-start"
                      }
                      pageClassName={"paginate_button page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"paginate_button page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />

                    <div className="d-flex justify-content-center align-items-center">
                      <div className="me-3">No of entries per page:</div>
                      <div>
                        <select
                          className="form-select"
                          value={entireLeaveAva}
                          onChange={(e) => {
                            setEntriesLeaveAva(e.target.value);
                            apiTotalEmployeeLeaveData(
                              currentPageLeaveAva,
                              e.target.value
                            );
                          }}
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveStatus;
