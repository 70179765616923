import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { VARIABLES } from "../../Constants";
import Swal from "sweetalert2";
import secureLocalStorage from "react-secure-storage";
import { useLocation, useNavigate } from "react-router-dom";

const ProjectTable = (props) => {
  const privileges = secureLocalStorage.getItem("privileges");
  const [releases, setReleases] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const changeSidebar = (section, id) => {
    props.handleSidebar(section, id);
    props.setProjectDetails(id);
  };
  // SearchFilterData filter the Data by Search Functionality
  const [filterProject, setFilterProject] = useState("");

  // FilterData filters the data as user search in search bar
  const filterData = (data, searchFilterData) => {
    return data.filter((api) =>
      !searchFilterData || searchFilterData.toLowerCase() === ""
        ? true
        : (api.project_name &&
            api.project_name
              .toLowerCase()
              .includes(searchFilterData.toLowerCase())) ||
          (api.project_incharge &&
            api.project_incharge.username &&
            api.project_incharge.username
              .toLowerCase()
              .includes(searchFilterData.toLowerCase()))
    );
  };

  const getReleases = (id) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: VARIABLES.url + "/api-app/release-management?project_id=" + id,
      headers: {},
    };
    setIsLoading(true);
    axios
      .request(config)
      .then((response) => {
        setReleases(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  // OnCHnage Event to get input value
  const onChangeHandler = (event) => {
    // if (event.target.value === "") {
    //   setFilterProject(props.rawProjectData);
    //   return;
    // }
    // let filterProjectData = props.rawProjectData.filter(
    //   (item) =>
    //     item.project_name
    //       .toLowerCase()
    //       .includes(event.target.value.toLowerCase()) ||
    //     item.project_incharge.username
    //       .toLowerCase()
    //       .includes(event.target.value.toLowerCase())
    // );
    // setFilterProject(filterProjectData);
  };

  useEffect(() => {
    getReleases(location.state.data);
  }, [location.state.data]);

  return (
    <>
      {releases === null ? (
        <div className="card-body">Loading...</div>
      ) : (
        <div className="row align-item-center">
          <div className="col-md-12">
            <div className="card mb-3">
              {isLoading ? (
                <div className="card-body">Project Name</div>
              ) : (
                <div className="row align-items-center">
                  <div className="border-0 mb-4">
                    <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                      <h5 className="fw-bold py-3 mb-0">
                        {releases[0]?.project?.project_name}
                      </h5>
                      <button
                        className="btn btn-primary"
                        onClick={() => navigate(-1)}
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {/* <div className="card-header py-3 bg-transparent border-bottom-0"> */}

              {/* <h6 className="mb-0 fw-bold ">Releases</h6> */}
              {/* <div className="filter">
                <input
                  type="text"
                  placeholder="Search by Project name/Incharge name..."
                  className="form-control"
                  onChange={onChangeHandler}
                />
              </div> */}
              {/* </div> */}
              {isLoading ? (
                <div className="card-body">Loading...</div>
              ) : (
                <div className="card-body basic-custome-color">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Incharge</th>
                          <th>Type</th>
                          <th> A </th>
                          <th> B </th>
                          <th> C </th>
                          <th> D </th>
                          <th> E </th>
                          <th> Version </th>
                          <th> Release Status </th>
                        </tr>
                      </thead>
                      {releases.length > 0 ? (
                        <tbody>
                          {releases.map((item) => {
                            const getReleaseData =
                              item.release_version.split(".");
                            const major_release = getReleaseData[0]
                              .split("")
                              .map((word) => word.charAt(0));
                            return (
                              <tr>
                                <td> {item.incharge.username} </td>
                                <td>
                                  {" "}
                                  {item.release_category} /{" "}
                                  {item.release_sub_category}{" "}
                                </td>
                                <td> {major_release[2]} </td>
                                <td> {getReleaseData[1]} </td>
                                <td> {getReleaseData[2]} </td>
                                <td> {getReleaseData[3]} </td>
                                <td> {getReleaseData[4]} </td>
                                <td> {item.release_version} </td>
                                <td>
                                  {" "}
                                  <span
                                    className={`${
                                      item.release_status === "completed"
                                        ? "badge rounded-pill light-info-bg text-success"
                                        : item.release_status === "discard"
                                        ? "badge rounded-pill text-danger light-info-bg"
                                        : "badge rounded-pill light-info-bg text-info"
                                    }`}
                                  >
                                    {item.release_status}
                                  </span>{" "}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      ) : (
                        <tbody>
                          <tr>
                            <td></td>
                            <td>No Data Found</td>
                            <td></td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProjectTable;
