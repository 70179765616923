import React, { createContext, useEffect, useRef, useState } from "react";
import uuid from "react-uuid";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../Constants";
import axios from "axios";
import { useLocation } from "react-router-dom";
import confetti from "canvas-confetti";

export const TaskManagementContext = createContext(null);

function TaskManagementContextProvider({ children }) {
  const [list, setList] = useState([]);
  const [currentAllowedSeq, setCurrentAllowedSeq] = useState([]);
  const [boardSettings, setBoardSettings] = useState([]);
  const [lifecycle, setLifecycle] = useState([]);
  const [tasksList, setTasksList] = useState([]);
  const [settingsLoading, setSettingsLoading] = useState(false);
  const [tasksLoading, setTasksLoading] = useState(false);
  const [boardsLoading, setBoardsLoading] = useState(false);
  const userID = secureLocalStorage.getItem("userID");
  const [loginFlagTM, setLoginFlagTM] = useState(false);
  const [showAllTasks, setShowAllTasks] = useState(true);
  const [currentBoardId, setCurrentBoardId] = useState(null);
  const completedListRef = useRef(null);
  const location = useLocation();

  const getBoardSettings = (boardId) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/api-app/board-info?project_id=${boardId}`,
      headers: {},
    };
    setSettingsLoading(true);
    axios
      .request(config)
      .then((response) => {
        setBoardSettings(response.data);
        setLifecycle(
          response.data.lifecycle_states.sort((a, b) => a.sequence - b.sequence)
        );
        setCurrentAllowedSeq(response.data.sequence_schema);
        setSettingsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setSettingsLoading(false);
      });
  };
  const getTasksList = (boardId) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/api-app/tasks-board-wise?project_id=${boardId}`,
      headers: {},
    };
    setTasksLoading(true);
    axios
      .request(config)
      .then((response) => {
        setTasksList(response.data);
        setTasksLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setTasksLoading(false);
      });
  };
  const patchCardData = async (id, cardData) => {
    const showCanfetti =
      boardSettings?.lifecycle_states.filter(
        (state) => state.id === cardData.lifecycle_state_id
      )[0]?.name === "Completed";
    let data = JSON.stringify({
      lifecycle_state_id: cardData.lifecycle_state_id,
      updated_by_id: userID,
    });
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/api-app/task/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        getTasksList(currentBoardId.id);
        if (showCanfetti) {
          performConfitte();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getTasksListForUpdate = () => {
    getTasksList(currentBoardId?.id);
  };

  function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }
  const performConfitte = () => {
    if (completedListRef?.current) {
      const rect = completedListRef.current.getBoundingClientRect();
      const x = rect.left + rect.width / 2;
      const y = rect.top + rect.height / 2;

      confetti({
        angle: randomInRange(55, 125),
        spread: randomInRange(50, 70),
        particleCount: randomInRange(50, 100),
        origin: {
          x: x / window.innerWidth,
          y: y / window.innerHeight,
        },
      });
    }
  };

  const patchBoard = async (boardId, show) => {
    setShowAllTasks(show);
  };

  const handleTaskmanagement = (id) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/api-app/project/${id}`,
      headers: {},
    };
    setBoardsLoading(true);
    axios
      .request(config)
      .then((response) => {
        setCurrentBoardId(response.data);
        setBoardsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setBoardsLoading(false);
      });
  };

  useEffect(() => {
    if (boardSettings.length !== 0) {
      let templist = [];
      if (showAllTasks) {
        templist = [...tasksList];
      } else {
        templist = tasksList.filter((task) =>
          task.assigned_to.includes(userID)
        );
      }
      setList(
        lifecycle.map((state, index) => {
          return {
            id: state.id,
            status_id: state.id,
            name: state.name === "Completed" ? "Completed 🎉" : state.name,
            status: state.name,
            type: "list",
            progress:
              state.name !== "Spillover"
                ? index === 0
                  ? 0
                  : ((index + 1) / lifecycle.length) * 100
                : 100,
            items: templist
              .map((item) => {
                if (item?.lifecycle_state === state.id)
                  return {
                    id: uuid(),
                    card_identification: item.id,
                    card_id_id: item.card_id,
                    value: item.card_title,
                    progress: 0,
                    priority: "L",
                    members: null,
                    date: null,
                    tags: null,
                    startDate: item?.start_time,
                    endDate: item?.end_time,
                    card_type: item?.card_belongs_to,
                    type: "card",
                    assigned_to: item.assigned_to,
                    assigned_by:
                      item.created_by.first_name +
                      " " +
                      item.created_by.last_name,
                    description: item.card_description,
                    notes: item?.notes,
                    agenda: item?.agenda,
                    current_status: item?.lifecycle_state,
                    card_identification_number: item.card_identification_number,
                  };
              })
              .filter((item) => item !== undefined),
          };
        })
      );
    }
  }, [tasksList, boardSettings, showAllTasks]);

  useEffect(() => {
    if (currentBoardId !== null) {
      getBoardSettings(currentBoardId.id);
      getTasksList(currentBoardId.id);
    }
  }, [currentBoardId]);

  useEffect(() => {
    const userStatus = secureLocalStorage.getItem("isLoggedIn");
    if (location.pathname.split("_")[1]) {
      handleTaskmanagement(location.pathname.split("_")[1]);
    }
    if (userStatus === "USER_LOGGED_IN") {
      setLoginFlagTM(true);
    } else if (userStatus === "USER_LOGGED_OUT") {
      setLoginFlagTM(false);
    }
  }, []);

  const value = {
    boardSettings,
    tasksList,
    setLoginFlagTM,
    list,
    setList,
    currentAllowedSeq,
    patchCardData,
    showAllTasks,
    setShowAllTasks,
    patchBoard,
    getTasksList,
    setTasksList,
    setCurrentBoardId,
    currentBoardId,
    getTasksListForUpdate,
    completedListRef,
    settingsLoading,
    tasksLoading,
    boardsLoading,
  };
  return (
    <TaskManagementContext.Provider value={value}>
      {children}
    </TaskManagementContext.Provider>
  );
}

export default TaskManagementContextProvider;
