import React, { useEffect, useState, useRef, useContext } from "react";
import Calendar from "react-calendar";
import "./Dropdown.css";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import { ListContext } from "../../../../context/ListContextProvider";

const Portal = ({ children }) => {
  return createPortal(children, document.body);
};

function CalendarDropdown({ listId, item, color }) {
  const { setCurrentDate, setNewValue } = useContext(ListContext);
  const [refrenceElement, setRefrenceElement] = useState();
  const [popperElement, setPopperElement] = useState();
  const DropdownRef = useRef(null);
  const [showDrop, setShowDrop] = useState(false);
  let { styles, attributes } = usePopper(refrenceElement, popperElement, {
    placement: "bottom",
  });
  const [value, onChange] = useState(item.date);

  const handleOutsideClick = (e) => {
    if (DropdownRef.current && !DropdownRef.current.contains(e.target)) {
      setShowDrop(false);
    }
  };

  const formatDate = (dateString) => {
    let dateObject = new Date(dateString);
    let monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let month = monthNames[dateObject.getMonth()];
    let day = dateObject.getDate().toString().padStart(2, "0");
    let year = dateObject.getFullYear();
    let currentYear = new Date().getFullYear();
    let formattedDate =
      month + " " + day + (year !== currentYear ? " " + year : "");
    return formattedDate;
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  useEffect(() => {
    setShowDrop(false);
    if (value !== item.date) {
      setNewValue(listId, null, item.id);
      setCurrentDate(value);
    }
  }, [value]);

  return (
    <>
      {item.date === null ? (
        <div
          style={{ cursor: "pointer", color: color }}
          onClick={() => setShowDrop(true)}
          ref={setRefrenceElement}
        >
          <i className="bi bi-calendar"></i>
          {color === "gray" && (
            <span className="ms-2 text-nowrap" style={{ color: "gray" }}>
              Add date
            </span>
          )}
        </div>
      ) : (
        <>
          {color === "gray" ? (
            <div
              style={{ cursor: "pointer", color: color }}
              onClick={() => setShowDrop(true)}
              ref={setRefrenceElement}
            >
              {color === "gray" && <i className="bi bi-calendar"></i>}
              <span className="ms-2 text-nowrap" style={{ color: "gray" }}>
                {formatDate(item.date)}
              </span>
            </div>
          ) : (
            <span
              className="text-nowrap"
              style={{ cursor: "pointer", color: color }}
              onClick={() => setShowDrop(true)}
              ref={setRefrenceElement}
            >
              {formatDate(item.date)}
            </span>
          )}
        </>
      )}
      {showDrop ? (
        <Portal>
          <div
            className="dropdown-list"
            style={styles.popper}
            {...attributes.popper}
            ref={setPopperElement}
          >
            <div ref={DropdownRef}>
              <Calendar
                minDate={new Date()}
                value={value}
                onChange={onChange}
              />
            </div>
          </div>
        </Portal>
      ) : (
        ""
      )}
    </>
  );
}

export default CalendarDropdown;
