import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import "./Dropdown.css";
import { usePopper } from "react-popper";
import { VARIABLES } from "../../Constants";
import { DropdownAPIsContext } from "../../context/DropdownAPIsContext/DropdownAPIsContextProvider";
import axios from "axios";
import Swal from "sweetalert2";
import secureLocalStorage from "react-secure-storage";

const Portal = ({ children }) => {
  return createPortal(children, document.body);
};

const UserImagePopper = ({ id, show, size = "md", margin = "", input }) => {
  const [refrenceElement, setRefrenceElement] = useState();
  const [popperElement, setPopperElement] = useState();
  const DropdownRef = useRef(null);
  const inputRef = useRef(null);
  const [hovered, setHovered] = useState(false);
  const [showDrop, setShowDrop] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const { nocache, setNocache } = useContext(DropdownAPIsContext);
  const privileges = secureLocalStorage.getItem("privileges");
  const hoverTimeout = useRef(null);
  let { styles, attributes } = usePopper(refrenceElement, popperElement, {
    placement: "auto", // Use auto placement
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 10], // Adds a margin of 10px
        },
      },
      {
        name: "flip",
        options: {
          fallbackPlacements: ["right", "left"], // Fallback to right or left
        },
      },
      {
        name: "preventOverflow",
        options: {
          boundary: "viewport",
        },
      },
    ],
  });

  const handleImageClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("profile_img", file);
      var config = {
        method: "patch",
        maxBodyLength: Infinity,
        url: VARIABLES.url + `/api-app/user-profile/${id}`,
        headers: {
          "Content-Type": "application/octet-stream",
        },
        data: formData,
      };
      axios(config)
        .then((response) => {
          Swal.fire({
            title: "Success!",
            text: "User profile image Updated Successfully",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => setNocache(new Date().getTime()));
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            title: "Error",
            text: "Failed to Update profile image",
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    }
  };

  useMemo(() => {
    if (nocache) {
      setImageUrl(
        `${VARIABLES.url}/media/profile_img/${id}.jpg?nocache=${nocache}`
      );
    }
  }, [nocache]);

  useEffect(() => {
    if (hovered) {
      hoverTimeout.current = setTimeout(() => {
        setShowDrop(true);
      }, 300);
    } else {
      clearTimeout(hoverTimeout.current);
      setShowDrop(false);
    }

    return () => clearTimeout(hoverTimeout.current);
  }, [hovered]);

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <img
        className={`avatar rounded-circle ${margin} ${size}`}
        src={imageUrl}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={
          input && privileges.includes("change_profile") && handleImageClick
        }
        ref={setRefrenceElement}
      />
      {showDrop && show ? (
        <Portal>
          <div
            className="dropdown-list"
            style={styles.popper}
            {...attributes.popper}
            ref={setPopperElement}
          >
            <div ref={DropdownRef}>
              <img src={imageUrl} className="popper-user-img" />
            </div>
          </div>
        </Portal>
      ) : (
        ""
      )}
    </>
  );
};

export default UserImagePopper;
