import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { VARIABLES } from "../../Constants";
import { DropdownAPIsContext } from "../../context/DropdownAPIsContext/DropdownAPIsContextProvider";
import CreateProjectDoc from "./CreateProjectDoc";

const ProjectDocument = () => {
  const location = useLocation();
  const projectDetails = location.state.data;
  const [selectedProject, setSelectedProject] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [formatedDocList, setFormatedDocList] = useState(null);
  const [CompulsoryDocs, setCompulsoryDocs] = useState([]);
  const { typeOfDoc, op } = useContext(DropdownAPIsContext);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const getDocuments = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/api-app/project-document/${projectDetails.id}`,
      headers: {},
    };
    setIsLoading(true);
    axios
      .request(config)
      .then((response) => {
        setIsLoading(false);
        setDocuments(response.data.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };
  useEffect(() => {
    if (op) {
      getDocuments();
    }
  }, [projectDetails, op]);
  useEffect(() => {
    console.log(typeOfDoc);
    console.log(documents);
    if (typeOfDoc.length !== 0 && documents.length !== 0) {
      const compulsoryDocsList = typeOfDoc
        .map((doc) => {
          if (doc?.is_compulsory) {
            return doc.name;
          }
        })
        .filter((e) => e !== undefined);
      setCompulsoryDocs(
        compulsoryDocsList.map((item) => {
          return {
            type: item,
            docs: documents.filter(
              (doc) => doc.type_of_document?.name === item
            ),
          };
        })
      );
      const groupedByType = documents.reduce((acc, item) => {
        const typeName = item.type_of_document.name;

        if (!acc[typeName]) {
          acc[typeName] = [];
        }

        acc[typeName].push(item);
        return acc;
      }, {});
      setFormatedDocList(groupedByType);
    }
  }, [typeOfDoc, documents]);

  useEffect(() => {
    console.log(formatedDocList);
  }, [formatedDocList]);
  return (
    <>
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            <h5 className="fw-bold py-3 mb-0">{projectDetails.project_name}</h5>
            <div>
              {projectDetails && (
                <button
                  className="btn btn-primary me-3"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#createprojectdoc"
                  onClick={() => setSelectedProject(projectDetails?.id)}
                >
                  Generate Document
                </button>
              )}
              <button className="btn btn-primary" onClick={() => navigate(-1)}>
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <h5>Loading...</h5>
      ) : (
        <div className="row d-flex">
          <div className="col-lg-6">
            <h5 className="fw-bold py-3 mb-0">Compulsory Documents</h5>
            <ol className="list-group list-group-numbered">
              {CompulsoryDocs.length !== 0 ? (
                CompulsoryDocs.map((doc) => {
                  return (
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">{doc.type}</div>
                        {doc.docs.length !== 0 ? (
                          doc.docs.map((e) => {
                            return (
                              <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto">
                                  <a
                                    href={e.link}
                                    style={{
                                      fontSize: "0.9em",
                                      textDecoration: "underline",
                                      color: "gray",
                                      paddingLeft: "10px",
                                    }}
                                    target="_blank"
                                  >
                                    {e.name}
                                  </a>
                                </div>
                              </li>
                            );
                          })
                        ) : (
                          <p className="text-danger">No Documents</p>
                        )}
                      </div>
                    </li>
                  );
                })
              ) : (
                <p>Compulsory documents not found</p>
              )}
            </ol>
          </div>
          <div className="col-lg-6">
            <h5 className="fw-bold py-3 mb-0">All Documents</h5>
            <ol className="list-group list-group-numbered">
              {formatedDocList && Object.keys(formatedDocList).length !== 0 ? (
                Object.keys(formatedDocList).map((doc) => {
                  return (
                    <li className="list-group-item d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">{doc}</div>
                        {formatedDocList[doc].map((e) => (
                          <li className="list-group-item d-flex justify-content-between align-items-start">
                            <div className="ms-2 me-auto">
                              <a
                                href={e.link}
                                style={{
                                  fontSize: "0.9em",
                                  textDecoration: "underline",
                                  color: "gray",
                                  paddingLeft: "10px",
                                }}
                                target="_blank"
                              >
                                {e.name}
                              </a>
                            </div>
                          </li>
                        ))}
                      </div>
                    </li>
                  );
                })
              ) : (
                <>No Documents</>
              )}
            </ol>
          </div>
        </div>
      )}
      <div
        className="modal fade"
        id="createprojectdoc"
        tabIndex="-1"
        data-bs-backdrop="static"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title  fw-bold" id="createprojectlLabel">
                Create Project Document
              </h5>
              <button
                type="button"
                className="btn-close"
                // onClick={() => getProjectResetValues()}
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <CreateProjectDoc
                project={selectedProject}
                getDocuments={getDocuments}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectDocument;
