import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { VARIABLES } from "../../Constants";
import Swal from "sweetalert2";
import axios from "axios";
import moment from "moment";
import useKeyboardShortcut from "use-keyboard-shortcut";
import secureLocalStorage from "react-secure-storage";
import { DropdownAPIsContext } from "../../context/DropdownAPIsContext/DropdownAPIsContextProvider";
import { ProjectContext } from "../../context/ProjectContext/ProjectContextProvider";

const ResourceAllocation = (props) => {
  const [selectShow, setSelectShow] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const { flushHeldKey } = useKeyboardShortcut(
    ["Control"],
    (shortcutKeys) => {
      // document.getElementById("btns").autofocus();
      setSelectShow(!selectShow);
    },
    {
      overrideSystem: false,
      ignoreInputFields: false,
      repeatOnHold: false,
    }
  );

  const [startDatee, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [employeeId, setEmployeeId] = useState([]);

  const [projectDetails, setprojectDetails] = useState([]);

  const subProjectForProject = props.subProject.filter((api) => {
    return api.project.id == projectDetails.value;
  });

  const { targetCardBoardId, targetTypes } = useContext(DropdownAPIsContext);
  const {
    apiGetProjectByUser,
    projectByUser,
    usersProjectsLoading,
    resetProjectByUser,
  } = useContext(ProjectContext);

  const employeeforOrganization = props.rawProjectData.filter((api) => {
    return (
      api.team.find((data) => data.id === employeeId.value) ||
      api.project_incharge.id === employeeId.value
    );
  });

  const options = employeeforOrganization.map((project) => ({
    label: project.project_name,
    value: project.id,
  }));

  const onSubmit = (data) => {
    const currentDate = new Date();
    const startDate = new Date(currentDate.getFullYear(), 0, 1);
    var days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));

    var weekNumber = Math.ceil(days / 7);
    var resourceMapping = JSON.stringify({
      project_id: projectDetails.value,
      sub_project_id: data.sub_project.value,
      employee_id: data.employee.value,
      week_number: weekNumber,
      target: data.target,
      release: "-",
      checklist: "This is a sample checklist for test purpose",
      start_date: startDatee,
      end_date: endDate,
      hours: data.hours,
      target_type: data.target_type.value,
      board_id_id: targetCardBoardId,
      created_by_id: secureLocalStorage.getItem("userID"),
      updated_by_id: secureLocalStorage.getItem("userID"),
    });

    var config = {
      method: "post",
      url: VARIABLES.url + "/api-app/resource-mapping",
      headers: {
        "Content-Type": "application/json",
      },
      data: resourceMapping,
    };

    axios(config)
      .then((response) => {
        Swal.fire({
          title: "Success!",
          text: "Mapping Resource Created Successfully",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => props.apiRawResourceMapping());
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Error",
          text: "Failed to Create Mapping Resource",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
    reset();
    setprojectDetails("");
    setEmployeeId("");
    resetProjectByUser();
  };

  useEffect(() => {
    // Calculate start and end dates with specific times when the component mounts
    const today = new Date();

    // Set specific times for start and end dates
    const startDateTime = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      10,
      0,
      0
    ); // 10:00 AM
    const endDateTime = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      19,
      0,
      0
    ); // 7:00 PM

    // Find the start of the week (Monday)
    const startOfWeek = new Date(startDateTime);
    startOfWeek.setDate(
      startDateTime.getDate() -
        startDateTime.getDay() +
        (startDateTime.getDay() === 0 ? -6 : 1)
    );

    // Find the end of the week (Saturday)
    const endOfWeek = new Date(endDateTime);
    endOfWeek.setDate(startOfWeek.getDate() + 5);

    setStartDate(startOfWeek.toISOString());
    setEndDate(endOfWeek.toISOString());
  }, []); // Empty dependency array ensures this effect runs only once, when the component mounts

  useEffect(() => {
    if (employeeId !== null || employeeId.value !== undefined)
      apiGetProjectByUser(employeeId.value);
  }, [employeeId]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <table className="table">
          <tbody>
            <tr>
              {selectShow && (
                <td>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        value={employeeId}
                        onChange={(data) => {
                          setEmployeeId(data);
                          field.onChange(data);
                        }}
                        options={props.employeeData}
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="employee"
                    control={control}
                  />
                  {errors.employee && (
                    <span className="required_field resource_field">
                      Employee name is required
                    </span>
                  )}
                </td>
              )}

              {!selectShow && (
                <td>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        value={employeeId}
                        onChange={(data) => {
                          setEmployeeId(data);
                          field.onChange(data);
                        }}
                        options={props.employeeData}
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="employee"
                    control={control}
                  />
                  {errors.employee && (
                    <span className="required_field resource_field">
                      Employee name is required
                    </span>
                  )}
                </td>
              )}

              <td>
                <Controller
                  defaultValue=""
                  name="project"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      value={projectDetails}
                      onChange={(data) => {
                        setprojectDetails(data);
                        field.onChange(data);
                      }}
                      isLoading={usersProjectsLoading}
                      options={projectByUser}
                    />
                  )}
                />
                {errors.project && (
                  <span className="required_field resource_field">
                    Project name is required
                  </span>
                )}
              </td>

              <td>
                <Controller
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={subProjectForProject.map((userData) => ({
                        value: userData.id,
                        label: userData.name,
                      }))}
                    />
                  )}
                  defaultValue=""
                  name="sub_project"
                  control={control}
                />
              </td>
              <td>
                <Controller
                  render={({ field }) => (
                    <Select {...field} options={targetTypes} />
                  )}
                  defaultValue=""
                  name="target_type"
                  control={control}
                />
              </td>
              <td>
                <input
                  type="text"
                  {...register("target", { required: true })}
                  className="form-control"
                  placeholder="enter target"
                />
                {errors.target && (
                  <span className="required_field resource_field">
                    Target is required
                  </span>
                )}
              </td>
              <td>
                <input
                  type="number"
                  {...register("hours", { required: true })}
                  className="form-control"
                  placeholder="enter hours"
                />
                {errors.hours && (
                  <span className="required_field resource_field">
                    Hour is required
                  </span>
                )}
              </td>
              <td>
                <button type="submit" className="btn btn-outline-primary">
                  {" "}
                  <i className="icofont-plus text-success"></i>{" "}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </>
  );
};

export default ResourceAllocation;
