import React from "react";
import axios from "axios";
import { Controller, useForm, useFormState } from "react-hook-form";
import Select from "react-select";
import { VARIABLES } from "../../Constants";
import Swal from "sweetalert2";
import { useState } from "react";
import { useEffect } from "react";
import secureLocalStorage from "react-secure-storage";

const SubProjectEdit = (props) => {
  const userID = secureLocalStorage.getItem("userID");
  // react hook form
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm();
  const { isDirty, isValid } = useFormState({
    control,
  });

  // onSubmit handler for form submitting
  const onSubmit = (data) => {
    var subProjectData = JSON.stringify({
      name: data.sub_project,
      project_id: data.project.value,
      updated_by_id: userID,
    });
    const url =
      VARIABLES.url + `/api-app/subproject/${props.editedSubProject.id}`;
    var config = {
      method: "patch",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: subProjectData,
    };
    axios(config)
      .then((response) => {
        Swal.fire({
          title: "Success!",
          text: "Sub-Project Updated Successfully",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => props.getSubProject());
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Error",
          text: "Failed to Update Sub-Project",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
    reset();
  };

  // pre-population of values after clicking on edit button
  useEffect(() => {
    if (props.editedSubProject) {
      var defaultValues = {};
      defaultValues.sub_project = props.editedSubProject.name;

      const getProjectName = {
        value: props.editedSubProject.project.id,
        label: props.editedSubProject.project.project_name,
      };
      defaultValues.project = getProjectName;

      reset(defaultValues);
    }
  }, [props.editedSubProject]);

  return (
    <>
      <div
        className="modal fade"
        id="editsubproject"
        tabIndex="-1"
        aria-labelledby="depaddLabel2"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title fw-bold" id="depaddLabel2">
                Edit Sub Project
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="mb-3">
                  <label className="form-label">
                    Project <span className="required_field">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={props.projectData}
                        isClearable={true}
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="project"
                    control={control}
                  />
                  {errors.project && (
                    <span className="required_field">
                      Project name is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Sub Project <span className="required_field">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Sub Project"
                    {...register("sub_project", { required: true })}
                  />
                  {errors.sub_project && (
                    <span className="required_field">
                      Sub Project name is required
                    </span>
                  )}
                </div>
                <div className="modal-footer">
                  {/* <button type="submit" className="btn btn-success">Submit</button> */}
                  {isValid === true ? (
                    <button
                      type="submit"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Submit
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubProjectEdit;
