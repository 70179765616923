import axios from "axios";
import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";
import { LeaveManagementContext } from "../../context/LeaveManagementContext/LeaveManagementProvider";

const LeaveAvaliable = () => {
  // const [isLoading, setIsLoading] = useState(false)
  // const [totalLeaveAvaliable, setTotalLeaveAvaliable] = useState([])

  const { isLoading, totalLeaveAvaliable } = useContext(LeaveManagementContext);

  const userID = secureLocalStorage.getItem("userID");

  // API call of Project Data and stores the data in "rawProjectData"
  // const apiTotalLeave = async () => {
  //   try {
  //     setIsLoading(true)
  //     const res = await axios.get(VARIABLES.url + '/api-app/leave-available?employee_id=' + parseInt(userID));
  //     setTotalLeaveAvaliable(res.data);
  //     console.log(res.data);
  //     setIsLoading(false)
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   apiTotalLeave();
  // }, [])

  return (
    <>
      {/* <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            <h5 className="fw-bold mb-0">Leave Available</h5>
          </div>
        </div>
      </div>

      <div className="row align-item-center">
        <div className="col-md-12">
          <div className="card mb-3">
            {isLoading ? <div className="card-body">Loading ...</div> :
            <div className="card-body basic-custome-color">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope='col'>Employee Name</th>
                      <th scope='col'>Sick Leave</th>
                      <th scope='col'>Earned Leave</th>
                      <th scope='col'>Casual Leave</th>
                      <th scope='col'>Half day</th>
                    </tr>
                  </thead>
                  {totalLeaveAvaliable.length === 0 &&
                        <tbody>
                          <tr>
                            <td></td>
                            <td>No data found</td>
                            <td></td>
                          </tr>
                        </tbody>
                      }
                  { totalLeaveAvaliable.length > 0 &&
                  <tbody>
                    {totalLeaveAvaliable.map((api) => {
                      return <tr>
                    <td className='leave-avaliable'><span className="fw-bold ms-1"> {api.employee.username} </span></td>
                    <td> {api.sick_leaves} </td>
                    <td> {api.earned_leaves} </td>
                    <td> {api.casual_leaves} </td>
                    <td> {api.half_day} </td>
                  </tr>
                    })} 
                  </tbody>
                  }
                </table>
              </div>
            </div>
          }
          </div>
        </div>
      </div> */}

      <div className="card">
        <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
          <h6 className="card-title calender-title mb-0">Leaves Available</h6>
        </div>
        {totalLeaveAvaliable?.length === 0 && (
          <div className="card-body">No data found</div>
        )}
        {totalLeaveAvaliable?.length > 0 &&
          totalLeaveAvaliable?.map((api) => {
            return (
              <div className="card-body">
                <div className="row g-2 row-deck">
                  <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="card card_bordered">
                      <div className="card-body leaves_card_body ">
                        {/* <i className="icofont-checked fs-3"></i> */}
                        <h6 className="mb-0 small-14">Sick Leaves</h6>
                        <span className="text-muted">{api.sick_leaves}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="card card_bordered">
                      <div className="card-body leaves_card_body ">
                        {/* <i className="icofont-stopwatch fs-3"></i> */}
                        <h6 className="mb-0 small-14">Casual Leaves</h6>
                        <span className="text-muted">{api.casual_leaves}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="card card_bordered">
                      <div className="card-body leaves_card_body ">
                        {/* <i className="icofont-ban fs-3"></i> */}
                        <h6 className="mb-0 small-14">Earned Leaves</h6>
                        <span className="text-muted">{api.earned_leaves}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-4 col-sm-6">
                    <div className="card card_bordered">
                      <div className="card-body leaves_card_body ">
                        {/* <i className="icofont-beach-bed fs-3"></i> */}
                        <h6 className="mb-0 small-14">Half Day</h6>
                        <span className="text-muted">{api.half_day}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default LeaveAvaliable;
