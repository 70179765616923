import axios from "axios";
import React from "react";
import { Controller, useForm, useFormState } from "react-hook-form";
import Select from "react-select";
import { VARIABLES } from "../../Constants";
import Swal from "sweetalert2";
import { useState } from "react";
import { useEffect } from "react";
import secureLocalStorage from "react-secure-storage";

const SubProjectReleases = (props) => {
  // React hook form
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm();
  const { isDirty, isValid } = useFormState({
    control,
  });
  // project selected from dropdown stored in projectDetails in {label,value} format
  const [projectDetails, setProjectDetails] = useState([]);

  // filtering sub-project according to project selected and stored in subProjectForProject
  const subProjectForProject = props.subProject.filter((api) => {
    return api.project.id == projectDetails.value;
  });

  const employeeforOrganization = props.rawProjectData.filter((api) => {
    return api.id === projectDetails.value;
  });

  const options = employeeforOrganization.flatMap((user) => {
    const userOption = {
      label: user.project_incharge.username,
      value: user.project_incharge.id,
    };

    const teamOptions = user.team.map((member) => ({
      value: member.id,
      label: member.username,
    }));

    return [userOption, ...teamOptions];
  });

  const uniqueNamesSet = new Set();

  // Use filter to include only options with unique names
  const uniqueOptions = options.filter((option) => {
    const isUnique = !uniqueNamesSet.has(option.label.toLowerCase());
    uniqueNamesSet.add(option.label.toLowerCase());
    return isUnique;
  });

  // onSubmit handler for SubProject
  const onSubmit = (data) => {
    var subProjectRelease = JSON.stringify({
      project_id: projectDetails.value,
      sub_project_id: data.sub_project.value,
      release_status: "Active",
      release_category: data.category.value,
      release_sub_category: data.sub_category.value,
      incharge_id: data.incharge_name.value,
      created_by_id: secureLocalStorage.getItem("userID"),
      updated_by_id: secureLocalStorage.getItem("userID"),
    });
    var config = {
      method: "post",
      url: VARIABLES.url + "/api-app/release-management",
      headers: {
        "Content-Type": "application/json",
      },
      data: subProjectRelease,
    };

    axios(config)
      .then((response) => {
        Swal.fire({
          title: "Success!",
          text: "Release Note Created Successfully",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => props.apiReleaseData());
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Error",
          text: "Failed to create Release Note",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
    reset();
    setProjectDetails("");
  };

  const getResetValues = () => {
    reset();
    setProjectDetails([]);
  };

  return (
    <>
      <div
        className="modal fade"
        id="releaseSubproject"
        tabIndex="-1"
        data-bs-backdrop="static"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title  fw-bold" id="leaveaddLabel">
                Project Release
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={() => getResetValues()}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                  <label className="form-label">
                    Project <span className="required_field">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        value={projectDetails}
                        onChange={(data) => {
                          setProjectDetails(data);
                          field.onChange(data);
                        }}
                        options={props.projectData}
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="project"
                    control={control}
                  />
                  {errors.project && (
                    <span className="required_field">
                      Project name is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">Sub-Project </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={subProjectForProject.map((userData) => ({
                          value: userData.id,
                          label: userData.name,
                        }))}
                        isClearable={true}
                      />
                    )}
                    defaultValue=""
                    name="sub_project"
                    control={control}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Incharge Name <span className="required_field">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={uniqueOptions}
                        isClearable={true}
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="incharge_name"
                    control={control}
                  />
                  {errors.incharge_name && (
                    <span className="required_field">
                      Incharge name is required
                    </span>
                  )}
                </div>
                {/* <div className="mb-3">
                  <label className="form-label">Release</label>
                  <Controller
                    render={({ field }) => <Select {...field} options={props.leaveType} />}
                    defaultValue=""
                    rules={{ required: true }}
                    name="release"
                    control={control} />
                </div> */}
                <div className="mb-3">
                  <label className="form-label">
                    Category <span className="required_field">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={props.category}
                        isClearable={true}
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="category"
                    control={control}
                  />
                  {errors.category && (
                    <span className="required_field">
                      Category name is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Sub-Category <span className="required_field">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={props.sub_category}
                        isClearable={true}
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="sub_category"
                    control={control}
                  />
                  {errors.sub_category && (
                    <span className="required_field">
                      Sub Category name is required
                    </span>
                  )}
                </div>
                <div className="modal-footer">
                  {/* <button type="submit" className="btn btn-primary">Submit</button> */}
                  {isValid === true ? (
                    <button
                      type="submit"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Submit
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubProjectReleases;
