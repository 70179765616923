import axios from "axios";
import React, { useState, useEffect } from "react";
import { VARIABLES } from "../../Constants";
import Swal from "sweetalert2";
import secureLocalStorage from "react-secure-storage";
import moment from "moment";
import ReactPaginate from "react-paginate";

const UserManagementTable = (props) => {
  const privileges = secureLocalStorage.getItem("privileges");
  // Search Filter OnChange from input field
  const [filterUserManagement, setFilterUserManagement] = useState([]);

  // OnCHnage Event to get input value
  const onChangeHandler = (event) => {
    if (event.target.value === "") {
      setFilterUserManagement(props.rawDataUserManagement);
      return;
    }
    let filterUserManagementData = props.rawDataUserManagement.filter(
      (item) =>
        item.user.first_name.toLowerCase().includes(event.target.value) ||
        item.user.email.toLowerCase().includes(event.target.value)
    );
    setFilterUserManagement(filterUserManagementData);
  };

  // Delete Function : delete the specific id
  const deleteUserManagement = (id) => {
    var config = {
      method: "delete",
      url: VARIABLES.url + `/api-app/user/${id}`,
      headers: {},
    };
    axios(config)
      .then((response) => {
        Swal.fire({
          icon: "success",
          text: "Deleted Successfully!",
        });
        props.setRawDataUserManagement(
          props.rawDataUserManagement.filter((user) => {
            return user.id !== id;
          })
        );
      })
      .catch((error) => {
        Swal.fire({
          icon: "warning",
          text: "Not Deleted Successfully!",
        });
        console.log(error);
      });
  };

  const deleteUserManagementAlert = (id) => {
    Swal.fire({
      title:
        "Are you sure? You will not be able to recover this User Management!",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteUserManagement(id);
      }
    });
  };

  useEffect(() => {
    setFilterUserManagement(props.rawDataUserManagement);
  }, [props.rawDataUserManagement]);
  return (
    <>
      <div className="row align-item-center">
        <div className="col-md-12">
          <div className="card mb-3">
            <div className="card-header py-3 bg-transparent border-bottom-0">
              <div className="filter">
                <p className="search-title">What are you looking for ?</p>
                <div className="input-group mb-3">
                  <span className="input-group-text" id="basic-addon1">
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </span>
                  <input
                    type="text"
                    placeholder="Search by employee name/email..."
                    className="form-control"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* {props.isLoading ? <div className='card-body'>Loading...</div> :
          <div className='row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4'>
            {filterUserManagement.map((api) => {
              return <div className='col'>
                <div className='card card-container'>
                  <div className="card-body d-flex flex-column justify-content-center">
                    Todo: Comment This
                    <img className="avatar text-center lg imag-icon mx-auto" src="assets/images/lg/avatar2.jpg" alt="profile" />
                    <div className="dropdown justify-content-end d-flex">
                        <button className="btn" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className='icofont-navigation-menu text-primary'></i>
                        </button>
                        <ul className="dropdown-menu border-0 shadow p-3">
                        {privileges.includes('edit_user') ? <li><a className="dropdown-item py-2 rounded" href="#" data-bs-toggle="modal" data-bs-target="#editUserManagement" onClick={() => props.editUserManagementData(api.id)}>Edit</a></li> : "" }
                        {privileges.includes('delete_user') ? <li><a className="dropdown-item py-2 rounded" href="#" onClick={() => deleteUserManagementAlert(api.user.id)}>Delete</a></li> : "" }
                        </ul>
                      </div>
                      Todo: Comment This
                    <div className='d-flex'>
                      <div className='d-flex justify-content-center'>
                        <span className="avatar md bg-white rounded-circle bg-primary text-white d-flex align-items-center justify-content-center">{api.user.first_name ? api.user.first_name[0].toUpperCase() : "-"}{api.user.last_name ? api.user.last_name[0].toUpperCase() : "-"}</span>
                      </div>
                      Todo: Comment This
                    </div>
                    <h6 className="fw-bold text-center mt-2 mb-1 small-14">{api.user.first_name ? api.user.first_name : "-"} {api.user.last_name ? api.user.last_name : "-"}</h6>
                    <span className="text-muted mb-2 text-center"> {api.designation} </span>
                    <div className='is_active text-center'>
                      <span className={`${api.user.is_active === true ? "badge rounded-pill light-info-bg text-success" : "badge rounded-pill light-info-bg text-danger"}`}> {api.user.is_active === true ? "ACTIVE" : "INACTIVE"} </span>
                    </div>
                    <div className='employee_details bg-light border-10'>
                      <div className='email_details d-flex align-items-center'>
                        <i className="icofont-email me-2 fs-5 text-primary"></i>
                        <span className='fs-7'> {api.user.email ? api.user.email : '-'} </span>
                      </div>
                      <div className='mobile_details pt-2 d-flex align-items-center'>
                        <i className="icofont-phone me-2 fs-5 text-primary"></i>
                        <span className='fs-7'> {api.mobile_number ? api.mobile_number : "-"} </span>
                      </div>
                    </div>
                    <div className='joining_details bg-light border-10'>
                      <div className='email_details d-flex justify-content-between'>
                        <span className='fw-bold'> Department </span> 
                        <span>{api.level_role ? api.level_role.name : "-"}</span> 
                      </div>
                      <div className='mobile_details d-flex justify-content-between'>
                        <span className='fw-bold'> Date of Joining </span>
                        <span> {api.user ? moment(api.user.date_joined).format("MMM Do YYYY")  : "-"} </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            })}
          </div>
} */}

          <div className="card">
            {props.isLoading ? (
              <div className="card-body">Loading...</div>
            ) : (
              <div className="card-body basic-custome-color">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Date of Birth</th>
                        <th>Email</th>
                        <th>Employee Id</th>
                        <th>Department</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    {filterUserManagement.length === 0 && (
                      <tbody>
                        <tr>
                          <td></td>
                          <td>No data found</td>
                          <td></td>
                        </tr>
                      </tbody>
                    )}
                    {filterUserManagement.length > 0 && (
                      <tbody>
                        {filterUserManagement.map((api, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                <span className="fw-bold">
                                  {" "}
                                  {api.user.first_name
                                    ? api.user.first_name
                                    : "-"}{" "}
                                  {api.user.last_name
                                    ? api.user.last_name
                                    : "-"}{" "}
                                </span>
                              </td>
                              <td>
                                <span className="ms-1">
                                  {" "}
                                  {api.dob ? api.dob : "-"}{" "}
                                </span>
                              </td>
                              <td> {api.user.email ? api.user.email : "-"} </td>
                              <td>
                                {" "}
                                {api.employee_id ? api.employee_id : "-"}{" "}
                              </td>
                              <td>
                                {" "}
                                {api.level_role
                                  ? api.level_role.name
                                  : "-"}{" "}
                              </td>
                              <td>
                                <div
                                  className="btn-group"
                                  role="group"
                                  aria-label="Basic outlined example"
                                >
                                  {privileges.includes("edit_user") ? (
                                    <button
                                      type="button"
                                      className="btn btn-outline-secondary"
                                      data-bs-toggle="modal"
                                      data-bs-target="#editUserManagement"
                                      onClick={() =>
                                        props.editUserManagementData(api.id)
                                      }
                                    >
                                      <i className="icofont-edit text-success"></i>
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                  {privileges.includes("delete_user") ? (
                                    <button
                                      type="button"
                                      className="btn btn-outline-secondary deleterow"
                                      onClick={() =>
                                        deleteUserManagementAlert(api.user.id)
                                      }
                                    >
                                      <i className="icofont-ui-delete text-danger"></i>
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                  <div className="d-flex justify-content-center justify-content-md-between mt-3">
                    <ReactPaginate
                      key={3}
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={props.noPages}
                      initialPage={props.currentPage - 1}
                      marginPagesDisplayed={2}
                      disableInitialCallback={true}
                      // pageRangeDisplayed={3}
                      onPageChange={(selectedPage) => {
                        // setCurrentPage(selectedPage.selected + 1);
                        props.apiGetRawUserManagement(
                          selectedPage.selected + 1,
                          props.entire
                        );
                      }}
                      containerClassName={
                        "pagination justify-content-center justify-content-md-start"
                      }
                      pageClassName={"paginate_button page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"paginate_button page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />

                    <div className="d-flex justify-content-center align-items-center">
                      <div className="me-3">No of entries per page:</div>
                      <div>
                        <select
                          className="form-select"
                          value={props.entire}
                          onChange={(e) => {
                            props.setEntries(e.target.value);
                            props.apiGetRawUserManagement(
                              props.currentPage,
                              e.target.value
                            );
                          }}
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserManagementTable;
