import axios from "axios";
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";

const ProjectCategoriesTable = (props) => {
  // TODO : Once we have privileges uncomment it.
  const privileges = secureLocalStorage.getItem("privileges");

  // Search Filter OnChange from input field
  const [filterProjectCategories, setFilterProjectCategories] = useState([]);

  // OnCHnage Event to get input value
  const onChangeHandler = (event) => {
    if (event.target.value === "") {
      setFilterProjectCategories(props.rawProjectCategoriesData);
      return;
    }
    let filterProjectCategoriesData = props.rawProjectCategoriesData.filter(
      (item) =>
        item.name.toLowerCase().includes(event.target.value.toLowerCase())
    );

    setFilterProjectCategories(filterProjectCategoriesData);
    // In above filter you have to check proper keys from database to apply filter.
  };

  // Delete Function : delete the specific id
  const deleteProjectCategories = (id) => {
    // TODO : Check the API properly from postman
    const url = VARIABLES.url + `/api-app/project-category/${id}`;

    var config = {
      method: "delete",
      url: url,
      headers: {},
    };
    axios(config)
      .then((response) => {
        Swal.fire({
          icon: "success",
          text: "Project Category Deleted Successfully",
        });
        props.setRawProjectCategoriesData(
          props.rawProjectCategoriesData.filter((data) => {
            return data.id !== id;
          })
        );
      })
      .catch((error) => {
        Swal.fire({
          icon: "warning",
          text: "Failed",
        });
        console.log(error);
      });
  };

  const deleteProjectCategoriesAlert = (id) => {
    Swal.fire({
      title: "Are you sure? You will not be able to recover this data!",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteProjectCategories(id);
      }
    });
  };

  useEffect(() => {
    setFilterProjectCategories(props.rawProjectCategoriesData);
  }, [props.rawProjectCategoriesData]);

  return (
    <>
      <div className="row align-item-center">
        <div className="col-md-12">
          <div className="card mb-3">
            <div className="card-header py-3 bg-transparent border-bottom-0">
              <div className="filter">
                <p className="search-title">What are you looking for ?</p>
                <div className="input-group mb-3">
                  <span className="input-group-text" id="basic-addon1">
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </span>
                  <input
                    type="text"
                    placeholder="Search by category name..."
                    className="form-control"
                    onChange={onChangeHandler}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            {props.isLoading ? (
              <div className="card-body">Loading...</div>
            ) : (
              <div className="card-body basic-custome-color">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Name</th>

                        <th>Description</th>
                        {/* TODO : Uncomment Privilleges once we get from localstorage */}
                        {["edit_tech_role", "delete_tech_role"].some(
                          (privilege) => privileges.includes(privilege)
                        ) ? (
                          <th>Action</th>
                        ) : (
                          ""
                        )}
                      </tr>
                    </thead>
                    {filterProjectCategories.length === 0 && (
                      <tbody>
                        <tr>
                          <td></td>
                          <td>No data found</td>
                          <td></td>
                        </tr>
                      </tbody>
                    )}
                    {filterProjectCategories.length > 0 && (
                      <tbody>
                        {filterProjectCategories.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {" "}
                                <span className="ms-1 me-4">
                                  {data.name}
                                </span>{" "}
                              </td>

                              <td>
                                {" "}
                                <span className="ms-1 me-4">
                                  {data.description}
                                </span>{" "}
                              </td>
                              <td>
                                <div className="btn-group">
                                  {privileges.includes("edit_tech_role") ? (
                                    <button
                                      type="button"
                                      className="btn btn-outline-secondary"
                                      data-bs-toggle="modal"
                                      data-bs-target="#editProjectCategoryModal"
                                      onClick={() =>
                                        props.editProjectCategoriesData(data.id)
                                      }
                                    >
                                      <i className="icofont-edit text-success"></i>
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                  {privileges.includes("delete_tech_role") ? (
                                    <button
                                      onClick={() =>
                                        deleteProjectCategoriesAlert(data.id)
                                      }
                                      className="btn btn-white btn-dim btn-outline-danger"
                                    >
                                      <i className="icofont-ui-delete text-danger"></i>
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectCategoriesTable;
