import React, { useContext } from "react";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { NotificationsContext } from "../../context/Notifications/NotificationsContextProvider";

function NotificationsTable() {
  const {
    allNotifications,
    noPages,
    currentPage,
    entries,
    totalEntries,
    setEntries,
    getAllNotifications,
    updateNotificationStatus,
    readNotifications,
    unReadNotifications,
    setReadNotifications,
    setUnReadNotifications,
    setAllNotifications,
  } = useContext(NotificationsContext);
  const handleNotificationStatus = (id, type) => {
    setAllNotifications(
      allNotifications.map((notification) => {
        if (notification.id === id) {
          return {
            ...notification,
            is_read: !notification.is_read,
          };
        }
        return notification;
      })
    );
    if (type === "read") {
      setReadNotifications(readNotifications.filter((e) => e.id !== id));
      setUnReadNotifications([...unReadNotifications, id]);
    } else {
      setUnReadNotifications(unReadNotifications.filter((e) => e.id !== id));
      setReadNotifications([...readNotifications, id]);
    }
  };
  return (
    <div className="container py-3">
      <div className="card">
        <div className="card-header d-flex align-items-center justify-content-center">
          <h5>All Notifications</h5>
          <button
            className="btn btn-sm btn-outline-primary ms-auto me-1 my-2"
            onClick={updateNotificationStatus}
          >
            Update
          </button>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table align-middle">
              <thead>
                <tr className="table-light">
                  <th>#</th>
                  <th>Title</th>
                  <th>Message</th>
                  <th>Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {allNotifications.map((notification, i) => {
                  return (
                    <tr key={i}>
                      <td>{i + 1 + entries * (currentPage - 1)}</td>
                      <td>{notification.title}</td>
                      <td>{notification.message}</td>
                      <td>
                        {moment(notification?.created_at).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )}
                      </td>
                      <td>
                        <span
                          className={`badge rounded-pill`}
                          style={{
                            minWidth: "105px",
                            cursor: "pointer",
                            backgroundColor: !notification.is_read
                              ? "#FFB22C"
                              : "green",
                          }}
                          onClick={() =>
                            handleNotificationStatus(
                              notification.id,
                              notification.is_read ? "read" : "unread"
                            )
                          }
                        >
                          {notification.is_read
                            ? "mark as unread"
                            : "mark as read"}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-center justify-content-md-between mt-3">
            <ReactPaginate
              key={3}
              previousLabel={"previous"}
              nextLabel={"next"}
              breakLabel={"..."}
              pageCount={noPages}
              initialPage={currentPage - 1}
              marginPagesDisplayed={2}
              disableInitialCallback={true}
              onPageChange={(selectedPage) => {
                setReadNotifications([]);
                setUnReadNotifications([]);
                getAllNotifications(selectedPage.selected + 1, entries);
              }}
              containerClassName={
                "pagination justify-content-center justify-content-md-start"
              }
              pageClassName={"paginate_button page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"paginate_button page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />

            <div className="d-flex justify-content-center align-items-center">
              <div>
                Total entries:
                <span className="fw-bold me-3">{totalEntries}</span>
              </div>
              <div className="me-3">No of entries per page:</div>
              <div>
                <select
                  className="form-select"
                  value={entries}
                  onChange={(e) => {
                    setEntries(e.target.value);
                    getAllNotifications(currentPage, e.target.value);
                  }}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationsTable;
