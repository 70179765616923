import React, { useState, useEffect } from "react";
import { wellnessQuotes } from "./WellnessQuotesData";

const WellnessQuote = () => {
  const [quoteStatus, setQuoteStatus] = useState("true");
  const [showModal, setShowModal] = useState(false);
  const quoteDisplayed = localStorage.getItem("quoteDisplayed");

  // State to hold the currently displayed quote
  const [currentQuote, setCurrentQuote] = useState("");

  // Function to display a random quote
  const displayRandomQuote = () => {
    const randomIndex = Math.floor(Math.random() * wellnessQuotes.length);
    setCurrentQuote(wellnessQuotes[randomIndex]);
  };

  // Display a random quote when the component mounts
  useEffect(() => {
    if (!quoteDisplayed) {
      displayRandomQuote();
      localStorage.setItem("quoteDisplayed", "true");
      setShowModal(true); // Show the modal
    } else {
      setShowModal(false); // Hide the modal
    }
  }, []);

  return (
    <>
      {showModal && (
        <div
          id="quoteToast"
          className={`${
            quoteStatus === "true" ? "toast show active" : "toast hide"
          }`}
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="toast-header">
            <strong className="me-auto">Wellness Quote</strong>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="toast"
              aria-label="Close"
              onClick={() => setQuoteStatus("false")}
            ></button>
          </div>
          <div className="toast-body">{currentQuote}</div>
        </div>
      )}
    </>
  );
};

export default WellnessQuote;
