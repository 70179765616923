import axios from "axios";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { VARIABLES } from "../../Constants";
import Select from "react-select";
import Swal from "sweetalert2";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { ProjectContext } from "../../context/ProjectContext/ProjectContextProvider";
import { TaskManagementContext } from "../../context/TaskManagementContext/TaskManagementContextProvider";
import { DropdownAPIsContext } from "../../context/DropdownAPIsContext/DropdownAPIsContextProvider";

const ProjectTable = (props) => {
  const privileges = secureLocalStorage.getItem("privileges");
  const [viewDropdown, setViewDropdown] = useState([
    { value: false, label: "Assigned To Me" },
    { value: true, label: "All" },
  ]);
  const [defaultCat, setDefaultCat] = useState(null);
  const [defaultShow, setDefaultShow] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  // SearchFilterData filter the Data by Search Functionality
  const [filterProject, setFilterProject] = useState([]);
  const {
    noPages,
    currentPage,
    entire,
    setEntries,
    apiGetProjects,
    isProjectLoading,
    showAllProjects,
    setShowAllProjects,
    setCategoryFilter,
    categoryFilter,
    projectData,
  } = useContext(ProjectContext);
  const { op } = useContext(DropdownAPIsContext);
  const { setCurrentBoardId } = useContext(TaskManagementContext);

  // OnCHnage Event to get input value
  const onChangeHandler = (event) => {
    if (event.target.value === "") {
      setFilterProject(projectData);
      return;
    }
    let filterProjectData = projectData.filter(
      (item) =>
        item.project_name
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        item.project_incharge.username
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
    );
    setFilterProject(filterProjectData);
  };

  const handleClick = (api) => {
    setCurrentBoardId({ ...api });
    navigate(
      `/${api.project_name.split(" ").join("").toLowerCase()}_${api.id}`
    );
  };

  // Delete button trigger the function to delete the specific id from table
  const deleteProject = (id) => {
    var config = {
      method: "delete",
      url: VARIABLES.url + `/api-app/project/${id}`,
      headers: {},
    };
    axios(config)
      .then((response) => {
        Swal.fire({
          icon: "success",
          text: "Project Deleted Successfully!",
        });

        // window.location.reload();
        apiGetProjects();
        props.setRawProjectData(
          projectData.filter((user) => {
            return user.id !== id;
          })
        );
      })
      .catch((error) => {
        Swal.fire(
          " Not Deleted Successfully!",
          `${id} is not deleted successfully`,
          "warning"
        );
        console.log(error);
      });
  };

  const deleteProjectAlert = (id) => {
    Swal.fire({
      title: "Are you sure? You will not be able to recover this project!",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteProject(id);
      }
    });
  };

  const handleFilterShow = (data) => {
    setShowAllProjects(data.value);
  };

  const handleFilterCat = (data) => {
    setCategoryFilter(data.map((e) => e.value));
  };

  useEffect(() => {
    if (isProjectLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isProjectLoading]);

  useEffect(() => {
    // if (projectData.length !== 0) {
    setFilterProject(projectData);
    setIsLoading(false);
    // }
  }, [projectData]);

  useEffect(() => {
    if (props.categoryType && categoryFilter !== null) {
      setDefaultCat(
        props.categoryType.filter((cat) => categoryFilter.includes(cat.value))
      );
    }
  }, [props.categoryType, categoryFilter]);

  useEffect(() => {
    if (!showAllProjects) {
      setDefaultShow({ value: false, label: "Assigned To Me" });
    } else {
      setDefaultShow({ value: true, label: "All" });
    }
  }, [showAllProjects]);

  return (
    <>
      <div className="row g-3 mb-3 row-deck">
        <div className="col-md-12">
          <div className="card mb-3">
            <div className="card-body">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  <i className="fa fa-search" aria-hidden="true"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by Project Name/Incharge Name"
                  onChange={onChangeHandler}
                />
              </div>
              <div className="d-flex align-items-center justify-content-end mt-3 gap-3">
                {defaultCat && (
                  <>
                    <h6 className="p-0 m-0">Filter:</h6>
                    {privileges.includes("show_all_project") ? (
                      <Select
                        defaultValue={defaultShow}
                        options={viewDropdown}
                        onChange={handleFilterShow}
                      />
                    ) : (
                      ""
                    )}
                    <Select
                      defaultValue={defaultCat}
                      options={props.categoryType}
                      onChange={handleFilterCat}
                      isMulti
                    />
                  </>
                )}
              </div>
              {isProjectLoading ? (
                <div className="card-body">Loading...</div>
              ) : (
                <>
                  <div className="table-responsive">
                    <table
                      id="myProjectTable"
                      className="table table-hover align-middle mb-0"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th>Project Name</th>
                          {/* <th>Project Id</th> */}
                          {/* <th>Is Converted</th> */}
                          {/* <th>Date Start</th> */}
                          {/* <th>Deadline</th> */}
                          <th>Project Incharge</th>
                          {/* <th>Completion</th> */}
                          {privileges.includes("view_latest_releases") ? (
                            <th>Releases</th>
                          ) : (
                            ""
                          )}
                          {privileges.includes("project_document") ? (
                            <th>Document</th>
                          ) : (
                            ""
                          )}
                          {privileges.includes("view_project_board") ? (
                            <th>Board</th>
                          ) : (
                            ""
                          )}
                          {privileges.includes("edit_project") ? (
                            <th>Edit</th>
                          ) : (
                            ""
                          )}
                          {privileges.includes("delete_project") ? (
                            <th>Delete</th>
                          ) : (
                            ""
                          )}
                        </tr>
                      </thead>
                      {filterProject.length === 0 && (
                        <tbody>
                          <tr>
                            <td></td>
                            <td>No data found</td>
                            <td></td>
                          </tr>
                        </tbody>
                      )}
                      {filterProject.length > 0 && (
                        <tbody>
                          {filterProject.map((api, index) => {
                            return (
                              <tr
                                key={index}
                                style={{
                                  backgroundColor:
                                    api.project_flag_status === "Red"
                                      ? "#ff4a4a"
                                      : api.project_flag_status === "Green"
                                      ? "#65B741"
                                      : api.project_flag_status === "Gray"
                                      ? "#B4B4B8"
                                      : api.project_flag_status === "Yellow"
                                      ? "#F4CE14"
                                      : "white",
                                }}
                              >
                                <td>
                                  <a
                                    href="javascript:void(0)"
                                    onClick={() => {
                                      // changeSidebar("project_doc", api);
                                      navigate("/projectdoc", {
                                        state: {
                                          data: api,
                                        },
                                      });
                                    }}
                                    style={{
                                      color:
                                        api.project_flag_status !== "White"
                                          ? "white"
                                          : "black",
                                    }}
                                  >
                                    {api.project_name}
                                  </a>
                                </td>
                                <td>
                                  <a
                                    href="#"
                                    style={{
                                      color:
                                        api.project_flag_status !== "White"
                                          ? "white"
                                          : "black",
                                    }}
                                  >
                                    {api.project_incharge.username
                                      .charAt(0)
                                      .toUpperCase() +
                                      api.project_incharge.username.slice(1)}
                                  </a>
                                </td>
                                {privileges.includes("view_latest_releases") ? (
                                  <td>
                                    <button
                                      type="button"
                                      className="btn btn-primary btn-sm"
                                      onClick={() =>
                                        navigate("/projectreleases", {
                                          state: { data: api.id },
                                        })
                                      }
                                    >
                                      {api.latest_release}
                                    </button>
                                  </td>
                                ) : (
                                  ""
                                )}
                                {privileges.includes("project_document") ? (
                                  <td>
                                    <button
                                      type="button"
                                      className="btn btn-primary btn-sm"
                                      onClick={() => {
                                        // changeSidebar("project_doc", api);
                                        navigate("/projectdoc", {
                                          state: {
                                            data: api,
                                          },
                                        });
                                      }}
                                    >
                                      Document
                                    </button>
                                  </td>
                                ) : (
                                  ""
                                )}
                                {privileges.includes("view_project_board") ? (
                                  <td>
                                    <button
                                      type="button"
                                      className="btn btn-primary btn-sm"
                                      onClick={() => handleClick(api)}
                                    >
                                      View
                                    </button>
                                  </td>
                                ) : (
                                  ""
                                )}
                                {privileges.includes("edit_project") ? (
                                  <td>
                                    <button
                                      type="button"
                                      className="btn"
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                      onClick={() =>
                                        props.getEditedProjectData(api.id)
                                      }
                                    >
                                      <i
                                        className="icofont-edit"
                                        style={{
                                          color:
                                            api.project_flag_status !== "White"
                                              ? "white"
                                              : "green",
                                        }}
                                      ></i>
                                    </button>
                                  </td>
                                ) : (
                                  ""
                                )}
                                {privileges.includes("delete_project") ? (
                                  <td>
                                    <button
                                      type="button"
                                      className="btn"
                                      onClick={() => deleteProjectAlert(api.id)}
                                    >
                                      <i
                                        className="icofont-ui-delete"
                                        style={{
                                          color:
                                            api.project_flag_status !== "White"
                                              ? "white"
                                              : "red",
                                        }}
                                      ></i>
                                    </button>
                                  </td>
                                ) : (
                                  ""
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                      )}
                    </table>
                  </div>
                  <div className="d-flex justify-content-center justify-content-md-between mt-3">
                    <ReactPaginate
                      key={3}
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={noPages}
                      initialPage={currentPage - 1}
                      marginPagesDisplayed={2}
                      disableInitialCallback={true}
                      // pageRangeDisplayed={3}
                      onPageChange={(selectedPage) => {
                        // setCurrentPage(selectedPage.selected + 1);
                        apiGetProjects(selectedPage.selected + 1, entire);
                      }}
                      containerClassName={
                        "pagination justify-content-center justify-content-md-start"
                      }
                      pageClassName={"paginate_button page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"paginate_button page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />

                    <div className="d-flex justify-content-center align-items-center">
                      <div>
                        Total entries:{" "}
                        <span className="fw-bold me-3">
                          {/* {entryCount === null ? 0 : entryCount} */}
                          {entire}
                        </span>
                      </div>
                      <div className="me-3">No of entries per page:</div>
                      <div>
                        <select
                          className="form-select"
                          value={entire}
                          onChange={(e) => {
                            setEntries(e.target.value);
                            apiGetProjects(currentPage, e.target.value);
                          }}
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectTable;
