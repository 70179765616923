import React, { useContext, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import "./Dropdown.css";
import { VARIABLES } from "../../../../Constants";
import { usePopper } from "react-popper";
import { UsersContext } from "../../../../context/UsersContext/UsersContextProvider";
import UserImagePopper from "../../../popper-drawer/UserImagePopper";

const Portal = ({ children }) => {
  return createPortal(children, document.body);
};

const UsersProfilesDropdown = ({ item }) => {
  const [refrenceElement, setRefrenceElement] = useState();
  const [popperElement, setPopperElement] = useState();
  const DropdownRef = useRef(null);
  const [showDrop, setShowDrop] = useState(false);
  const { users } = useContext(UsersContext);
  let { styles, attributes } = usePopper(refrenceElement, popperElement);

  const handleOutsideClick = (e) => {
    if (DropdownRef.current && !DropdownRef.current.contains(e.target)) {
      setShowDrop(false);
    }
  };

  const handleScroll = (e) => {
    if (!e.target.classList.contains("user-profile-dropdown-list")) {
      setShowDrop(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    document.addEventListener("scroll", handleScroll, true);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
      document.removeEventListener("scroll", handleScroll, true);
    };
  }, []);

  return (
    <>
      <div
        className={`avatar-list avatar-list-stacked d-flex ${
          item?.assigned_to.length <= 3 && "me-1"
        } mb-1`}
        onClick={() => setShowDrop(true)}
        ref={setRefrenceElement}
      >
        {item?.assigned_to.map((assignee, i) => {
          if (i <= 3) {
            return (
              <React.Fragment key={i}>
                <UserImagePopper
                  id={
                    item?.card_type !== "regularcard" ? assignee : assignee?.id
                  }
                  size={"sm"}
                  show={false}
                />
              </React.Fragment>
            );
          } else if (i === 4 && item?.assigned_to.length < 99) {
            return (
              <div
                className="avatar rounded-circle d-flex align-items-center justify-content-center m-0 p-0 sm"
                style={{ fontSize: "10px" }}
              >
                <span>{item?.assigned_to.length - i}</span>
              </div>
            );
          } else if (i === 4 && item?.assigned_to.length >= 103) {
            <div
              className="avatar rounded-circle d-flex align-items-center justify-content-center m-0 p-0 sm"
              style={{ fontSize: "10px" }}
            >
              <span>+99</span>
            </div>;
          }
        })}
      </div>
      {showDrop ? (
        <Portal>
          <div
            className="dropdown-list user-profile-dropdown-list"
            style={styles.popper}
            {...attributes.popper}
            ref={setPopperElement}
          >
            <div ref={DropdownRef}>
              <div className="list-group">
                {item?.card_type !== "regularcard" ? (
                  <>
                    {item?.assigned_to.map((assignee, i) => (
                      <button
                        key={i}
                        type="button"
                        className="list-group-item list-group-item-action border-0"
                      >
                        <UserImagePopper id={assignee} show={true} />
                        <span style={{ fontSize: "1.2em" }} className="ms-3">
                          {
                            users.filter((user) => user.value === assignee)[0]
                              .label
                          }
                        </span>
                      </button>
                    ))}
                  </>
                ) : (
                  <>
                    {item?.assigned_to.map((assignee, i) => (
                      <button
                        key={i}
                        type="button"
                        className="list-group-item list-group-item-action border-0"
                      >
                        <UserImagePopper
                          id={assignee?.id}
                          show={true}
                          margin={"mb-1"}
                        />
                        <span style={{ fontSize: "1.2em" }} className="ms-3">
                          {assignee?.username}
                        </span>
                      </button>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </Portal>
      ) : (
        ""
      )}
    </>
  );
};

export default UsersProfilesDropdown;
