import React, { useState } from "react";
import axios from "axios";
import { Controller, useForm, useFormState } from "react-hook-form";
import Select from "react-select";
import { VARIABLES } from "../../Constants";
import Swal from "sweetalert2";
import secureLocalStorage from "react-secure-storage";

const Gender = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Other", value: "Other" },
];

const UserManagementCreate = (props) => {
  const userID = secureLocalStorage.getItem("userID");
  // react hook form
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm();
  const { isDirty, isValid } = useFormState({
    control,
  });
  const [passwordToggle, setPasswordToggle] = useState(false);
  const [confirmPassToggle, setConfirmPassToggle] = useState(false);

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    let user = new FormData();
    user.append("first_name", data.first_name);
    user.append("last_name", data.last_name);
    user.append("username", data.username);
    user.append("email", data.email);
    user.append("mobile_number", data.number);
    user.append("organisation", [data.organization.value]);
    user.append("gender", data.gender.value);
    user.append("employee_id", data.employee_id);
    user.append("password", data.password);
    user.append("confirm_pwd", data.confirm_pwd);
    user.append("level_role_id", data.level_role_id.value);
    user.append(
      "technology_role_id",
      data.technology_role_id.map((val) => val.value)
    );
    user.append("designation", data.designation);
    user.append("created_by_id", userID);
    user.append("updated_by_id", userID);
    user.append("dob", data.dob);
    user.append("epc_no", data.epc_no);
    user.append("card_number", data.card_number);
    user.append("profile_img", data.profile_img);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: VARIABLES.url + "/api-app/user",
      headers: {
        "Content-Type": "application/octet-stream",
      },
      data: user,
    };

    axios(config)
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            title: "Success!",
            text: "User Created Successfully",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => props.apiGetRawUserManagement());
        } else if (response.status == 212) {
          Swal.fire({
            icon: "error",
            title: response.data?.message,
            showConfirmButton: true,
          });
        } else if (response.status == 210) {
          Swal.fire({
            icon: "error",
            title: response.data?.message,
            showConfirmButton: true,
          });
        } else if (response.status == 211) {
          Swal.fire({
            icon: "error",
            title: response.data?.message,
            showConfirmButton: true,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: response.data?.message,
            showConfirmButton: true,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        const resStatus = error.response.status;
        if (resStatus == 402) {
          Swal.fire({
            icon: "error",
            title: "Password & Confirm Password does not match",
            showConfirmButton: true,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Failed to create User",
            showConfirmButton: true,
          });
        }
      });
    reset();
  };

  const passwordValidate = (data) => {
    const pass = watch("password");
    return pass === data;
  };

  const getResetValues = () => {
    reset();
  };

  return (
    <>
      <div
        className="modal fade"
        id="addUserManagement"
        tabIndex="-1"
        data-bs-backdrop="static"
        aria-labelledby="depaddLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title fw-bold" id="depaddLabel">
                Create User
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => getResetValues()}
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="mb-3">
                  <label className="form-label">
                    First Name <span className="required_field">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter first name"
                    {...register("first_name", { required: true })}
                  />
                  {errors.first_name && (
                    <span className="required_field">
                      First name is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Last Name <span className="required_field">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter last name"
                    {...register("last_name", { required: true })}
                  />
                  {errors.last_name && (
                    <span className="required_field">
                      Last name is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Mobile Number <span className="required_field">*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Mobile number"
                    {...register("number", {
                      required: true,
                    })}
                  />
                  {errors.number && (
                    <span className="required_field">
                      Mobile number is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Email Address <span className="required_field">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter Email Address"
                    {...register("email", { required: true })}
                  />
                  {errors.email && (
                    <span className="required_field">
                      Email Address is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Username<span className="required_field">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter username"
                    {...register("username", { required: true })}
                  />
                  {errors.username && (
                    <span className="required_field">Username is required</span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Password<span className="required_field">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type={`${passwordToggle === true ? "text" : "password"}`}
                      className="form-control"
                      placeholder="Enter Password"
                      {...register("password", { required: true })}
                    />
                    <span
                      className="input-group-text"
                      onClick={() => setPasswordToggle(!passwordToggle)}
                      id="basic-addon2"
                    >
                      <i
                        className={`${
                          passwordToggle === true
                            ? "icofont-eye me-2 fs-6"
                            : "icofont-eye-blocked me-2 fs-6"
                        }`}
                      ></i>
                    </span>
                  </div>
                  {errors.password && (
                    <span className="required_field">Password is required</span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Confirm Password<span className="required_field">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type={`${
                        confirmPassToggle === true ? "text" : "password"
                      }`}
                      className="form-control"
                      placeholder="Enter Password"
                      {...register("confirm_pwd", {
                        required: true,
                        validate: { passwordValidate },
                      })}
                    />
                    <span
                      className="input-group-text"
                      onClick={() => setConfirmPassToggle(!confirmPassToggle)}
                      id="basic-addon2"
                    >
                      <i
                        className={`${
                          confirmPassToggle === true
                            ? "icofont-eye me-2 fs-6"
                            : "icofont-eye-blocked me-2 fs-6"
                        }`}
                      ></i>
                    </span>
                  </div>
                  {errors.confirm_pwd &&
                    errors.confirm_pwd.type === "required" && (
                      <span className="required_field">
                        Confirm Password is required
                      </span>
                    )}
                  {errors.confirm_pwd &&
                    errors.confirm_pwd.type === "passwordValidate" && (
                      <span className="required_field">
                        Password and confirm password does not match
                      </span>
                    )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Designation<span className="required_field">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter the designation"
                    {...register("designation", { required: true })}
                  />
                  {errors.designation && (
                    <span className="required_field">
                      Designation is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Gender <span className="required_field">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select {...field} options={Gender} isClearable={true} />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="gender"
                    control={control}
                  />
                  {errors.gender && (
                    <span className="required_field">Gender is required</span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Level Role <span className="required_field">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={props.levelRole}
                        isClearable={true}
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="level_role_id"
                    control={control}
                  />
                  {errors.level_role_id && (
                    <span className="required_field">
                      Level Role is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Technology Role <span className="required_field">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={props.technologyRole}
                        isMulti
                        isClearable={true}
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="technology_role_id"
                    control={control}
                  />
                  {errors.technology_role_id && (
                    <span className="required_field">
                      Technology Role is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Organization <span className="required_field">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={props.organizationDetails}
                        isClearable={true}
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="organization"
                    control={control}
                  />
                  {errors.organization && (
                    <span className="required_field">
                      Organization is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Employee Id<span className="required_field">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Employee Id"
                    {...register("employee_id", { required: true })}
                  />
                  {errors.employee_id && (
                    <span className="required_field">
                      Employee Id is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">EPC number</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter EPC number"
                    {...register("epc_no")}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Card number</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter EPC number"
                    {...register("card_number")}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Date of Birth<span className="required_field">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="dob"
                    {...register("dob", { required: true })}
                  />
                  {errors.dob && (
                    <span className="required_field">
                      Date of Birth is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">Profile Image</label>
                  <Controller
                    name="profile_img" // The name of the field in the form data
                    control={control}
                    // rules={{ required: true }}
                    defaultValue={null}
                    render={({ field }) => (
                      <input
                        type="file"
                        onChange={(e) => field.onChange(e.target.files[0])}
                      />
                    )}
                  />
                </div>
                <div className="modal-footer">
                  {/* <button type="submit" className="btn btn-success">Submit</button> */}
                  {isValid === true ? (
                    <button
                      type="submit"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Submit
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserManagementCreate;
