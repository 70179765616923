import axios from "axios";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useRef } from "react";
import { useState } from "react";
import { VARIABLES } from "../../Constants";
import ResourceMappingCreate from "./ResourceMappingCreate";
import ResourceMappingEdit from "./ResourceMappingEdit";
import ResourceMappingTable from "./ResourceMappingTable";
import secureLocalStorage from "react-secure-storage";
import { DropdownAPIsContext } from "../../context/DropdownAPIsContext/DropdownAPIsContextProvider";
import { ProjectContext } from "../../context/ProjectContext/ProjectContextProvider";
import { UsersContext } from "../../context/UsersContext/UsersContextProvider";

const ResourceMapping = () => {
  const privileges = secureLocalStorage.getItem("privileges");
  //  isLoading loader states wait for data come form api
  const [isLoading, setIsloading] = useState(false);
  // raw resource mapping data stored in "rawResourceMappingData" state
  const [rawResourceMappingData, setRawResourceMappingData] = useState([]);

  const [editID, setEditId] = useState(null);

  // Particular edited data of Resource map
  const [editedResourceMapping, setEditedResourceMapping] = useState(null);

  // Stores Project data in {id, name format i.e., value and label}
  const [projectData, setProjectData] = useState([]);

  const [rawProjectData, setRawProjectData] = useState([]);

  // Stores Employee data in {id, name format i.e., value and label}
  const [employeeData, setEmployeeData] = useState([]);

  // Stores Sub Project data in {id, name format i.e., value and label}
  const [subProject, setSubProject] = useState([]);

  const [noPages, setNoPages] = useState(1);
  const [entire, setEntries] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const { projects, subproject } = useContext(DropdownAPIsContext);
  const { projectData: projectDataRaw } = useContext(ProjectContext);
  const { users } = useContext(UsersContext);

  // API call of Resource Mapping and stores the data in "rawResourceMappingData"
  const apiRawResourceMapping = async (
    page_no = currentPage,
    entires = entire
  ) => {
    try {
      const source = axios.CancelToken.source();
      const res = await axios.get(
        VARIABLES.url +
          `/api-app/resource-mapping?page_no=${page_no}&entries=${entires}`,
        { cancelToken: source.token }
      );
      setNoPages(res.data.num_pages);
      setCurrentPage(res.data.current_page);
      setRawResourceMappingData(res.data.results.data);
      setIsloading(false);
    } catch (error) {
      console.log(error);
    }
  };

  // API call to store subproject name and id details in "subProject" state
  const getSubProject = async () => {
    try {
      const res = await axios.get(VARIABLES.url + "/api-app/subproject");
      setSubProject(res.data);
      // setSubProject(
      //   res.data.map((userData) => ({
      //     value: userData.id,
      //     label: userData.name,
      //   }))
      // );
    } catch (error) {
      console.log(error);
    }
  };

  const editResourceMappingData = (id) => {
    setEditId(id);
    const getEditedTask = rawResourceMappingData.find((task) => {
      return task.id === id;
    });
    setEditedResourceMapping(getEditedTask);
  };

  useEffect(() => {
    if (users.length !== 0) {
      setEmployeeData(users);
    }
  }, [users]);

  useEffect(() => {
    if (rawResourceMappingData.length === 0) {
      setIsloading(true);
    }
  }, [rawResourceMappingData]);

  useEffect(() => {
    if (projects.length !== 0) {
      setProjectData(
        projects.map((proj) => {
          return {
            value: proj.id,
            label: proj.project_name,
          };
        })
      );
    }
  }, [projects]);

  useEffect(() => {
    if (projectDataRaw.length !== 0) {
      setRawProjectData(projectDataRaw);
    }
  }, [projectDataRaw]);

  useEffect(() => {
    apiRawResourceMapping();
    getSubProject();
  }, []);
  return (
    <>
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            <h5 className="fw-bold mb-0">Resource Mapping</h5>
            {privileges.includes("create_resource_mapping") ? (
              <div className="col-auto d-flex w-sm-100">
                <button
                  type="button"
                  className="btn resource-tab btn-dark btn-set-task w-sm-100"
                  data-bs-toggle="modal"
                  data-bs-target="#depadd"
                >
                  <i className="icofont-plus-circle me-2 fs-6"></i>Map Resource
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {/* <!-- Row end  --> */}

      <ResourceMappingTable
        isLoading={isLoading}
        editResourceMappingData={editResourceMappingData}
        rawResourceMappingData={rawResourceMappingData}
        setRawResourceMappingData={setRawResourceMappingData}
        projectData={projectData}
        employeeData={employeeData}
        subProject={subProject}
        apiRawResourceMapping={apiRawResourceMapping}
        editID={editID}
        editedResourceMapping={editedResourceMapping}
        setEditId={setEditId}
        rawProjectData={rawProjectData}
        noPages={noPages}
        entire={entire}
        currentPage={currentPage}
        setEntries={setEntries}
      />

      <ResourceMappingCreate
        rawProjectData={rawProjectData}
        projectData={projectData}
        employeeData={employeeData}
        subProject={subProject}
        apiRawResourceMapping={apiRawResourceMapping}
      />

      <ResourceMappingEdit
        projectData={projectData}
        employeeData={employeeData}
        subProject={subProject}
        apiRawResourceMapping={apiRawResourceMapping}
        editedResourceMapping={editedResourceMapping}
      />
    </>
  );
};

export default ResourceMapping;
