import React from 'react'

const ReleasesSubProject = () => {
  return (
    <>

      <div className="card teacher-card  mb-3">
        <div className="card-body d-flex teacher-fulldeatil">
          <div className="teacher-info ps-xl-4 ps-md-4 ps-sm-4 ps-4 w-100">
            <h6 className="mb-0 mt-2  fw-bold d-block fs-6">Project Name</h6>
            <span className="py-1 fw-bold small-11 mb-0 mt-1 text-muted">v.0.1.0.0.0</span>
            <p className="mt-2 small">The purpose of lorem ipsum is to create a natural looking block of text (sentence, paragraph, page, etc.) that doesn't distract from the layout. A practice not without controversy</p>
          </div>
        </div>
      </div>

      <h6 className="fw-bold  py-3 mb-3">Sub Project</h6>
      <div className="teachercourse-list mb-3">
        <div className="row g-3 gy-5 row-deck">
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="lesson_name">
                    <h6 className="mb-0 fw-bold fs-6 mb-2">UI/UX Design</h6>
                  </div>
                </div>

                <div className="row g-2 pt-4">
                  <div className="col-6">
                    <div className="d-flex align-items-center">
                      <i className="icofont-paper-clip"></i>
                      <span className="ms-2">5 Attach</span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex align-items-center">
                      <i className="icofont-sand-clock"></i>
                      <span className="ms-2">4 Month</span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex align-items-center">
                      <i className="icofont-group-students "></i>
                      <span className="ms-2">5 Members</span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex align-items-center">
                      <i className="icofont-ui-text-chat"></i>
                      <span className="ms-2">10</span>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>

          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="lesson_name">
                    <h6 className="mb-0 fw-bold fs-6 mb-2">UI/UX Design</h6>
                  </div>
                </div>

                <div className="row g-2 pt-4">
                  <div className="col-6">
                    <div className="d-flex align-items-center">
                      <i className="icofont-paper-clip"></i>
                      <span className="ms-2">5 Attach</span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex align-items-center">
                      <i className="icofont-sand-clock"></i>
                      <span className="ms-2">4 Month</span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex align-items-center">
                      <i className="icofont-group-students "></i>
                      <span className="ms-2">5 Members</span>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex align-items-center">
                      <i className="icofont-ui-text-chat"></i>
                      <span className="ms-2">10</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </>
  )
}

export default ReleasesSubProject