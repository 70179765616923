import { useMemo } from "react";

function useFinancialYearWeeks() {
  const generateWeekOptionsForFinancialYear = () => {
    const weeks = [];
    for (let i = 0; i < 52; i++) {
      weeks.push({
        value: i + 1,
        label: `Week ${i + 1}`,
      });
    }
    return weeks;
  };

  const getFinancialYearWeek = (date) => {
    const startOfFinancialYear = new Date(date.getFullYear(), 3, 1); // April 1st
    const endOfFinancialYear = new Date(date.getFullYear() + 1, 2, 31); // March 31st of the next year

    // Adjust if the date is before April 1st (within January-March)
    if (date < startOfFinancialYear) {
      startOfFinancialYear.setFullYear(startOfFinancialYear.getFullYear() - 1);
      endOfFinancialYear.setFullYear(endOfFinancialYear.getFullYear() - 1);
    }

    const oneWeek = 1000 * 60 * 60 * 24 * 7; // One week in milliseconds
    const diffInTime = date - startOfFinancialYear;
    const weekNumber = Math.ceil(diffInTime / oneWeek);

    return weekNumber;
  };

  // useMemo to avoid recalculating week options on every render
  const weekOptions = useMemo(generateWeekOptionsForFinancialYear, []);

  const currentWeekNumber = useMemo(() => {
    return getFinancialYearWeek(new Date());
  }, []);

  return { weekOptions, getFinancialYearWeek, currentWeekNumber };
}

export default useFinancialYearWeeks;
