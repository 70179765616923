import React, { useState, useEffect } from "react";
import axios from "axios";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";

function TimeSheetCards() {
  const [timesheetscards, SetTimesheetCards] = useState([]);
  const [loading, setLoading] = useState(true);

  const loadTimeSheetCards = async () => {
    const userID = secureLocalStorage.getItem("userID");
    const url = VARIABLES.url + "/api/timesheet-card/?assignedTo__id=" + userID;
    //Config
    let header = { "Content-Type": "application/json" };
    let reqOptions = {
      url: url,
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      mode: "no-cors",
    };

    //API Call
    axios.request(reqOptions).then(function (response) {
      var data = response.data;
      //State to save the data

      SetTimesheetCards(data);
      setLoading(false);
    });
  };

  useEffect(() => {
    loadTimeSheetCards();
  }, []);

  //loadTimeSheetCards();
  return (
    <div className="row g-3 mb-3 row-deck">
      <div className="col-md-12">
        <div className="card mb-3"></div>
      </div>
      <div className="col-md-12">
        <div className="card mb-3">
          {loading ? (
            <div className="card-body">Loading...</div>
          ) : (
            <div className="card-body">
              <table
                id="myProjectTable"
                className="App table table-hover align-middle mb-0"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th>Task ID</th>
                    <th>Card Title</th>
                    <th>Description</th>
                  </tr>
                </thead>
                {timesheetscards.length === 0 && (
                  <tbody>
                    <tr>
                      <td></td>
                      <td>No data found</td>
                      <td></td>
                    </tr>
                  </tbody>
                )}

                {timesheetscards.length > 0 && (
                  <tbody>
                    {timesheetscards.map((item) => (
                      <tr key={item.id}>
                        <td>{item.cardIdentificationNumber}</td>
                        <td>{item.cardTitle}</td>
                        <td>{item.cardDescription}</td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TimeSheetCards;
