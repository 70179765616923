import React from "react";
import axios from "axios";
import {
  Controller,
  useForm,
  useFormState,
  useFieldArray,
} from "react-hook-form";
import Select from "react-select";
import { VARIABLES } from "../../Constants";
import Swal from "sweetalert2";
import { useState } from "react";
import secureLocalStorage from "react-secure-storage";

const TeamReportingCreate = (props) => {
  const userID = secureLocalStorage.getItem("userID");
  // react hook form
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm({
    defaultValues: {
      leads_fields: [{ leads_id_id: "", start_date: "", end_date: "" }],
    },
  });
  const { isDirty, isValid } = useFormState({
    control,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "leads_fields",
  });

  // onSubmit handler to post form details
  const onSubmit = (data) => {
    try {
      data.leads_fields.map((lead) => {
        if (new Date(lead.start_date) > new Date(lead.end_date)) {
          throw "invalid date input";
        }
      });
      var reportingConfiguration = JSON.stringify(
        data.leads_fields.map((leads) => {
          return {
            employee_id: data.user.value,
            lead_id: leads.leads_id.value,
            start_date: leads.start_date,
            end_date: leads.end_date,
            created_by_id: userID,
            updated_by_id: userID,
          };
        })
      );

      var config = {
        method: "post",
        url: VARIABLES.url + "/api-app/bulk-lead-assignment/",
        headers: {
          "Content-Type": "application/json",
        },
        data: reportingConfiguration,
      };

      axios(config)
        .then((response) => {
          Swal.fire({
            title: "Success!",
            text: "Lead Assigned Successfully",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => props.getReportingConfigurationData());
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            title: "Error",
            text: "Failed to Assign Lead",
            icon: "error",
            confirmButtonText: "OK",
          });
        });
      reset();
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const getResetValues = () => {
    reset();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div
          className="modal fade"
          id="assignlead"
          tabIndex="-1"
          data-bs-backdrop="static"
          aria-labelledby="depaddLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title fw-bold" id="depaddLabel">
                  Assign Lead
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => getResetValues()}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-6 mb-3">
                    <label className="form-label">
                      User <span className="required_field">*</span>
                    </label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={props.users}
                          isClearable={true}
                        />
                      )}
                      defaultValue=""
                      rules={{ required: true }}
                      name="user"
                      control={control}
                    />
                    {errors.user && (
                      <span className="required_field">
                        User name is required
                      </span>
                    )}
                  </div>
                </div>

                {fields.map((field, index) => {
                  return (
                    <div className="row">
                      <div className="col-4 col-lg-5 mb-3">
                        <label className="form-label">
                          Lead <span className="required_field">*</span>
                        </label>
                        <Controller
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={props.users}
                              isClearable={true}
                            />
                          )}
                          defaultValue=""
                          rules={{ required: true }}
                          name={`leads_fields.${index}.leads_id`}
                          control={control}
                        />
                        {errors.leads_fields?.[index]?.leads_id && (
                          <span className="required_field">
                            Lead name is required
                          </span>
                        )}
                      </div>
                      <div className="deadline-form col-6">
                        <div className="row g-3 mb-3">
                          <div className="col-sm-6">
                            <label className="form-label">
                              Start Date{" "}
                              <span className="required_field">*</span>{" "}
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              id="start_date"
                              {...register(`leads_fields.${index}.start_date`, {
                                required: true,
                              })}
                            />
                            {errors.leads_fields?.[index]?.start_date && (
                              <span className="required_field">
                                Start Date is required
                              </span>
                            )}
                          </div>
                          <div className="col-sm-6">
                            <label className="form-label">
                              End Date <span className="required_field">*</span>
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              id="end_date"
                              {...register(`leads_fields.${index}.end_date`, {
                                required: true,
                              })}
                            />

                            {errors.leads_fields?.[index]?.end_date && (
                              <span className="required_field">
                                End Date is required
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      {index >= 1 && (
                        <div className="col-1 delete_button">
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => remove(index)}
                          >
                            <i className="icofont-ui-delete"></i>
                          </button>
                        </div>
                      )}
                    </div>
                  );
                })}

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() =>
                    append({ leads_id: "", start_date: "", end_date: "" })
                  }
                >
                  Add
                </button>

                <div className="modal-footer">
                  {/* <button type="submit" className="btn btn-success">Submit</button> */}
                  {isValid === true ? (
                    <button
                      type="submit"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Submit
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default TeamReportingCreate;
