import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../Constants";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ListContext } from "../../context/ListContextProvider";
import ReactPaginate from "react-paginate";

const TeamReport = (props) => {
  const userID = secureLocalStorage.getItem("userID");
  const [isLoading, setIsloading] = useState(false);
  const [rawReportingData, setRawReportingReporting] = useState([]);
  const [filterReporting, setFilterReporting] = useState([]);
  const { setView } = useContext(ListContext);
  const [noPages, setNoPages] = useState(1);
  const [entire, setEntries] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const privileges = secureLocalStorage.getItem("privileges");
  // const [filterDate,setFilterDate] = useState(null)
  const getReportingDate = async (page_no = currentPage, entires = entire) => {
    // API call of Project Data and stores the data in "rawProjectData"
    try {
      const res = await axios.get(
        VARIABLES.url +
          `/api-app/reporting-data?lead_id=` +
          userID +
          `&entries=${entires}&page_no=${page_no}`
      );
      setNoPages(res.data.num_pages);
      setCurrentPage(res.data.current_page);
      setRawReportingReporting(res.data.results.data);
      setIsloading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getFilterByDate = async (event) => {
    if (event.target.value === "") {
      getReportingDate();
    } else {
      try {
        setIsloading(true);
        const res = await axios.get(
          VARIABLES.url +
            `/api-app/reporting-data?lead_id=${userID}&date=${event.target.value}`
        );
        setRawReportingReporting(res.data);
        setIsloading(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  // OnCHnage Event to get input value
  const onChangeHandler = (event) => {
    if (event.target.value === "") {
      setFilterReporting(rawReportingData);
      return;
    }
    let filterReportingData = rawReportingData.filter((item) =>
      item.employee.username
        .toLowerCase()
        .includes(event.target.value.toLowerCase())
    );
    setFilterReporting(filterReportingData);
  };

  useEffect(() => {
    getReportingDate();
  }, []);

  useEffect(() => {
    setFilterReporting(rawReportingData);
  }, [rawReportingData]);

  return (
    <>
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            <h5 className="fw-bold py-3 mb-0">Team Reporting</h5>
            <div className="d-flex py-2 project-tab flex-wrap w-sm-100">
              {privileges.includes("reporting_kanban") ? (
                <button
                  type="button"
                  className="btn btn-dark w-sm-100"
                  onClick={() => {
                    navigate("/teamreportkanban");
                    setView("lead");
                  }}
                >
                  Kanban Board
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row align-item-center">
        <div className="col-md-12">
          <div className="card mb-3">
            <div className="card-header py-3 bg-transparent border-bottom-0">
              <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <div className="filter">
                    <input
                      type="text"
                      placeholder="Search by name"
                      className="form-control"
                      onChange={onChangeHandler}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="filter">
                    <input
                      type="date"
                      className="form-control"
                      onChange={getFilterByDate}
                    />
                  </div>
                </div>
              </div>
            </div>
            {isLoading ? (
              <div className="card-body">Loading...</div>
            ) : (
              <div className="card-body basic-custome-color">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Reporting Date</th>
                        <th>Reporting</th>
                      </tr>
                    </thead>
                    {filterReporting.length === 0 && (
                      <tbody>
                        <tr>
                          <td></td>
                          <td>No data found</td>
                          <td></td>
                        </tr>
                      </tbody>
                    )}
                    {filterReporting.length > 0 && (
                      <tbody>
                        {filterReporting.map((task, index) => {
                          return (
                            <tr>
                              <td>
                                <span className="fw-bold">
                                  {" "}
                                  {task.employee.username}{" "}
                                </span>
                              </td>

                              <td>
                                {" "}
                                {moment(task.reporting_date).format(
                                  "MMMM Do YYYY, h:mm a"
                                )}{" "}
                              </td>
                              <td>
                                <span className="ms-1">
                                  {" "}
                                  {task.reporting_text}{" "}
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                  <div className="d-flex justify-content-center justify-content-md-between mt-3">
                    <ReactPaginate
                      key={3}
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={noPages}
                      initialPage={currentPage - 1}
                      marginPagesDisplayed={2}
                      disableInitialCallback={true}
                      // pageRangeDisplayed={3}
                      onPageChange={(selectedPage) => {
                        // setCurrentPage(selectedPage.selected + 1);
                        getReportingDate(selectedPage.selected + 1, entire);
                      }}
                      containerClassName={
                        "pagination justify-content-center justify-content-md-start"
                      }
                      pageClassName={"paginate_button page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"paginate_button page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />

                    <div className="d-flex justify-content-center align-items-center">
                      <div className="me-3">No of entries per page:</div>
                      <div>
                        <select
                          className="form-select"
                          value={entire}
                          onChange={(e) => {
                            setEntries(e.target.value);
                            getReportingDate(currentPage, e.target.value);
                          }}
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamReport;
