import React from 'react'
import secureLocalStorage from "react-secure-storage";

const ProjectReleaseNote = (props) => {
  const privileges = secureLocalStorage.getItem("privileges")
  const changeSidebar = (section) => {
    props.handleSidebar(section);
  }
  return (
    <>
      <div className="row g-3 py-1 pb-4">
        {props.isLoading ? <div className='card-body'>Loading...</div> :
          <>
            {props.filterReleaseData.length === 0 &&
              <div className='card-body'>"No Data Found"</div>
            }
            {props.filterReleaseData.length > 0 &&
              <>
                {props.filterReleaseData.map((item, index) => {
                  return <div className="col-lg-4 col-md-6 col-sm-12">
                    <div className="card teacher-card">
                      <div className="card-body d-flex">
                        <div className="teacher-info ps-xl-4 ps-md-3 ps-sm-4 ps-4 w-100">
                          <h6 className="mb-0 mt-2  fw-bold d-block fs-6"> {item.project.project_name} </h6>
                          <span className="py-1 fw-bold small-11 mb-0 mt-1 text-muted"> {item.release_version} </span>
                          <p className="fw-bold mb-0 mt-1 text-muted"> {item.release_category[0].toUpperCase() +
                            item.release_category.slice(1)} </p>
                          <p className="fw-bold mb-0 mt-1 text-muted"> {item.release_sub_category} </p>
                          {/* <div className="video-setting-icon mt-3 pt-3 border-top">
                <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices.Vestibulum ante ipsum primis in faucibus orci luctus et ultrices</p>
              </div> */}
                          {/* {privileges.includes('view_project_releases') ? <div className="d-flex flex-wrap align-items-center ct-btn-set">
                <a href="javscript:void(0)" onClick={() => changeSidebar("ShowProjectProfile")} className="btn btn-dark btn-sm mt-1"><i className="icofont-invisible me-2 fs-6"></i>View Project</a>
              </div> : "" } */}
                        </div>
                      </div>
                    </div>
                  </div>
                })}
              </>
            }

          </>
        }



      </div>
    </>
  )
}

export default ProjectReleaseNote