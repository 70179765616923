export const supportNotification = () => {
  return "Notification" in window && "serviceWorker" in navigator;
};

export const haveNotificationPermission = () => {
  return Notification.permission === "granted";
};

export const askNotificationPermission = async () => {
  const permission = await Notification.requestPermission();
  return permission === "granted";
};

export const pushNotification = (data) => {
  if (supportNotification() && haveNotificationPermission()) {
    navigator.serviceWorker.getRegistration().then((registration) => {
      if (registration) {
        registration.showNotification(data.title, {
          body: data.body,
          icon: data.image,
        });
      }
    });
  }
};
