import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { VARIABLES } from "../../Constants";
import Swal from "sweetalert2";
import { TaskManagementContext } from "../TaskManagementContext/TaskManagementContextProvider";
import { DropdownAPIsContext } from "../DropdownAPIsContext/DropdownAPIsContextProvider";

export const ProjectCardContext = createContext(null);

function ProjectCardsContextProvider({ children }) {
  const [boardsList, setBoardsList] = useState([]);
  const [rawBoardsList, setRawBoardsList] = useState([]);
  const [projectCardsList, setProjectCardsList] = useState([]);
  const [currentBoard, setCurrentBoard] = useState(null);
  const [currentBoardMembers, setCurrentBoardMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { getTasksList } = useContext(TaskManagementContext);
  const { boards } = useContext(DropdownAPIsContext);
  const [noData, setNoData] = useState(false);
  const getBoardsList = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/api-app/board`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setRawBoardsList(response.data.data[0]);
        setBoardsList(
          response.data.data[0].map((board) => {
            return {
              value: board.id,
              label: board.name,
            };
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getProjectCardsList = (id) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/api-app/project-card/${id}`,
      headers: {},
    };
    setIsLoading(true);
    axios
      .request(config)
      .then((response) => {
        setProjectCardsList(response.data.data);
        if (response.data.data.length === 0) {
          setNoData(true);
        } else {
          setNoData(false);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setNoData(true);
        console.log(error);
      });
  };
  const createProjectCard = (data) => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/api-app/project-card`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        Swal.fire({
          title: "Success!",
          text: "Project Card Created successfully",
          icon: "success",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            getProjectCardsList(currentBoard.id);
            getTasksList(currentBoard.id);
          }
        });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Error",
          text: "Failed to Create Project Card",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };
  const updateProjectCard = (data, id) => {
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/api-app/project-card/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        Swal.fire({
          title: "Success!",
          text: "Project Card Updated successfully",
          icon: "success",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            getProjectCardsList(currentBoard.id);
            getTasksList(currentBoard.id);
          }
        });
      })
      .catch((error) => {
        console.log(error.response);
        Swal.fire({
          title: "Error",
          text: "Failed to Update Project Card",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  useEffect(() => {
    if (boards.length !== 0) {
      setCurrentBoard(boards[0]);
    }
  }, [boards]);

  useEffect(() => {
    if (currentBoard) {
      setCurrentBoardMembers(
        rawBoardsList.filter((item) => item.id === currentBoard.id)[0]
          ?.board_members
      );
    }
  }, [currentBoard, rawBoardsList]);

  useEffect(() => {
    getBoardsList();
  }, []);
  const value = {
    boardsList,
    projectCardsList,
    isLoading,
    noData,
    currentBoard,
    setCurrentBoard,
    getProjectCardsList,
    createProjectCard,
    updateProjectCard,
    currentBoardMembers,
  };
  return (
    <ProjectCardContext.Provider value={value}>
      {children}
    </ProjectCardContext.Provider>
  );
}

export default ProjectCardsContextProvider;
