import axios from "axios";
import React from "react";
import { VARIABLES } from "../../Constants";
import Swal from "sweetalert2";
import { useState } from "react";
import { useEffect } from "react";
import secureLocalStorage from "react-secure-storage";

const SubProjectTable = (props) => {
  const privileges = secureLocalStorage.getItem("privileges");
  // Search Filter OnChange from input field
  const [filterSubProject, setFilterSubProject] = useState([]);

  // OnCHnage Event to get input value
  const onChangeHandler = (event) => {
    if (event.target.value === "") {
      setFilterSubProject(props.rawSubProjectData);
      return;
    }
    let filterSubProjectData = props.rawSubProjectData.filter(
      (item) =>
        item.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
        item.project.project_name
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
    );
    setFilterSubProject(filterSubProjectData);
  };

  // Delete Function : delete the specific id
  const deleteProject = (id) => {
    var config = {
      method: "delete",
      url: VARIABLES.url + `/api-app/subproject/${id}`,
      headers: {},
    };
    axios(config)
      .then((response) => {
        Swal.fire({
          icon: "success",
          text: "Sub Project Deleted Successfully!",
        });
        props.setRawSubProjectData(
          props.rawSubProjectData.filter((user) => {
            return user.id !== id;
          })
        );
      })
      .catch((error) => {
        Swal.fire({
          icon: "warning",
          text: "Failed to delete Sub Project!",
        });
        console.log(error);
      });
  };

  const deleteProjectAlert = (id) => {
    Swal.fire({
      title: "Are you sure? You will not be able to recover this project!",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteProject(id);
      }
    });
  };

  useEffect(() => {
    setFilterSubProject(props.rawSubProjectData);
  }, [props.rawSubProjectData]);
  return (
    <>
      <div className="row align-item-center">
        <div className="col-md-12">
          <div className="card mb-3">
            <div className="card-body">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  <i className="fa fa-search" aria-hidden="true"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by Project / Sub-Project Name"
                  onChange={onChangeHandler}
                />
              </div>
              {props.isLoading ? (
                <div className="card-body">Loading...</div>
              ) : (
                <div className="card-body basic-custome-color">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Sr. No</th>
                          <th>Project</th>
                          <th>Sub-Project</th>
                          {["edit_sub_project", "delete_sub_project"].some(
                            (privilege) => privileges.includes(privilege)
                          ) ? (
                            <th>Action</th>
                          ) : (
                            ""
                          )}
                        </tr>
                      </thead>
                      {filterSubProject === 0 && (
                        <tbody>
                          <tr>
                            <td></td>
                            <td>No data found</td>
                            <td></td>
                          </tr>
                        </tbody>
                      )}
                      {filterSubProject.length > 0 && (
                        <tbody>
                          {filterSubProject.map((task, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  <span className="fw-bold"> {index + 1} </span>
                                </td>
                                <td>
                                  <span className="ms-1">
                                    {" "}
                                    {task.project.project_name}{" "}
                                  </span>
                                </td>
                                <td> {task.name} </td>
                                <td>
                                  <div
                                    className="btn-group"
                                    role="group"
                                    aria-label="Basic outlined example"
                                  >
                                    {privileges.includes("edit_sub_project") ? (
                                      <button
                                        type="button"
                                        className="btn btn-outline-secondary"
                                        data-bs-toggle="modal"
                                        data-bs-target="#editsubproject"
                                        onClick={() =>
                                          props.editSubProjectData(task.id)
                                        }
                                      >
                                        <i className="icofont-edit text-success"></i>
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                    {privileges.includes(
                                      "delete_sub_project"
                                    ) ? (
                                      <button
                                        type="button"
                                        className="btn btn-outline-secondary deleterow"
                                        onClick={() =>
                                          deleteProjectAlert(task.id)
                                        }
                                      >
                                        <i className="icofont-ui-delete text-danger"></i>
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubProjectTable;
