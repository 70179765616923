import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm, useFormState } from "react-hook-form";
import Select from "react-select";
import { ProjectContext } from "../../context/ProjectContext/ProjectContextProvider.js";
import { UsersContext } from "../../context/UsersContext/UsersContextProvider.js";
import { SubCompanyContext } from "../../context/SubCompanyContext/SubCompanyContextProvider.js";
import { VARIABLES } from "../../Constants.js";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";
import { DropdownAPIsContext } from "../../context/DropdownAPIsContext/DropdownAPIsContextProvider.js";
import ReactPaginate from "react-paginate";

function ProjectBulkEdit() {
  const [filterProject, setFilterProject] = useState([]);
  const [projectManagement, setProjectManagement] = useState([]);
  const [projectCategory, setProjectCategory] = useState([]);
  const [subcompany, setSubcompany] = useState([]);
  const privileges = secureLocalStorage.getItem("privileges");
  const {
    projectData,
    apiGetProjects,
    noPages,
    currentPage,
    entire,
    setEntries,
    isProjectLoading,
  } = useContext(ProjectContext);
  const {
    projectManagementType,
    projectCategory: projectCategoryDropdown,
    subcompany: subcompanyDropdown,
  } = useContext(DropdownAPIsContext);
  const [isLoading, setIsLoading] = useState(true);
  const { users: projectIncharge } = useContext(UsersContext);
  const { subCompany: subDivision } = useContext(SubCompanyContext);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const { isDirty, isValid, dirtyFields } = useFormState({
    control,
  });

  const onChangeHandler = (event) => {
    if (event.target.value === "") {
      setFilterProject(projectData);
      return;
    }
    let filterProjectData = projectData.filter(
      (item) =>
        item.project_name
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        item.project_incharge.username
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
    );
    setFilterProject(filterProjectData);
  };

  const onSubmit = (data) => {
    const matchedData = {};
    for (const field in dirtyFields) {
      matchedData[field] = data[field];
    }
    let result = [];
    Object.keys(matchedData).map((pair) => {
      const key = pair.split("-")[0];
      const id = parseInt(pair.split("-")[1]);
      const tempObj = result.filter((obj) => obj?.project_id === id)[0] || null;
      if (tempObj === null) {
        result.push({
          project_id: id,
          [key]:
            key === "project_name"
              ? matchedData[pair]
              : matchedData[pair].value,
        });
      } else {
        result = result.filter((prev) => prev.project_id !== id);
        result.push({
          ...tempObj,
          [key]:
            key === "project_name"
              ? matchedData[pair]
              : matchedData[pair].value,
        });
      }
    });
    let patchData = JSON.stringify(result);

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/api-app/bulk-update-project`,
      headers: {
        "Content-Type": "application/json",
      },
      data: patchData,
    };
    setIsLoading(true);
    axios
      .request(config)
      .then((response) => {
        Swal.fire({
          title: "Success!",
          text: "Projects Updated successfully",
          icon: "success",
          confirmButtonText: "OK",
        });
        apiGetProjects();
      })
      .catch((error) => {
        console.log(error);
        console.log("project set false error");
        setIsLoading(false);
        Swal.fire({
          title: "Error",
          text: "Failed to Update Projects",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  useEffect(() => {
    if (projectManagementType.length !== 0) {
      setProjectManagement(
        projectManagementType.map((projectType) => {
          return {
            value: projectType.name,
            label: projectType.name,
          };
        })
      );
    }
  }, [projectManagementType]);

  useEffect(() => {
    if (projectCategoryDropdown.length !== 0) {
      setProjectCategory(
        projectCategoryDropdown.map((category) => {
          return {
            value: category.id,
            label: category.name,
          };
        })
      );
    }
  }, [projectCategoryDropdown]);

  useEffect(() => {
    if (subcompanyDropdown.length !== 0) {
      setSubcompany(
        subcompanyDropdown.map((sub) => {
          return {
            value: sub.id,
            label: sub.name,
          };
        })
      );
    }
  }, [subcompanyDropdown]);

  useEffect(() => {
    setFilterProject(projectData);
  }, [projectData]);

  useEffect(() => {
    if (isProjectLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isProjectLoading]);

  useEffect(() => {
    if (projectData.length !== 0 && projectManagement.length !== 0) {
      const obj = {};
      projectData.map((project) => {
        obj[`project_name-${project.id}`] = project.project_name;
        obj[`project_incharge_id-${project.id}`] = {
          value: project?.project_incharge.id,
          label: project?.project_incharge.username,
        };
        obj[`subcompany_id-${project.id}`] = {
          value: project?.subcompany?.id,
          label: project?.subcompany?.name,
        };
        obj[`category_id-${project.id}`] = {
          value: project?.category?.id,
          label: project?.category?.name,
        };
        obj[`project_management_type-${project.id}`] = {
          value: project?.project_management_type,
          label: project?.project_management_type,
        };
      });
      setIsLoading(false);
      reset(obj);
    }
  }, [projectData, projectManagement]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="row align-items-center">
          <div className="border-0 mb-4">
            <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
              <h5 className="fw-bold py-3 mb-0">Project Spreadsheet</h5>
              {privileges.includes("edit_project") ? (
                <div className="d-flex py-2 project-tab sub-project-tab flex-wrap w-sm-100">
                  <button
                    type="submit"
                    className={`btn btn-dark w-sm-100 ${
                      isDirty ? "" : "disabled"
                    }`}
                  >
                    Save
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="row align-item-center">
          <div className="col-md-12">
            <div className="card mb-3">
              <div className="card-header py-3 bg-transparent border-bottom-0">
                <div className="filter">
                  <p className="search-title">What are you looking for ?</p>
                  <div className="input-group mb-3">
                    <span className="input-group-text" id="basic-addon1">
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </span>
                    <input
                      type="text"
                      placeholder="Search by name..."
                      className="form-control"
                      onChange={onChangeHandler}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card mb-3">
              {isLoading ? (
                <div className="card-body">Loading...</div>
              ) : (
                <div className="card-body basic-custome-color">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Sr. No</th>
                          <th>Project Name</th>
                          <th>Project Incharge</th>
                          <th>SubDivision</th>
                          <th>Project Category</th>
                          <th>Corrected Project</th>
                          {/* <th>Status</th> */}
                        </tr>
                      </thead>
                      {filterProject.length === 0 && (
                        <tbody>
                          <tr>
                            <td></td>
                            <td>No data found</td>
                            <td></td>
                          </tr>
                        </tbody>
                      )}
                      {filterProject.length > 0 && (
                        <tbody>
                          {filterProject.map((project, index) => {
                            // if (index < 10) {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    {...register(`project_name-${project.id}`)}
                                    style={{
                                      border: Object.keys(dirtyFields).includes(
                                        `project_name-${project.id}`
                                      )
                                        ? "2px solid gold"
                                        : "",
                                    }}
                                  />
                                </td>
                                <td>
                                  <Controller
                                    id={`project_incharge_id-${project.id}`}
                                    name={`project_incharge_id-${project.id}`}
                                    control={control}
                                    render={({ field }) => (
                                      <Select
                                        styles={{
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            border: Object.keys(
                                              dirtyFields
                                            ).includes(
                                              `project_incharge_id-${project.id}`
                                            )
                                              ? "2px solid gold"
                                              : "1px solid gray",
                                            boxShadow: "none",
                                            "&:hover": {
                                              border: Object.keys(
                                                dirtyFields
                                              ).includes(
                                                `project_incharge_id-${project.id}`
                                              )
                                                ? "2px solid gold"
                                                : "1px solid gray",
                                            },
                                          }),
                                        }}
                                        {...field}
                                        options={projectIncharge}
                                        isClearable={true}
                                      />
                                    )}
                                  />
                                </td>
                                <td>
                                  <Controller
                                    id={`subcompany_id-${project.id}`}
                                    name={`subcompany_id-${project.id}`}
                                    className="form-select"
                                    control={control}
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        styles={{
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            border: Object.keys(
                                              dirtyFields
                                            ).includes(
                                              `subcompany_id-${project.id}`
                                            )
                                              ? "2px solid gold"
                                              : "1px solid gray",
                                            boxShadow: "none",
                                            "&:hover": {
                                              border: Object.keys(
                                                dirtyFields
                                              ).includes(
                                                `subcompany_id-${project.id}`
                                              )
                                                ? "2px solid gold"
                                                : "1px solid gray",
                                            },
                                          }),
                                        }}
                                        options={subDivision}
                                        isClearable={true}
                                      />
                                    )}
                                  />
                                </td>
                                <td>
                                  <Controller
                                    id={`category_id-${project.id}`}
                                    name={`category_id-${project.id}`}
                                    className="form-select"
                                    control={control}
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        styles={{
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            border: Object.keys(
                                              dirtyFields
                                            ).includes(
                                              `category_id-${project.id}`
                                            )
                                              ? "2px solid gold"
                                              : "1px solid gray",
                                            boxShadow: "none",
                                            "&:hover": {
                                              border: Object.keys(
                                                dirtyFields
                                              ).includes(
                                                `category_id-${project.id}`
                                              )
                                                ? "2px solid gold"
                                                : "1px solid gray",
                                            },
                                          }),
                                        }}
                                        options={projectCategory}
                                        isClearable={true}
                                      />
                                    )}
                                  />
                                </td>
                                <td>
                                  <Controller
                                    id={`project_management_type-${project.id}`}
                                    name={`project_management_type-${project.id}`}
                                    className="form-select"
                                    control={control}
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        styles={{
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            border: Object.keys(
                                              dirtyFields
                                            ).includes(
                                              `project_management_type-${project.id}`
                                            )
                                              ? "2px solid gold"
                                              : "1px solid gray",
                                            boxShadow: "none",
                                            "&:hover": {
                                              border: Object.keys(
                                                dirtyFields
                                              ).includes(
                                                `project_management_type-${project.id}`
                                              )
                                                ? "2px solid gold"
                                                : "1px solid gray",
                                            },
                                          }),
                                        }}
                                        options={projectManagement}
                                        isClearable={true}
                                      />
                                    )}
                                  />
                                </td>
                              </tr>
                            );
                            // }
                          })}
                        </tbody>
                      )}
                    </table>
                    <div className="d-flex justify-content-center justify-content-md-between mt-3">
                      <ReactPaginate
                        key={3}
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        pageCount={noPages}
                        marginPagesDisplayed={2}
                        // pageRangeDisplayed={3}
                        onPageChange={(selectedPage) => {
                          // setCurrentPage(selectedPage.selected + 1);
                          apiGetProjects(selectedPage.selected + 1, entire);
                        }}
                        containerClassName={
                          "pagination justify-content-center justify-content-md-start"
                        }
                        pageClassName={"paginate_button page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"paginate_button page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                      />

                      <div className="d-flex justify-content-center align-items-center">
                        <div>
                          Total entries:{" "}
                          <span className="fw-bold me-3">
                            {/* {entryCount === null ? 0 : entryCount} */}
                            {entire}
                          </span>
                        </div>
                        <div className="me-3">No of entries per page:</div>
                        <div>
                          <select
                            className="form-select"
                            value={entire}
                            onChange={(e) => {
                              setEntries(e.target.value);
                              apiGetProjects(currentPage, e.target.value);
                            }}
                          >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default ProjectBulkEdit;
