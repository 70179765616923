import React, { useEffect, useState } from "react";
import { Controller, useForm, useFormState, useWatch } from "react-hook-form";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../Constants";
import axios from "axios";

function CorrectionForm({ id, timesheets, list, setList }) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
    getValues,
  } = useForm();
  const { isValid, dirtyFields } = useFormState({
    control,
  });
  const timesheet = useWatch({ control, name: "timesheet", defaultValue: [] });
  const [loading, setLoading] = useState(false);
  const onSubmit = (data) => {
    const hoursArray = Object.keys(data)
      .map((key) => {
        if (key !== "timesheet") {
          return [key.split(".")[1], data[key]];
        }
      })
      .filter((e) => e !== undefined);
    const response = {
      corrected_timesheet_details: [
        ...hoursArray
          .map((corrected) => {
            if (parseInt(corrected[0]) !== 0) {
              return {
                user: id,
                lead: parseInt(secureLocalStorage.getItem("userID")),
                original_timesheet_entry: parseInt(corrected[0]),
                corrected_hours: parseInt(corrected[1]),
                created_by_id: parseInt(secureLocalStorage.getItem("userID")),
                updated_by_id: parseInt(secureLocalStorage.getItem("userID")),
              };
            }
          })
          .filter((e) => e !== undefined),
      ],
    };
    let res = JSON.stringify(response);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/api-app/corrected-timesheet`,
      headers: {
        "Content-Type": "application/json",
      },
      data: res,
    };
    setLoading(true);
    axios
      .request(config)
      .then((response) => {
        setList(
          list.map((item) => {
            if (item.userId === id) {
              return {
                ...item,
                completed: true,
              };
            }
            return item;
          })
        );
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const calculateDateSumStatic = (date) => {
    let sum = 0;
    timesheets.forEach((timesheet) => {
      if (timesheet.date === date) {
        sum += parseFloat(timesheet.hours) || 0;
      }
    });
    return sum;
  };

  const calculateDateSum = (date) => {
    let sum = 0;
    timesheets.forEach((timesheet) => {
      if (timesheet.date === date) {
        sum += parseFloat(watch(`timesheet.${timesheet.id}`)) || 0;
      }
    });
    return sum;
  };

  useEffect(() => {
    if (timesheets.length !== 0) {
      const obj = {};
      timesheets.map((timesheet) => {
        obj[`timesheet.${timesheet.id}`] = timesheet.hours;
      });
      reset(obj);
    }
  }, [timesheets]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <div className="row align-items-center">
        <div className="border-0">
          <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            <div className="d-flex py-2 project-tab sub-project-tab flex-wrap w-sm-100 ms-auto">
              {loading ? (
                <button
                  className="btn btn-primary"
                  type="button"
                  style={{ minWidth: "54.2px" }}
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden" role="status">
                    Loading...
                  </span>
                </button>
              ) : (
                <button
                  type="submit"
                  className={`btn btn-dark w-sm-100 ${
                    isValid ? "" : "disabled"
                  }`}
                >
                  Save
                </button>
              )}
            </div>
            {/* ) : (
                ""
              )} */}
          </div>
        </div>
      </div>
      <div className="card mb-3">
        <div className="card-body basic-custome-color">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Task Id</th>
                  <th>Project</th>
                  <th>Hours</th>
                  <th>Corrected Hours</th>
                </tr>
              </thead>
              <tbody>
                {timesheets.map((timesheet, index) => {
                  let date = "";
                  let showTotal = false;
                  let total = 0;
                  let totalStitic = 0;
                  if (index === 0) {
                    date = timesheet.date;
                  } else if (index > 0) {
                    if (timesheet.date !== timesheets[index - 1].date) {
                      date = timesheet.date;
                    }
                  }
                  if (index < timesheets.length - 1) {
                    if (timesheet.date !== timesheets[index + 1].date) {
                      showTotal = true;
                      total = calculateDateSum(timesheet.date);
                      totalStitic = calculateDateSumStatic(timesheet.date);
                    }
                  } else {
                    showTotal = true;
                    total = calculateDateSum(timesheet.date);
                    totalStitic = calculateDateSumStatic(timesheet.date);
                  }
                  return (
                    <>
                      {timesheet.task !== "missed" &&
                      timesheet.task !== "holiday" ? (
                        <tr>
                          <td className="d-flex align-items-start justify-content-start mb-5">
                            <span style={{ fontWeight: "bolder" }}>{date}</span>
                          </td>
                          <td>
                            <span
                              className={`ms-1 ${
                                timesheet.ismissed ? "text-danger" : ""
                              }`}
                            >
                              {timesheet.task?.card_identification_number} /{" "}
                              {timesheet.task?.card_title}
                            </span>
                          </td>
                          <td>
                            <span className="ms-1">{timesheet.project}</span>
                          </td>
                          <td>
                            <span className="ms-1">{timesheet.hours}</span>
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              id="timesheet"
                              {...register(`timesheet.${timesheet.id}`, {
                                required: true,
                              })}
                            />
                            {errors[`timesheet.${timesheet.id}`] && (
                              <p className="required_field">
                                Field is required
                              </p>
                            )}
                          </td>
                        </tr>
                      ) : timesheet.task !== "holiday" ? (
                        <tr>
                          <td className="d-flex align-items-start justify-content-start mb-5">
                            <span style={{ fontWeight: "bolder" }}>
                              {timesheet.date}
                            </span>
                          </td>
                          <td>
                            <span className="ms-1">Timesheet Not Filled</span>
                          </td>
                          <td>
                            <span className="ms-1"></span>
                          </td>
                          <td>
                            <span className="ms-1"></span>
                          </td>
                          <td></td>
                        </tr>
                      ) : (
                        <tr>
                          <td className="d-flex align-items-start justify-content-start mb-5">
                            <span style={{ fontWeight: "bolder" }}>
                              {timesheet.date}
                            </span>
                          </td>
                          <td>
                            <span className="ms-1">Holiday</span>
                          </td>
                          <td>
                            <span className="ms-1"></span>
                          </td>
                          <td>
                            <span className="ms-1"></span>
                          </td>
                          <td></td>
                        </tr>
                      )}

                      {showTotal &&
                        timesheet.task !== "missed" &&
                        timesheet.task !== "holiday" && (
                          <tr style={{ backgroundColor: "#ededed" }}>
                            <td className="d-flex align-items-start justify-content-start mb-5"></td>
                            <td></td>
                            <td></td>
                            <td style={{ fontWeight: "bolder", color: "red" }}>
                              Total: {totalStitic} hrs
                            </td>
                            <td style={{ fontWeight: "bolder", color: "red" }}>
                              Total: {total} hrs
                            </td>
                          </tr>
                        )}
                    </>
                  );
                })}
              </tbody>
            </table>
            {/* <div className="d-flex justify-content-center justify-content-md-between mt-3">
              <ReactPaginate
                key={3}
                previousLabel={"previous"}
                nextLabel={"next"}
                breakLabel={"..."}
                pageCount={noPages}
                initialPage={currentPage - 1}
                marginPagesDisplayed={2}
                disableInitialCallback={true}
                // pageRangeDisplayed={3}
                onPageChange={(selectedPage) => {
                  console.log("Selected Page:", selectedPage);
                  // setCurrentPage(selectedPage.selected + 1);
                  apiGetRawUserManagement(selectedPage.selected + 1, entire);
                }}
                containerClassName={
                  "pagination justify-content-center justify-content-md-start"
                }
                pageClassName={"paginate_button page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"paginate_button page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
              <div className="d-flex justify-content-center align-items-center">
                <div className="me-3">No of entries per page:</div>
                <div>
                  <select
                    className="form-select"
                    value={entire}
                    onChange={(e) => {
                      setEntries(e.target.value);
                      apiGetRawUserManagement(currentPage, e.target.value);
                    }}
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </form>
  );
}

export default CorrectionForm;
