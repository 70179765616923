import React, { useContext, useEffect } from "react";
import { useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { ProjectContext } from "../../context/ProjectContext/ProjectContextProvider";
import { TaskManagementContext } from "../../context/TaskManagementContext/TaskManagementContextProvider";

function DepartmentsTable(props) {
  const privileges = secureLocalStorage.getItem("privileges");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  // SearchFilterData filter the Data by Search Functionality
  const [filterProject, setFilterProject] = useState([]);
  const {
    noPagesDoc,
    currentPageDoc,
    entireDoc,
    setEntriesDoc,
    apiGetDepartments,
    isDepartmentsLoading,
  } = useContext(ProjectContext);

  const { setCurrentBoardId } = useContext(TaskManagementContext);

  // OnCHnage Event to get input value
  const onChangeHandler = (event) => {
    if (event.target.value === "") {
      setFilterProject(props.rawProjectData);
      return;
    }
    let filterProjectData = props.rawProjectData.filter(
      (item) =>
        item.project_name
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        item.project_incharge.username
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
    );
    setFilterProject(filterProjectData);
  };

  const handleClick = (api) => {
    setCurrentBoardId({ ...api });
    navigate(
      `/${api.project_name.split(" ").join("").toLowerCase()}_${api.id}`
    );
  };

  useEffect(() => {
    if (isDepartmentsLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  });

  useEffect(() => {
    setFilterProject(props.rawProjectData);
    setIsLoading(false);
  }, [props.rawProjectData]);

  return (
    <>
      <div className="row g-3 mb-3 row-deck">
        <div className="col-md-12">
          <div className="card mb-3">
            <div className="card-body">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  <i className="fa fa-search" aria-hidden="true"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by Department Name/Incharge Name"
                  onChange={onChangeHandler}
                />
              </div>
              {isLoading ? (
                <div className="card-body">Loading...</div>
              ) : (
                <>
                  <div className="table-responsive">
                    <table
                      id="myProjectTable"
                      className="table table-hover align-middle mb-0"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th>Department Name</th>
                          <th>Department Incharge</th>
                          {privileges.includes("department_document") ? (
                            <th>Document</th>
                          ) : (
                            ""
                          )}
                          {privileges.includes("view_department_board") ? (
                            <th>Board</th>
                          ) : (
                            ""
                          )}
                        </tr>
                      </thead>
                      {filterProject.length === 0 && (
                        <tbody>
                          <tr>
                            <td></td>
                            <td>No data found</td>
                            <td></td>
                          </tr>
                        </tbody>
                      )}
                      {filterProject.length > 0 && (
                        <tbody>
                          {filterProject.map((api, index) => {
                            return (
                              <tr
                                key={index}
                                style={{
                                  backgroundColor:
                                    api.project_flag_status === "Red"
                                      ? "#ff4a4a"
                                      : api.project_flag_status === "Green"
                                      ? "#65B741"
                                      : api.project_flag_status === "Gray"
                                      ? "#B4B4B8"
                                      : api.project_flag_status === "Yellow"
                                      ? "#F4CE14"
                                      : "white",
                                }}
                              >
                                <td>
                                  <a
                                    href="javascript:void(0)"
                                    onClick={() => {
                                      // changeSidebar("project_doc", api);
                                      navigate("/departmentdoc", {
                                        state: {
                                          data: api,
                                        },
                                      });
                                    }}
                                    style={{
                                      color:
                                        api.project_flag_status !== "White"
                                          ? "white"
                                          : "black",
                                    }}
                                  >
                                    {api.project_name}
                                  </a>
                                </td>
                                <td>
                                  <a
                                    href="#"
                                    style={{
                                      color:
                                        api.project_flag_status !== "White"
                                          ? "white"
                                          : "black",
                                    }}
                                  >
                                    {api.project_incharge.username
                                      .charAt(0)
                                      .toUpperCase() +
                                      api.project_incharge.username.slice(1)}
                                  </a>
                                </td>
                                {privileges.includes("department_document") ? (
                                  <td>
                                    <button
                                      type="button"
                                      className="btn btn-primary btn-sm"
                                      onClick={() => {
                                        // changeSidebar("project_doc", api);
                                        navigate("/departmentdoc", {
                                          state: {
                                            data: api,
                                          },
                                        });
                                      }}
                                    >
                                      Document
                                    </button>
                                  </td>
                                ) : (
                                  ""
                                )}
                                {privileges.includes(
                                  "view_department_board"
                                ) ? (
                                  <td>
                                    <button
                                      type="button"
                                      className="btn btn-primary btn-sm"
                                      onClick={() => handleClick(api)}
                                    >
                                      View
                                    </button>
                                  </td>
                                ) : (
                                  ""
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                      )}
                    </table>
                  </div>
                  <div className="d-flex justify-content-center justify-content-md-between mt-3">
                    <ReactPaginate
                      key={3}
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={noPagesDoc}
                      initialPage={currentPageDoc - 1}
                      marginPagesDisplayed={2}
                      disableInitialCallback={true}
                      // pageRangeDisplayed={3}
                      onPageChange={(selectedPage) => {
                        // setCurrentPage(selectedPage.selected + 1);
                        apiGetDepartments(selectedPage.selected + 1, entireDoc);
                      }}
                      containerClassName={
                        "pagination justify-content-center justify-content-md-start"
                      }
                      pageClassName={"paginate_button page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"paginate_button page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />

                    <div className="d-flex justify-content-center align-items-center">
                      <div>
                        Total entries:{" "}
                        <span className="fw-bold me-3">
                          {/* {entryCount === null ? 0 : entryCount} */}
                          {entireDoc}
                        </span>
                      </div>
                      <div className="me-3">No of entries per page:</div>
                      <div>
                        <select
                          className="form-select"
                          value={entireDoc}
                          onChange={(e) => {
                            setEntriesDoc(e.target.value);
                            apiGetDepartments(currentPageDoc, e.target.value);
                          }}
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DepartmentsTable;
