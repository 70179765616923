import React, { useContext, useState, useEffect } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { ListContext } from "../../../context/ListContextProvider";
import { DrawerContext } from "../../../context/DrawerContextProvider";
import PriorityListDropdown from "./dropdown/PriorityListDropdown";
import CalendarDropdown from "./dropdown/CalendarDropdown";
import "./kanbanhome.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { VARIABLES } from "../../../Constants";
import moment from "moment/moment";
import UsersProfilesDropdown from "./dropdown/UsersProfilesDropdown";
// import TagsDropdown from "./dropdown/TagsDropdown";

function Card({ item, listId, cursor }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: item.id,
    data: {
      type: item.type,
      item: item,
      parent: listId,
    },
  });
  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
    cursor: cursor && cursor,
    minWidth: "270px",
    maxWidth: "270px",
  };
  const { setNewValue, view, deleteRegularCard } = useContext(ListContext);
  const { toggleDrawer } = useContext(DrawerContext);
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(item.value);
  const [showDel, setShowDel] = useState(false);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {view !== "lead" ? item?.assigned_by : item.assigned_to}
    </Tooltip>
  );

  const handleBlur = () => {
    if (item.value !== value) {
      setNewValue(listId, value, item);
    }
    setEdit(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setEdit(false);
    if (item.value !== value) {
      setNewValue(listId, value, item.id);
    }
  };
  useEffect(() => {
    setValue(item.value);
  }, [item.value]);

  return (
    <>
      {item?.value && (
        <div
          className="card mb-3 mx-1 bg-light rounded-1"
          style={style}
          {...attributes}
          {...listeners}
          ref={setNodeRef}
          onMouseEnter={() => setShowDel(true)}
          onMouseLeave={() => setShowDel(false)}
        >
          <div
            className={`card-body rounded-1 px-3 py-0 position-relative ${
              isDragging ? "opacity-50" : ""
            } `}
            style={{
              boxShadow: isDragging
                ? "rgba(0, 0, 0, 0.3) 2.4px 2.4px 3.2px inset, rgba(255, 255, 255, 0.3) -2.4px -2.4px 3.2px inset"
                : "",
              border: cursor === "move" ? "1px solid gray" : "none",
            }}
            onClick={() => {
              if (true) {
                toggleDrawer(listId, item);
              }
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              {/* <div style={{ width: "30px" }}>
            <CircularProgressbar
              value={item.progress}
              strokeWidth={50}
              styles={buildStyles({
                strokeLinecap: "butt",
                pathColor: `tomato`,
              })}
            />
          </div> */}
              <>
                {/* {edit ? (
              <div style={{ height: "50px" }}>
                <form onSubmit={handleSubmit} className="mt-0 mx-2 mb-2 p-0">
                  <input
                    type="text"
                    className="form-control form-control-sm mt-2"
                    style={{
                      background: "transparent",
                      height: "30px",
                      paddingLeft: "11px",
                      marginTop: "5px",
                      fontSize: "1em",
                      color: "black",
                      border: "none",
                    }}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    id="task"
                    autoFocus
                    onBlur={handleBlur}
                  />
                </form>
              </div>
            ) : ( */}
                <div
                  className="mt-3"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "8px",
                    marginRight: "20px",
                  }}
                >
                  <p
                    onClick={() => setEdit(true)}
                    style={{
                      cursor: "pointer",
                      fontSize: "1em",
                    }}
                  >
                    {item.value}
                  </p>
                </div>
                {/* )} */}
              </>
            </div>
            <div className="ps-1 pt-1 row">
              {/* <div className="col-2 text-center mb-3 d-flex align-items-center justify-content-center">
            <span
              className="badge rounded position-relative text-wrap"
              style={{
                backgroundColor:
                  item.priority === "L"
                    ? "green"
                    : item.priority === "M"
                    ? "orange"
                    : "red",
              }}
            >
              {item.priority}
            </span>
          </div> */}

              {/* {view === "lead" && (
            <div className="col-4 ps-3 text-start">
              <span
                className="text-nowrap"
                style={{ cursor: "pointer", color: "red" }}
              >
                {item?.assigned_to}
              </span>
              
            </div>
          )} */}
              <div className="col-12 ps-3 text-start d-flex align-items-center justify-content-between">
                <p>
                  <span
                    className="badge bg-warning"
                    style={{ fontSize: "10px" }}
                  >
                    <span className="me-2">
                      {moment(item?.start_date, "YYYY-MM-DD").format(
                        "DD-MM-YYYY"
                      )}
                    </span>
                    -
                    <span className="ms-2">
                      {moment(item?.end_date, "YYYY-MM-DD").format(
                        "DD-MM-YYYY"
                      )}
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </div>
          {showDel && item.card_type === "regularcard" ? (
            <i
              className="icofont-trash position-absolute top-0 end-0 mt-3 me-3 text-danger z-1"
              style={{ fontSize: "1.3em" }}
              onClick={() => {
                deleteRegularCard(item.card_identification);
              }}
            ></i>
          ) : (
            <span
              className="badge rounded position-absolute top-0 end-0 mt-3 me-3 text-wrap"
              style={{
                color: "white",
                backgroundColor:
                  item.card_type === "projectcard"
                    ? "purple"
                    : item.card_type === "regularcard"
                    ? "skyblue"
                    : item.card_type === "structuralcard"
                    ? "pink"
                    : item.card_type === "meetingcard"
                    ? "#06D001"
                    : "tomato",
                fontSize: ".7em",
              }}
            >
              {item.card_type === "projectcard"
                ? "PC"
                : item.card_type === "regularcard"
                ? "RC"
                : item.card_type === "structuralcard"
                ? "SC"
                : item.card_type === "meetingcard"
                ? "MC"
                : "TC"}
            </span>
          )}
          <div className="position-absolute bottom-0 end-0 me-4 mb-2 ">
            <UsersProfilesDropdown item={item} />
          </div>
        </div>
      )}
    </>
  );
}

export default Card;
