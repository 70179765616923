import React, { useEffect, useState } from "react";
import axios from "axios";
import { Controller, useForm, useFormState } from "react-hook-form";
import Select from "react-select";
import { VARIABLES } from "../../Constants";
import Swal from "sweetalert2";
import secureLocalStorage from "react-secure-storage";

const Gender = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Other", value: "Other" },
];

const UserManagementEdit = (props) => {
  const userID = secureLocalStorage.getItem("userID");
  // react hook form
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm();
  const { isDirty, isValid } = useFormState({
    control,
  });
  // onSubmit handler to post form details
  const onSubmit = (data) => {
    let user = new FormData();
    console.log(data);
    user.append("first_name", data.first_name);
    user.append("last_name", data.last_name);
    user.append("username", data.username);
    user.append("email", data.email);
    user.append("mobile_number", data.mobile_number);
    user.append("organisation", data.organization.value);
    user.append("gender", data.gender.value);
    user.append("employee_id", data.employee_id);
    user.append("level_role_id", data.level_role_id.value);
    user.append(
      "technology_role_id",
      data.technology_role_id.map((role) => role.value)
    );
    user.append("designation", data.designation);
    user.append("card_number", data.card_number);
    user.append("epc_no", data.epc_no);
    user.append("updated_by_id", userID);
    user.append("dob", data.dob);
    user.append("profile_img", data.profile_img);
    var config = {
      method: "patch",
      maxBodyLength: Infinity,
      url:
        VARIABLES.url + `/api-app/user/${props.editedUserManagement.user.id}`,
      headers: {
        "Content-Type": "application/octet-stream",
      },
      data: user,
    };
    axios(config)
      .then((response) => {
        Swal.fire({
          title: "Success!",
          text: "User Management Updated Successfully",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => props.apiGetRawUserManagement());
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Error",
          text: "Failed to Update User Management",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
    reset();
  };

  // TODO
  useEffect(() => {
    if (props.editedUserManagement) {
      var defaultValues = {};
      defaultValues.first_name = props.editedUserManagement.user.first_name;
      defaultValues.last_name = props.editedUserManagement.user.last_name;
      defaultValues.username = props.editedUserManagement.user.username;
      defaultValues.email = props.editedUserManagement.user.email;
      defaultValues.mobile_number = props.editedUserManagement.mobile_number;
      if (props.editedUserManagement.designation !== null) {
        defaultValues.designation = props.editedUserManagement.designation;
      }

      if (props.editedUserManagement.organisation !== null) {
        const organizationSelection =
          props.editedUserManagement.organisation.map((org) => {
            return {
              value: org.id,
              label: org.org_name,
            };
          })[0];
        defaultValues.organization = organizationSelection;
      }

      if (props.editedUserManagement.technology_role !== null) {
        const roleTechnologySelection =
          props.editedUserManagement.technology_role.map((item) => {
            return {
              value: item.id,
              label: item.name,
            };
          });
        defaultValues.technology_role_id = roleTechnologySelection;
      }

      if (props.editedUserManagement.level_role !== null) {
        const roleLevelSelection = {
          value: props.editedUserManagement.level_role.id,
          label: props.editedUserManagement.level_role.name,
        };
        defaultValues.level_role_id = roleLevelSelection;
      }

      if (props.editedUserManagement !== null) {
        const genderSelection = {
          value: props.editedUserManagement.gender,
          label: props.editedUserManagement.gender,
        };
        defaultValues.gender = genderSelection;
      }

      if (props.editedUserManagement.employee_id !== null) {
        defaultValues.employee_id = props.editedUserManagement.employee_id;
      }
      if (props.editedUserManagement.epc_no !== null) {
        defaultValues.epc_no = props.editedUserManagement.epc_no;
      }
      if (props.editedUserManagement.card_number !== null) {
        defaultValues.card_number = props.editedUserManagement.card_number;
      }
      if (props.editedUserManagement.dob !== null) {
        defaultValues.dob = props.editedUserManagement.dob;
      }
      if (props.editedUserManagement.profile_img) {
        defaultValues.profile_img = props.editedUserManagement.profile_img;
      }

      reset(defaultValues);
    }
  }, [props.editedUserManagement]);

  const getResetValues = () => {
    reset();
  };
  return (
    <>
      <div
        className="modal fade"
        id="editUserManagement"
        tabIndex="-1"
        data-bs-backdrop="static"
        aria-labelledby="depaddLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title fw-bold" id="depaddLabel">
                {" "}
                Edit User{" "}
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => getResetValues()}
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="mb-3">
                  <label className="form-label">
                    First Name <span className="required_field">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter first name"
                    {...register("first_name", { required: true })}
                  />
                  {errors.first_name && (
                    <span className="required_field">
                      First name is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Last Name <span className="required_field">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter last name"
                    {...register("last_name", { required: true })}
                  />
                  {errors.last_name && (
                    <span className="required_field">
                      Last name is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Mobile Number <span className="required_field">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Mobile number"
                    {...register("mobile_number", { required: true })}
                  />
                  {errors.mobile_number && (
                    <span className="required_field">
                      Mobile numberis required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Email Address <span className="required_field">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter Email Address"
                    {...register("email", { required: true })}
                  />
                  {errors.email && (
                    <span className="required_field">
                      Email Address is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Username<span className="required_field">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter username"
                    {...register("username", { required: true })}
                  />
                  {errors.username && (
                    <span className="required_field">Username is required</span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Designation<span className="required_field">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your designation"
                    {...register("designation", { required: true })}
                  />
                  {errors.designation && (
                    <span className="required_field">
                      Designation is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Gender <span className="required_field">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select {...field} options={Gender} isClearable={true} />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="gender"
                    control={control}
                  />
                  {errors.gender && (
                    <span className="required_field">Gender is required</span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Level Role <span className="required_field">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={props.levelRole}
                        isClearable={true}
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="level_role_id"
                    control={control}
                  />
                  {errors.level_role_id && (
                    <span className="required_field">
                      Level Role is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Technology Role <span className="required_field">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={props.technologyRole}
                        isMulti
                        isClearable={true}
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="technology_role_id"
                    control={control}
                  />
                  {errors.technology_role_id && (
                    <span className="required_field">
                      Technology Role is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Organization <span className="required_field">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={props.organizationDetails}
                        isClearable={true}
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="organization"
                    control={control}
                  />
                  {errors.organization && (
                    <span className="required_field">
                      Organization is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Employee Id<span className="required_field">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Employee Id"
                    {...register("employee_id", { required: true })}
                  />
                  {errors.employee_id && (
                    <span className="required_field">
                      Employee Id is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">EPC number</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter EPC number"
                    {...register("epc_no")}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Card number</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter EPC number"
                    {...register("card_number")}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Date of Birth<span className="required_field">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="dob"
                    {...register("dob", { required: true })}
                  />
                  {errors.dob && (
                    <span className="required_field">
                      Date of Birth is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">Profile Image</label>
                  <Controller
                    name="profile_img" // The name of the field in the form data
                    control={control}
                    // rules={{ required: true }}
                    defaultValue={null}
                    render={({ field }) => (
                      <input
                        type="file"
                        onChange={(e) => field.onChange(e.target.files[0])}
                      />
                    )}
                  />
                </div>
                <div className="modal-footer">
                  {/* <button type="submit" className="btn btn-success">Submit</button> */}
                  {isValid === true ? (
                    <button
                      type="submit"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Submit
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserManagementEdit;
