import Board from "./kanban/Board";
import CardDrawer from "./kanban/CardDrawer";
import React, { useContext, useState } from "react";
import { ListContext } from "../../context/ListContextProvider";
import AddCard from "./kanban/AddCard";
import secureLocalStorage from "react-secure-storage";
import { useLocation } from "react-router-dom";

const KanbanBoard = () => {
  const { isLoading } = useContext(ListContext);
  const [isOpen, setIsOpen] = useState(false);
  const privileges = secureLocalStorage.getItem("privileges");
  const location = useLocation();
  return (
    <>
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-start border-bottom flex-wrap">
            <h5 className="fw-bold py-3 mb-0">Kanban</h5>
            {isLoading && (
              <div className="spinner-border text-primary ms-3" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
            {privileges.includes("create_regular_card") ? (
              <div className="ms-auto">
                {location.pathname === "/kanban" && (
                  <button
                    type="button"
                    className="btn btn-dark w-sm-100"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <i className="icofont-plus-circle me-2 fs-6"></i>Create Card
                  </button>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {/* {isLoading ? (
        <div>Loading...</div>
      ) : ( */}
      <>
        <Board />
        <CardDrawer />
      </>
      {/* )} */}
      <AddCard isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default KanbanBoard;
