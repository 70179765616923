import axios from "axios";
import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../../Constants";
import moment from "moment";
import ReactPaginate from "react-paginate";

const Reporting = () => {
  const userID = secureLocalStorage.getItem("userID");
  const [isLoading, setIsloading] = useState(false);
  const [rawReportingData, setRawReportingReporting] = useState([]);
  const [noPages, setNoPages] = useState(1);
  const [entire, setEntries] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const getReportingDate = async (page_no = currentPage, entires = entire) => {
    // API call of Project Data and stores the data in "rawProjectData"
    try {
      const res = await axios.get(
        VARIABLES.url +
          `/api-app/reporting-data?employee_id=` +
          userID +
          `&page_no=${page_no}&entries=${entires}`
      );
      setNoPages(res.data.num_pages);
      setCurrentPage(res.data.current_page);
      setRawReportingReporting(res.data.results.data);
      setIsloading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (rawReportingData.length === 0) {
      setIsloading(true);
    }
  }, [rawReportingData]);

  useEffect(() => {
    getReportingDate();
  }, []);

  return (
    <>
      <div className="row align-item-center">
        <div className="col-md-12">
          <div className="card mb-3">
            {isLoading ? (
              <div className="card-body">Loading...</div>
            ) : (
              <div className="card-body basic-custome-color">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Reporting</th>
                        <th>Reporting Date</th>
                      </tr>
                    </thead>
                    {rawReportingData.length === 0 && (
                      <tbody>
                        <tr>
                          <td></td>
                          <td>No data found</td>
                          <td></td>
                        </tr>
                      </tbody>
                    )}
                    {rawReportingData.length > 0 && (
                      <tbody>
                        {rawReportingData.map((task, index) => {
                          return (
                            <tr>
                              <td>
                                <span className="fw-bold">
                                  {" "}
                                  {task.employee.username}{" "}
                                </span>
                              </td>
                              <td>
                                <span className="ms-1">
                                  {" "}
                                  {task.reporting_text}{" "}
                                </span>
                              </td>
                              <td>
                                {" "}
                                {moment(task.reporting_date).format(
                                  "MMMM Do YYYY, h:mm a"
                                )}{" "}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                  <div className="d-flex justify-content-center justify-content-md-between mt-3">
                    <ReactPaginate
                      key={3}
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={noPages}
                      initialPage={currentPage - 1}
                      marginPagesDisplayed={2}
                      disableInitialCallback={true}
                      // pageRangeDisplayed={3}
                      onPageChange={(selectedPage) => {
                        // setCurrentPage(selectedPage.selected + 1);
                        getReportingDate(selectedPage.selected + 1, entire);
                      }}
                      containerClassName={
                        "pagination justify-content-center justify-content-md-start"
                      }
                      pageClassName={"paginate_button page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"paginate_button page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />

                    <div className="d-flex justify-content-center align-items-center">
                      <div className="me-3">No of entries per page:</div>
                      <div>
                        <select
                          className="form-select"
                          value={entire}
                          onChange={(e) => {
                            setEntries(e.target.value);
                            getReportingDate(currentPage, e.target.value);
                          }}
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Reporting;
