import React, { useState, useEffect } from "react";

const DocumentList = (props) => {
  const documentSelector = () => {
    props.documentSelector(props.value);
  };

  return (
    <>
      <li
        className={`${
          props.documentSelected[0] === props.value[0]
            ? "list-group-item d-flex bg-primary"
            : "list-group-item d-flex"
        }`}
      >
        <div className="number border-end pe-2 fw-bold">
          <strong className="color-light-success">{props.index + 1}</strong>
        </div>
        <div className="cs-text flex-fill ps-2">
          <a href="javascript:void(0)" onClick={documentSelector}>
            <span
              className={`${
                props.documentSelected[0] === props.value[0] ? "text-white" : ""
              }`}
            >
              {props.value[0]}
            </span>
          </a>
        </div>
      </li>
    </>
  );
};

export default DocumentList;
