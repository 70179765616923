import axios from "axios";
import React from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";
import ReactPaginate from "react-paginate";

const NoticeBoardTable = (props) => {
  const [search, setSearch] = useState("");
  const privileges = secureLocalStorage.getItem("privileges");

  const filterData = (data, search) => {
    return data.filter((api) =>
      search.toLowerCase() === ""
        ? true
        : api.title.toLowerCase().includes(search.toLowerCase()) ||
          api.description.toLowerCase().includes(search.toLowerCase())
    );
  };

  const deleteNotice = (id) => {
    var config = {
      method: "delete",
      url: VARIABLES.url + `/api-app/noticeboard/${id}`,
      headers: {},
    };
    axios(config)
      .then((response) => {
        Swal.fire({
          icon: "success",
          text: "Notice deleted successfully",
        });
        // window.location.reload();
        props.setRawDataNoticeBoard(
          props.rawDataNoticeBoard.filter((user) => {
            return user.id !== id;
          })
        );
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text: "Notice not deleted successfully",
        });
        console.log(error);
      });
  };

  const deleteNoticeBoard = (id) => {
    Swal.fire({
      text: "Are you sure? You will not be able to recover this Notice!",
      showDenyButton: true,
      confirmButtonText: "Yes, delete it!",
      denyButtonText: `No, cancel!`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteNotice(id);
      }
    });
  };

  return (
    <>
      <div className="row clearfix g-3">
        <div className="col-sm-12">
          <div className="card mb-3">
            <div className="card-body">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  <i className="fa fa-search" aria-hidden="true"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by title or Description"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
              {props.isLoading ? (
                <div className="card-body">Loading...</div>
              ) : (
                <div className="table-responsive">
                  <table
                    className="table table-hover align-middle mb-0"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Publish On</th>
                        {privileges.includes("edit_notice") ? (
                          <th>Edit</th>
                        ) : (
                          ""
                        )}
                        {privileges.includes("delete_notice") ? (
                          <th>Delete</th>
                        ) : (
                          ""
                        )}
                      </tr>
                    </thead>
                    {filterData(props.rawDataNoticeBoard, search).length ===
                      0 && (
                      <tbody>
                        <tr>
                          <td></td>
                          <td>No data found</td>
                          <td></td>
                        </tr>
                      </tbody>
                    )}
                    {filterData(props.rawDataNoticeBoard, search).length >
                      0 && (
                      <tbody>
                        {filterData(props.rawDataNoticeBoard, search).map(
                          (api) => {
                            const getPublish = new Date(
                              api.published_on
                            ).toLocaleDateString();
                            return (
                              <tr key={api.id}>
                                <td>{api.title}</td>
                                <td>{api.description}</td>
                                <td>{getPublish}</td>
                                {privileges.includes("edit_notice") ? (
                                  <td>
                                    <button
                                      type="button"
                                      className="btn"
                                      data-bs-toggle="modal"
                                      data-bs-target="#editnoticeboard"
                                      onClick={() =>
                                        props.selectProject(api.id)
                                      }
                                    >
                                      <i className="icofont-edit text-success"></i>
                                    </button>
                                  </td>
                                ) : (
                                  ""
                                )}
                                {privileges.includes("delete_notice") ? (
                                  <td>
                                    <button
                                      type="button"
                                      className="btn"
                                      onClick={() => deleteNoticeBoard(api.id)}
                                    >
                                      <i className="icofont-ui-delete text-danger"></i>
                                    </button>
                                  </td>
                                ) : (
                                  ""
                                )}
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    )}
                  </table>
                  <div className="d-flex justify-content-center justify-content-md-between mt-3">
                    <ReactPaginate
                      key={3}
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={props.noPages}
                      initialPage={props.currentPage - 1}
                      marginPagesDisplayed={2}
                      disableInitialCallback={true}
                      // pageRangeDisplayed={3}
                      onPageChange={(selectedPage) => {
                        // setCurrentPage(selectedPage.selected + 1);
                        props.apiGetRawNoticeBoard(
                          selectedPage.selected + 1,
                          props.entire
                        );
                      }}
                      containerClassName={
                        "pagination justify-content-center justify-content-md-start"
                      }
                      pageClassName={"paginate_button page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"paginate_button page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />

                    <div className="d-flex justify-content-center align-items-center">
                      <div className="me-3">No of entries per page:</div>
                      <div>
                        <select
                          className="form-select"
                          value={props.entire}
                          onChange={(e) => {
                            props.setEntries(e.target.value);
                            props.apiGetRawNoticeBoard(
                              props.currentPage,
                              e.target.value
                            );
                          }}
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoticeBoardTable;
