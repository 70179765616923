import React, { useState, useEffect, useContext } from "react";
import UserManagementCreate from "./UserManagementCreate";
import UserManagementEdit from "./UserManagementEdit";
import UserManagementTable from "./UserManagementTable";
import axios from "axios";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";
import { DropdownAPIsContext } from "../../context/DropdownAPIsContext/DropdownAPIsContextProvider";
import { UsersContext } from "../../context/UsersContext/UsersContextProvider";

const UserManagement = (props) => {
  const privileges = secureLocalStorage.getItem("privileges");
  const [role, setRole] = useState([]);
  const [levelRole, setLevelRole] = useState([]);
  const [technologyRole, setTechnologyRole] = useState([]);
  const [organizationDetails, setOrganizationDetails] = useState([]);
  const {
    noPages,
    entire,
    setEntries,
    currentPage,
    apiGetRawUserManagement,
    isUsersDataLoading,
    rawDataUserManagement,
    setRawDataUserManagement,
  } = useContext(UsersContext);

  const [editedUserManagement, setEditedUserManagement] = useState(null);

  const {
    organisations,
    levelRole: levelRoleDropdown,
    techRole,
  } = useContext(DropdownAPIsContext);

  // API call of Project Data and stores the data in "projectData"
  const apiRoleDetails = async () => {
    try {
      const res = await axios.get(VARIABLES.url + "/api-app/role");
      setRole(
        res.data.map((userData) => ({
          value: userData.id,
          label: userData.name,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (techRole.length !== 0) {
      setTechnologyRole(
        techRole.map((userData) => ({
          value: userData.id,
          label: userData.name,
        }))
      );
    }
  }, [techRole]);

  const apiUserReport = async () => {
    try {
      const res = await axios.get(VARIABLES.url + "/api-app/user-report");
      const report = res.data;
      const fileLink = res.data.data;
      const link = document.createElement("a");
      link.href = fileLink;
      link.setAttribute("download", "filename.xlsx");

      // Append the link to the document
      document.body.appendChild(link);

      // Trigger the click event on the link to start the download
      link.click();

      // Remove the link from the document after the download is initiated
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (levelRoleDropdown.length !== 0) {
      setLevelRole(
        levelRoleDropdown.map((userData) => ({
          value: userData.id,
          label: userData.name,
        }))
      );
    }
  }, [levelRoleDropdown]);

  useEffect(() => {
    if (organisations.length !== 0) {
      setOrganizationDetails(
        organisations.map((org) => {
          return {
            value: org.id,
            label: org.org_name,
          };
        })
      );
    }
  }, [organisations]);

  useEffect(() => {
    apiRoleDetails();
  }, []);

  const editUserManagementData = (id) => {
    const getEditedTask = rawDataUserManagement.find((task) => {
      return task.id === id;
    });
    setEditedUserManagement(getEditedTask);
  };

  return (
    <>
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            <h5 className="fw-bold py-3 mb-0"> Staff Management </h5>
            <div className="d-flex">
              {privileges.includes("create_user") ? (
                <div className="d-flex me-2 py-2 project-tab sub-project-tab flex-wrap w-sm-100">
                  <button
                    type="button"
                    className="btn btn-dark w-sm-100"
                    data-bs-toggle="modal"
                    data-bs-target="#addUserManagement"
                  >
                    <i className="icofont-plus-circle me-2 fs-6"></i> Create
                    User{" "}
                  </button>
                </div>
              ) : (
                ""
              )}
              <div className="d-flex py-2 project-tab sub-project-tab flex-wrap w-sm-100">
                <button
                  type="button"
                  className="btn btn-dark w-sm-100"
                  onClick={() => apiUserReport()}
                >
                  <i className="icofont-download me-2 fs-6"></i> Download{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* UserManagement Create Modal Pop Up */}
      <UserManagementCreate
        apiGetRawUserManagement={apiGetRawUserManagement}
        role={role}
        levelRole={levelRole}
        technologyRole={technologyRole}
        organizationDetails={organizationDetails}
      />

      {/* UserManagement Edit Modal Pop Up */}
      <UserManagementEdit
        editedUserManagement={editedUserManagement}
        apiGetRawUserManagement={apiGetRawUserManagement}
        organizationDetails={organizationDetails}
        role={role}
        levelRole={levelRole}
        technologyRole={technologyRole}
      />

      {/* UserManagement Table Page */}
      <UserManagementTable
        rawDataUserManagement={rawDataUserManagement}
        isLoading={isUsersDataLoading}
        editUserManagementData={editUserManagementData}
        setRawDataUserManagement={setRawDataUserManagement}
        noPages={noPages}
        entire={entire}
        currentPage={currentPage}
        setEntries={setEntries}
        apiGetRawUserManagement={apiGetRawUserManagement}
      />
    </>
  );
};

export default UserManagement;
