import React, { useEffect, useState } from "react";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import ProjectCardsCard from "./ProjectCardsCard";
import AddCard from "./AddCard";

function ProjectCardsList({ list, setCurrentList }) {
  return (
    <div className="ListContainer">
      <div className={`List card rounded-3 position-relative`}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingTop: "10px",
          }}
        >
          <div className="card-body overflow-y-auto p-2">
            <SortableContext
              items={list.items}
              strategy={verticalListSortingStrategy}
            >
              <div className={`card rounded-1`}>
                <div
                  className={`card-body rounded-1 px-3 py-0 position-relative`}
                  style={{
                    minHeight: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "gray",
                    color: "white",
                    marginBottom: "10px",
                  }}
                >
                  <p className="pt-3" style={{ fontSize: "25px" }}>
                    {list.name}
                  </p>
                  <span
                    className="position-absolute top-0 end-0 me-2 mt-1"
                    style={{ fontSize: "1.3em", cursor: "pointer" }}
                    data-bs-toggle="modal"
                    data-bs-target="#createCard"
                    onClick={() => setCurrentList(list)}
                  >
                    <i className="bi bi-plus-circle "></i>
                  </span>
                </div>
              </div>
              {list.items.map((item) => {
                return (
                  <ProjectCardsCard
                    key={item.id}
                    item={item}
                    listId={list.id}
                  />
                );
              })}
            </SortableContext>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectCardsList;
