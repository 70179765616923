import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm, useFormState } from "react-hook-form";
import Select from "react-select";
import { VARIABLES } from "../../Constants";
import Swal from "sweetalert2";
import secureLocalStorage from "react-secure-storage";
import { LeaveManagementContext } from "../../context/LeaveManagementContext/LeaveManagementProvider";

const LeaveManagementCreate = (props) => {
  const privileges = secureLocalStorage.getItem("privileges");
  const userID = secureLocalStorage.getItem("userID");
  const { apiGetLeaveData, apiGetLeaveDataAll } = useContext(
    LeaveManagementContext
  );
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm();
  const { isDirty, isValid } = useFormState({
    control,
  });

  const onSubmit = (data) => {
    var leaveResponse = JSON.stringify({
      employee_id: data.employee.value,
      start_date: data.start_date,
      end_date: data.end_date,
      leave_type_id: data.leave_type.value,
      approval_status: data.approval_status,
      reason: data.leave_reason,
      created_by_id: userID,
      updated_by_id: userID,
    });

    var config = {
      method: "post",
      url: VARIABLES.url + "/api-app/leave-management",
      headers: {
        "Content-Type": "application/json",
      },
      data: leaveResponse,
    };

    axios(config)
      .then((response) => {
        Swal.fire({
          title: "Success!",
          text: "Leave Apply Successfully",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => {
          apiGetLeaveData();
          apiGetLeaveDataAll();
        });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Error",
          text: "Failed to apply Leave",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
    reset();
  };

  const getResetValues = () => {
    reset();
  };

  return (
    <>
      <div
        className="modal fade"
        id="leaveadd"
        tabIndex="-1"
        data-bs-backdrop="static"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title  fw-bold" id="leaveaddLabel">
                {" "}
                Leave Request
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => getResetValues()}
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-3">
                  {privileges.includes("display_leave_name") ? (
                    <>
                      <label className="form-label">
                        Employee <span className="required_field">*</span>
                      </label>
                      <Controller
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={props.employeeData}
                            isClearable={true}
                          />
                        )}
                        defaultValue=""
                        rules={{ required: true }}
                        name="employee"
                        control={control}
                      />
                    </>
                  ) : (
                    <>
                      <label className="form-label">
                        Employee <span className="required_field">*</span>
                      </label>
                      <Controller
                        render={({ field }) => (
                          <Select {...field} isClearable={true} />
                        )}
                        defaultValue={{ label: props.username, value: userID }}
                        rules={{ required: true }}
                        name="employee"
                        control={control}
                      />
                    </>
                  )}
                  {errors.employee && (
                    <span className="required_field">
                      Employee name is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  {privileges.includes("display_leave_name") ? (
                    <>
                      <label className="form-label">
                        Select Leave type{" "}
                        <span className="required_field">*</span>
                      </label>
                      <Controller
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={props.leaveType}
                            isClearable={true}
                          />
                        )}
                        defaultValue=""
                        rules={{ required: true }}
                        name="leave_type"
                        control={control}
                      />
                    </>
                  ) : (
                    <>
                      <label className="form-label">
                        Leave type <span className="required_field">*</span>
                      </label>
                      <Controller
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={props.desiredOptions}
                            isClearable={true}
                          />
                        )}
                        defaultValue=""
                        rules={{ required: true }}
                        name="leave_type"
                        control={control}
                      />
                    </>
                  )}
                  {errors.leave_type && (
                    <span className="required_field">
                      Leave Type is required
                    </span>
                  )}
                </div>
                <div className="deadline-form">
                  <form>
                    <div className="row g-3 mb-3">
                      <div className="col-sm-6">
                        <label className="form-label">
                          Leave From Date{" "}
                          <span className="required_field">*</span>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="start_date"
                          {...register("start_date", { required: true })}
                        />
                        {errors.start_date && (
                          <span className="required_field">
                            Start Date is required
                          </span>
                        )}
                      </div>
                      <div className="col-sm-6">
                        <label className="form-label">
                          Leave to Date{" "}
                          <span className="required_field">*</span>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          id="end_date"
                          {...register("end_date", { required: true })}
                        />
                        {errors.end_date && (
                          <span className="required_field">
                            End Date is required
                          </span>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
                <div className="mb-3">
                  {privileges.includes("display_leave_name") ? (
                    <>
                      <label className="form-label">
                        Approval Status{" "}
                        <span className="required_field">*</span>
                      </label>
                      <input
                        value="Pending"
                        className="form-control"
                        id="approval_status"
                        {...register("approval_status", { required: true })}
                      />
                    </>
                  ) : (
                    " "
                  )}
                  {errors.approval_status && (
                    <span className="required_field">
                      Approval Status is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Leave Reason <span className="required_field">*</span>
                  </label>
                  <textarea
                    className="form-control"
                    id="leave_reason"
                    rows="3"
                    {...register("leave_reason", { required: true })}
                  ></textarea>
                  {errors.leave_reason && (
                    <span className="required_field">
                      Leave Reason is required
                    </span>
                  )}
                </div>
                <div className="modal-footer">
                  {/* <button type="submit" className="btn btn-primary">Submit</button> */}
                  {isValid === true ? (
                    <button
                      type="submit"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Submit
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveManagementCreate;
