import { DndContext, DragOverlay, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import React, { useCallback, useEffect, useState } from "react";
import SequenceItem from "./SequenceItem";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";
import { VARIABLES } from "../../Constants";
import axios from "axios";

function GenerateSequence() {
  const [activeId, setActiveId] = useState(null);
  const [seq, setSeq] = useState([]);
  const userID = secureLocalStorage.getItem("userID");
  const privileges = secureLocalStorage.getItem("privileges");
  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const apiSequence = async () => {
    let data = JSON.stringify({
      sequence: seq.map((s) => parseInt(s.id)),
      created_by_id: userID,
      updated_by_id: userID,
      is_confirmed: false,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/password-app/squence-generator`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        Swal.fire({
          title: "Success!",
          text: response.data.message,
          icon: "success",
          confirmButtonText: "OK",
        });
      })
      .catch((error) => {
        console.log(error.data);
        Swal.fire({
          title: "Error",
          text: error.response.data.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };

  const confirmSequence = async () => {
    let data = JSON.stringify({
      is_confirmed: true,
      updated_by_id: userID,
    });
    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/password-app/confirm-squence`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    Swal.fire({
      title: "Do you want to save the sequence? Once saved cannot be changed",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Save",
      denyButtonText: `Don't save`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .request(config)
          .then((response) => {
            Swal.fire({
              title: "Success!",
              text: response.data.message,
              icon: "success",
              confirmButtonText: "OK",
            });
          })
          .catch((error) => {
            console.log(error.response);
            Swal.fire({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              confirmButtonText: "OK",
            });
          });
      } else if (result.isDenied) {
        Swal.fire("Sequence is not saved", "", "info");
      }
    });
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      setSeq((items) => {
        let oldIndex;
        items.map((item, index) => {
          if (item.id === active.id) {
            oldIndex = index;
          }
        });
        let newIndex;
        items.map((item, index) => {
          if (item.id === over.id) {
            newIndex = index;
          }
        });
        return arrayMove(items, oldIndex, newIndex);
      });
    }
    setActiveId(null);
  };

  const generateRandomSeq = () => {
    let array = [...seq];
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    setSeq(array);
  };

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  useEffect(() => {
    let arr = [
      {
        id: "1",
        icon: (
          <i
            className="bi bi-gift"
            style={{ color: "black", fontWeight: "bolder", fontSize: "1.5em" }}
          ></i>
        ),
      },
      {
        id: "2",
        icon: (
          <i
            className="bi bi-alarm"
            style={{ color: "black", fontWeight: "bolder", fontSize: "1.5em" }}
          ></i>
        ),
      },
      {
        id: "3",
        icon: (
          <i
            className="bi bi-arrow-up"
            style={{ color: "black", fontWeight: "bolder", fontSize: "1.5em" }}
          ></i>
        ),
      },
      {
        id: "4",
        icon: (
          <i
            className="bi bi-arrow-down"
            style={{ color: "black", fontWeight: "bolder", fontSize: "1.5em" }}
          ></i>
        ),
      },
      {
        id: "5",
        icon: (
          <i
            className="bi bi-arrow-left"
            style={{ color: "black", fontWeight: "bolder", fontSize: "1.5em" }}
          ></i>
        ),
      },
      {
        id: "6",
        icon: (
          <i
            className="bi bi-arrow-right"
            style={{ color: "black", fontWeight: "bolder", fontSize: "1.5em" }}
          ></i>
        ),
      },
      {
        id: "7",
        icon: (
          <i
            className="bi bi-bell"
            style={{ color: "black", fontWeight: "bolder", fontSize: "1.5em" }}
          ></i>
        ),
      },
      {
        id: "8",
        icon: (
          <i
            className="bi bi-bookmark"
            style={{ color: "black", fontWeight: "bolder", fontSize: "1.5em" }}
          ></i>
        ),
      },
      {
        id: "9",
        icon: (
          <i
            className="bi bi-camera"
            style={{ color: "black", fontWeight: "bolder", fontSize: "1.5em" }}
          ></i>
        ),
      },
      {
        id: "10",
        icon: (
          <i
            className="bi bi-chat"
            style={{ color: "black", fontWeight: "bolder", fontSize: "1.5em" }}
          ></i>
        ),
      },
      {
        id: "11",
        icon: (
          <i
            className="bi bi-check"
            style={{ color: "black", fontWeight: "bolder", fontSize: "1.5em" }}
          ></i>
        ),
      },
      {
        id: "12",
        icon: (
          <i
            className="bi bi-clock"
            style={{ color: "black", fontWeight: "bolder", fontSize: "1.5em" }}
          ></i>
        ),
      },
      {
        id: "13",
        icon: (
          <i
            className="bi bi-cloud"
            style={{ color: "black", fontWeight: "bolder", fontSize: "1.5em" }}
          ></i>
        ),
      },
      {
        id: "14",
        icon: (
          <i
            className="bi bi-code"
            style={{ color: "black", fontWeight: "bolder", fontSize: "1.5em" }}
          ></i>
        ),
      },
      {
        id: "15",
        icon: (
          <i
            className="bi bi-envelope"
            style={{ color: "black", fontWeight: "bolder", fontSize: "1.5em" }}
          ></i>
        ),
      },
      {
        id: "16",
        icon: (
          <i
            className="bi bi-flag"
            style={{ color: "black", fontWeight: "bolder", fontSize: "1.5em" }}
          ></i>
        ),
      },
      {
        id: "17",
        icon: (
          <i
            className="bi bi-folder"
            style={{ color: "black", fontWeight: "bolder", fontSize: "1.5em" }}
          ></i>
        ),
      },
      {
        id: "18",
        icon: (
          <i
            className="bi bi-gear"
            style={{ color: "black", fontWeight: "bolder", fontSize: "1.5em" }}
          ></i>
        ),
      },
      {
        id: "19",
        icon: (
          <i
            className="bi bi-heart"
            style={{ color: "black", fontWeight: "bolder", fontSize: "1.5em" }}
          ></i>
        ),
      },
      {
        id: "20",
        icon: (
          <i
            className="bi bi-house"
            style={{ color: "black", fontWeight: "bolder", fontSize: "1.5em" }}
          ></i>
        ),
      },
      {
        id: "21",
        icon: (
          <i
            className="bi bi-lightbulb"
            style={{ color: "black", fontWeight: "bolder", fontSize: "1.5em" }}
          ></i>
        ),
      },
      {
        id: "22",
        icon: (
          <i
            className="bi bi-link"
            style={{ color: "black", fontWeight: "bolder", fontSize: "1.5em" }}
          ></i>
        ),
      },
      {
        id: "23",
        icon: (
          <i
            className="bi bi-lock"
            style={{ color: "black", fontWeight: "bolder", fontSize: "1.5em" }}
          ></i>
        ),
      },
      {
        id: "24",
        icon: (
          <i
            className="bi bi-music-note"
            style={{ color: "black", fontWeight: "bolder", fontSize: "1.5em" }}
          ></i>
        ),
      },
      {
        id: "25",
        icon: (
          <i
            className="bi bi-pen"
            style={{ color: "black", fontWeight: "bolder", fontSize: "1.5em" }}
          ></i>
        ),
      },
      {
        id: "26",
        icon: (
          <i
            className="bi bi-person"
            style={{ color: "black", fontWeight: "bolder", fontSize: "1.5em" }}
          ></i>
        ),
      },
      {
        id: "27",
        icon: (
          <i
            className="bi bi-phone"
            style={{ color: "black", fontWeight: "bolder", fontSize: "1.5em" }}
          ></i>
        ),
      },
      {
        id: "28",
        icon: (
          <i
            className="bi bi-search"
            style={{ color: "black", fontWeight: "bolder", fontSize: "1.5em" }}
          ></i>
        ),
      },
      {
        id: "29",
        icon: (
          <i
            className="bi bi-star"
            style={{ color: "black", fontWeight: "bolder", fontSize: "1.5em" }}
          ></i>
        ),
      },
      {
        id: "30",
        icon: (
          <i
            className="bi bi-wallet"
            style={{ color: "black", fontWeight: "bolder", fontSize: "1.5em" }}
          ></i>
        ),
      },
    ];
    setSeq(arr);
  }, []);

  return (
    <div>
      <div className="row align-items-center">
        <div className="border-0 mb-4">
          <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
            <h5 className="fw-bold py-3 mb-0"> Password Sequence </h5>
            {privileges.includes("create_sequence") ? (
              <div className="d-flex py-2 project-tab sub-project-tab flex-wrap w-sm-100">
                <button
                  type="button"
                  className="btn btn-secondary me-3"
                  onClick={generateRandomSeq}
                >
                  Generate Random Sequence
                </button>
                <button
                  type="button"
                  className="btn btn-dark w-sm-100 me-3"
                  onClick={() => apiSequence()}
                >
                  Save Generated Sequence
                </button>
                <button
                  type="button"
                  className="btn btn-danger w-sm-100"
                  onClick={() => confirmSequence()}
                >
                  Confirn Sequence
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <div>
          <DndContext
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={handleDragCancel}
          >
            <SortableContext items={seq} strategy={rectSortingStrategy}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: `repeat(6, 1fr)`,
                  gridGap: 10,
                  maxWidth: "800px",
                  marginBottom: "10px",
                }}
              >
                {seq.map((item) => {
                  return (
                    <SequenceItem key={item.id} id={item.id} icon={item.icon} />
                  );
                })}
              </div>
            </SortableContext>
            <DragOverlay adjustScale style={{ transformOrigin: "0 0 " }}>
              {activeId ? (
                <SequenceItem
                  id={activeId}
                  icon={seq.filter((item) => item.id === activeId)[0].icon}
                  isDragging
                />
              ) : null}
            </DragOverlay>
          </DndContext>
        </div>
      </div>
    </div>
  );
}

export default GenerateSequence;
