import React, { useEffect, useState } from "react";
import { VARIABLES } from "../../Constants";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";

const ProjectWorkflow = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [workflowData, setWorkflowData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const projectId = location.state.data;
  const apiGetWorkFlowData = async () => {
    try {
      setIsLoading(true);
      const url = VARIABLES.url + `/api-app/project-workflow/${projectId}`;
      const res = await axios.get(url);
      setWorkflowData(res.data);
      setIsLoading(false);
    } catch (error) {
      if (error) {
        setIsLoading(false);
      }
      console.log(error);
    }
  };

  const changeSidebar = (section, id) => {
    props.handleSidebar(section, id);
  };

  const showAlert = (data) => {
    if (data.is_enabled === false && data.assigned_to.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "The block is not assigned and not enabled.",
      });
    } else if (data.assigned_to.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "The block is not Assigned. Please Assigned the block to Incharge!",
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please complete the Prvious Block First!",
      });
    }
    // {data.is_enabled === false && Swal.fire({
    //   icon: 'error',
    //   title: 'Oops...',
    //   text: 'Please complete the Prvious Block First!',
    // })}
    // {data.assigned_to.length != 0 && Swal.fire({
    //   icon: 'error',
    //   title: 'Oops...',
    //   text: 'The block is Assigned. Please Assigned the block to Incharge!',
    // })}
    // {data.is_enabled === false && data.assigned_to.length != 0 && Swal.fire({
    //   icon: 'error',
    //   title: 'Oops...',
    //   text: 'The block is not assigned and not enabled.',
    // })}
  };

  useEffect(() => {
    apiGetWorkFlowData();
  }, []);

  return (
    <>
      <div className="row align-items-center">
        <div className="card-body p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-end flex-wrap">
          <div className="d-flex py-2 project-tab flex-wrap w-sm-100">
            <button
              type="button"
              className="btn btn-outline-info w-sm-100"
              onClick={() => navigate("/projects")}
            >
              <i className="icofont-arrow-left me-2 fs-6"></i>Back
            </button>
          </div>
        </div>
      </div>
      <div className="row clearfix g-3 mb-3">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="card-header py-3">
                  <h6 className="mb-0 fw-bold ">Project Workflow</h6>
                </div>
                {isLoading ? (
                  <div className="card-body">Loading...</div>
                ) : (
                  <>
                    {workflowData.length === 0 && (
                      <div className="row">
                        <div
                          className="col-sm-12"
                          style={{ marginTop: "20px" }}
                        >
                          <div className="card-body">
                            Workflow Block not created
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="card-body">
                      {workflowData.map((workflow, index) => {
                        return workflow.is_enabled &&
                          workflow.assigned_to.length != 0 ? (
                          <a
                            className="style-hover-no"
                            href="javascript:void(0)"
                            onClick={() =>
                              navigate("/projectworkflowblock", {
                                state: {
                                  data: workflow.id,
                                },
                              })
                            }
                          >
                            <div
                              className={`${
                                workflow.is_completed === true
                                  ? "timeline-item ti-warn border-bottom ms-2"
                                  : "timeline-item ti-danger border-bottom ms-2"
                              }`}
                            >
                              <div className="d-flex">
                                {workflow.is_completed === true ? (
                                  <span className="avatar d-flex justify-content-center align-items-center rounded-circle warn-success-bg">
                                    <i className="icofont-tick-mark fs-2"></i>
                                  </span>
                                ) : (
                                  <span className="avatar d-flex justify-content-center align-items-center rounded-circle danger-success-bg">
                                    <i className="icofont-close fs-2"></i>
                                  </span>
                                )}
                                <div className="flex-fill ms-3">
                                  <div className="card-block">
                                    <div className="mb-1">
                                      <strong>{workflow.card_title}</strong>
                                    </div>
                                    <div className="mb-1">
                                      Assigned to :{" "}
                                      <strong>
                                        {workflow.assigned_to.length === 0
                                          ? "Incharge is not assigned"
                                          : workflow.assigned_to.map(
                                              (assigned) => assigned.username
                                            )}
                                      </strong>
                                    </div>
                                  </div>
                                  <div className="card-block">
                                    <div className="mb-1">
                                      Target Date :{" "}
                                      <strong>
                                        {workflow.target_date === null
                                          ? "Targeted Date is not Assigned"
                                          : moment(workflow.target_date).format(
                                              "yyyy-MM-DD HH:mm"
                                            )}
                                      </strong>
                                    </div>
                                    <div className="mb-1">
                                      Date of submission :{" "}
                                      <strong>
                                        {workflow.completed_on === null
                                          ? "Not Submitted Yet"
                                          : moment(
                                              workflow.completed_on
                                            ).format("yyyy-MM-DD HH:mm")}
                                      </strong>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        ) : (
                          <a
                            className="style-hover-no"
                            href="javascript:void(0)"
                            onClick={() => showAlert(workflow)}
                            data-bs-toggle="tooltip"
                            data-bs-html="true"
                            title="Complete Early Block first"
                          >
                            <div
                              className={`${
                                workflow.is_completed === true
                                  ? "timeline-item ti-warn border-bottom ms-2"
                                  : "timeline-item ti-danger border-bottom ms-2"
                              }`}
                            >
                              <div className="d-flex">
                                {workflow.is_completed === true ? (
                                  <span className="avatar d-flex justify-content-center align-items-center rounded-circle warn-success-bg">
                                    <i className="icofont-tick-mark fs-2"></i>
                                  </span>
                                ) : (
                                  <span className="avatar d-flex justify-content-center align-items-center rounded-circle danger-success-bg">
                                    <i className="icofont-close fs-2"></i>
                                  </span>
                                )}
                                <div className="flex-fill ms-3">
                                  <div className="card-block">
                                    <div className="mb-1">
                                      <strong>{workflow.card_title}</strong>
                                    </div>
                                    <div className="mb-1">
                                      Assigned to :{" "}
                                      <strong>
                                        {workflow.assigned_to.length === 0
                                          ? "Incharge is not assigned"
                                          : workflow.assigned_to.map(
                                              (assigned) => assigned.username
                                            )}
                                      </strong>
                                    </div>
                                  </div>
                                  <div className="card-block">
                                    <div className="mb-1">
                                      Target Date :{" "}
                                      <strong>
                                        {workflow.target_date === null
                                          ? "Targeted Date is not Assigned"
                                          : moment(workflow.target_date).format(
                                              "yyyy-MM-DD HH:mm"
                                            )}
                                      </strong>
                                    </div>
                                    <div className="mb-1">
                                      Date of submission :{" "}
                                      <strong>
                                        {workflow.completed_on === null
                                          ? "Not Submitted Yet"
                                          : moment(
                                              workflow.completed_on
                                            ).format("yyyy-MM-DD HH:mm")}
                                      </strong>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          {/* <!-- .card: My Timeline --> */}
        </div>
      </div>
    </>
  );
};

export default ProjectWorkflow;
