import React, { useContext, useEffect, useState } from "react";
import { VARIABLES } from "../../../Constants";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import useKeyboardShortcut from "use-keyboard-shortcut";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { ListContext } from "../../../context/ListContextProvider";
import { TaskManagementContext } from "../../../context/TaskManagementContext/TaskManagementContextProvider";
import Swal from "sweetalert2";
import { DropdownAPIsContext } from "../../../context/DropdownAPIsContext/DropdownAPIsContextProvider";

const Sidebar = (props) => {
  const navigate = useNavigate();
  const { setCurrentBoardId } = useContext(TaskManagementContext);
  const { targetCardProjectId, regularCardProjectId, meetProjectId } =
    useContext(DropdownAPIsContext);
  const { workdesk } = useKeyboardShortcut(["w"], (shortcutKeys) => {
    document
      .getElementById("workdesk-Components")
      .classList.toggle("show", "d-block");
    props.handleSidebar("NoticeBoard");
    setIsActive("NoticeBoard");
  });

  const { timesheet } = useKeyboardShortcut(["t"], (shortcutKeys) => {
    document
      .getElementById("timesheet-Components")
      .classList.toggle("show", "d-block");
    props.handleSidebar("TimeSheet");
    setIsActive("TimeSheet");
  });

  const releaseMessage = (name, platform, link) => {
    Swal.fire({
      icon: "info",
      title: "Notice",
      text: `${name} is on ${platform} now!`,
      footer: `<a href=${link} style="color: skyblue; font-weight: bolder;" target="_blank">Checkout ${platform}</a>`,
    });
  };

  const handleTaskmanagement = (id) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${VARIABLES.url}/api-app/project/${id}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setCurrentBoardId(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // useKeyboardShortcut(['shift','w'], () => {
  //   // Handle Ctrl+S shortcut
  //   document.getElementById("timesheet-Components").classList.toggle("show", "d-block");
  //     props.handleSidebar("TimeSheet");
  //     setIsActive("TimeSheet");
  // });

  const { projects } = useKeyboardShortcut(["p"], (shortcutKeys) => {
    document
      .getElementById("project-Components")
      .classList.toggle("show", "d-block");
    props.handleSidebar("projects");
    setIsActive("projects");
  });

  const { resourceMapping } = useKeyboardShortcut(
    ["r", "m"],
    (shortcutKeys) => {
      document
        .getElementById("resource-Components")
        .classList.toggle("show", "d-block");
      props.handleSidebar("resourcemapping");
      setIsActive("resourcemapping");
    }
  );

  const { leaveManagement } = useKeyboardShortcut(["l"], (shortcutKeys) => {
    document
      .getElementById("leave-Components")
      .classList.toggle("show", "d-block");
    props.handleSidebar("leave");
    setIsActive("leave");
  });

  const { devReleases } = useKeyboardShortcut(["r", "d"], (shortcutKeys) => {
    document
      .getElementById("release-Components")
      .classList.toggle("show", "d-block");
    props.handleSidebar("devRelease");
    setIsActive("devRelease");
  });

  const { QAReleases } = useKeyboardShortcut(["r", "q"], (shortcutKeys) => {
    document
      .getElementById("release-Components")
      .classList.toggle("show", "d-block");
    props.handleSidebar("QARelease");
    setIsActive("QARelease");
  });

  const userID = secureLocalStorage.getItem("userID");
  const lead = secureLocalStorage.getItem("lead");

  // Defining the active sidebar title to highlight it
  const [isActive, setIsActive] = useState("NoticeBoard");
  // const [links, setLinks] = useState([]);
  const [leadLinks, setLeadLinks] = useState([]);
  const [currentTab, setCurrentTab] = useState("Home");
  const { setView } = useContext(ListContext);

  // Changing the main window using state.
  // Name of the section is received as an argument and passed on to base.js
  // The respective is made visible and isActive is set
  const changeSidebar = (section) => {
    // props.handleSidebar(section);
    setIsActive(section);
  };
  // const loadLinks = async () => {
  //   const url = VARIABLES.url + "/api/links/";

  //   let reqOptions = {
  //     url: url,
  //     method: "GET",
  //     headers: {
  //       Accept: "*/*",
  //       "Content-Type": "application/json",
  //     },
  //     mode: "no-cors",
  //   };

  //   axios.request(reqOptions).then(function (response) {
  //     var data = response.data;
  //     console.log(data);
  //     const lead = data.filter((link) => link.isLead === true);
  //     const general = data.filter((link) => link.isLead === false);
  //     console.log(lead);
  //     console.log(general);
  //     setLeadLinks(lead);
  //     setLinks(general);
  //   });
  // };
  // useEffect(() => {
  //   loadLinks();
  // }, []);

  // useEffect(() => {
  //   const handleKeyDown = (event) => {
  //     if (event.ctrlKey && event.key === 'b') {
  //       // Handle Ctrl+b shortcut
  //       event.preventDefault(); // Prevent browser's default behavior
  //       document.getElementById("timesheet-Components").classList.toggle("show", "d-block");
  //       props.handleSidebar("TimeSheet");
  //       setIsActive("TimeSheet");
  //     } else if (event.ctrlKey && event.key === 'z') {
  //       // Handle Ctrl+Z shortcut
  //       event.preventDefault(); // Prevent browser's default behavior
  //       // undoChanges();
  //     }
  //   };

  //   document.addEventListener('keydown', handleKeyDown);

  //   // Clean up the event listener
  //   return () => {
  //     document.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, []);

  if (!props.correctTS) {
    return (
      <>
        <div
          className={`${
            props.showSideBar === true
              ? "sidebar px-4 py-4 py-md-5 me-0 open"
              : "sidebar px-4 py-4 py-md-5 me-0"
          }`}
        >
          <div className="d-flex flex-column h-100">
            <a href="javascript:void(0)" className="mb-0 brand-icon">
              <span className="logo-icon  fs-1">
                <img
                  className="avatar rounded-circle"
                  src="nipun-logo.png"
                  alt=""
                />
              </span>
              <span className="logo-text">THE NIPUN</span>
            </a>
            <ul className="menu-list flex-grow-1 mt-3">
              {["correct_timesheet"].some((privilege) =>
                props.privileges.includes(privilege)
              ) ? (
                <li className="collapsed">
                  <Link
                    to="/"
                    className="m-link"
                    data-bs-toggle="collapse"
                    data-bs-target="#correct-timesheet"
                  >
                    <i className="icofont-paperclip fs-5"></i>{" "}
                    <span>Timesheet</span>{" "}
                    <span className="arrow icofont-dotted-down ms-auto text-end fs-5"></span>
                  </Link>
                  {/* <!-- Menu: Sub menu ul --> */}
                  <ul
                    className={`sub-menu collapse ${
                      currentTab === "Correct Timesheet" && "show"
                    }`}
                    id="correct-timesheet"
                  >
                    {props.privileges.includes("correct_timesheet") ? (
                      <li className="collapsed">
                        <NavLink
                          to="/"
                          className={({ isActive }) => {
                            if (isActive) {
                              setCurrentTab("Correct Timesheet");
                              return "m-link active";
                            }
                            return "m-link";
                          }}
                          onClick={() => changeSidebar("Correct_TS")}
                        >
                          {/* <i className="icofont-file-document"></i> */}
                          <span>Correct Timesheet</span>
                        </NavLink>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div
        className={`${
          props.showSideBar === true
            ? "sidebar px-4 py-4 py-md-5 me-0 open"
            : "sidebar px-4 py-4 py-md-5 me-0"
        }`}
      >
        <div className="d-flex flex-column h-100">
          <a href="javascript:void(0)" className="mb-0 brand-icon">
            <span className="logo-icon  fs-1">
              <img
                className="avatar rounded-circle"
                src="nipun-logo.png"
                alt=""
              />
            </span>
            <span className="logo-text">THE NIPUN</span>
          </a>
          <ul className="menu-list flex-grow-1 mt-3">
            {/* Home Menu bar with collapse */}
            {["workdesk", "Kanban"].some((privilege) =>
              props.privileges.includes(privilege)
            ) ? (
              <li className="collapsed">
                <Link
                  to="/"
                  className="m-link"
                  data-bs-toggle="collapse"
                  data-bs-target="#workdesk-Components"
                >
                  <i className="icofont-billboard"></i> <span>Home</span>{" "}
                  <span className="arrow icofont-dotted-down ms-auto text-end fs-5"></span>
                </Link>
                {/* <!-- Menu: Sub menu ul --> */}
                <ul
                  className={`sub-menu collapse ${
                    currentTab === "Home" && "show"
                  }`}
                  id="workdesk-Components"
                >
                  {props.privileges.includes("workdesk") ? (
                    <li className="collapsed">
                      {/* Logic to add active class dynamically */}
                      <NavLink
                        to="/"
                        className={({ isActive }) => {
                          if (isActive) {
                            setCurrentTab("Home");
                            return "m-link active";
                          }
                          return "m-link";
                        }}
                        onClick={() => changeSidebar("NoticeBoard")}
                      >
                        <span>Workdesk</span>{" "}
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}

                  {props.privileges.includes("Kanban") ? (
                    <li className="collapsed">
                      <NavLink
                        to="/kanban"
                        className={({ isActive }) => {
                          if (isActive) {
                            setCurrentTab("Home");
                            return "m-link active";
                          }
                          return "m-link";
                        }}
                        onClick={() => setView("user")}
                      >
                        <span>Kanban</span>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </li>
            ) : (
              ""
            )}

            {/* Time Sheet Menu bar with collapse */}
            {["view_fill_timeheet", "reporting", "missed_timesheet"].some(
              (privilege) => props.privileges.includes(privilege)
            ) ? (
              <li className="collapsed">
                <Link
                  to="timesheet"
                  className="m-link"
                  data-bs-toggle="collapse"
                  data-bs-target="#timesheet-Components"
                >
                  <i className="icofont-paperclip fs-5"></i>{" "}
                  <span>My Timesheets</span>{" "}
                  <span className="arrow icofont-dotted-down ms-auto text-end fs-5"></span>
                </Link>
                {/* <!-- Menu: Sub menu ul --> */}
                <ul
                  className={`sub-menu collapse ${
                    currentTab === "TimeSheet" && "show"
                  }`}
                  id="timesheet-Components"
                >
                  {/* Privilege to view all timesheets */}
                  {props.privileges.includes("view_fill_timeheet") ? (
                    <li className="collapsed">
                      <NavLink
                        to="timesheet"
                        className={({ isActive }) => {
                          if (isActive) {
                            setCurrentTab("TimeSheet");
                            return "m-link active";
                          }
                          return "m-link";
                        }}
                        onClick={() => changeSidebar("TimeSheet")}
                      >
                        <span>View timesheets</span>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}

                  {/* Privilege to view all timesheets */}
                  {props.privileges.includes("missed_timesheet") ? (
                    <li className="collapsed">
                      <NavLink
                        to="missedtimesheets"
                        className={({ isActive }) => {
                          if (isActive) {
                            setCurrentTab("TimeSheet");
                            return "m-link active";
                          }
                          return "m-link";
                        }}
                        onClick={() => changeSidebar("missedTimesheet")}
                      >
                        {/* <i className="icofont-paperclip"></i> */}
                        <span>Missed timesheets</span>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}

                  {/* {props.privileges.includes("timesheet_dashboard") ? ( */}
                  <li className="collapsed">
                    <NavLink
                      to="timesheetdashboard"
                      className={({ isActive }) => {
                        if (isActive) {
                          setCurrentTab("TimeSheet");
                          return "m-link active";
                        }
                        return "m-link";
                      }}
                      onClick={() => changeSidebar("TimesheetDashboard")}
                    >
                      {/* <i className="icofont-paperclip"></i> */}
                      <span>Timesheet Dashboard</span>
                    </NavLink>
                  </li>
                  {/* ) : (
                    ""
                  )} */}
                  {/* {props.privileges.includes("reporting") ? (
                    <li className="collapsed">
                      <NavLink
                        to="/reporting"
                        className={({ isActive }) => {
                          if (isActive) {
                            setCurrentTab("TimeSheet");
                            return "m-link active";
                          }
                          return "m-link";
                        }}
                        onClick={() => changeSidebar("Reporting")}
                      >
                        <span>Filled Reportings</span>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )} */}
                </ul>
              </li>
            ) : (
              ""
            )}

            {/* Documentation menu bar collapse */}
            {["doc_tag_manager"].some((privilege) =>
              props.privileges.includes(privilege)
            ) ? (
              <li className="collapsed">
                <Link
                  to="/docgen"
                  className="m-link"
                  data-bs-toggle="collapse"
                  data-bs-target="#document-Components"
                >
                  <i className="icofont-paper"></i> <span>Documentation</span>{" "}
                  <span className="arrow icofont-dotted-down ms-auto text-end fs-5"></span>
                </Link>
                {/* <!-- Menu: Sub menu ul --> */}
                <ul
                  className={`sub-menu collapse ${
                    currentTab === "Documentation" && "show"
                  }`}
                  id="document-Components"
                >
                  {props.privileges.includes("doc_tag_manager") ? (
                    <li className="collapsed">
                      <NavLink
                        to="/docgen"
                        className={({ isActive }) => {
                          if (isActive) {
                            setCurrentTab("Documentation");
                            return "m-link active";
                          }
                          return "m-link";
                        }}
                        onClick={() => changeSidebar("Doc_GEN")}
                      >
                        {/* <i className="icofont-file-document"></i> */}
                        <span>Document manager</span>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </li>
            ) : (
              ""
            )}

            {/* Project Managemnet Menu Bar */}
            {[
              "project",
              "sub_project",
              "project_settings",
              "project_category",
              "project_labels",
              "project_cards",
              "project_summary",
              "project_card_list",
              "project_card_gantt",
            ].some((privilege) => props.privileges.includes(privilege)) ? (
              <li className="collapsed">
                <Link
                  to="/projects"
                  className="m-link"
                  data-bs-toggle="collapse"
                  data-bs-target="#project-Components"
                >
                  <i className="icofont-clip-board"></i>{" "}
                  <span>Project Management</span>{" "}
                  <span className="arrow icofont-dotted-down ms-auto text-end fs-5"></span>
                </Link>
                {/* <!-- Menu: Sub menu ul --> */}
                <ul
                  className={`sub-menu collapse ${
                    (currentTab === "Project Management" ||
                      currentTab === "Project Settings" ||
                      currentTab === "Project Cards") &&
                    "show"
                  }`}
                  id="project-Components"
                >
                  {props.privileges.includes("project") ? (
                    <li className="collapsed">
                      <NavLink
                        to="/projects"
                        className={({ isActive }) => {
                          if (isActive) {
                            setCurrentTab("Project Management");
                            return "m-link active";
                          }
                          return "m-link";
                        }}
                        onClick={() => changeSidebar("projects")}
                      >
                        {/* <i className="icofont-clip-board"></i> */}
                        <span>Projects</span>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}
                  {props.privileges.includes("sub_project") ? (
                    <li className="collapsed">
                      <NavLink
                        to="/subproject"
                        className={({ isActive }) => {
                          if (isActive) {
                            setCurrentTab("Project Management");
                            return "m-link active";
                          }
                          return "m-link";
                        }}
                        onClick={() => changeSidebar("subProject")}
                      >
                        {/* <i className="icofont-clip-board"></i> */}
                        <span>Sub Project</span>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}
                  {props.privileges.includes("project_status") ? (
                    <li className="collapsed">
                      <NavLink
                        to="/projectstatus"
                        className={({ isActive }) => {
                          if (isActive) {
                            setCurrentTab("Project Management");
                            return "m-link active";
                          }
                          return "m-link";
                        }}
                        onClick={() => changeSidebar("subProject")}
                      >
                        <span>Projects Status</span>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}
                  {props.privileges.includes("project_summary") ? (
                    <li className="collapsed">
                      <NavLink
                        to="/projectspreadsheet"
                        className={({ isActive }) => {
                          if (isActive) {
                            setCurrentTab("Project Management");
                            return "m-link active";
                          }
                          return "m-link";
                        }}
                        onClick={() => changeSidebar("projectSummary")}
                      >
                        <span>Project Spreadsheet</span>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}
                  {["project_card_list", "project_card_gantt"].some(
                    (privilege) => props.privileges.includes(privilege)
                  ) ? (
                    // </li>
                    <li className="collapsed">
                      <Link
                        to="/projectcardslistview"
                        className="m-link"
                        data-bs-toggle="collapse"
                        data-bs-target="#project-cards-Components"
                      >
                        <span>Project Cards</span>{" "}
                        <span className="arrow icofont-dotted-down ms-auto text-end fs-5"></span>
                      </Link>
                      {/* <!-- Menu: Sub menu ul --> */}
                      <ul
                        className={`sub-menu collapse ${
                          currentTab === "Project Cards" && "show"
                        }`}
                        id="project-cards-Components"
                      >
                        {props.privileges.includes("project_card_list") ? (
                          <li className="collapsed">
                            <NavLink
                              to="/projectcardslistview"
                              className={({ isActive }) => {
                                if (isActive) {
                                  setCurrentTab("Project Cards");
                                  return "m-link active";
                                }
                                return "m-link";
                              }}
                              onClick={() => changeSidebar("projectCards")}
                            >
                              {/* <i className="icofont-clip-board"></i> */}
                              <span>List View</span>
                            </NavLink>
                          </li>
                        ) : (
                          ""
                        )}
                        {props.privileges.includes("project_card_gantt") ? (
                          <li className="collapsed">
                            <NavLink
                              to="/ganttview"
                              className={({ isActive }) => {
                                if (isActive) {
                                  setCurrentTab("Project Cards");
                                  return "m-link active";
                                }
                                return "m-link";
                              }}
                              onClick={() => changeSidebar("projectCards")}
                            >
                              {/* <i className="icofont-clip-board"></i> */}
                              <span>Gantt View</span>
                            </NavLink>
                          </li>
                        ) : (
                          ""
                        )}
                      </ul>
                    </li>
                  ) : (
                    ""
                  )}
                  {props.privileges.includes("project_settings") ? (
                    <li className="collapsed">
                      <Link
                        to="/projectcategories"
                        className="m-link"
                        data-bs-toggle="collapse"
                        data-bs-target="#project-settings-Components"
                      >
                        <span>Settings</span>{" "}
                        <span className="arrow icofont-dotted-down ms-auto text-end fs-5"></span>
                      </Link>
                      {/* <!-- Menu: Sub menu ul --> */}
                      <ul
                        className={`sub-menu collapse ${
                          currentTab === "Project Settings" && "show"
                        }`}
                        id="project-settings-Components"
                      >
                        {props.privileges.includes("project_category") ? (
                          <li className="collapsed">
                            <NavLink
                              to="/projectcategories"
                              className={({ isActive }) => {
                                if (isActive) {
                                  setCurrentTab("Project Settings");
                                  return "m-link active";
                                }
                                return "m-link";
                              }}
                              onClick={() => changeSidebar("project-cat")}
                            >
                              {/* <i className="icofont-clip-board"></i> */}
                              <span>Project Categories</span>
                            </NavLink>
                          </li>
                        ) : (
                          ""
                        )}
                        {props.privileges.includes("project_labels") ? (
                          <li className="collapsed">
                            <NavLink
                              to="/projecttags"
                              className={({ isActive }) => {
                                if (isActive) {
                                  setCurrentTab("Project Settings");
                                  return "m-link active";
                                }
                                return "m-link";
                              }}
                              onClick={() => changeSidebar("project-tag")}
                            >
                              {/* <i className="icofont-clip-board"></i> */}
                              <span>Project Tags</span>
                            </NavLink>
                          </li>
                        ) : (
                          ""
                        )}
                      </ul>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </li>
            ) : (
              ""
            )}

            {/* Project Managemnet Menu Bar */}
            {["department"].some((privilege) =>
              props.privileges.includes(privilege)
            ) ? (
              <li className="collapsed">
                <Link
                  to="/departments"
                  className="m-link"
                  data-bs-toggle="collapse"
                  data-bs-target="#department-Components"
                >
                  <i className="icofont-people"></i>{" "}
                  <span>Department Management</span>{" "}
                  <span className="arrow icofont-dotted-down ms-auto text-end fs-5"></span>
                </Link>
                {/* <!-- Menu: Sub menu ul --> */}
                <ul
                  className={`sub-menu collapse ${
                    currentTab === "Department Management" && "show"
                  }`}
                  id="department-Components"
                >
                  {props.privileges.includes("department") ? (
                    <li className="collapsed">
                      <NavLink
                        to="/departments"
                        className={({ isActive }) => {
                          if (isActive) {
                            setCurrentTab("Department Management");
                            return "m-link active";
                          }
                          return "m-link";
                        }}
                        onClick={() => changeSidebar("projects")}
                      >
                        {/* <i className="icofont-clip-board"></i> */}
                        <span>Departments</span>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}
                  {props.privileges.includes("department_stats") ? (
                    <li className="collapsed">
                      <NavLink
                        to="/departmentstatus"
                        className={({ isActive }) => {
                          if (isActive) {
                            setCurrentTab("Department Management");
                            return "m-link active";
                          }
                          return "m-link";
                        }}
                        onClick={() => changeSidebar("subProject")}
                      >
                        <span>Departments Status</span>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </li>
            ) : (
              ""
            )}

            {/* Project Managemnet Menu Bar */}
            {["password", "password_seq"].some((privilege) =>
              props.privileges.includes(privilege)
            ) ? (
              <li className="collapsed">
                <Link
                  to="/password"
                  className="m-link"
                  data-bs-toggle="collapse"
                  data-bs-target="#password-Components"
                >
                  <i className="icofont-ui-password"></i>{" "}
                  <span>Password Management</span>{" "}
                  <span className="arrow icofont-dotted-down ms-auto text-end fs-5"></span>
                </Link>
                {/* <!-- Menu: Sub menu ul --> */}
                <ul
                  className={`sub-menu collapse ${
                    currentTab === "Password Management" && "show"
                  }`}
                  id="password-Components"
                >
                  {props.privileges.includes("password") ? (
                    <li className="collapsed">
                      <NavLink
                        to="/password"
                        className={({ isActive }) => {
                          if (isActive) {
                            setCurrentTab("Password Management");
                            return "m-link active";
                          }
                          return "m-link";
                        }}
                        onClick={() => changeSidebar("password")}
                      >
                        <span>Password</span>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}
                  {props.privileges.includes("password_seq") ? (
                    <li className="collapsed">
                      <NavLink
                        to="/passwordsequence"
                        className={({ isActive }) => {
                          if (isActive) {
                            setCurrentTab("Password Management");
                            return "m-link active";
                          }
                          return "m-link";
                        }}
                        onClick={() => changeSidebar("password")}
                      >
                        {/* <i className="icofont-clip-board"></i> */}
                        <span>Password Sequence</span>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </li>
            ) : (
              ""
            )}

            {/* User Managemnet Menu Bar */}
            {[
              "users_list",
              "reporting_leads",
              "assign_epc",
              "technical_roles",
            ].some((privilege) => props.privileges.includes(privilege)) ? (
              <li className="collapsed">
                <Link
                  to="/userslist"
                  className="m-link"
                  data-bs-toggle="collapse"
                  data-bs-target="#users-Components"
                >
                  <i className="icofont-user"></i> <span>Staff Management</span>{" "}
                  <span className="arrow icofont-dotted-down ms-auto text-end fs-5"></span>
                </Link>
                {/* <!-- Menu: Sub menu ul --> */}
                <ul
                  className={`sub-menu collapse ${
                    currentTab === "User Management" && "show"
                  }`}
                  id="users-Components"
                >
                  {props.privileges.includes("users_list") ? (
                    <li className="collapsed">
                      <NavLink
                        to="/stafflist"
                        className={({ isActive }) => {
                          if (isActive) {
                            setCurrentTab("User Management");
                            return "m-link active";
                          }
                          return "m-link";
                        }}
                        onClick={() => changeSidebar("usermanagement")}
                      >
                        {/* <i className="icofont-clip-board"></i> */}
                        <span>Staff List</span>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}

                  {props.privileges.includes("reporting_leads") ? (
                    <li className="collapsed">
                      <NavLink
                        to="/reportingleads"
                        className={({ isActive }) => {
                          if (isActive) {
                            setCurrentTab("User Management");
                            return "m-link active";
                          }
                          return "m-link";
                        }}
                        onClick={() => changeSidebar("ReportingLeads")}
                      >
                        {/* <i className="icofont-clip-board"></i> */}
                        <span>Manage Leads</span>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}
                  {props.privileges.includes("assign_epc") ? (
                    <li className="collapsed">
                      <NavLink
                        to="/assignepc"
                        className={({ isActive }) => {
                          if (isActive) {
                            setCurrentTab("User Management");
                            return "m-link active";
                          }
                          return "m-link";
                        }}
                        onClick={() => changeSidebar("AssignEPC")}
                      >
                        {/* <i className="icofont-clip-board"></i> */}
                        <span>ID cards</span>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}
                  {props.privileges.includes("technical_roles") ? (
                    <li className="collapsed">
                      <NavLink
                        to="/techroles"
                        className={({ isActive }) => {
                          if (isActive) {
                            setCurrentTab("User Management");
                            return "m-link active";
                          }
                          return "m-link";
                        }}
                        onClick={() => changeSidebar("TechRoles")}
                      >
                        {/* <i className="icofont-clip-board"></i> */}
                        <span>Technical Roles</span>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </li>
            ) : (
              ""
            )}

            {/* Team Managemnet Menu Bar */}
            {[
              "employee_timesheet",
              "team_reporting",
              "add_task_card",
              "noticeboard",
              "correct_timesheet",
            ].some((privilege) => props.privileges.includes(privilege)) ? (
              <li className="collapsed">
                <Link
                  to="/viewtimesheet"
                  className="m-link"
                  data-bs-toggle="collapse"
                  data-bs-target="#team-Components"
                >
                  <i className="icofont-users-alt-5"></i>{" "}
                  <span>Team Management</span>{" "}
                  <span className="arrow icofont-dotted-down ms-auto text-end fs-5"></span>
                </Link>
                {/* <!-- Menu: Sub menu ul --> */}
                <ul
                  className={`sub-menu collapse ${
                    currentTab === "Team Management" && "show"
                  }`}
                  id="team-Components"
                >
                  {/* Privilege to view all timesheets */}
                  {props.privileges.includes("employee_timesheet") ? (
                    <li className="collapsed">
                      {/* Logic to add active class dynamically */}
                      <NavLink
                        to="/viewtimesheet"
                        className={({ isActive }) => {
                          if (isActive) {
                            setCurrentTab("Team Management");
                            return "m-link active";
                          }
                          return "m-link";
                        }}
                        onClick={() => changeSidebar("ViewTimeSheet")}
                      >
                        {/* <i className="icofont-admin fs-5"></i>  */}
                        <span>Team Timesheets</span>{" "}
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}

                  {props.privileges.includes("team_reporting") ? (
                    <li className="collapsed">
                      <NavLink
                        to="/teamreport"
                        className={({ isActive }) => {
                          if (isActive) {
                            setCurrentTab("Team Management");
                            return "m-link active";
                          }
                          return "m-link";
                        }}
                        onClick={() => changeSidebar("TeamReport")}
                      >
                        <span>Team Reports</span>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}

                  {props.privileges.includes("add_task_card") ? (
                    <li className="collapsed">
                      <NavLink
                        to="addtask"
                        className={({ isActive }) => {
                          if (isActive) {
                            setCurrentTab("Team Management");
                            return "m-link active";
                          }
                          return "m-link";
                        }}
                        onClick={() => changeSidebar("addTask")}
                      >
                        {/* <i className="icofont-paperclip"></i> */}
                        <span>Add Timesheet Card</span>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}

                  {/* Notice Board  */}
                  {props.privileges.includes("noticeboard") ? (
                    <li className="collapsed">
                      <NavLink
                        to="/noticeboard"
                        className={({ isActive }) => {
                          if (isActive) {
                            setCurrentTab("Team Management");
                            return "m-link active";
                          }
                          return "m-link";
                        }}
                        onClick={() => changeSidebar("noticeboard")}
                      >
                        <span>Notice Board</span>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}

                  {/* {props.privileges.includes("correct_timesheet") ? (
                    <li className="collapsed">
                      <NavLink
                        to="/correcttimesheet"
                        className={({ isActive }) => {
                          if (isActive) {
                            setCurrentTab("Team Management");
                            return "m-link active";
                          }
                          return "m-link";
                        }}
                        onClick={() => changeSidebar("Correct_TS")}
                      >
                        <span>Correct Timesheet</span>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )} */}
                </ul>
              </li>
            ) : (
              ""
            )}

            {props.privileges.includes("ticket") ? (
              <li className="collapsed">
                <NavLink
                  to="/ticket"
                  className={`m-link ${isActive === "Ticket" ? "active" : ""}`}
                  onClick={() => changeSidebar("Ticket")}
                >
                  <i className="icofont-tag"></i> <span>Ticket</span>
                </NavLink>
              </li>
            ) : (
              ""
            )}

            {/* Resource Management Menu bar */}
            {[
              "resource_mapping",
              "resource_mapping_sheet",
              "resource_spreadsheet",
              "resource_dashboard",
            ].some((privilege) => props.privileges.includes(privilege)) ? (
              <li className="collapsed">
                <Link
                  to="/resourcemapping"
                  className="m-link"
                  data-bs-toggle="collapse"
                  data-bs-target="#resource-Components"
                >
                  <i className="icofont-notepad"></i>{" "}
                  <span>Resource Management</span>{" "}
                  <span className="arrow icofont-dotted-down ms-auto text-end fs-5"></span>
                </Link>
                {/* <!-- Menu: Sub menu ul --> */}
                <ul
                  className={`sub-menu collapse ${
                    currentTab === "Resource Management" && "show"
                  }`}
                  id="resource-Components"
                >
                  {props.privileges.includes("resource_mapping") ? (
                    <li className="collapsed">
                      <NavLink
                        to="/resourcemapping"
                        className={({ isActive }) => {
                          if (isActive) {
                            setCurrentTab("Resource Management");
                            return "m-link active";
                          }
                          return "m-link";
                        }}
                        onClick={() => changeSidebar("resourcemapping")}
                      >
                        <span>Resource Mapping</span>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}
                  {props.privileges.includes("resource_mapping_sheet") ? (
                    <li className="collapsed">
                      <NavLink
                        to="/resourcesheet"
                        className={({ isActive }) => {
                          if (isActive) {
                            setCurrentTab("Resource Management");
                            return "m-link active";
                          }
                          return "m-link";
                        }}
                        onClick={() => changeSidebar("resourcesheet")}
                      >
                        <span>Resource Sheet</span>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}
                  {props.privileges.includes("resource_dashboard") ? (
                    <li className="collapsed">
                      <NavLink
                        to="/resourcedashboard"
                        className={({ isActive }) => {
                          if (isActive) {
                            setCurrentTab("Resource Management");
                            return "m-link active";
                          }
                          return "m-link";
                        }}
                        onClick={() => changeSidebar("resourcedashboard")}
                      >
                        <span>Resource Dashboard</span>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}
                  {props.privileges.includes("resource_spreadsheet") ? (
                    <li className="collapsed">
                      <NavLink
                        to="/resourcespreadsheet"
                        className={({ isActive }) => {
                          if (isActive) {
                            setCurrentTab("Resource Management");
                            return "m-link active";
                          }
                          return "m-link";
                        }}
                        onClick={() => changeSidebar("resourcespreadsheet")}
                      >
                        <span>Resource Spreadsheet</span>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </li>
            ) : (
              ""
            )}

            {/* Leave Status Menu bar */}
            {["leave_request", "leave_application", "leave_status"].some(
              (privilege) => props.privileges.includes(privilege)
            ) ? (
              <li className="collapsed">
                <Link
                  to="/leave"
                  className="m-link"
                  data-bs-toggle="collapse"
                  data-bs-target="#leave-Components"
                >
                  <i className="icofont-user-male fs-5"></i>{" "}
                  <span>Leave Management</span>{" "}
                  <span className="arrow icofont-dotted-down ms-auto text-end fs-5"></span>
                </Link>
                {/* <!-- Menu: Sub menu ul --> */}
                <ul
                  className={`sub-menu collapse ${
                    currentTab === "Leave Management" && "show"
                  }`}
                  id="leave-Components"
                >
                  {props.privileges.includes("leave_request") ? (
                    <li className="collapsed">
                      <NavLink
                        to="/leave"
                        className={({ isActive }) => {
                          if (isActive) {
                            setCurrentTab("Leave Management");
                            return "m-link active";
                          }
                          return "m-link";
                        }}
                        onClick={() => changeSidebar("leave")}
                      >
                        {/* <i className="icofont-student-alt"></i> */}
                        <span>Leave Request</span>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}
                  {props.privileges.includes("leave_application") ? (
                    <li className="collapsed">
                      <NavLink
                        to="/leaveapplication"
                        className={({ isActive }) => {
                          if (isActive) {
                            setCurrentTab("Leave Management");
                            return "m-link active";
                          }
                          return "m-link";
                        }}
                        onClick={() => changeSidebar("leaveApplication")}
                      >
                        {/* <i className="icofont-student-alt"></i> */}
                        <span>Leave Application</span>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}
                  {/* {props.privileges.includes('leave_avaliable') ?
                  <li className="collapsed">
                    <a className={`m-link ${isActive === "leaveAvaliable" ? "active" : ""}`} href="javascript:void(0)" onClick={() => changeSidebar("leaveAvaliable")}>
                      <i className="icofont-student-alt"></i>
                      <span>Leave Available</span></a>
                  </li> : ""} */}
                  {props.privileges.includes("leave_status") ? (
                    <li className="collapsed">
                      <NavLink
                        to="/leavestatus"
                        className={({ isActive }) => {
                          if (isActive) {
                            setCurrentTab("Leave Management");
                            return "m-link active";
                          }
                          return "m-link";
                        }}
                        onClick={() => changeSidebar("leaveStatus")}
                      >
                        {/* <i className="icofont-student-alt"></i> */}
                        <span>Leave Status</span>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </li>
            ) : (
              ""
            )}

            {[
              "TaskManagement",
              "regular_card_board",
              "target_card_board",
              "meetings",
            ].some((privilege) => props.privileges.includes(privilege)) ? (
              <li className="collapsed">
                <Link
                  to="/taskmanagement"
                  className="m-link"
                  data-bs-toggle="collapse"
                  data-bs-target="#taskmanagement-Components"
                >
                  <i className="icofont-tasks icofont-md"></i>
                  <span style={{ whiteSpace: "nowrap" }}>
                    Task Management{" "}
                    <span
                      style={{
                        marginLeft: "0px",
                        fontSize: ".8em",
                        color: "yellow",
                      }}
                    >
                      BETA
                    </span>
                  </span>
                  <span className="arrow icofont-dotted-down ms-auto text-end fs-5"></span>
                </Link>
                {/* <!-- Menu: Sub menu ul --> */}
                <ul
                  className={`sub-menu collapse ${
                    currentTab === "Task Management" && "show"
                  }`}
                  id="taskmanagement-Components"
                >
                  {props.privileges.includes("regular_card_board") &&
                  regularCardProjectId ? (
                    <li className="collapsed">
                      <NavLink
                        // To-Do: Manually change regular card project id here...
                        to={`/regularcardboard_${regularCardProjectId}`}
                        className={({ isActive }) => {
                          if (isActive) {
                            setCurrentTab("Task Management");
                            return "m-link active";
                          }
                          return "m-link";
                        }}
                        // To-Do: Manually change regular card project id here...
                        onClick={() =>
                          handleTaskmanagement(regularCardProjectId)
                        }
                      >
                        {/* <i className="icofont-student-alt"></i> */}
                        <span>Regular Card Board</span>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}
                  {props.privileges.includes("target_card_board") &&
                  targetCardProjectId ? (
                    <li className="collapsed">
                      <NavLink
                        // To-Do: Manually change target card project id here...
                        to={`/targetcardboard_${targetCardProjectId}`}
                        className={({ isActive }) => {
                          if (isActive) {
                            setCurrentTab("Task Management");
                            return "m-link active";
                          }
                          return "m-link";
                        }}
                        // To-Do: Manually change target card project id here...
                        onClick={() =>
                          handleTaskmanagement(targetCardProjectId)
                        }
                      >
                        {/* <i className="icofont-student-alt"></i> */}
                        <span>Target Card Board</span>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}
                  {props.privileges.includes("meetings") ? (
                    <li className="collapsed">
                      <NavLink
                        to={`/meets_${meetProjectId}`}
                        className={({ isActive }) => {
                          if (isActive) {
                            setCurrentTab("Task Management");
                            return "m-link active";
                          }
                          return "m-link";
                        }}
                        onClick={() => handleTaskmanagement(meetProjectId)}
                      >
                        <span>Meets</span>
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </li>
            ) : (
              ""
            )}

            {[
              "crm_management",
              "bug_management",
              "stories_management",
              "expenses_management",
              "inventory_management",
              "release_management",
            ].some((privilege) => props.privileges.includes(privilege)) ? (
              <li className="collapsed">
                <a
                  className="m-link"
                  data-bs-toggle="collapse"
                  data-bs-target="#out-tools"
                  style={{ cursor: "pointer" }}
                >
                  <i className="icofont-tools"></i> <span>Our Tools</span>
                  <span className="arrow icofont-dotted-down ms-auto text-end fs-5"></span>
                </a>
                {/* <!-- Menu: Sub menu ul --> */}
                <ul
                  className={`sub-menu collapse ${
                    currentTab === "CRM Management" && "show"
                  }`}
                  id="out-tools"
                >
                  {props.privileges.includes("crm_management") ? (
                    <li className="collapsed">
                      <span
                        className={`m-link ${
                          isActive === "CRM Management" ? "active" : ""
                        }`}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          releaseMessage(
                            "CRM Management",
                            "CRM Tools",
                            "https://crm-forms.elorca.com/"
                          )
                        }
                      >
                        {/* <i className="icofont-file-document"></i> */}
                        <span>CRM Management</span>
                      </span>
                    </li>
                  ) : (
                    ""
                  )}
                  {props.privileges.includes("bug_management") ? (
                    <li className="collapsed">
                      <span
                        className={`m-link ${
                          isActive === "Bug management" ? "active" : ""
                        }`}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          releaseMessage(
                            "Bug Management",
                            "Bugzilla",
                            "https://bugzilla.techwalnut.co.in/"
                          )
                        }
                      >
                        {/* <i className="icofont-file-document"></i> */}
                        <span>Bugs Management</span>
                      </span>
                    </li>
                  ) : (
                    ""
                  )}
                  {props.privileges.includes("stories_management") ? (
                    <li className="collapsed">
                      <span
                        className={`m-link ${
                          isActive === "Users-Stories management"
                            ? "active"
                            : ""
                        }`}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          releaseMessage(
                            "Users-Stories Management",
                            "Beaver",
                            "https://beaver.techwalnut.co.in/"
                          )
                        }
                      >
                        {/* <i className="icofont-file-document"></i> */}
                        <span>Users-Stories Management</span>
                      </span>
                    </li>
                  ) : (
                    ""
                  )}
                  {props.privileges.includes("expenses_management") ? (
                    <li className="collapsed">
                      <span
                        className={`m-link ${
                          isActive === "Expense management" ? "active" : ""
                        }`}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          releaseMessage(
                            "Expense Management",
                            "Expense Tracker",
                            "https://et.techwalnut.co.in/"
                          )
                        }
                      >
                        {/* <i className="icofont-file-document"></i> */}
                        <span>Expenses Management</span>
                      </span>
                    </li>
                  ) : (
                    ""
                  )}
                  {props.privileges.includes("inventory_management") ? (
                    <li className="collapsed">
                      <span
                        className={`m-link ${
                          isActive === "Inventory management" ? "active" : ""
                        }`}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          releaseMessage(
                            "Inventory Management",
                            "IMS",
                            "https://ims.techwalnut.co.in/"
                          )
                        }
                      >
                        {/* <i className="icofont-file-document"></i> */}
                        <span>Inventory Management</span>
                      </span>
                    </li>
                  ) : (
                    ""
                  )}
                  {props.privileges.includes("release_management") ? (
                    <li className="collapsed">
                      <span
                        className={`m-link ${
                          isActive === "Releases management" ? "active" : ""
                        }`}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          releaseMessage(
                            "Releases Management",
                            "Beaver",
                            "https://beaver.techwalnut.co.in/"
                          )
                        }
                      >
                        <span>Releases Management</span>
                      </span>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </li>
            ) : (
              ""
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
