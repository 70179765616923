import axios from "axios";
import React, { useContext, useEffect } from "react";
import { Controller, useForm, useFormState } from "react-hook-form";
import Select from "react-select";
import { VARIABLES } from "../../Constants";
import Swal from "sweetalert2";
import { useState } from "react";
import secureLocalStorage from "react-secure-storage";
import { TaskManagementContext } from "../../context/TaskManagementContext/TaskManagementContextProvider";
import { UsersContext } from "../../context/UsersContext/UsersContextProvider";
import { DropdownAPIsContext } from "../../context/DropdownAPIsContext/DropdownAPIsContextProvider";
import { ProjectContext } from "../../context/ProjectContext/ProjectContextProvider";

const ResourceMappingCreate = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm();
  const { isDirty, isValid } = useFormState({
    control,
  });
  const userID = secureLocalStorage.getItem("userID");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [projectDetails, setprojectDetails] = useState([]);

  const [employeeId, setEmployeeId] = useState([]);

  const { boardsList, getTasksList } = useContext(TaskManagementContext);
  const { targetCardBoardId, targetTypes } = useContext(DropdownAPIsContext);
  const {
    apiGetProjectByUser,
    projectByUser,
    usersProjectsLoading,
    resetProjectByUser,
  } = useContext(ProjectContext);
  const subProjectForProject = props.subProject.filter((api) => {
    return api.project.id == projectDetails.value;
  });
  const { users, isUsersLoading } = useContext(UsersContext);

  const employeeforOrganization = props.rawProjectData.filter((api) => {
    return (
      api.team.find((data) => data.id === employeeId.value) ||
      api.project_incharge.id === employeeId.value
    );
  });

  const options = employeeforOrganization.map((project) => ({
    label: project.project_name,
    value: project.id,
  }));

  const onSubmit = (data) => {
    var resourceMapping = JSON.stringify({
      project_id: projectDetails.value,
      sub_project_id: data.sub_project.value,
      employee_id: data.employee.value,
      week_number: data.week_number,
      target: data.target,
      release: data.release,
      checklist: data.checklist,
      start_date: startDate,
      end_date: endDate,
      hours: data.hours,
      target_type: data.target_type.value,
      // To-Do: change target card board id manually
      board_id_id: targetCardBoardId,
      created_by_id: userID,
      updated_by_id: userID,
    });
    var config = {
      method: "post",
      url: VARIABLES.url + "/api-app/resource-mapping",
      headers: {
        "Content-Type": "application/json",
      },
      data: resourceMapping,
    };

    axios(config)
      .then((response) => {
        let targetcardboard = null;
        boardsList?.map((board) => {
          if (board.name === "Target Card Board") {
            targetcardboard = board.id;
          }
        });
        if (targetcardboard !== null) {
          getTasksList(targetcardboard);
        }
        Swal.fire({
          title: "Success!",
          text: "Mapping Resource Created Successfully",
          icon: "success",
          confirmButtonText: "OK",
        }).then(() => props.apiRawResourceMapping());
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Error",
          text: "Failed to Create Mapping Resource",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
    reset();
    resetProjectByUser();
    setprojectDetails("");
    setEmployeeId("");
  };

  useEffect(() => {
    // Calculate start and end dates with specific times when the component mounts
    const today = new Date();

    // Set specific times for start and end dates
    const startDateTime = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      10,
      0,
      0
    ); // 10:00 AM
    const endDateTime = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      19,
      0,
      0
    ); // 7:00 PM

    // Find the start of the week (Monday)
    const startOfWeek = new Date(startDateTime);
    startOfWeek.setDate(
      startDateTime.getDate() -
        startDateTime.getDay() +
        (startDateTime.getDay() === 0 ? -6 : 1)
    );

    // Find the end of the week (Saturday)
    const endOfWeek = new Date(endDateTime);
    endOfWeek.setDate(startOfWeek.getDate() + 5);

    setStartDate(startOfWeek.toISOString());
    setEndDate(endOfWeek.toISOString());
  }, []); // Empty dependency array ensures this effect runs only once, when the component mounts

  const getResetValues = () => {
    reset();
    setprojectDetails([]);
    setEmployeeId([]);
    resetProjectByUser();
  };

  useEffect(() => {
    if (employeeId !== null || employeeId.value !== undefined)
      apiGetProjectByUser(employeeId.value);
  }, [employeeId]);

  return (
    <>
      <div className="modal fade" id="depadd" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title fw-bold" id="depaddLabel">
                Mapping Resource
              </h5>
              <button
                type="button"
                onClick={() => getResetValues()}
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className="mb-3">
                  <label className="form-label">
                    Employee <span className="required_field">*</span>
                  </label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        value={employeeId}
                        onChange={(data) => {
                          setEmployeeId(data);
                          field.onChange(data);
                        }}
                        options={users}
                        isLoading={isUsersLoading}
                      />
                    )}
                    defaultValue=""
                    rules={{ required: true }}
                    name="employee"
                    control={control}
                  />
                  {errors.employee && (
                    <span className="required_field">
                      Employee name is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Project <span className="required_field">*</span>
                  </label>
                  <Controller
                    defaultValue=""
                    name="project"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        onChange={(data) => {
                          setprojectDetails(data);
                          field.onChange(data);
                        }}
                        isLoading={usersProjectsLoading}
                        options={projectByUser}
                      />
                    )}
                  />
                  {errors.project && (
                    <span className="required_field">
                      Project name is required
                    </span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">Sub Project</label>
                  <Controller
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={subProjectForProject.map((userData) => ({
                          value: userData.id,
                          label: userData.name,
                        }))}
                      />
                    )}
                    defaultValue=""
                    name="sub_project"
                    control={control}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Target Type</label>
                  <Controller
                    render={({ field }) => (
                      <Select {...field} options={targetTypes} />
                    )}
                    defaultValue=""
                    name="target_type"
                    control={control}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Week Number <span className="required_field">*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Week Number"
                    {...register("week_number", { required: true })}
                  />
                  {errors.week_number && (
                    <span className="required_field">Week No is required</span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Target <span className="required_field">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Targets"
                    {...register("target", { required: true })}
                  />
                  {errors.target && (
                    <span className="required_field">Targets is required</span>
                  )}
                </div>
                <div className="mb-3">
                  <label className="form-label">Release Number</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Release Number"
                    {...register("release")}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">
                    Checklist <span className="required_field">*</span>
                  </label>
                  <textarea
                    className="form-control"
                    rows="3"
                    placeholder="Add Checklist related to project"
                    {...register("checklist", { required: true })}
                  ></textarea>
                  {errors.checklist && (
                    <span className="required_field">
                      Checklist is required
                    </span>
                  )}
                </div>
                {/* <div className="deadline-form">
                  <div className="row g-3 mb-3">
                    <div className="col">
                      <label className="form-label">Start Date <span className="required_field">*</span></label>
                      <input type="datetime-local" className="form-control" {...register("start_date", { required: true })} />
                      {errors.start_date && <span className='required_field'>Start Date is required</span>}
                    </div>
                    <div className="col">
                      <label className="form-label">End Date <span className="required_field">*</span></label>
                      <input type="datetime-local" className="form-control" {...register("end_date", { required: true })} />
                      {errors.end_date && <span className='required_field'>End Date is required</span>}
                    </div>
                  </div>
                </div> */}
                <div className="mb-3">
                  <label className="form-label">
                    Hours <span className="required_field">*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Hours"
                    {...register("hours", { required: true })}
                  />
                  {errors.hours && (
                    <span className="required_field">Hours are required</span>
                  )}
                </div>
                <div className="modal-footer">
                  {/* <button type="submit" className="btn btn-success">Submit</button> */}
                  {isValid === true ? (
                    <button
                      type="submit"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Submit
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResourceMappingCreate;
