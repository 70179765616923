import React, { useState, useEffect } from "react";
import axios from "axios";
import { Controller, useForm, useFormState } from "react-hook-form";
import Select from "react-select";
import Swal from "sweetalert2";
import { VARIABLES } from "../../Constants";
import secureLocalStorage from "react-secure-storage";

const TechnicalRolesEdit = (props) => {
  // TODO : Once we get the user credentials, uncomment it.
  const userID = secureLocalStorage.getItem("userID");

  // react hook form
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    reset,
  } = useForm();

  const { isValid } = useFormState({ control });

  // onSubmit handler for form submitting
  const onSubmit = (data) => {
    var form_data = JSON.stringify({
      name: data.name,
      description: data.description,
      privileges_id: data.privileges_id.map((privileg) => privileg.value), // TODO : Until we gets userID, use default value as 1.
      updated_by_id: userID,
    });

    // TODO : Check the API properly from postman
    const url =
      VARIABLES.url + `/api-app/technology-role/${props.editTechnicalRoles.id}`;
    var config = {
      method: "patch",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: form_data,
    };
    axios(config)
      .then((response) => {
        Swal.fire({
          title: "Success!",
          text: "Technical Role Edited Successfully",
          icon: "success",
          confirmButtonText: "OK",
        });
        props.getTechnicalRoles();
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: "Error",
          text: "Failed",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
    reset();
  };

  //   pre-population of values after clicking on edit button
  useEffect(() => {
    if (props.editTechnicalRoles) {
      const editedTechnicalRoles = props.editTechnicalRoles;
      if (editedTechnicalRoles) {
        var defaultValues = {};
        defaultValues.name = editedTechnicalRoles.name;
        defaultValues.description = editedTechnicalRoles.description;
        if (editedTechnicalRoles.privileges !== null) {
          const getprivileges_id = editedTechnicalRoles.privileges.map(
            (privilege) => {
              return {
                value: privilege.id,
                label: privilege.name,
              };
            }
          );
          defaultValues.privileges_id = getprivileges_id;
        }
        reset(defaultValues);
      }
    }
  }, [props.editTechnicalRoles]);

  const getResetValues = () => {
    reset();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div
          className="modal fade"
          id="editTechRolesModal"
          tabIndex="-1"
          data-bs-backdrop="static"
          aria-labelledby="depaddLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title fw-bold" id="depaddLabel">
                  Edit Technical Role
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => getResetValues()}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row g-4">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label className="form-label">
                      {" "}
                      name
                      <span className="required_field">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter The Technology Name"
                      {...register("name", { required: true })}
                    />
                    {errors.name && (
                      <span className="required_field">Name is required</span>
                    )}
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <label className="form-label"> description</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter The Description Name"
                      {...register("description")}
                    />
                  </div>

                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <label className="form-label">
                      {" "}
                      privileges_id
                      <span className="required_field">*</span>
                    </label>
                    <Controller
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={props.privilegesData} //TODO : your_option_name
                          isClearable={true}
                          isMulti
                        />
                      )}
                      defaultValue=""
                      rules={{ required: true }}
                      name="privileges_id"
                      control={control}
                    />
                    {errors.privileges_id && (
                      <span className="required_field">
                        Privileges are required{" "}
                      </span>
                    )}
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      {isValid === true ? (
                        <button
                          type="submit"
                          className="btn btn-primary"
                          data-bs-dismiss="modal"
                        >
                          Submit
                        </button>
                      ) : (
                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default TechnicalRolesEdit;
