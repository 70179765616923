import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { VARIABLES } from "../../Constants";
import axios from "axios";
import Select from "react-select";
import EditTimesheet from "../Forms/EditTimesheet";
import secureLocalStorage from "react-secure-storage";
import FillTimesheet from "../Forms/FillTimesheet";
import { DropdownAPIsContext } from "../../context/DropdownAPIsContext/DropdownAPIsContextProvider";

const TimeSheetDetails = (props) => {
  // Declaring states
  const [timesheets, SetTimesheets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [formDetails, setFormDetails] = useState([]);
  const [taskDropdown, setTaskDropdown] = useState([]);
  const [projectDropdown, setProjectDropdown] = useState([]);
  const [taskLoading, setTaskLoading] = useState(false);
  const [projectLoading, setProjectLoading] = useState(false);
  const { taskByIdDropdown, projectByIdDropdown, loadingGenericById } =
    useContext(DropdownAPIsContext);

  const privileges = secureLocalStorage.getItem("privileges");

  var today = new Date();

  // Fetching current month
  const currentMonth = moment(today).format("MMMM");
  // Setting up month as string and setting the format "01"
  // var month = String(today.getMonth()+1).padStart(2, '0');
  var month = String(today.getMonth() + 1).padStart(1);

  const dropdownFormat = { value: month, label: currentMonth };

  const [filter, setFilter] = useState(dropdownFormat);

  const currentYear = moment(today).format("YYYY");

  const dropdownYearFormat = { value: currentYear, label: currentYear };

  const [yearFilter, setYearFilter] = useState(dropdownYearFormat);

  // Month dropdown
  const months = [
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const years = [
    { value: "2021", label: "2021" },
    { value: "2022", label: "2022" },
    { value: "2023", label: "2023" },
    { value: "2024", label: "2024" },
  ];

  // Loading timesheets for current month
  const loadTimesheets = async (month, year) => {
    setLoading(true);
    const userID = secureLocalStorage.getItem("userID");
    // const url = VARIABLES.url+'/api/timesheet-filling/?date='+month.value+'&user__id='+parseInt(userID);
    const url =
      VARIABLES.url +
      "/api-app/timesheet-filling?user_id=" +
      parseInt(userID) +
      "&project_id=&year=" +
      year.value +
      "&month=" +
      month.value;
    let header = { "Content-Type": "application/json" };

    let reqOptions = {
      url: url,
      method: "GET",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      mode: "no-cors",
    };

    axios.request(reqOptions).then(function (response) {
      var data = response.data;
      SetTimesheets(data.data);
      setLoading(false);
    });
  };

  const reloadTimesheets = () => {
    loadTimesheets(filter, yearFilter);
  };

  // Setting up Form edit data
  // Not functional yet
  const editForm = (form) => {
    setFormDetails(form);
    setIsEdit(true);
  };

  // Handling the modal close of edit form
  const handleCloseForm = () => {
    setIsEdit(false);
    setFormDetails({});
  };

  // Toggle view button used for mobileview sidebar toggle
  const handleToggleView = () => {
    props.toggleView("NoticeBoard");
  };

  useEffect(() => {
    setProjectLoading(true);
    if (projectByIdDropdown.length !== 0) {
      setProjectDropdown(
        projectByIdDropdown.map((project) => {
          return { value: project.id, label: project.project_name };
        })
      );
      setProjectLoading(false);
    } else if (!loadingGenericById) {
      setTaskLoading(false);
    }
  }, [projectByIdDropdown, loadingGenericById]);

  useEffect(() => {
    setTaskLoading(true);
    if (taskByIdDropdown.length !== 0) {
      setTaskDropdown(
        taskByIdDropdown.map((task) => {
          return {
            value: task.id,
            label: task?.card_identification_number + "- " + task?.card_title,
          };
        })
      );
      setTaskLoading(false);
    }
    if (!loadingGenericById) {
      setTaskLoading(false);
    }
  }, [taskByIdDropdown, loadingGenericById]);

  // Call loadTimesheets everytime filter state is changed
  useEffect(() => {
    loadTimesheets(filter, yearFilter);
  }, [filter, yearFilter]);

  return (
    <div className="row g-3 mb-3 row-deck">
      <div className="border-0">
        <div className="card-header p-0 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
          <h5 className="fw-bold py-3 mb-0"> Timesheet for {filter.label} </h5>
          <div className="d-flex py-2 project-tab flex-wrap w-sm-100">
            {/* {privileges.includes('tw_planner') ? <button type="button" className="btn btn-dark mx-2 btn-set-task w-sm-100" href="https://docs.google.com/spreadsheets/d/1TqcGG0jEK4PVBAL1xt9xr1YaqWtr2maxFvY5eUc_B_I/edit?usp=sharing" ><i className="icofont-file-spreadsheet me-2 fs-6"></i>TW-Planner</button> : "" } */}
            {privileges.includes("fill_timesheet") ? (
              <button
                type="button"
                className="fill-timesheet btn btn-dark mx-2 btn-set-task w-sm-100"
                data-bs-toggle="modal"
                data-bs-target="#createtask"
                id="button-m-g-02-filltimesheet"
              >
                <i className="icofont-plus-circle me-2 fs-6"></i>Fill Timesheet
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {/* <div className="col-md-12">
        <div className="card mb-3">
          <button type="button" className="btn btn-sm btn-dark btn-set-task w-sm-100" onClick={handleToggleView}><i className="icofont-billboard me-2 fs-6"></i>View Workdesk</button>

        </div>
      </div> */}
      <div className="col-md-12">
        <div className="card mb-3">
          <div className="card-header py-3 d-flex justify-content-between align-items-center">
            {/* <div className="info-header">
              <h6 className="mb-0 fw-bold view-timesheet">Filter</h6>
            </div> */}
            {privileges.includes("timesheet_month") ? (
              <div className="info-header">
                <h6> Timesheet Month </h6>
                <Select
                  onChange={setFilter}
                  options={months}
                  defaultValue={dropdownFormat}
                />
              </div>
            ) : (
              ""
            )}

            {privileges.includes("timesheet_year") ? (
              <div className="info-header">
                <h6> Timesheet Year </h6>
                <Select
                  onChange={setYearFilter}
                  options={years}
                  defaultValue={dropdownYearFormat}
                />
              </div>
            ) : (
              ""
            )}
          </div>
          {loading ? (
            <div className="card-body">Loading...</div>
          ) : (
            <div className="card-body">
              <table
                id="myProjectTable"
                className="App table table-hover align-middle mb-0"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th width="25%">Project</th>
                    <th width="15%">Task ID</th>
                    <th width="20%">Date</th>
                    <th width="15%">Working Hours</th>
                    {/* <th width="25%">Comments</th> */}
                  </tr>
                </thead>
                {timesheets.length === 0 && (
                  <tbody>
                    <tr>
                      <td></td>
                      <td>No data found</td>
                      <td></td>
                    </tr>
                  </tbody>
                )}

                {timesheets.length > 0 && (
                  <tbody>
                    {timesheets.map((item) => (
                      <tr key={item.id}>
                        <td>{item.project?.project_name}</td>
                        <td>{item.task_id?.card_identification_number}</td>
                        <td
                          className={`${
                            item.flag_status === 0 ? "text-danger" : ""
                          }`}
                        >
                          {moment(item?.date).format("MMMM Do YYYY")}
                        </td>
                        <td>{item?.hours}</td>
                        {/* <td>{item.comments}</td> */}

                        {/* Edit form button */}
                        {/* <td>
                                        <button 
                                            className='btn btn-danger btn-sm'
                                            onClick={()=>editForm(item)}
                                        >
                                            Edit
                                        </button>
                                    </td> */}
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          )}
        </div>
      </div>
      <FillTimesheet
        taskDropdown={taskDropdown}
        projectDropdown={projectDropdown}
        taskLoading={taskLoading}
        projectLoading={projectLoading}
        reloadTimesheets={reloadTimesheets}
      />
      {/* Edit form modal */}
      {/* <EditTimesheet 
                formDetails={formDetails} 
                showForm={isEdit} 
                closeForm={handleCloseForm}    
            />    */}
    </div>
  );
};

export default TimeSheetDetails;
