import React from "react";

const SignalsRaised = ({ signals }) => {
  return (
    <div className="card shadow">
      <div className="card-header">
        <div className="card-title">
          <h5>Signals</h5>
        </div>
      </div>
      <div className="card-body basic-custome-color p-0 m-0">
        <div className="table-responsive" style={{ maxHeight: "300px" }}>
          <table className="table">
            <thead>
              <tr
                className="bg-light"
                style={{
                  position: "sticky",
                  top: 0,
                }}
              >
                <th className="text-center">Sr.no</th>
                <th className="text-center">Signal</th>
              </tr>
            </thead>
            {signals === 0 ? (
              <tbody>
                <tr>
                  <td></td>
                  <td>No data found</td>
                  <td></td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {signals && signals.length !== 0 ? (
                  signals.map((signal, i) => (
                    <tr key={i}>
                      <td className="text-center">{i + 1}</td>
                      <td className="text-center">
                        {signal?.signal__flag_type}
                      </td>
                    </tr>
                  ))
                ) : (
                  <p className="card-header">No data found</p>
                )}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default SignalsRaised;
