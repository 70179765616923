import React, { useContext } from "react";
import secureLocalStorage from "react-secure-storage";
import { UsersContext } from "../../../context/UsersContext/UsersContextProvider";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { useMediaQuery } from "react-responsive";
import { TaskManagementContext } from "../../../context/TaskManagementContext/TaskManagementContextProvider";
import { DropdownAPIsContext } from "../../../context/DropdownAPIsContext/DropdownAPIsContextProvider";
import AddStructuralCard from "./forms/AddStructuralCard";
import AddMeetingCard from "./forms/AddMeetingCard";

function AddCard({ isOpen, setIsOpen }) {
  const userID = secureLocalStorage.getItem("userID");
  const { users, isUsersLoading } = useContext(UsersContext);
  const { currentBoardId, getTasksList } = useContext(TaskManagementContext);
  const { meetBoardId } = useContext(DropdownAPIsContext);
  const isMobile = useMediaQuery({
    query: "(max-width: 480px)",
  });

  return (
    <Drawer
      open={isOpen}
      onClose={() => {
        setIsOpen(!isOpen);
      }}
      direction="right"
      className="bla bla bla index-drawer"
      size={isMobile ? "80%" : "450px"}
      zIndex={2000}
    >
      <div
        style={{
          margin: "0px 10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span style={{ fontSize: "20px", marginTop: "5px" }}>
          {currentBoardId?.category?.name === "ST"
            ? "Create Structural Card"
            : "Create Meeting Card"}
        </span>
        <i
          className="bi bi-x"
          style={{
            cursor: "pointer",
            fontSize: "35px",
          }}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        ></i>
      </div>
      {currentBoardId?.category?.name === "ST" ? (
        <AddStructuralCard
          currentBoardId={currentBoardId}
          getTasksList={getTasksList}
          userID={userID}
          users={users}
          isUsersLoading={isUsersLoading}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      ) : (
        <AddMeetingCard
          currentBoardId={currentBoardId}
          getTasksList={getTasksList}
          userID={userID}
          users={users}
          isUsersLoading={isUsersLoading}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          meetBoardId={meetBoardId}
        />
      )}
    </Drawer>
  );
}

export default AddCard;
